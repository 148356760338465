const initialState = {
  beamFormulas: {
    inputs: {
      L: {
        name: 'L',
        text: 'L',
        value: 10,
        description: 'Beam Length',
        unit: 'm'
      },
      E: {
        name: 'E',
        text: 'E',
        value: 210000000,
        description: 'Young modulus',
        unit: 'kPa'
      },
      I: {
        name: 'I',
        text: 'I',
        value: 1.333,
        description: 'Inertia',
        unit: 'm4'
      },
      P: {
        name: 'P',
        text: 'P',
        value: 1000,
        description: 'Ponctual load',
        unit: 'kN'
      },
      a: {
        name: 'a',
        text: 'a',
        value: 10,
        description: 'Distance from A to load application point',
        unit: 'm'
      },
      b: {
        name: 'b',
        text: 'b',
        value: 0,
        description: 'Distance from load application point to B',
        unit: 'm'
      }
    },
    outputs: {
      MA: {
        name: 'MA',
        text: 'MA',
        value: 10000,
        description: 'Moment at A',
        unit: 'kNm'
      },
      dispB: {
        name: 'dispB',
        text: 'dispB',
        value: 0,
        description: 'Displacement at B location',
        unit: 'm'
      }
    }
  }
};

export default initialState;
