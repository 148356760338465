import axios from 'axios';
import fileDownload from 'react-file-download';
import { toast } from 'react-toastify';
import {
  SA_CHANGE_INPUT_VALUE,
  SA_FETCH_DATA
} from './actionTypes';
import { URL } from '../../config/config';
import URL_PATH from '../../config/default';

import beamFormulas from '../../utils/structuralAnalysis/beamFormulas';

const calculation = (inputs) => {
  const MA = beamFormulas.MA(inputs);
  const dispB = beamFormulas.dispB(inputs);
  return {
    MA,
    dispB
  };
};

export function changeInputValue(inputs, sectionType, inputValue, inputName) {
  const newInputs = { ...inputs };
  newInputs[inputName].value = inputValue;

  const {
    MA,
    dispB
  } = calculation(inputs);

  return {
    type: SA_CHANGE_INPUT_VALUE,
    payload: {
      sectionType,
      inputs: {
        value: inputValue,
        name: inputName
      },
      outputs: {
        MA,
        dispB
      }
    }
  };
}

export const fetchSectionData = (sectionType, inputs) => {
  const {
    sectionext,
    sectionint,
    Pext,
    Pint,
    xg,
    yg,
    area,
    ixx,
    iyy,
    ixy
  } = calculation(sectionType, inputs);
  return {
    type: SA_FETCH_DATA,
    payload: {
      outputs: {
        sectionext,
        sectionint,
        Pext,
        Pint,
        xg,
        yg,
        area,
        ixx,
        iyy,
        ixy
      }
    }
  };
};

export const exportToCSV = async (sectionType, sectionValues, outputs) => {
  try {
    const res = await axios.post(`${URL}${URL_PATH.calculs.sectionGeometryCSV}`, { sectionType, sectionValues, outputs });
    fileDownload(res.data, 'geometry.csv');
    toast.success('File downloaded');
  } catch (err) {
    toast.error('No access to the server');
  }
};
