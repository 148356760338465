/* eslint no-underscore-dangle: 0 */
import axios from 'axios';
import fileDownload from 'react-file-download';
import { toast } from 'react-toastify';
import Papa from 'papaparse';
import html2canvas from 'html2canvas';
import {
  ADD_ACTIVE,
  ADD_FIELDS,
  EDIT_FIELDS,
  S3_CHANGE_SECTION_GEOMETRY,
  S3_FETCH_SECTION_DATA,
  S3_SECTION_DATA_CALCULATION,
  S3_OUTPUTS,
  S3_CHANGE_NAME,
  S3_CHANGE_LIST_SELECTION,
  S3_UI_MANAGEMENT,
  S3_ACTIVE_MEMBER,
  S3_REMOVE_FIELDS,
  S3_GET_FIELDS,
  S3_LOAD_PROJECT,
  S3_NEW_PROJECT,
  S3_IMPORT_FROM_CSV,
  S3_FETCH_LOADING,
  S3_SCENE_REFRESH,
  S3_UI_MANAGEMENT_RESET,
  OUTPUTS_LINKS,
  ACTIVE_OBJECT
} from './actionTypes';
import sectionTypesArray from '../../../../utils/sectionGeometry/sectionTypesArray';
import { URL } from '../../../../config/config';
import URL_PATH from '../../../../config/default';

const sceneRefresh = (sceneRefresh) => {
  return (dispatch) => {
    dispatch({
      type: S3_SCENE_REFRESH,
      payload: {
        sceneRefresh
      }
    });
  };
};

const resetUiManagement = () => {
  return (dispatch) => {
    dispatch({
      type: S3_UI_MANAGEMENT_RESET
    });
  };
};

const setActive = (section, active) => {
  return (dispatch) => {
    dispatch({
      type: ADD_ACTIVE,
      payload: {
        section,
        active
      }
    });
  };
};

const addFields = (section, fields) => {
  return (dispatch) => {
    dispatch({
      type: ADD_FIELDS,
      payload: {
        section,
        fields
      }
    });
  };
};

const removeFields = (section, index) => {
  return (dispatch) => {
    dispatch({
      type: S3_REMOVE_FIELDS,
      payload: {
        section,
        index
      }
    });
  };
};

const activeObject = (object) => {
  return (dispatch) => {
    const lists = ['nodes', 'members'];
    const findArrayToReset = lists.find(list => !list.includes(object.clickObjectData.type));
    dispatch({
      type: ACTIVE_OBJECT,
      payload: {
        findArrayToReset,
        object
      }
    });
  };
};

const getFields = (section, fields) => {
  return (dispatch) => {
    dispatch({
      type: S3_GET_FIELDS,
      payload: {
        fields,
        section
      }
    });
  };
};

const editFields = (section, index, fields) => {
  return (dispatch) => {
    dispatch({
      type: EDIT_FIELDS,
      payload: {
        index,
        section,
        fields
      }
    });
  };
};

const changeName = (section, index, inputName) => {
  return (dispatch) => {
    dispatch({
      type: S3_CHANGE_NAME,
      payload: {
        section,
        index,
        inputName
      }
    });
  };
};

const changeListSelection = (section, field, list, index, inputName) => {
  return (dispatch) => {
    dispatch({
      type: S3_CHANGE_LIST_SELECTION,
      payload: {
        section,
        field,
        list,
        index,
        inputName
      }
    });
  };
};

const changeGeometry = (inputs, sectionType, inputValue, inputName) => {
  const newInputs = { ...inputs, [inputName]: { ...inputs[inputName], value: inputValue } };
  const {
    sectionext,
    sectionint
  } = sectionTypesArray(sectionType, newInputs);
  return {
    type: S3_CHANGE_SECTION_GEOMETRY,
    payload: {
      sectionType,
      value: Number(inputValue),
      name: inputName,
      sectionext,
      sectionint
    }
  };
};

const fetchSectionData = (sectionType, geometry) => {
  const {
    sectionext,
    sectionint
  } = sectionTypesArray(sectionType, geometry);

  return {
    type: S3_FETCH_SECTION_DATA,
    payload: {
      sectionType,
      sectionext,
      sectionint
    }
  };
};

const fetchStructure3DProject = (projectId) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: S3_FETCH_LOADING,
        payload: true
      });
      if (projectId === 'new') {
        dispatch({ type: S3_NEW_PROJECT });
        dispatch({
          type: S3_FETCH_LOADING,
          payload: false
        });
      } else {
        const res = await axios.get(`${URL}${URL_PATH.structure3D.project}/${projectId}`);
        console.log('REPONSE', res.data);
        let projectData = { ...res.data };
        if (projectData.memberLoads.value.length <= 0) {
          projectData = {
            ...projectData,
            memberLoads: {
              ...projectData.memberLoads,
              value: [{
                name: 'MemberLoad0',
                label: 'MemberLoad0',
                value: 'MemberLoad0',
                member: projectData.members.value[0].name,
                forceX: 0,
                forceY: 0,
                forceZ: 0,
                momentX: 0,
                momentY: 0,
                momentZ: 0
              }]
            }
          };
        }

        dispatch({
          type: S3_LOAD_PROJECT,
          payload: projectData
        });
        dispatch({
          type: S3_FETCH_LOADING,
          payload: false
        });
      }
    } catch (err) {
      console.log('SECTION DATA', err);
    }
  };
};

const sectionDataCalculation = (sectionType, inputs, structure3D) => {
  // let sectionGeometry = {};
  // if (sectionType === 'editSection') {
  //   sectionGeometry = {
  //     unit: inputs.characteristics.unit.name,
  //     sectionext: inputs.characteristics.sectionext,
  //     sectionint: inputs.characteristics.sectionint
  //     // unit: inputs.unit.name,
  //     // sectionext: inputs.sectionext,
  //     // sectionint: inputs.sectionint
  //   };
  // } else {
  //   sectionGeometry = {
  //     unit: inputs.unit.name,
  //     sectionext: inputs.sectionext,
  //     sectionint: inputs.sectionint
  //   };
  // }

  const sectionGeometry = {
    unit: structure3D.sections.listSectionTypes.unit.name,
    sectionext: structure3D.sections.listSectionTypes.sectionext,
    sectionint: structure3D.sections.listSectionTypes.sectionint
  };
  return async (dispatch) => {
    try {
      const res = await axios.post(`${URL}${URL_PATH.calculs.sectiondata}`, sectionGeometry);
      console.log(res)
      dispatch({
        type: S3_SECTION_DATA_CALCULATION,
        payload: {
          msg: res.data.msg,
          data: res.data,
          sectionType,
          inputs,
          structure3D,
          sectionext: sectionGeometry.sectionext,
          sectionint: sectionGeometry.sectionint
        }
      });
    } catch (err) {
      console.log('SECTION DATA', err);
    }
  };
};

function saveProject(project) {
  return async (dispatch) => {
    try {
      const user = localStorage.getItem('user');
      const attachedStructure3DToUser = { ...project, userId: JSON.parse(user)._id };
      const res = await axios.post(`${URL}${URL_PATH.structure3D.project}`, attachedStructure3DToUser);
      dispatch({
        type: S3_LOAD_PROJECT,
        payload: res.data
      });
      toast.success('Your project has been saved', {
        autoClose: 2000
      });
      return res.data._id;
    } catch (error) {
      toast.error(error, {
        autoClose: 2000
      });
      return null;
    }
  };
}

const linearAnalysis = (structure3DInputs, user) => {
  return async (dispatch) => {
    dispatch({
      type: OUTPUTS_LINKS,
      payload: {
        loading: true
      }
    });
    try {
      const attachedStructure3DToUser = { ...structure3DInputs, userId: user._id, _id: structure3DInputs._id };
      await axios.post(`${URL}${URL_PATH.structure3D.project}`, attachedStructure3DToUser);
      const res = await axios.post(`${URL}${URL_PATH.structure3D.linearanalysis}`, structure3DInputs);

      const {
        _id, status, logs, nodeDisplacements, reactions, localForces
      } = res.data.analysis;
      if (status === 'OK') {
        dispatch({
          type: OUTPUTS_LINKS,
          payload: {
            loading: false,
            link: `/structure3D/projects/${structure3DInputs._id}/output/${_id}`,
            error: false
          }
        });
        dispatch({
          type: S3_OUTPUTS,
          payload: {
            logs,
            nodeDisplacements,
            reactions,
            localForces
          }
        });
      // const validation = await axios.post(`${URL}${URL_PATH.structure3D.modelValidation}`, structure3DInputs);
      // console.log('VALIDATION', validation);
      // if(validation.status === 'OK') {
      //   const res = await axios.post(`${URL}${URL_PATH.structure3D.linearanalysis}`, structure3DInputs);
      //   const {
      //     _id, status, logs, nodeDisplacements, reactions, localForces
      //   } = res.data.analysis;
      //   if (status === 'OK') {
      //     dispatch({
      //       type: OUTPUTS_LINKS,
      //       payload: {
      //         loading: false,
      //         link: `/structure3D/projects/${structure3DInputs._id}/output/${_id}`,
      //         error: false
      //       }
      //     });
      //     dispatch({
      //       type: S3_OUTPUTS,
      //       payload: {
      //         logs,
      //         nodeDisplacements,
      //         reactions,
      //         localForces
      //       }
      //     });
      //   } else {
      //     dispatch({
      //       type: OUTPUTS_LINKS,
      //       payload: {
      //         loading: false,
      //         link: `/structure3D/projects/${structure3DInputs._id}/logs`,
      //         error: true,
      //         logs
      //       }
      //     });
      //   }   
      } else {
        dispatch({
          type: OUTPUTS_LINKS,
          payload: {
            loading: false,
            link: `/structure3D/projects/${structure3DInputs._id}/logs`,
            error: true,
            logs: logs
          }
        });
      }
      

      const userCalculationCounter = await axios.post(`${URL}${URL_PATH.payments.calculationCounter}`, user);
      const uCalculationCounter = userCalculationCounter.data.calculationCounter;
      const userUpdated = { ...user, calculationCounter: uCalculationCounter };
      localStorage.setItem('user', JSON.stringify(userUpdated));
      // history.push(`${structure3DInputs._id}/output/${_id}`);
    } catch (error) {
      dispatch({
        type: OUTPUTS_LINKS,
        payload: {
          loading: false
        }
      });
    }
  };
};

const takeScreenShot = async () => {
  const canvas = await html2canvas(document.querySelector('#structure3D'));
  return canvas.toDataURL();
};

const getOutput = (projectId, outputId) => {
  return async (dispatch) => {
    try {
      const res = await axios.get(`${URL}${URL_PATH.structure3D.project}/${projectId}/${URL_PATH.structure3D.output}/${outputId}`);
      const {
        logs, nodeDisplacements, reactions, localForces
      } = res.data;
      dispatch({
        type: S3_OUTPUTS,
        payload: {
          logs,
          nodeDisplacements,
          reactions,
          localForces
        }
      });
    } catch (error) {
      console.log('STRUCTURE 3D ERROR', error);
    }
  };
};

const graphicalUIManagement = (command, bool) => {
  return (dispatch) => {
    dispatch({
      type: S3_UI_MANAGEMENT,
      payload: {
        command,
        bool: !(bool)
      }
    });
  };
};

const showActiveMember = (value, localForces) => {
  const member = localForces.find(item => item.Name === value);
  return (dispatch) => {
    dispatch({
      type: S3_ACTIVE_MEMBER,
      payload: {
        member
      }
    });
  };
};

const exportToCSV = async (dataType, outputs) => {
  try {
    // const fileLocation = path.basename('/Users/guill/Downloads/nodeDisplacements (11).csv');
    // const data = await importFromCSV('/Users/guill/Downloads/boston-housing-train.csv');
    // const data = await importFromCSV(BOSTON_HOUSING_CSV_URL);
    // const data = await importFromCSV('/Users/guill/Downloads/nodeDisplacements (11).csv');
    // console.log(data);
    const res = await axios.post(`${URL}${URL_PATH.structure3D.structure3DCSV}`, { dataType, outputs });
    fileDownload(res.data, `${dataType}.csv`);
    toast.success('File downloaded');
  } catch (err) {
    toast.error('No access to the server');
  }
};

const importFromCSV = (file, dataType) => {
  return (dispatch) => {
    const reader = new FileReader();
    reader.readAsText(file);
    reader.onload = async (evt) => {
      const csvData = evt.target.result;
      const res = await Papa.parse(csvData, {
        header: true,
        dynamicTyping: true,
        transform: (value) => {
          return value.replace(/\./g, '').replace(',', '.');
        }
      });

      const inputs = res.data.map((item) => {
        const updateItem = { ...item, label: item.name, value: item.name };
        return updateItem;
      });

      await dispatch({
        type: S3_IMPORT_FROM_CSV,
        payload: {
          dataType,
          inputs
        }
      });
    };
    reader.onerror = function () {
      alert('Wrong');
    };
  };
};

// const importFromCSV = (file, dataType) => {
//   return (dispatch) => {
//     new Promise((resolve, reject) => {
//       const reader = new FileReader();
//       reader.onloadend = () => { resolve(reader.result); };
//       reader.readAsText(file);
//     }).then(res => {
//       console.log(res.data);
//       dispatch({
//         type: S3_IMPORT_FROM_CSV,
//         payload: {
//           dataType,
//           inputs: res.data
//         }
//     }))};
// };

// const importFromCSV = async (url) => {
//   const jsonArray = [];
//   Papa.parse(url, {
//     download: true,
//     header: true, // Keys data by field name rather than an array.
//     dynamicTyping: true, // Turns numeric data into numbers and true/false into booleans.
//     complete: (results) => {
//       results.data.map((value) => {
//         jsonArray.push(value);
//       });
//     }
//   });

//   // const jsonArray = await csv().fromFile(url);
//   return jsonArray;
// };

export {
  setActive,
  activeObject,
  editFields,
  addFields,
  getFields,
  removeFields,
  changeName,
  changeListSelection,
  fetchSectionData,
  changeGeometry,
  sectionDataCalculation,
  linearAnalysis,
  graphicalUIManagement,
  showActiveMember,
  fetchStructure3DProject,
  exportToCSV,
  importFromCSV,
  saveProject,
  getOutput,
  sceneRefresh,
  takeScreenShot,
  resetUiManagement
};
