import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  Row,
  Col,
  Label,
  Card
} from 'reactstrap';
import CardApp from '../../../../../../components/UIComponents/Card/CardApp';
import Modal from '../../../../../../components/UIComponents/Modal';
import TriAzurIcon from '../../img/TriAzurIcon.png';
import EiffageIcon from '../../img/EiffageIcon.jpg';
// import ApplicationRestrictions from '../../../../../../components/HOC/ApplicationAuthorization';
import RegressionSvg from '../../img/Regression.svg';
import { showVideo } from '../../../../actions';

const { REACT_APP_COMPANY } = process.env;

const applicationsList = [
  {
    imgsrc: RegressionSvg,
    title: 'Regression',
    subtitle: 'AI',
    description: 'Regression is the task of approximating a mapping function (f) from input variables (X) to a continuous output variable (y)',
    link: '/regression',
    status: 'Beta',
    logoCompany: TriAzurIcon,
    company: 'TriAzur',
    video: ''
  }
];

const AIApp = ({
  appGroupTitle,
  colnum,
  showVideo
}) => (
  <div className="structureapp">
    <Row>
      <h4 className="appgrouptitle">{appGroupTitle}</h4>
    </Row>
    <Row>
      { applicationsList.map((app, index) => (
        <CardApp
          key={index}
          colnum={colnum}
          imgsrc={app.imgsrc}
          title={app.title}
          subtitle={app.subtitle}
          description={app.description}
          link={app.link}
          status={app.status}
          logoCompany={app.logoCompany}
          company={app.company}
          video={app.video}
          openModal={showVideo}
        />
      ))}
    </Row>
  </div>
);

AIApp.propTypes = {
  appGroupTitle: PropTypes.string.isRequired,
  colnum: PropTypes.number.isRequired
};

const mapStateToProps = (state) => {
  return {
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({
    showVideo
  }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(AIApp);
