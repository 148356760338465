import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { FieldArray, reduxForm } from 'redux-form';
import { compose } from 'recompose';
import {
  Container,
  Col,
  Row,
  Table,
  Button,
  ButtonGroup,
  Label
} from 'reactstrap';
import { Scatter } from 'react-chartjs-2';
import InputField from '../../../../../../../../../../components/UIComponents/Fields/InputRF';
import InputsUnit from '../../../../../../../../../../components/UIComponents/Fields/InputsUnit2';
import SelectRF from '../../../../../../../../../../components/UIComponents/Fields/SelectRF';
import {
  addFields,
  setActive,
  changeGeometry,
  fetchSectionData,
  sectionDataCalculation
} from '../../../../../../actions';


import chartOptions from './utils/chartOptions';
import chartData from './utils/chartData';

import './styles/Geometry.css';

import RandomSection from './scenes/RandomSection';
import RectangularSection from './scenes/RectangularSection';
import TSection from './scenes/TSection';
import ISection from './scenes/ISection';
import CircleSection from './scenes/CircleSection';
import HollowCircleSection from './scenes/HollowCircleSection';

class Geometry extends React.Component {
  constructor(props) {
    super(props);
    // this.addSec = this.addSec.bind(this);
    this.state = {
    };
  }
  
  componentDidMount() {
    const { fetchSectionData, sectionType, structure3D } = this.props;
    if (sectionType === 'editSection') {
      for (let i = 0; i < structure3D.sections.value.length; i += 1) {
        if (structure3D.sections.active === structure3D.sections.value[i].name) {
          const geometry = {
            sectionext: structure3D.sections.value[i].characteristics.sectionext,
            sectionint: structure3D.sections.value[i].characteristics.sectionint
          };
          fetchSectionData(sectionType, geometry);
        } else {
          console.log('ERROR SECTION DOES NOT EXIST');
        }
      }
    } else {
      fetchSectionData(sectionType, structure3D.sections.listSectionTypes[sectionType].inputs);
    }
  }

  render() {
    const {
      structure3D,
      changeView,
      addFields,
      setActive,
      changeGeometry,
      sectionDataCalculation,
      changeSectionType,
      sectionType,
      fields
      // geometry,
      // fields
    } = this.props;
    console.log('SECTIONS', structure3D.sections);
    return (
      <Container className="geometry">
        <Row>
          <Col md={12}>
            <div className="chartContainer">
              <Scatter
                className="chart"
                width={400}
                height={400}
                data={chartData(structure3D.sections.listSectionTypes.sectionext, structure3D.sections.listSectionTypes.sectionint)}
                options={chartOptions(structure3D.sections.listSectionTypes.sectionext, structure3D.sections.listSectionTypes.unit.text)}
              />
            </div>
          </Col>
        </Row>
        <Row>
          <Col md={12}>
            <Label>Section dimensions</Label>
            {sectionType === 'rectangularSection'
              && <RectangularSection
                sectionType={sectionType}
                sections={structure3D.sections}
                changeGeometry={changeGeometry}
              />}
            {sectionType === 'tSection'
              && <TSection
                sectionType={sectionType}
                sections={structure3D.sections}
                changeGeometry={changeGeometry}
              />}
            {sectionType === 'iSection'
              && <ISection
                sectionType={sectionType}
                sections={structure3D.sections}
                changeGeometry={changeGeometry}
              />}
            {sectionType === 'circleSection'
              && <CircleSection
                sectionType={sectionType}
                sections={structure3D.sections}
                changeGeometry={changeGeometry}
              />}
            {sectionType === 'hollowCircleSection'
              && <HollowCircleSection
                sectionType={sectionType}
                sections={structure3D.sections}
                changeGeometry={changeGeometry}
              />}
            {sectionType === 'randomSection'
              && <RandomSection
                sectionType={sectionType}
                sections={structure3D.sections}
                changeGeometry={changeGeometry}
                unit={structure3D.sections.listSectionTypes.unit}
                fields={fields}
              />}
            {sectionType === 'editSection'
              && <RandomSection
                sectionType={sectionType}
                sections={structure3D.sections}
                changeGeometry={changeGeometry}
                unit={structure3D.sections.listSectionTypes.unit}
                fields={fields}
              />}
          </Col>
        </Row>
        <br />
        <Row>
          <Col xs={4} className="align-center">
            <Button
              className="negativeButton"
              outline
              color="primary"
              onClick={() => {
                if (sectionType === 'editSection') {
                  changeView('Select');
                } else {
                  changeView('SectionTypes');
                }
              }}
            >
              {'Back'}
            </Button>
          </Col>
          <Col xs={4} className="align-center" />
          <Col xs={4} className="align-center">
            <Button
              className="negativeButton"
              outline
              color="primary"
              onClick={() => {
                if (sectionType === 'editSection') {
                  for (let i = 0; i < structure3D.sections.value.length; i += 1) {
                    if (structure3D.sections.active === structure3D.sections.value[i].name) {
                      sectionDataCalculation(
                        sectionType,
                        structure3D.sections.value[i],
                        structure3D
                      );
                    } else {
                      console.log('ERROR SECTION DOES NOT EXIST');
                    }
                  }
                } else {
                  sectionDataCalculation(
                    sectionType,
                    structure3D.sections.listSectionTypes,
                    structure3D
                  );
                }
                changeView('SectionData');
              }}
              // onClick={this.addSec}
            >
              { sectionType === 'editSection' ? 'Edit' : 'Add' }
            </Button>
          </Col>
        </Row>
      </Container>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({
    addFields,
    setActive,
    changeGeometry,
    fetchSectionData,
    sectionDataCalculation
  }, dispatch);
};

// export default compose(
//   connect(null, mapDispatchToProps),
//   reduxForm({
//     destroyOnUnmount: false,
//     asyncBlurFields: []
//   }),
// )(Geometry);

export default connect(null, mapDispatchToProps)(Geometry);
