const initialState = {
  rectangularSection: {
    inputs: {
      unit: 'm',
      b: {
        name: 'b',
        text: 'b',
        value: 2,
        description: 'Section Width',
        unit: 'm'
      },
      h: {
        name: 'h',
        text: 'h',
        value: 2,
        description: 'Section Height',
        unit: 'm'
      }
    }
  },
  tSection: {
    inputs: {
      unit: 'm',
      b: {
        name: 'b',
        text: 'b',
        value: 2,
        description: 'Section Width',
        unit: 'm'
      },
      h: {
        name: 'h',
        text: 'h',
        value: 2,
        description: 'Section Height',
        unit: 'm'
      },
      b0: {
        name: 'b0',
        text: 'b0',
        value: 0.5,
        description: 'Section Web Width',
        unit: 'm'
      },
      h0: {
        name: 'h0',
        text: 'h0',
        value: 0.5,
        description: 'Height of the top flange of the section',
        unit: 'm'
      }
    }
  },
  iSection: {
    inputs: {
      unit: 'm',
      b: {
        name: 'b',
        text: 'b',
        value: 0.5,
        description: 'Section Width',
        unit: 'm'
      },
      h: {
        name: 'h',
        text: 'h',
        value: 2,
        description: 'Section Height',
        unit: 'm'
      },
      b0: {
        name: 'b0',
        text: 'b0',
        value: 2,
        description: 'Width of the top flange of the section',
        unit: 'm'
      },
      h0: {
        name: 'h0',
        text: 'h0',
        value: 0.5,
        description: 'Height of the top flange of the section',
        unit: 'm'
      },
      b1: {
        name: 'b1',
        text: 'b1',
        value: 1,
        description: 'Width of the bottom flange of the section',
        unit: 'm'
      },
      h1: {
        name: 'h1',
        text: 'h1',
        value: 0.5,
        description: 'Height of the bottom flange of the section',
        unit: 'm'
      }
    }
  },
  circleSection: {
    inputs: {
      unit: 'm',
      D: {
        name: 'D',
        text: 'D',
        value: 1,
        description: 'Diameter of the section',
        unit: 'm'
      }
    }
  },
  hollowCircleSection: {
    inputs: {
      unit: 'm',
      D: {
        name: 'D',
        text: 'D',
        value: 1,
        description: 'Diameter of the section',
        unit: 'm'
      },
      t: {
        name: 't',
        text: 't',
        value: 0.1,
        description: 'Thickness of the section',
        unit: 'm'
      }
    }
  },
  outputs: {
    sectionext: [
      { x: 0, y: 0 }
    ],
    sectionint: [
      { x: 0, y: 0 }
    ],
    xg: {
      name: 'xg',
      text: 'xg',
      value: 1,
      description: 'Section center of gravity x coordinate',
      unit: 'm'
    },
    yg: {
      name: 'yg',
      text: 'yg',
      value: 1.5,
      description: 'Section center of gravity y coordinate',
      unit: 'm'
    },
    area: {
      name: 'area',
      text: 'Area',
      value: 1.5,
      description: 'Area of the Section',
      unit: 'm2'
    },
    Pext: {
      name: 'Pext',
      text: 'Pext',
      value: 1.5,
      description: 'Outer Perimeter',
      unit: 'm'
    },
    Pint: {
      name: 'Pint',
      text: 'Pint',
      value: 1.5,
      description: 'Inside Perimeter',
      unit: 'm'
    },
    ixx: {
      name: 'ixx',
      text: 'Ixx',
      value: 1.5,
      description: 'Inertia at the Center of Gravity and related to x axis of the section',
      unit: 'm4'
    },
    iyy: {
      name: 'iyy',
      text: 'Iyy',
      value: 1.5,
      description: 'Inertia at the Center of Gravity and related to y axis of the section',
      unit: 'm4'
    }
  }
};

export default initialState;
