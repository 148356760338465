import React from 'react';
import { FormGroup, Input } from 'reactstrap';
import PropTypes from 'prop-types';

// const Select = ({
//   onChange,
//   name,
//   selectedOption,
//   placeholder,
//   list
// }) => (
//   <FormGroup>
//     <select
//       name={name}
//       onChange={onChange}
//       placeholder={placeholder}
//       value={selectedOption}
//     >
//       {
//         list.map(item => (
//           <option
//             key={item.value}
//             value={item.value}
//           >
//             {item.label}
//           </option>
//         ))
//       }
//     </select>
//   </FormGroup>
// );

const Select = ({
  onChange,
  name,
  selectedOption,
  value,
  // placeholder,
  defaultValue,
  list
}) => (
  <Input
    type="select"
    name={name}
    onChange={onChange}
    // selectedOption={selectedOption}
    // placeholder={placeholder}
    defaultValue={defaultValue}
    value={value}
  >
    {
      list.map(item => (
        <option
          key={JSON.stringify(item.value)}
          value={item.label}
          data={JSON.stringify(item.value)}
        >
          {item.label}
        </option>
      ))
    }
  </Input>
);

Select.propTypes = {
  name: PropTypes.string.isRequired,
  defaultValue: PropTypes.string.isRequired,
  // selectedOption: PropTypes.string.isRequired,
  // placeholder: PropTypes.string.isRequired,
  list: PropTypes.arrayOf(PropTypes.object).isRequired,
  onChange: PropTypes.func.isRequired
};

export default Select;
