import React from 'react';
import {
  Container,
  Breadcrumb,
  BreadcrumbItem,
  Card,
  CardBody,
  CardTitle,
  Button,
  Row,
  Col
} from 'reactstrap';
import InputCalc from '../../../../../../components/UIComponents/Fields/InputCalc';

const Inputs = ({
  inputs,
  changeInputValue,
  optionsLengthUnits,
  optionsStressUnits,
  optionsInertiaUnits,
  optionsForceUnits
}) => {
  return (
    <div>
      <InputCalc
        input={inputs.L}
        inputs={inputs}
        changeInput={changeInputValue}
        unitList={optionsLengthUnits}
      />
      <InputCalc
        input={inputs.E}
        inputs={inputs}
        changeInput={changeInputValue}
        unitList={optionsStressUnits}
      />
      <InputCalc
        input={inputs.I}
        inputs={inputs}
        changeInput={changeInputValue}
        unitList={optionsInertiaUnits}
      />
      <InputCalc
        input={inputs.P}
        inputs={inputs}
        changeInput={changeInputValue}
        unitList={optionsForceUnits}
      />
      <InputCalc
        input={inputs.a}
        inputs={inputs}
        changeInput={changeInputValue}
        unitList={optionsLengthUnits}
      />
      <InputCalc
        input={inputs.b}
        inputs={inputs}
        changeInput={changeInputValue}
        unitList={optionsLengthUnits}
      />
    </div>
  );
};

export default Inputs;
