import React from 'react';
import { Link } from 'react-router-dom';
import {
  Breadcrumb,
  BreadcrumbItem,
  Button,
  CardDeck
} from 'reactstrap';
import CardAddProject from '../../../../components/UIComponents/Card/CardAddProject';

import Title from '../../../../components/UIComponents/Title';
import ConcreteSectionSvg from './img/ConcreteSection.svg';
import RectangularconcretesectionSvg from './img/rectangularconcretesection.svg';
import TconcretesectionSvg from './img/Tconcretesection.svg';
import IconcretesectionSvg from './img/Iconcretesection.svg';
import CircleconcretesectionSvg from './img/circleconcretesection.svg';
import HollowcircleconcretesectionSvg from './img/hollowcircleconcretesection.svg';
import RandomconcretesectionSvg from './img/randomconcretesection.svg';
import { LoadableConcreteSection } from '../../../ConcreteSection';

const SectionTypes = () => (
  <LoadableConcreteSection>
    <Title
      title="Section Types"
      imgsrc={ConcreteSectionSvg}
      description="Select the geometry of the section you want to design"
    />
    <Breadcrumb>
      <BreadcrumbItem><Link to="/dashboard">Dashboard</Link></BreadcrumbItem>
      <BreadcrumbItem><Link to="/concretesection">Concrete Section</Link></BreadcrumbItem>
      {/* <BreadcrumbItem active>Section Types</BreadcrumbItem> */}
    </Breadcrumb>
    <CardDeck className="projects">
      <CardAddProject
        colnum={3}
        imgsrc={RectangularconcretesectionSvg}
        link="/concretesection/rectangularSection"
      />
      <CardAddProject
        colnum={3}
        imgsrc={TconcretesectionSvg}
        link="/concretesection/tSection"
      />
      <CardAddProject
        colnum={3}
        imgsrc={IconcretesectionSvg}
        link="/concretesection/iSection"
      />
      <CardAddProject
        colnum={3}
        imgsrc={CircleconcretesectionSvg}
        link="/concretesection/circleSection"
      />
      <CardAddProject
        colnum={3}
        imgsrc={HollowcircleconcretesectionSvg}
        link="/concretesection/hollowCircleSection"
      />
      <CardAddProject
        colnum={3}
        imgsrc={RandomconcretesectionSvg}
        link="/concretesection/randomSection"
      />
    </CardDeck>
  </LoadableConcreteSection>
);

export default SectionTypes;
