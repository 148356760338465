import React, { useState, useEffect, memo } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import Papa from 'papaparse';
import Draggable from 'react-draggable';
import {
  Row,
  Col,
  Label,
  Card,
  CardHeader,
  CardBody,
  Button,
  Input
} from 'reactstrap';
import { FieldArray } from 'redux-form';
import Materials from './scenes/Materials';
import Sections from './scenes/Sections';
import Supports from './scenes/Supports';
import Nodes from './scenes/Nodes';
import Members from './scenes/Members';
import NodeLoads from './scenes/NodeLoads';
import MemberLoads from './scenes/MemberLoads';
import {
  importFromCSV
} from '../../actions';
import { resolve } from 'path';

const Inputs = ({
  name,
  structure3D,
  tableLarge,
  extendTable,
  onDisplayInputsData,
  startRefreshScene,
  refreshScene,
  uiManagement,
  // displayInputsData,
  // onDisplayInputsData,
  importFromCSV
}) => {
  //const [active, setActive] = useState(displayInputsData);

  return (
    <Draggable
      enableUserSelectHack={false}
      defaultPosition={{ x: 0, y: 0 }}
      bounds='.sceneThreejs'
      cancel=".input-form"
    >
      <Card className='input-card' id="card">
        <CardHeader id="headerinputs" className="handle">
          <Row>
            <Col xs={2} />
            <Col xs={8}>
              <Label className="headerCard">{structure3D[name].text}</Label>
            </Col>
            <Col xs={2}>
              <Row>
                <div className="controlCard">
                  <i
                    className="material-icons"
                    onClick={() => 
                      // extendTable(tableLarge)
                      onDisplayInputsData(false)
                    }
                  >
                    {'clear'}
                  </i>
                </div> 
                {/*<div className="controlCard">
                  <i
                    className="material-icons"
                    //onClick={() => 
                      // extendTable(tableLarge)
                      //onDisplayInputsData()
                    //}
                  >
                    {tableLarge === true ? 'crop' : 'clear'}
                  </i>
                </div>*/}            
              </Row>
            </Col>
          </Row>
        </CardHeader>
        <CardBody>
          {(name === 'nodes' || name === 'members' || name === 'nodeLoads' || name === 'memberLoads') &&
            <div>
              <Row>
                <Col xs={4}>
                  <Input
                    type="file"
                    name="File Upload"
                    id="txtFileUpload"
                    accept=".csv"
                    onChange={(e) => {
                      const file = e.target.files[0];
                      importFromCSV(file, name);
                    }}
                  />
                </Col>
                <Col xs={4} />
                <Col xs={4} className="align-center">
                  <Button
                    className="negativeButton"
                    outline
                    color="primary"
                    onClick={() => {
                      if(name === 'nodes') {
                        if(uiManagement['showNodeLabel']) {
                          refreshScene('showNodeLabel', uiManagement['showNodeLabel']);
                        } else {
                          startRefreshScene();
                        }
                      }
                      if(name === 'members') {
                        if(uiManagement['showMemberLabel']) {
                          refreshScene('showMemberLabel', uiManagement['showMemberLabel']);
                        } else {
                          startRefreshScene();
                        }
                      }
                      if(name === 'nodeLoads') {
                        if(!uiManagement['showNodeLoad']) {
                          refreshScene('showNodeLoad', uiManagement['showNodeLoad']);
                          refreshScene('showNodeLoadLabel', uiManagement['showNodeLoadLabel']);
                        } else {
                          startRefreshScene();
                        }
                      }
                      if(name === 'memberLoads') {
                        if(!uiManagement['showMemberLoad']) {
                          refreshScene('showMemberLoad', uiManagement['showMemberLoad']);
                          refreshScene('showMemberLoadLabel', uiManagement['showMemberLoadLabel']);
                        } else {
                          startRefreshScene();
                        }
                      }
                    }}
                  >
                    {'Apply'}
                  </Button>
                </Col>
              </Row>
              <br />
            </div>
          }
          <Row>
            <Col md={12} className="input-form">
              {name === 'materials'
                && <Materials
                  name={`${name}`}
                  active={structure3D[name].active}
                  inputs={structure3D[name]}
                />
              }
              {name === 'sections'
                && <Sections
                  name={`${name}.value`}
                  active={structure3D[name].active}
                  structure3D={structure3D}
                  sectionList={structure3D[name].value}
                  startRefreshScene={startRefreshScene}
                />
              }
              {name === 'supports'
                && <Supports
                  name={`${name}`}
                  active={structure3D[name].active}
                  inputs={structure3D[name]}
                  startRefreshScene={startRefreshScene}
                />
              }
              {name === 'nodes'
                && <FieldArray
                  name={`${name}.value`}
                  unit={structure3D[name].unit}
                  supportsList={structure3D.supports.value}
                  component={Nodes}
                />
              }
              {name === 'members'
                && <FieldArray
                  name={`${name}.value`}
                  sectionsList={structure3D.sections.value}
                  nodesList={structure3D.nodes.value}
                  component={Members}
                />
              }
              {name === 'nodeLoads'
                && <FieldArray
                  name={`${name}.value`}
                  unit={structure3D[name].unit}
                  nodesList={structure3D.nodes.value}
                  component={NodeLoads}
                />
              }
              {name === 'memberLoads'
                && <FieldArray
                  name={`${name}.value`}
                  unit={structure3D[name].unit}
                  membersList={structure3D.members.value}
                  component={MemberLoads}
                />
              }
            </Col>
          </Row>
          {/* <Link to='/structure3d#edit'>Edit</Link>
          <Link to='/structure3d#add'>Add</Link> */}
        </CardBody>
      </Card>

    </Draggable>
  );
};

Inputs.propTypes = {
  // action: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired
};

const mapStateToProps = (state) => {
  return {
    structure3D: state.form.structure3D.inputs.values,
    uiManagement: state.form.structure3D.inputs.ui
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({
    importFromCSV
  }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(Inputs);
