import React, { Component } from 'react';
import {
  Card,
  CardHeader,
  CardBody,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Button,
  CardTitle,
  CardText,
  Row,
  Col,
  Container,
  Badge
} from 'reactstrap';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { compose } from 'recompose';
import HorizontalScrollMenu from '../../../../../../components/UIComponents/Bar/HorizontalScrollMenu';

/*import ResultsSls from './scenes/ResultsSls';
import ResultsUls from './scenes/ResultsUls';
import InteractionDiagramUls from './scenes/InteractionDiagramUls';*/
import SectionData from './scenes/SectionData';
import Bending from './scenes/Bending';
import Shear from './scenes/Shear';
import Torsion from './scenes/Torsion';

const itemScrollMenu = [
  {
    num: '1',
    icon: 'view_quilt',
    title: 'Section Data',
    status: 'Beta'
  },
  {
    num: '2',
    icon: 'list_alt',
    title: 'Bending',
    status: 'Beta'
  },
  {
    num: '3',
    icon: 'toll',
    title: 'Shear',
    status: 'Beta'
  },
  {
    num: '4',
    icon: 'polymer',
    title: 'Torsion',
    status: 'Beta'
  }
];

class Outputs extends React.Component {
  constructor(props) {
    super(props);
    this.toggle = this.toggle.bind(this);
    this.state = {
      activeTab: '1'
    };
  }

  toggle(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab
      });
    }
  }

  render() {
    const {
      outputs,
      steelSection
    } = this.props;
    console.log('OUTPUTS', outputs);
    return (
      <div className="outputs">
        <Card id="card">
          <CardHeader id="headerinputs">
            {'Outputs Steel Section'}
          </CardHeader>
          <HorizontalScrollMenu
            toggle={this.toggle}
            activeTab={this.state.activeTab}
            itemScrollMenu={itemScrollMenu}
          />
          <TabContent
            id="sectionsDataOptions"
            activeTab={this.state.activeTab}
          >
            <TabPane tabId="1">
              <SectionData
                sectionData={outputs.sectionData}
                steel={steelSection.geometry.steel}
              />
            </TabPane>
            <TabPane tabId="2">
              <Bending
                bending={outputs.bending}
                steel={steelSection.geometry.steel}
              />
            </TabPane>
            <TabPane tabId="3">
              <Shear
                shear={outputs.shear}
                steel={steelSection.geometry.steel}
              />
            </TabPane>
            <TabPane tabId="4">
              <Torsion
                torsion={outputs.torsion}
                steel={steelSection.geometry.steel}
              />
            </TabPane>
          </TabContent>
        </Card>

      </div>
    );
  }
}

const mapStateToProps = (state, props) => {
  const { sectionType } = props.match.params;
  return {
    form: `steelSection.${sectionType}`,
    steelSection: state.form.steelSection[sectionType].values,
    sectionType,
    outputs: state.form.steelSection.outputs
  };
};

export default compose(
  withRouter,
  connect(mapStateToProps)
)(Outputs);
