import React from 'react';
import { Link } from 'react-router-dom';
import {
  Breadcrumb,
  BreadcrumbItem,
  Button,
  CardDeck
} from 'reactstrap';
import CardAddProject from '../../../../components/UIComponents/Card/CardAddProject';

import Title from '../../../../components/UIComponents/Title';
import SteelSectionSvg from './img/SteelSection.svg';
import ISteelSectionSvg from './img/Isteelsection.svg';
import BoxSteelSectionSvg from './img/Boxsteelsection.svg';
import { LoadableSteelSection } from '../../../SteelSection';

const SectionTypes = () => (
  <LoadableSteelSection>
    <Title
      title="Section Types"
      imgsrc={SteelSectionSvg}
      description="Select the geometry of the section you want to design"
    />
    <Breadcrumb>
      <BreadcrumbItem><Link to="/dashboard">Dashboard</Link></BreadcrumbItem>
      <BreadcrumbItem><Link to="/steelsection">Steel Section</Link></BreadcrumbItem>
    </Breadcrumb>
    <CardDeck className="projects">
      <CardAddProject
        colnum={3}
        imgsrc={ISteelSectionSvg}
        link="/steelsection/iSectionSteel"
      />
      <CardAddProject
        colnum={3}
        imgsrc={BoxSteelSectionSvg}
        link="/steelsection/boxSection"
      />
    </CardDeck>
  </LoadableSteelSection>
);

export default SectionTypes;
