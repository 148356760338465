const optionsUnits = [{
  label: 'm-kN-kg',
  value: {
    unitLength: 'm',
    unitArea: 'm²',
    unitVolume: 'm³',
    unitTime: 's',
    unitWeight: 'kg',
    unitForce: 'kN',
    unitMoment: 'kN.m',
    unitStress: 'kPa',
    unitTemperature: '°C'
  }
},
{
  label: 'm-MN-kg',
  value: {
    unitLength: 'm',
    unitArea: 'm²',
    unitVolume: 'm³',
    unitTime: 's',
    unitWeight: 'kg',
    unitForce: 'MN',
    unitMoment: 'MN.m',
    unitStress: 'MPa',
    unitTemperature: '°C'
  }
},
{
  label: 'cm-MN-kg',
  value: {
    unitLength: 'cm',
    unitArea: 'cm²',
    unitVolume: 'cm³',
    unitTime: 's',
    unitWeight: 'kg',
    unitForce: 'MN',
    unitMoment: 'MN.cm',
    unitStress: 'MPa',
    unitTemperature: '°C'
  }
},
{
  label: 'mm-MN-kg',
  value: {
    unitLength: 'mm',
    unitArea: 'mm²',
    unitVolume: 'mm³',
    unitTime: 's',
    unitWeight: 'kg',
    unitForce: 'MN',
    unitMoment: 'MN.mm',
    unitStress: 'MPa',
    unitTemperature: '°C'
  }
},
{
  label: 'inch-kip-kg',
  value: {
    unitLength: 'inch',
    unitArea: 'inch²',
    unitVolume: 'inch³',
    unitTime: 's',
    unitWeight: 'kg',
    unitForce: 'kip',
    unitMoment: 'kip.inch',
    unitStress: 'psi',
    unitTemperature: '°C'
  }
}];

export default optionsUnits;
