import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { openModal } from './actions';

const Modal = ({ children, modal, openModal, size }) => modal.status &&
  <div className='modal'>
    <div className='content' style={{maxWidth: size}}>
      <div className='close-bar'>
        <div className='close-circle' onClick={() => openModal({ status: false })}>
          <i className="material-icons d-inline-block align-top">close</i>
        </div>
      </div>
      {children}
    </div >
    <div className='background' onClick={() => openModal({ status: false })}></div>
  </div >;
  
Modal.propTypes = {
  modal: PropTypes.shape({
    status: PropTypes.bool.isRequired,
  }).isRequired,
  children: PropTypes.node.isRequired,
};


const mapStateToProps = ({ modal }) => {
  return { modal };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ openModal }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(Modal);
