const chartData = (inputs, waterLevel) => {
  // const water = () => {
  //   const arr = [...waterLevel];
  //   arr.push({ x: L, y: -inputs.d.value });
  //   arr.push({ x: 0, y: -inputs.d.value });
  //   arr.push({ x: 0, y: inputs.H.value });

  //   return arr;
  // };

  return {
    labels: ['Scatter'],
    datasets: [{
      label: 'Sea Water Elevation',
      type: 'scatter',
      data: waterLevel,
      backgroundColor: [
        'rgba(0,0,255, 0)'
      ],
      borderColor: [
        'blue'
      ],
      borderWidth: 3,
      lineTension: 0,
      fill: 1
    }
    ]
  };
};

export default chartData;
