import React from 'react';
import PropTypes from 'prop-types';
import {
  Container,
  Table,
  Row,
  Col,
  Input,
  Button,
  ButtonGroup
} from 'reactstrap';
import { Scatter } from 'react-chartjs-2';

import chartOptions from './utils/chartOptions';
import chartData from './utils/chartData';
import OutputCalc from '../../../../../../../../components/UIComponents/Fields/OutputCalc';
import optionsStressUnits from '../../../../../../../../utils/units/optionsStressUnits2';


class ResultsSls extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      graphLarge: 1
    };
  }

  render() {
    const {
      sls,
      concrete,
      reinforcement
    } = this.props;
    return (
      <Container className="geometry">
        <br />
        <div id="scrollmenu">
          <Row>
            <Col md={this.state.graphLarge === 1 ? 6 : 12}>
              <div className="controlChart">
                <i
                  className="material-icons"
                  onClick={() => this.setState({ graphLarge: -this.state.graphLarge })}
                >
                  {this.state.graphLarge === 1 ? 'crop_free' : 'crop'}
                </i>
              </div>
              <div className="chartLargeContainer">
                <Scatter
                  className="chart"
                  width={1000}
                  height={1000}
                  data={chartData(concrete.sectionext, concrete.sectionint, sls)}
                  options={chartOptions(concrete.sectionext, reinforcement.unit, reinforcement.geometry)}
                />
                <br />
              </div>
            </Col>
            <Col md={this.state.graphLarge === 1 ? 6 : 12}>
              <h2 className="subtitle">Stress in Concrete</h2>
              {/* <OutputCalc
                input={sls.resExtremStress.concrete.sigmacmin}
                unitList={optionsStressUnits}
              /> */}
              <OutputCalc
                input={sls.resExtremStress.concrete.sigmacmax}
                unitList={optionsStressUnits}
              />
              <h2 className="subtitle">Stress in Reinforcement</h2>
              <OutputCalc
                input={sls.resExtremStress.steel.sigmasmax}
                unitList={optionsStressUnits}
              />
              <Table responsive>
                <thead>
                  <tr className="column">
                    <th>{`x(${reinforcement.unit.text})`}</th>
                    <th>{`y(${reinforcement.unit.text})`}</th>
                    <th>number</th>
                    <th>{`diameter(${reinforcement.unitDiameter.text})`}</th>
                    <th>{`stress(${sls.resExtremStress.steel.sigmar.unitStress})`}</th>
                  </tr>
                </thead>
                <tbody>
                  {sls.resExtremStress.steel.sigmar.geometry.map((coordinate, index) => (
                    <tr key={index}>
                      <td className="tableTd">
                        <Input value={coordinate.x} type="number" />
                      </td>
                      <td className="tableTd">
                        <Input value={coordinate.y} type="number" />
                      </td>
                      <td className="tableTd">
                        <Input value={coordinate.number} type="number" />
                      </td>
                      <td className="tableTd">
                        <Input value={coordinate.diameter} type="number" />
                      </td>
                      <td className="tableTd">
                        <Input value={Math.round(coordinate.stress * 1000) / 1000} type="number" />
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </Col>
          </Row>
        </div>
      </Container>
    );
  }
}

export default ResultsSls;