import * as THREE from 'three';
import { ThreeBSP } from 'three-js-csg-es6';
import showLabel from '../ShowLabel';
import localAxisDesign from '../LocalAxis';

const matrixGlobalToLocal = (
  Ai,
  Aj,
) => {
  const Lij = ((((Aj.X - Ai.X) ** 2) + ((Aj.Y - Ai.Y) ** 2) + ((Aj.Z - Ai.Z) ** 2)) ** 0.5);
  const P = [];

  for (let i = 0; i < 3; i += 1) {
    P[i] = [];
    for (let j = 0; j < 3; j += 1) {
      P[i][j] = 0;
    }
  }

  // vecteur u1
  if (Lij !== 0) {
    P[0][0] = (Aj.X - Ai.X) / Lij;
    P[0][1] = (Aj.Y - Ai.Y) / Lij;
    P[0][2] = (Aj.Z - Ai.Z) / Lij;
  }
  // vecteur u2
  if (Math.abs(P[0][2]) === 1) {
    P[1][0] = 0;
    P[1][1] = 1 * P[0][2];
    P[1][2] = 0;
  } else {
    P[1][0] = -P[0][1] / (((P[0][0] ** 2) + (P[0][1] ** 2)) ** 0.5);
    P[1][1] = P[0][0] / (((P[0][0] ** 2) + (P[0][1] ** 2)) ** 0.5);
    P[1][2] = 0;
  }

  // vecteur u3
  const ProdVect = P[0][0] * P[1][0] + P[0][1] * P[1][1] + P[0][2] * P[1][2];
  P[2][0] = (P[0][1] * P[1][2] - P[0][2] * P[1][1]) / ((1 - (ProdVect ** 2)) ** 0.5);
  P[2][1] = (P[0][2] * P[1][0] - P[0][0] * P[1][2]) / ((1 - (ProdVect ** 2)) ** 0.5);
  P[2][2] = (P[0][0] * P[1][1] - P[0][1] * P[1][0]) / ((1 - (ProdVect ** 2)) ** 0.5);

  return {
    P,
    Lij
  };
};

const localCoordinate = (
  Ai,
  Aj,
  x,
  y
) => {
  const { P, Lij } = matrixGlobalToLocal(Ai, Aj);
  const Xi = Number(Ai.X) + P[1][0] * Number(x) + P[2][0] * Number(y);
  const Yi = Number(Ai.Y) + P[1][1] * Number(x) + P[2][1] * Number(y);
  const Zi = Number(Ai.Z) + P[1][2] * Number(x) + P[2][2] * Number(y);

  const Xj = P[0][0] * Lij + Xi;
  const Yj = P[0][1] * Lij + Yi;
  const Zj = P[0][2] * Lij + Zi;
  return {
    Xi, Yi, Zi, Xj, Yj, Zj
  };
};

const geometryFace = (
  Ai,
  Aj,
  XG,
  YG,
  sectionArray,
  geometry
) => {
  let xmin = 0;
  let xmax = 0;
  let ymin = 0;
  let ymax = 0;
  const FaceExt = [];

  const N = sectionArray.length;
  for (let i = 0; i < N; i += 1) {
    xmin = Math.min(Number(sectionArray[i].x - XG), xmin);
    xmax = Math.max(Number(sectionArray[i].x - XG), xmax);
    ymin = Math.min(Number(sectionArray[i].y - YG), ymin);
    ymax = Math.max(Number(sectionArray[i].y - YG), ymax);
  }

  // (0, 0)
  FaceExt.push(localCoordinate(Ai, Aj, 0, 0));
  // (xmin, ymin)
  FaceExt.push(localCoordinate(Ai, Aj, xmin, ymin));
  // (xmax, ymin)
  FaceExt.push(localCoordinate(Ai, Aj, xmax, ymin));
  // (xmax, ymax)
  FaceExt.push(localCoordinate(Ai, Aj, xmax, ymax));
  // (xmin, ymax)
  FaceExt.push(localCoordinate(Ai, Aj, xmin, ymax));

  for (let i = 0; i < FaceExt.length; i += 1) {
    geometry.vertices.push(new THREE.Vector3(FaceExt[i].Xi, FaceExt[i].Yi, FaceExt[i].Zi));
    geometry.vertices.push(new THREE.Vector3(FaceExt[i].Xj, FaceExt[i].Yj, FaceExt[i].Zj));
  }

  for (let i = 0; i < 3; i += 1) {
    const j = 2 * i;
    // Face i
    geometry.faces.push(new THREE.Face3(j, j + 2, j + 4));
    // Face j
    geometry.faces.push(new THREE.Face3(j + 1, j + 3, j + 5));
  }
  geometry.faces.push(new THREE.Face3(8, 2, 0));
  geometry.faces.push(new THREE.Face3(9, 3, 1));
  return geometry;
};


const geometryMember = (
  Ai,
  Aj,
  XG,
  YG,
  sectionArray,
  geometry
) => {
  const Xi = [];
  const Yi = [];
  const Zi = [];
  const Xj = [];
  const Yj = [];
  const Zj = [];

  const N = sectionArray.length;
  for (let i = 0; i < N; i += 1) {
    const deltaX = sectionArray[i].x - XG;
    const deltaY = sectionArray[i].y - YG;

    Xi[i] = localCoordinate(Ai, Aj, deltaX, deltaY).Xi;
    Yi[i] = localCoordinate(Ai, Aj, deltaX, deltaY).Yi;
    Zi[i] = localCoordinate(Ai, Aj, deltaX, deltaY).Zi;
    geometry.vertices.push(new THREE.Vector3(Xi[i], Yi[i], Zi[i]));
    Xj[i] = localCoordinate(Ai, Aj, deltaX, deltaY).Xj;
    Yj[i] = localCoordinate(Ai, Aj, deltaX, deltaY).Yj;
    Zj[i] = localCoordinate(Ai, Aj, deltaX, deltaY).Zj;
    geometry.vertices.push(new THREE.Vector3(Xj[i], Yj[i], Zj[i]));
  }


  // Centre de gravité
  // CdG Face i: Elément 2N
  const XiG = localCoordinate(Ai, Aj, XG, YG).Xi;
  const YiG = localCoordinate(Ai, Aj, XG, YG).Yi;
  const ZiG = localCoordinate(Ai, Aj, XG, YG).Zi;
  geometry.vertices.push(new THREE.Vector3(XiG, YiG, ZiG));
  // CdG Face j: Elément 2N+1
  const XjG = localCoordinate(Ai, Aj, XG, YG).Xj;
  const YjG = localCoordinate(Ai, Aj, XG, YG).Yj;
  const ZjG = localCoordinate(Ai, Aj, XG, YG).Zj;
  geometry.vertices.push(new THREE.Vector3(XjG, YjG, ZjG));

  // Maille Longi
  for (let i = 0; i < N - 1; i += 1) {
    const j = 2 * i;
    // Maille face i
    // geometry.faces.push(new THREE.Face3(2 * N, j, j + 2));
    // Maille face j
    // geometry.faces.push(new THREE.Face3(2* N + 1, j + 1, j + 3, j + 5));
    // Maille Longi
    geometry.faces.push(new THREE.Face3(j, j + 2, j + 1));
    geometry.faces.push(new THREE.Face3(j + 1, j + 2, j + 3));
  }
  // Maille Longi
  geometry.faces.push(new THREE.Face3(2 * N - 2, 0, 2 * N - 1));
  geometry.faces.push(new THREE.Face3(2 * N - 1, 0, 1));

  return geometry;
};

const sectionMemberDesign = (
  // mesh,
  // scene,
  // meshindex,
  Ai,
  Aj,
  XG,
  YG,
  sectionext,
  sectionint,
  colorvalue
) => {
  const geometryExt = new THREE.Geometry();
  const geometryInt = new THREE.Geometry();
  const geometryFaces = new THREE.Geometry();

  geometryMember(Ai, Aj, XG, YG, sectionext, geometryExt);
  geometryFace(Ai, Aj, XG, YG, sectionext, geometryFaces);

  const material = new THREE.MeshPhongMaterial({
    color: colorvalue,
    side: THREE.DoubleSide,
    flatShading: true,
    wireframe: false
  });

  let member;
  if (sectionint.length < 3) {
    const memberExt = new THREE.Mesh(geometryExt);
    const memberFace = new THREE.Mesh(geometryFaces);

    const eBSP = new ThreeBSP(memberExt);
    const fBSP = new ThreeBSP(memberFace);

    const sub = eBSP.intersect(fBSP);
    member = sub.toMesh(material);
  } else {
    geometryMember(Ai, Aj, XG, YG, sectionint, geometryInt);
    const memberExt = new THREE.Mesh(geometryExt);
    const memberFace = new THREE.Mesh(geometryFaces);
    const memberInt = new THREE.Mesh(geometryInt);

    const eBSP = new ThreeBSP(memberExt);
    const fBSP = new ThreeBSP(memberFace);
    const iBSP = new ThreeBSP(memberInt);
    const sub = eBSP.intersect(fBSP).subtract(iBSP);
    member = sub.toMesh(material);
  }
  // return Object.assign({}, { csg: newMesh  });
  return member;
};

export default (name, {
  scene,
  labelMesh,
  memberMesh,
  uiManagement,
  data
}) => {
  const listnodes = data.nodes.value;
  const listmembers = data.members.value;
  const listsections = data.sections.value;
  // Deleting all members mesh
  if (typeof memberMesh !== 'undefined' && memberMesh.length > 0) {
    for (let i = memberMesh.length - 1; i >= 0; i -= 1) {
      scene.remove(memberMesh[i]);
      // memberMesh[i].geometry.dispose();
      // memberMesh[i].material.dispose();
      memberMesh.pop();
    }
  }

  // Drawing line corresponding to members
  for (let i = 0; i < listmembers.length; i += 1) {
    let Ai = {};
    let Aj = {};
    for (let j = 0; j < listnodes.length; j += 1) {
      if (listmembers[i].nodei === listnodes[j].name) {
        Ai = listnodes[j];
      }
      if (listmembers[i].nodej === listnodes[j].name) {
        Aj = listnodes[j];
      }
    }
    if (uiManagement.show3DStructure === false) {
      for (let j = 0; j < listsections.length; j += 1) {
        if (listmembers[i].section === listsections[j].name) {
          const section = listsections[j].characteristics;
          const lineGeo = new THREE.Geometry();
          const lineMat = new THREE.LineBasicMaterial({ color: section.color });
          lineGeo.vertices.push(
            new THREE.Vector3(Ai.X, Ai.Y, Ai.Z),
            new THREE.Vector3(Aj.X, Aj.Y, Aj.Z)
          );
          const member = new THREE.LineSegments(lineGeo, lineMat);
          member.userData = {
            data: listmembers[i],
            type: 'member',
            color: section.color
          };
          memberMesh.push(member);
          scene.add(member);
        }
      }
    } else {
      for (let j = 0; j < listsections.length; j += 1) {
        if (listmembers[i].section === listsections[j].name) {
          const section = listsections[j].characteristics;
          const member = sectionMemberDesign(Ai, Aj, section.xg.value, section.yg.value, section.sectionext, section.sectionint, section.color);
          member.userData = {
            data: listmembers[i],
            type: 'member',
            color: section.color
          };
          memberMesh.push(member);
          scene.add(member);
        }
      }
    }
    if (uiManagement.showMember) {
      const labelData = {
        scene,
        mesh: labelMesh,
        type: 'member',
        object: listmembers[i],
        text: listmembers[i].name,
        x: (Number(Aj.X) + Number(Ai.X)) / 2,
        y: (Number(Aj.Y) + Number(Ai.Y)) / 2,
        z: (Number(Aj.Z) + Number(Ai.Z)) / 2
      };
      showLabel(labelData);
    }
    if (uiManagement.showLocalAxis) {
      localAxisDesign(Ai, Aj, memberMesh, scene);
    }
  }


  return {
    mesh: memberMesh
  };
};
