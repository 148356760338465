import React from 'react';
import PropTypes from 'prop-types';
import {
  Container,
  Table,
  Row,
  Col,
  Input
} from 'reactstrap';
import { Scatter } from 'react-chartjs-2';

import chartOptions from './utils/chartOptions';
import chartData from './utils/chartData';
import OutputCalc from '../../../../../../../../components/UIComponents/Fields/OutputCalc';
import optionsLengthUnits from '../../../../../../../../utils/units/optionsLengthUnits2';
import optionsAreaUnits from '../../../../../../../../utils/units/optionsAreaUnits2';
import optionsInertiaUnits from '../../../../../../../../utils/units/optionsInertiaUnits';
import optionsAutreUnits from '../../../../../../../../utils/units/optionsAutreUnits';
import optionsVolumeUnits from '../../../../../../../../utils/units/optionsVolumeUnits';


const SectionData = ({
  sectionData,
  steel
}) => {
  return (
    <Container className="geometry">
      <br />
      <Row>
        <Col md={6}>
          <div className="chartContainer">
            <Scatter
              className="chart"
              width={500}
              height={500}
              data={chartData(steel.sectionext, steel.sectionint, sectionData)}
              options={chartOptions(steel.sectionext, steel.unit)}
            />
          </div>
        </Col>
        <Col md={6}>
          <h2 className="subtitle">Section Data</h2>
          <OutputCalc
            input={sectionData.xg}
            unitList={optionsLengthUnits}
          />
          <OutputCalc
            input={sectionData.yg}
            unitList={optionsLengthUnits}
          />
          <OutputCalc
            input={sectionData.A}
            unitList={optionsAreaUnits}
          />
          <OutputCalc
            input={sectionData.Av}
            unitList={optionsAreaUnits}
          />
          <OutputCalc
            input={sectionData.Ix}
            unitList={optionsInertiaUnits}
          />
          <OutputCalc
            input={sectionData.Iy}
            unitList={optionsInertiaUnits}
          />
          <OutputCalc
            input={sectionData.It}
            unitList={optionsInertiaUnits}
          />
          <OutputCalc
            input={sectionData.Iw}
            unitList={optionsAutreUnits}
          />
          <OutputCalc
            input={sectionData.Welx}
            unitList={optionsVolumeUnits}
          />
          <OutputCalc
            input={sectionData.Wely}
            unitList={optionsVolumeUnits}
          />
          <OutputCalc
            input={sectionData.Wplx}
            unitList={optionsVolumeUnits}
          />
          <OutputCalc
            input={sectionData.Wply}
            unitList={optionsVolumeUnits}
          />
        </Col>
      </Row>
    </Container>
  );
};


export default SectionData;