import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
  Card,
  CardImg,
  CardHeader,
  CardBody,
  CardTitle,
  CardSubtitle,
  Button,
  Col,
  UncontrolledDropdown,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  InputGroup,
  Input,
  InputGroupAddon
} from 'reactstrap';
import AddProjectSvg from './img/AddProject.svg';

export default class CardProject extends React.Component {
  constructor(props) {
    super(props);
    this.handleChange = this.handleChange.bind(this);
    this.toggle = this.toggle.bind(this);
    this.state = {
      // title: this.props.title,
      dropdownOpen: false
    };
  }

  handleChange(e) {
    this.setState({
      title: e.target.value
    });
  }

  toggle() {
    this.setState(prevState => ({
      dropdownOpen: !prevState.dropdownOpen
    }));
  }

  render() {
    const {
      colnum,
      imgsrc,
      title,
      subtitle,
      link,
      project,
      openModal,
      selectProjectId
    } = this.props;

    return (
      <Col md={colnum}>
        <Card
          className={project === '' ? 'cardAddProject' : 'cardProject'}
        >
          <InputGroup>
            {/* <InputGroupAddon addonType="prepend">
              <UncontrolledDropdown>
                <DropdownToggle tag="a" className="nav-link">
                  <i className="material-icons">more_vert</i>
                </DropdownToggle>
                <DropdownMenu className="dropdownmenu">
                  <DropdownItem className="dropdownitem">
                    <i className="material-icons">border_color</i>
                    Edit
                  </DropdownItem>
                  <DropdownItem className="dropdownitem">
                    <i className="material-icons">content_copy</i>
                    Duplicate
                  </DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>
            </InputGroupAddon> */}
            {/* <Input className="cardprojectinput"/> */}
            <InputGroupAddon
              addonType="append"
              className="ml-auto projectDelete "
              onClick={() => {
                if (project !== '') {
                  selectProjectId(project._id);
                  openModal({ status: true });
                }
              }}
            >
              <i className="material-icons">close</i>
            </InputGroupAddon>
          </InputGroup>
          <Link to={link} className="cardLink">
            <CardImg top className="cardImg" src={project === '' ? AddProjectSvg : imgsrc} alt="Card image cap" />
          </Link>
          <CardBody className="cardBody">
            {/* <CardTitle>
              <Input disable className="cardProjectTitle" value={title} />
            </CardTitle> */}
            <CardTitle className="title">{title}</CardTitle>
            <CardSubtitle className="subtitle">{subtitle}</CardSubtitle>
          </CardBody>
        </Card>
      </Col>
    );
  }
}

CardProject.propTypes = {
  colnum: PropTypes.number.isRequired,
  imgsrc: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired,
  link: PropTypes.string.isRequired
};
