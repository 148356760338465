import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as tf from '@tensorflow/tfjs';
import * as tfvis from '@tensorflow/tfjs-vis';
import {
  Container,
  Breadcrumb,
  BreadcrumbItem,
  Card,
  CardBody,
  CardTitle,
  Button,
  Row,
  Col,
  Input,
  Table
} from 'reactstrap';
import { Link } from 'react-router-dom';
import { LoadableRegression } from '../../../Regression';
import InputCalc from '../../../../components/UIComponents/Fields/InputCalc';
import OutputCalc from '../../../../components/UIComponents/Fields/OutputCalc';
import optionsLengthUnits from '../../../../utils/units/optionsLengthUnits2';
import optionsInertiaUnits from '../../../../utils/units/optionsInertiaUnits';
import optionsStressUnits from '../../../../utils/units/optionsStressUnits2';
import optionsForceUnits from '../../../../utils/units/optionsForceUnits2';
import optionsMomentUnits from '../../../../utils/units/optionsMomentUnits2';
import { Scatter } from 'react-chartjs-2';

import {
  importDataset,
  updateStatus,
  loadData,
  trainSimpleLinearRegression,
  trainNeuralNetworkLinearRegression1Hidden,
  trainNeuralNetworkLinearRegression2Hidden
} from '../../actions';

class RegressionCalculation extends React.Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    // this.status.innerText = 'HELLO GUIGUI';
    // const baselineStatusElement = document.getElementById('baselineStatus');
    // const inspectionHeadlineElement = document.getElementById('inspectionHeadline');
    // const table = document.getElementById('myTable');
    // this.table.current.innerHTML = 'Hello';
    window.tf = tf;
    window.tfvis = tfvis;
    const visorInstance = tfvis.visor();
    visorInstance.surface({ name: 'Surface 1' });
    visorInstance.surface({ name: 'Surface 2' });
    visorInstance.surface({ name: 'Surface 3', tab: 'Tab 2' });
  }

  render() {
    const {
      inputs,
      importDataset,
      updateStatus,
      loadData,
      trainSimpleLinearRegression,
      trainNeuralNetworkLinearRegression1Hidden,
      trainNeuralNetworkLinearRegression2Hidden
    } = this.props;
    return (
      <LoadableRegression>
        <Container className="regression">
          <Row>
            <Breadcrumb>
              <BreadcrumbItem><Link to="/dashboard">Dashboard</Link></BreadcrumbItem>
              <BreadcrumbItem>Regression</BreadcrumbItem>
            </Breadcrumb>
          </Row>
          <Card className="card">
            <CardBody className="header">
              <Row>
                <Col lg={12} className="align-center">
                  <CardTitle className="title">
                    {`Regression`}
                  </CardTitle>
                </Col>
              </Row>
            </CardBody>
            <CardBody>
              <Row>
                <Col md={12}>
                  <h2 className="subtitle">Inputs</h2>
                  <Input
                    type="file"
                    name="File Upload"
                    id="txtFileUpload"
                    accept=".csv"
                    onChange={importDataset}
                  />
                  <Button
                    className="negativeButton"
                    onClick={loadData}
                  >
                    Calculation
                  </Button>
                  <Button
                    className="negativeButton"
                    onClick={(e) => {
                      tfvis.visor().open();
                    }}
                  >
                    Visio
                  </Button>
                  <Button
                    className="negativeButton"
                    onClick={(e) => {
                      const data = [
                        { index: 0, value: 50 },
                        { index: 1, value: 100 },
                        { index: 2, value: 150 }
                      ];
                      // Render to visor
                      const surface = tfvis.visor().surface({ name: 'Barchart', tab: 'Charts' });
                      tfvis.render.barchart(surface, data, {});
                    }}
                  >
                    LoadData
                  </Button>
                  <div className='tfjs-example-container centered-container'>
                    <section className='title-area'>
                      <h1>Multivariate Regression</h1>
                      <p className='subtitle'>Compare different models for housing price prediction.</p>
                    </section>

                    <section>
                      <p className='section-head'>Description</p>
                      <p>
                        This example shows you how to perform regression with more than one input feature using the
                        <a href="https://www.cs.toronto.edu/~delve/data/boston/bostonDetail.html">Boston Housing Dataset</a>,
                        which is a famous dataset derived from information collected by the U.S. Census Service concerning housing
                        in the area of Boston Massachusetts.
                      </p>
                      <p>
                        It allows you to compare the perfomance of 3 different models for predicting the house prices. When training
                        the linear model, it will also display the largest 5 weights (by absolute value) of the model and
                        the feature associated with each of those weights.
                      </p>
                    </section>

                    <section>
                      <p className='section-head'>Status</p>
                      <p id="status">{inputs.status}</p>
                      <p id="baselineStatus">{inputs.baselineStatus}</p>
                      <p id="modelStatus">
                        {inputs.modelStatus.map((value, key) => (
                          <div id={key}>{value}</div>
                        ))}
                      </p>
                    </section>
                    <section>
                      <p className='section-head'>Training Progress</p>
                      <div className="with-cols">
                        <div id="linear">
                          <div className="chart"></div>
                          <div className="status"></div>
                          <div id="modelInspectionOutput">
                            <p id="inspectionHeadline">{inputs.inspectionHeadlineElement}</p>
                            <table id="myTable"></table>
                          </div>
                        </div>
                        <div id="oneHidden">
                          <div className="chart"></div>
                          <div className="status"></div>
                        </div>
                        <div id="twoHidden">
                          <div className="chart"></div>
                          <div className="status"></div>
                        </div>
                      </div>

                      <div id="buttons">
                        <div className="with-cols">
                          <Button
                            id="simple-mlr"
                            onClick={trainSimpleLinearRegression}
                          >
                            { 'Train Linear Regressor' }
                          </Button>
                          <Button
                            id="nn-mlr-1hidden"
                            onClick={trainNeuralNetworkLinearRegression1Hidden}
                          >
                            { 'Train Neural Network Regressor (1 hidden layer)' }
                          </Button>
                          <Button
                            id="nn-mlr-2hidden"
                            onClick={trainNeuralNetworkLinearRegression2Hidden}
                          >
                            { 'Train Neural Network Regressor (2 hidden layers)' }
                          </Button>
                        </div>
                      </div>

                    </section>
                  </div>
                </Col>
                {/* <Col md={6}>
                  <h2 className="subtitle">Outputs</h2>
                    <OutputCalc
                      input={outputs.MA}
                      unitList={optionsMomentUnits}
                    />
                    <OutputCalc
                      input={outputs.dispB}
                      unitList={optionsLengthUnits}
                    />
                </Col> */}
              </Row>
            </CardBody>
          </Card>
        </Container>
      </LoadableRegression>
    );
  }
}

const mapStateToProps = (state, props) => {
  // const { sectionType } = props.match.params;
  return {
    inputs: state.regression.inputs
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({
    importDataset,
    updateStatus,
    loadData,
    trainSimpleLinearRegression,
    trainNeuralNetworkLinearRegression1Hidden,
    trainNeuralNetworkLinearRegression2Hidden
  }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(RegressionCalculation);

const WeightTable = ({
  weight,
  i
}) => {
  return (
    <Table>

    </Table>
  );
};
