import axios from 'axios';
import {
  FETCH_DATBIM_CLASSES,
  FETCH_DATBIM_CATEGORIES
} from './actionTypes';
import { URL } from '../../../../config/config';
import URL_PATH from '../../../../config/default';

const fetchClasses = () => {
  return async (dispatch) => {
    try {
      const res = await axios.get(`${URL}${URL_PATH.datBim.classes}`);
      const options = res.data;
      dispatch({
        type: FETCH_DATBIM_CLASSES,
        payload: { data: options.data, loading: false }
      });
    } catch (error) {
      console.log(error);
    }
  };
};

const fetchCategories = (category) => {
  return async (dispatch) => {
    try {
      const res = await axios.get(`${URL}${URL_PATH.datBim[category]}`);
      const options = res.data;
      dispatch({
        type: FETCH_DATBIM_CATEGORIES,
        payload: {
          category,
          data: options.data,
          loading: false 
        }
      });
    } catch (error) {
      console.log(error);
    }
  };
};

export {
  fetchClasses,
  fetchCategories
};