import createNode from '../../../../../components/GraphicModules/Nodes';
import createMember from '../../../../../components/GraphicModules/Members';
import createNodeLoad from '../../../../../components/GraphicModules/NodeLoads';
import createMemberLoad from '../../../../../components/GraphicModules/MemberLoads';
import createMemberSollicitation from '../../../../../components/GraphicModules/MemberSollicitations';

export default (
  sceneState,
  sceneOutputState,
  uiManagement,
  labelMesh,
  nodeMesh,
  memberMesh,
  nodeLoadMesh,
  memberLoadMesh,
  memberSollicitationMesh,
  scene
) => {
  // NODES
  const nodesOptions = {
    scene,
    labelMesh,
    nodeMesh,
    uiManagement,
    data: sceneState
  };

  const nodes = createNode('nodes', nodesOptions);

  // MEMBERS
  const membersOptions = {
    scene,
    labelMesh,
    memberMesh,
    uiManagement,
    data: sceneState
  };

  const members = createMember('members', membersOptions);

  // NODE LOADS
  const nodeLoadsOptions = {
    scene,
    labelMesh,
    nodeLoadMesh,
    uiManagement,
    data: sceneState
  };

  const nodeLoads = createNodeLoad('nodeLoads', nodeLoadsOptions);

  // MEMBER LOADS
  const memberLoadsOptions = {
    scene,
    labelMesh,
    memberLoadMesh,
    uiManagement,
    data: sceneState
  };

  const memberLoads = createMemberLoad('memberLoads', memberLoadsOptions);


  // MEMBER SollicitationS
  const memberSollicitationsOptions = {
    scene,
    labelMesh,
    memberSollicitationMesh,
    uiManagement,
    data: sceneOutputState
  };

  const memberSollicitations = createMemberSollicitation('memberSollicitations', memberSollicitationsOptions);

  return {
    nodes,
    members,
    nodeLoads,
    memberLoads,
    memberSollicitations
  };
};
