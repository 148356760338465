import {
  Raycaster,
  Vector3
} from 'three';
import { UPDATE_GRAPH } from './actionTypes';
import { openModal } from '../../components/UIComponents/Modal/actions';

const showVideo = () => {
  return (dispatch) => {
    dispatch(openModal({ status: true }));
  };
};


const rayCasterDefinedIntersect = (camera, rayCasterTargetList, event) => {
  const mouse = { x: 0, y: 0 };
  mouse.x = (event.clientX / window.innerWidth) * 2 - 1;
  mouse.y = -(event.clientY / window.innerHeight) * 2 + 1;
  const vector = new Vector3(mouse.x, mouse.y, 1);
  vector.unproject(camera);
  const ray = new Raycaster(camera.position, vector.sub(camera.position).normalize());
  const intersects = ray.intersectObjects(rayCasterTargetList);
  return intersects;
};

function selectRenderobject({
  camera,
  rayCasterTargetList
}, event) {
  const intersects = rayCasterDefinedIntersect(camera, rayCasterTargetList, event);
  if (intersects.length > 0) {
    return openModal({ ...intersects[0], status: true });
  }
  return openModal({ status: false });
}

function updateGraph(data) {
  return {
    type: UPDATE_GRAPH,
    payload: data
  };
}
// const selectRenderobject = ({
//   camera,
//   rayCasterTargetList,
// }, event) => {
//   return (dispatch) => {
//     const intersects = rayCasterDefinedIntersect(camera, rayCasterTargetList, event);
//     let object = null;
//     // if (intersects.length > 0) {
//     //   //  const firstIntersectedObject = intersects[0];

//     // }
//     dispatch(openModal({ ...intersects[0], status: true }))
//   };

//   // return {
//   //   type: OPEN_MODAL,
//   //   payload: object,
//   // };
//   // TODO USE THIS IN MODAL COMPONENT
//   // if there is one (or more) intersections
//   // if (intersects.length > 0) {
//   //     if (Modal.classList.contains('show')) {
//   //         Modal.classList.remove('show');
//   //         Modal.classList.add('hide');
//   //     } else {
//   //         Modal.classList.remove('hide');
//   //         Modal.className = `${Modal.className} show`;
//   //         if (callback) {
//   //             return callback(intersects);
//   //         }
//   //     }
// }

export {
  showVideo,
  updateGraph,
  selectRenderobject
};
