import * as THREE from 'three';

export default (
  A,
  B,
  memberMesh,
  scene
) => {
  const Ai = {
    X: Number(A.X),
    Y: Number(A.Y),
    Z: Number(A.Z)
  };

  const Aj = {
    X: Number(B.X),
    Y: Number(B.Y),
    Z: Number(B.Z)
  };

  const Lij = ((((Aj.X - Ai.X) ** 2) + ((Aj.Y - Ai.Y) ** 2) + ((Aj.Z - Ai.Z) ** 2)) ** 0.5);
  const P = [];

  for (let i = 0; i < 3; i += 1) {
    P[i] = [];
    for (let j = 0; j < 3; j += 1) {
      P[i][j] = 0;
    }
  }

  // vecteur u1
  if (Lij !== 0) {
    P[0][0] = (Aj.X - Ai.X) / Lij;
    P[0][1] = (Aj.Y - Ai.Y) / Lij;
    P[0][2] = (Aj.Z - Ai.Z) / Lij;
  }
  // vecteur u2
  if (Math.abs(P[0][2]) === 1) {
    P[1][0] = 0;
    P[1][1] = 1 * P[0][2];
    P[1][2] = 0;
  } else {
    P[1][0] = -P[0][1] / (((P[0][0] ** 2) + (P[0][1] ** 2)) ** 0.5);
    P[1][1] = P[0][0] / (((P[0][0] ** 2) + (P[0][1] ** 2)) ** 0.5);
    P[1][2] = 0;
  }

  // vecteur u3
  const ProdVect = P[0][0] * P[1][0] + P[0][1] * P[1][1] + P[0][2] * P[1][2];
  P[2][0] = (P[0][1] * P[1][2] - P[0][2] * P[1][1]) / ((1 - (ProdVect ** 2)) ** 0.5);
  P[2][1] = (P[0][2] * P[1][0] - P[0][0] * P[1][2]) / ((1 - (ProdVect ** 2)) ** 0.5);
  P[2][2] = (P[0][0] * P[1][1] - P[0][1] * P[1][0]) / ((1 - (ProdVect ** 2)) ** 0.5);


  const lineMatU1 = new THREE.LineBasicMaterial({ color: 'red' });
  const lineMatU2 = new THREE.LineBasicMaterial({ color: 'green' });
  const lineMatU3 = new THREE.LineBasicMaterial({ color: 'blue' });

  // LOCAL AXIS U1
  const lineGeoU1 = new THREE.Geometry();
  lineGeoU1.vertices.push(
    new THREE.Vector3((Aj.X + Ai.X) / 2, (Aj.Y + Ai.Y) / 2, (Aj.Z + Ai.Z) / 2),
    new THREE.Vector3((Aj.X + Ai.X) / 2 + P[0][0], (Aj.Y + Ai.Y) / 2 + P[0][1], (Aj.Z + Ai.Z) / 2 + P[0][2])
  );

  // LOCAL AXIS U2
  const lineGeoU2 = new THREE.Geometry();
  lineGeoU2.vertices.push(
    new THREE.Vector3((Aj.X + Ai.X) / 2, (Aj.Y + Ai.Y) / 2, (Aj.Z + Ai.Z) / 2),
    new THREE.Vector3((Aj.X + Ai.X) / 2 + P[1][0], (Aj.Y + Ai.Y) / 2 + P[1][1], (Aj.Z + Ai.Z) / 2 + P[1][2])
  );

  // LOCAL AXIS U3
  const lineGeoU3 = new THREE.Geometry();
  lineGeoU3.vertices.push(
    new THREE.Vector3((Aj.X + Ai.X) / 2, (Aj.Y + Ai.Y) / 2, (Aj.Z + Ai.Z) / 2),
    new THREE.Vector3((Aj.X + Ai.X) / 2 + P[2][0], (Aj.Y + Ai.Y) / 2 + P[2][1], (Aj.Z + Ai.Z) / 2 + P[2][2])
  );

  const U1 = new THREE.LineSegments(lineGeoU1, lineMatU1);
  memberMesh.push(U1);
  scene.add(U1);
  const U2 = new THREE.LineSegments(lineGeoU2, lineMatU2);
  memberMesh.push(U2);
  scene.add(U2);
  const U3 = new THREE.LineSegments(lineGeoU3, lineMatU3);
  memberMesh.push(U3);
  scene.add(U3);
};
