import * as types from './actionTypes';
import { fromJS } from 'immutable';
import update from 'immutability-helper';
import initialState from './initialState';

const datBimApi = (state = initialState, action) => {
  console.log('INITIAL STATE', initialState)
  switch (action.type) {
    case types.FETCH_DATBIM_CLASSES:
      console.log('REDUCER', action.payload.data)
      return {
        classes: action.payload.data,
        loading: action.payload.loading
      };
    case types.FETCH_DATBIM_CATEGORIES:
      return update(state, {
        [action.payload.category]: {
          $set: action.payload.data
        },
        loading: {
          $set: action.payload.loading
        }
      })

    default:
      return state;
  }
};

export default datBimApi;
