import axios from 'axios';
import fileDownload from 'react-file-download';
import * as tf from '@tensorflow/tfjs';
// import * as tfvis from '@tensorflow/tfjs-vis';
import Papa from 'papaparse';
import { toast } from 'react-toastify';
import {
  RE_CHANGE_MESSAGE,
  RE_UPDATE_MODEL_STATUS
} from './actionTypes';
import { URL } from '../../config/config';
import URL_PATH from '../../config/default';

import {
  BostonHousingDataset,
  featureDescriptions
} from './utils/data';
// import {
//   // linearRegressionModel,
//   // multiLayerPerceptronRegressionModel1Hidden,
//   // multiLayerPerceptronRegressionModel2Hidden,
//   runLoadData
// } from './utils/calculation';
import * as normalization from './utils/normalization';

export const importDataset = async (e) => {
  const file = e.target.files[0];
  Papa.parse(file, {
    header: true,
    dynamicTyping: true,
    complete: (results) => {
      return results.data;
    }
  });
};

export const exportToCSV = async (sectionType, sectionValues, outputs) => {
  try {
    const res = await axios.post(`${URL}${URL_PATH.calculs.sectionGeometryCSV}`, { sectionType, sectionValues, outputs });
    fileDownload(res.data, 'geometry.csv');
    toast.success('File downloaded');
  } catch (err) {
    toast.error('No access to the server');
  }
};


export const updateStatus = (type, message) => {
  return {
    type: RE_CHANGE_MESSAGE,
    payload: {
      type,
      message
    }
  };
};

export const updateModelStatus = (section, message) => {
  return (dispatch) => {
    dispatch({
      type: RE_UPDATE_MODEL_STATUS,
      payload: {
        section,
        message
      }
    });
  };
};

// const NUM_EPOCHS = 200;
// const BATCH_SIZE = 40;
const LEARNING_RATE = 0.01;

const bostonData = new BostonHousingDataset();
const tensors = {};

export function arraysToTensors() {
  tensors.rawTrainFeatures = tf.tensor2d(bostonData.trainFeatures);
  tensors.trainTarget = tf.tensor2d(bostonData.trainTarget);
  tensors.rawTestFeatures = tf.tensor2d(bostonData.testFeatures);
  tensors.testTarget = tf.tensor2d(bostonData.testTarget);
  // Normalize mean and standard deviation of data.
  const {
    dataMean,
    dataStd
  } = normalization.determineMeanAndStddev(tensors.rawTrainFeatures);

  tensors.trainFeatures = normalization.normalizeTensor(
    tensors.rawTrainFeatures, dataMean, dataStd
  );
  tensors.testFeatures = normalization.normalizeTensor(tensors.rawTestFeatures, dataMean, dataStd);
}

/**
 * Builds and returns Linear Regression Model.
 *
 * @returns {tf.Sequential} The linear regression model.
 */
export const linearRegressionModel = () => {
  const model = tf.sequential();
  model.add(tf.layers.dense({ inputShape: [bostonData.numFeatures], units: 1 }));

  model.summary();
  return model;
};

/**
 * Builds and returns Multi Layer Perceptron Regression Model
 * with 1 hidden layers, each with 10 units activated by sigmoid.
 *
 * @returns {tf.Sequential} The multi layer perceptron regression model.
 */
export const multiLayerPerceptronRegressionModel1Hidden = () => {
  const model = tf.sequential();
  model.add(tf.layers.dense({
    inputShape: [bostonData.numFeatures],
    units: 50,
    activation: 'sigmoid',
    kernelInitializer: 'leCunNormal'
  }));
  model.add(tf.layers.dense({ units: 1 }));

  model.summary();
  return model;
};


/**
 * Builds and returns Multi Layer Perceptron Regression Model
 * with 2 hidden layers, each with 10 units activated by sigmoid.
 *
 * @returns {tf.Sequential} The multi layer perceptron regression mode  l.
 */
export const multiLayerPerceptronRegressionModel2Hidden = () => {
  const model = tf.sequential();
  model.add(tf.layers.dense({
    inputShape: [bostonData.numFeatures],
    units: 50,
    activation: 'sigmoid',
    kernelInitializer: 'leCunNormal'
  }));
  model.add(tf.layers.dense(
    { units: 50, activation: 'sigmoid', kernelInitializer: 'leCunNormal' }
  ));
  model.add(tf.layers.dense({ units: 1 }));

  model.summary();
  return model;
};

/**
 * Describe the current linear weights for a human to read.
 *
 * @param {Array} kernel Array of floats of length 12.  One value per feature.
 * @returns {List} List of objects, each with a string feature name, and value
 *     feature weight.
 */
export const describeKernelElements = (kernel) => {
  tf.util.assert(
    kernel.length === 12,
    `kernel must be a array of length 12, got ${kernel.length}`
  );
  const outList = [];
  for (let idx = 0; idx < kernel.length; idx += 1) {
    outList.push({ description: featureDescriptions[idx], value: kernel[idx] });
  }
  return outList;
};

/**
 * Compiles `model` and trains it using the train data and runs model against
 * test data. Issues a callback to update the UI after each epcoh.
 *
 * @param {tf.Sequential} model Model to be trained.
 * @param {boolean} weightsIllustration Whether to print info about the learned
 *  weights.
 */

export const run = async (model, modelName, weightsIllustration, dispatch) => {
  model.compile(
    { optimizer: tf.train.sgd(LEARNING_RATE), loss: 'meanSquaredError' }
  );

  // const trainLogs = [];
  // const container = document.querySelector(`#${modelName} .chart`);

  dispatch(updateStatus('status', 'Starting training process...'));
  // await model.fit(tensors.trainFeatures, tensors.trainTarget, {
  //   batchSize: BATCH_SIZE,
  //   epochs: NUM_EPOCHS,
  //   validationSplit: 0.2,
  //   // callbacks: {
  //   //   onEpochEnd: async (epoch, logs) => {
  //   //     await dispatch(updateModelStatus('modelStatus',
  //   //       `Epoch ${epoch + 1} of ${NUM_EPOCHS} completed.`, modelName));
  //   //     trainLogs.push(logs);
  //   //     tfvis.show.history(container, trainLogs, ['loss', 'val_loss']);

  //   //     // if (weightsIllustration) {
  //   //     //   model.layers[0].getWeights()[0].data().then((kernelAsArr) => {
  //   //     //     const weightsList = describeKernelElements(kernelAsArr);
  //   //     //     ui.updateWeightDescription(weightsList);
  //   //     //   });
  //   //     // }
  //   //   }
  //   // }
  // });

  // dispatch(updateStatus('status', 'Running on test data...'));
  // const result = model.evaluate(
  //   tensors.testFeatures, tensors.testTarget, { batchSize: BATCH_SIZE }
  // );
  // const testLoss = result.dataSync()[0];

  // const trainLoss = trainLogs[trainLogs.length - 1].loss;
  // const valLoss = trainLogs[trainLogs.length - 1].val_loss;
  // await dispatch(updateModelStatus('modelStatus',
  //   `Final train-set loss: ${trainLoss.toFixed(4)}\n`
  //   + `Final validation-set loss: ${valLoss.toFixed(4)}\n`
  //   + `Test-set loss: ${testLoss.toFixed(4)}`, modelName));
};

export function computeBaseline(dispatch) {
  const avgPrice = tf.mean(tensors.trainTarget);
  console.log(`Average price: ${avgPrice.dataSync()}`);
  const baseline = tf.mean(tf.pow(tf.sub(tensors.testTarget, avgPrice), 2));
  console.log(`Baseline loss: ${baseline.dataSync()}`);
  const baselineMsg = `Baseline loss (meanSquaredError) is ${baseline.dataSync()[0].toFixed(2)}`;
  dispatch(updateStatus('baselineStatus', baselineMsg));
}

export const loadData = () => {
  return async (dispatch) => {
    dispatch(updateStatus('status', 'Loading data... '));
    dispatch(updateStatus('baselineStatus', 'Baseline not computed...'));
    await bostonData.loadData();
    dispatch(updateStatus('status', 'Data loaded, converting to tensors'));
    await arraysToTensors();
    dispatch(updateStatus('status', 'Data is now available as tensors.\n'
      + 'Click a train button to begin.'));
    // // TODO Explain what baseline loss is. How it is being computed in this
    // // Instance
    dispatch(updateStatus('baselineStatus', 'Estimating baseline loss'));
    await computeBaseline(dispatch);
  };
};


export const trainNeuralNetworkLinearRegression1Hidden = async () => {
  const model = multiLayerPerceptronRegressionModel1Hidden();
  await run(model, 'oneHidden', false);
};

export const trainNeuralNetworkLinearRegression2Hidden = async () => {
  const model = multiLayerPerceptronRegressionModel2Hidden();
  await run(model, 'twoHidden', false);
};


const BOSTON_HOUSING_CSV_URL = 'https://storage.googleapis.com/tfjs-examples/multivariate-linear-regression/data/boston-housing-train.csv';
const NUMBER_EPOCHS = 50;

const runModel = async (url, dispatch) => {
  dispatch(updateStatus('status', 'Loading data... '));
  // We want to predict the column "medv", which represents a median value of a
  // home (in $1000s), so we mark it as a label.
  const csvDataset = tf.data.csv(url, {
    columnConfigs: {
      medv: {
        isLabel: true
      }
    }
  });
  // Number of features is the number of column names minus one for the label
  // column.
  const numOfFeatures = (await csvDataset.columnNames()).length - 1;
  dispatch(updateStatus('status', 'Prepare the Dataset for training'));
  // Prepare the Dataset for training.
  const flattenedDataset = csvDataset.map(({ xs, ys }) => {
    // Convert rows from object form (keyed by column name) to array form.
    return { xs: Object.values(xs), ys: Object.values(ys) };
  }).batch(10);

  // Define the model.
  const model = tf.sequential();
  model.add(tf.layers.dense({
    inputShape: [numOfFeatures],
    units: 1
  }));
  dispatch(updateStatus('status', 'Training:'));
  model.compile({
    optimizer: tf.train.sgd(0.000001),
    loss: 'meanSquaredError'
  });

  // Fit the model using the prepared Dataset
  return model.fitDataset(flattenedDataset, {
    epochs: NUMBER_EPOCHS,
    callbacks: {
      onEpochEnd: async (epoch, logs) => {
        console.log(epoch, logs.loss);
        await dispatch(updateModelStatus('modelStatus',
          `Epoch ${epoch + 1} of ${NUMBER_EPOCHS} completed. loss: ${logs.loss}`, 'linear'));
      }
    }
  });
};

export const trainSimpleLinearRegression = () => {
  return async (dispatch) => {
    // const model = await linearRegressionModel();
    // await run(model, 'linear', true, dispatch);
    await runModel(BOSTON_HOUSING_CSV_URL, dispatch);
  };
};
