import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  Container,
  Row,
  Col,
  Label,
  Card,
  CardHeader,
  Button,
  Form
} from 'reactstrap';
import InputsUnit from '../../../../../../../../../../components/UIComponents/Fields/InputsUnit2';
import SelectRF from '../../../../../../../../../../components/UIComponents/Fields/SelectRF';
import InputField from '../../../../../../../../../../components/UIComponents/Fields/InputRF';
import optionsDensityUnits from '../../../../../../../../../../utils/units/optionsDensityUnits2';
import optionsStressUnits from '../../../../../../../../../../utils/units/optionsStressUnits2';
import optionsLengthUnits from '../../../../../../../../../../utils/units/optionsLengthUnits2';
import optionsAreaUnits from '../../../../../../../../../../utils/units/optionsAreaUnits2';
import optionsInertiaUnits from '../../../../../../../../../../utils/units/optionsInertiaUnits';
import { required, numberConfirmation, isFormMaterialValidate } from '../../../../../../../../../../utils/validations';

import {
  addFields,
  editFields,
  changeListSelection
} from '../../../../../../actions';



const FormMaterial = ({
  materials,
  view,
  showErrorMessage,
  errorMessage,
  changeView,
  addFields,
  editFields,
  changeListSelection
  // structure3D
}) => {
  return (
    <div>
      <Row>
        <Col xs={10}>
          <Label>{`${materials.outputs.description}`}</Label>
          <InputField
            name={`materials.outputs.name`}
            type="text"
            validate={[required]}
          // onChange={(e) => {
          //   const val = e.target.value;
          //   const list = materials.value;
          //   console.log(list);
          //   changeListSelection('sections', 'nodei', list, index, val);
          // }}
          />
        </Col>
      </Row>
      <br />
      <Row>
        <Col xs={12}>
          <InputsUnit
            label={materials.outputs.characteristics.density.text}
            inputName="materials.outputs.characteristics.density.value"
            selectList={optionsDensityUnits}
            selectName="materials.outputs.characteristics.density.unit.name"
            tooltipDescription={materials.outputs.characteristics.density.description}
            tooltipTarget={materials.outputs.characteristics.density.name}
            validate={[required, numberConfirmation]}
            type="number"
          />
          <InputsUnit
            label={materials.outputs.characteristics.eYoung.text}
            inputName="materials.outputs.characteristics.eYoung.value"
            selectList={optionsStressUnits}
            selectName="materials.outputs.characteristics.eYoung.unit.name"
            tooltipDescription={materials.outputs.characteristics.eYoung.description}
            tooltipTarget={materials.outputs.characteristics.eYoung.name}
            validate={[required, numberConfirmation]}
          />
          <InputsUnit
            label={materials.outputs.characteristics.poisson.text}
            inputName="materials.outputs.characteristics.poisson.value"
            tooltipDescription={materials.outputs.characteristics.poisson.description}
            tooltipTarget={materials.outputs.characteristics.poisson.name}
            validate={[required, numberConfirmation]}
          />
          {/* <InputsUnit
            label={materials.outputs.characteristics.thermal.text}
            inputName="materials.outputs.characteristics.thermal.value"
            tooltipDescription={materials.outputs.characteristics.thermal.description}
            tooltipTarget={materials.outputs.characteristics.thermal.name}
          /> */}
          <InputsUnit
            label={materials.outputs.characteristics.shearModulus.text}
            inputName="materials.outputs.characteristics.shearModulus.value"
            selectList={optionsStressUnits}
            selectName="materials.outputs.characteristics.eYoung.unit.name"
            tooltipDescription={materials.outputs.characteristics.shearModulus.description}
            tooltipTarget={materials.outputs.characteristics.shearModulus.name}
            validate={[required, numberConfirmation]}
          />
        </Col>
      </Row>
      <Row>
        <Col xs={12}>
          <Label style={{ color: 'red' }}>{errorMessage}</Label>
        </Col>
      </Row>
      <Row>
        <Col xs={4} className="align-center">
          <Button
            className="negativeButton"
            outline
            color="primary"
            onClick={() => {
              changeView('Select');
            }}
          >
            {'Back'}
          </Button>
        </Col>
        <Col xs={4} className="align-center" />
        <Col xs={4} className="align-center">
          <Button
            className="negativeButton"
            outline
            color="primary"
            disabled={isFormMaterialValidate(materials.outputs)}
            onClick={() => {
              const newMaterial = {
                name: materials.outputs.name,
                text: materials.outputs.text,
                value: materials.outputs.name,
                label: materials.outputs.name,
                description: materials.outputs.description,
                characteristics: {
                  ...materials.outputs.characteristics
                }
              };
              const indexSameName = materials.value.findIndex(item => item.value === materials.outputs.name);
              if (view === 'Edit') {
                const index = materials.value.findIndex(item => item.value === materials.active);
                if (index === indexSameName || indexSameName === -1) {
                  editFields('materials', index, newMaterial);
                  const list = materials.value;
                  changeListSelection('sections', 'material', list, index, newMaterial.name);
                  changeView('Select');
                } else {
                  showErrorMessage('this material already exists');
                }
              } else if (indexSameName === -1) {
                addFields('materials', newMaterial);
                changeView('Select');
              } else {
                showErrorMessage('this material already exists');
              }
            }}
          >
            {view === 'Edit' ? 'Edit' : 'Add'}
          </Button>
        </Col>
      </Row>
    </div>
  );
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({
    addFields,
    editFields,
    changeListSelection
  }, dispatch);
};

export default connect(null, mapDispatchToProps)(FormMaterial);
