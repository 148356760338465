import conversionUnits from './conversionUnits';
import decomposeUnits from './decomposeUnits';

const conversionValue = (number, oldunit, newunit) => {
  let res = number;
  for (let i = 0; i < oldunit.length; i += 1) {
    res *= (conversionUnits[oldunit[i].unit][newunit[i].unit] ** oldunit[i].pow);
  }

  return Math.round(res * 100000) / 100000;
};

const conversionValue2 = (number, oldUnitName, newUnitName) => {
  const oldUnitSpec = decomposeUnits[oldUnitName].unitSpec;
  const newUnitSpec = decomposeUnits[newUnitName].unitSpec;
  let res = number;
  for (let i = 0; i < oldUnitSpec.length; i += 1) {
    res *= (conversionUnits[oldUnitSpec[i].unit][newUnitSpec[i].unit] ** oldUnitSpec[i].pow);
  }

  return Math.round(res * 100000) / 100000;
};

const conversionArray = (array, oldunit, newunit) => {
  const res = array;
  for (let j = 0; j < array.length; j += 1) {
    for (let i = 0; i < oldunit.length; i += 1) {
      res[j] *= (conversionUnits[oldunit[i].unit][newunit[i].unit] ** oldunit[i].pow);
    }
    res[j] = Math.round(res[j] * 1000) / 1000;
  }

  return res;
  // return Math.round(res * 1000) / 1000;
};


const convert = (number, oldUnitName, newUnitName) => {
  const oldUnitSpec = decomposeUnits[oldUnitName].unitSpec;
  const newUnitSpec = decomposeUnits[newUnitName].unitSpec;
  let res = number;
  for (let i = 0; i < oldUnitSpec.length; i += 1) {
    res *= (conversionUnits[oldUnitSpec[i].unit][newUnitSpec[i].unit] ** oldUnitSpec[i].pow);
  }

  return Math.round(res * 100000) / 100000;
};

export {
  conversionValue,
  conversionValue2,
  conversionArray,
  convert
};
