import * as types from './actionTypes';
import initialState from './initialState';

const Applications = (state = initialState.applications, action) => {
  switch (action.type) {
    case types.SEARCH_APP:
      return {
        ...state,
        plans: action.payload
      };
    default:
      return state;
  }
};

export default Applications;
