import { CSS2DObject } from 'three-css2drender';

export default (
  data
) => {
  const div = document.createElement('div');
  div.className = `${data.type}-label`;
  div.textContent = data.text;
  div.style.marginTop = '-1em';
  const label = new CSS2DObject(div);
  label.position.set(data.x, data.y, data.z);
  label.userData = {
    data: data.object,
    color: 'black'
  };
  data.mesh.push(label);
  data.scene.add(label);
};
