const initialState = {
  rectangularSection: {
    values: {
      options: {
        sectionname: {
          name: 'sectionname',
          text: 'Name',
          description: 'Project Name',
          value: 'Section Name',
          unit: '_'
        },
        unit: {
          name: 'unit',
          text: 'Units',
          description: 'Unit system',
          value: { label: 'm-MN-kg', value: 'mMNkg' },
          unit: '_'
        }
      },
      geometry: {
        concrete: {
          unit: { name: 'm', text: 'm' },
          b: {
            name: 'b',
            text: 'b',
            value: 2,
            description: 'Section Width',
            unit: { name: 'm', text: 'm' }
          },
          h: {
            name: 'h',
            text: 'h',
            value: 2,
            description: 'Section Height',
            unit: { name: 'm', text: 'm' }
          },
          sectionext: [
            { x: 0, y: 0 },
            { x: 2, y: 0 },
            { x: 2, y: 2 },
            { x: 0, y: 2 },
            { x: 0, y: 0 }
          ],
          sectionint: [
            { x: 0, y: 0 }
          ]
        }
      },
      concrete: {
        fck: {
          name: 'fck',
          text: 'fck',
          description: 'Concrete Strength',
          value: 35,
          unit: { name: 'MPa', text: 'MPa' }
        },
        gammac: {
          name: 'gammac',
          text: 'ϒc',
          description: 'Concrete Partial Factor',
          value: 1.5,
          unit: '_'
        },
        alphacc: {
          name: 'alphacc',
          text: 'αcc',
          description: 'Concrete Coefficient',
          value: 1,
          unit: '_'
        }
      },
      steel: {
        fyk: {
          name: 'fyk',
          text: 'fyk',
          description: 'Steel Strength',
          value: 500,
          unit: { name: 'MPa', text: 'MPa' }
        },
        gammas: {
          name: 'gammas',
          text: 'ϒs',
          description: 'Steel Partial Factor',
          value: 1.15,
          unit: '_'
        },
        Es: {
          name: 'Es',
          text: 'Es',
          description: 'Steel Young Modulus',
          value: 200000,
          unit: { name: 'MPa', text: 'MPa' }
        },
        ductilityClass: {
          name: 'ductilityClass',
          text: 'Ductility Class',
          description: 'Ductility Class',
          value: 'A',
          list: [
            { label: 'A', value: 'A' },
            { label: 'B', value: 'B' },
            { label: 'C', value: 'C' }
          ]
        }
      },
      reinforcement: {
        unit: { name: 'm', text: 'm' },
        unitDiameter: { name: 'm', text: 'm' },
        geometry: [
          {
            x: 0.1, y: 0.1, number: 1, diameter: 0.032
          },
          {
            x: 0.1, y: 1.90, number: 1, diameter: 0.032
          },
          {
            x: 1.9, y: 0.1, number: 1, diameter: 0.032
          },
          {
            x: 1.9, y: 1.90, number: 1, diameter: 0.032
          }
        ]
      },
      loads: {
        Nsls: {
          name: 'Nsls',
          text: 'N,sls',
          description: 'Axial Load at SLS',
          value: 0,
          unit: { name: 'kN', text: 'kN' }
        },
        Mxsls: {
          name: 'Mxsls',
          text: 'Mx,sls',
          description: 'Bending Moment around x axis at SLS.',
          value: 500,
          unit: { name: 'kNm', text: 'kN.m' }
        },
        Mysls: {
          name: 'Mysls',
          text: 'My,sls',
          description: 'Bending Moment around y axis at SLS',
          value: 0,
          unit: { name: 'kNm', text: 'kN.m' }
        },
        Nuls: {
          name: 'Nuls',
          text: 'N,uls',
          description: 'Axial Load at ULS',
          value: 0,
          unit: { name: 'kN', text: 'kN' }
        },
        Mxuls: {
          name: 'Mxuls',
          text: 'Mx,uls',
          description: 'Bending Moment around x axis at ULS ',
          value: 750,
          unit: { name: 'kNm', text: 'kN.m' }
        },
        Myuls: {
          name: 'Myuls',
          text: 'My,uls',
          description: 'Bending Moment around y axis at ULS',
          value: 0,
          unit: { name: 'kNm', text: 'kN.m' }
        }
      }
    }
  },
  tSection: {
    values: {
      options: {
        sectionname: {
          name: 'sectionname',
          text: 'Name',
          description: 'Project Name',
          value: 'Section Name',
          unit: '_'
        },
        unit: {
          name: 'unit',
          text: 'Units',
          description: 'Unit system',
          value: { label: 'm-MN-kg', value: 'mMNkg' },
          unit: '_'
        }
      },
      geometry: {
        concrete: {
          unit: { name: 'm', text: 'm' },
          b: {
            name: 'b',
            text: 'b',
            value: 2,
            description: 'Section Width',
            unit: { name: 'm', text: 'm' }
          },
          h: {
            name: 'h',
            text: 'h',
            value: 2,
            description: 'Section Height',
            unit: { name: 'm', text: 'm' }
          },
          b0: {
            name: 'b0',
            text: 'b0',
            value: 0.5,
            description: 'Section Web Width',
            unit: { name: 'm', text: 'm' }
          },
          h0: {
            name: 'h0',
            text: 'h0',
            value: 0.5,
            description: 'Height of the top flange of the section',
            unit: { name: 'm', text: 'm' }
          },
          sectionext: [
            { x: 0, y: 0 }
          ],
          sectionint: [
            { x: 0, y: 0 }
          ]
        }
      },
      concrete: {
        fck: {
          name: 'fck',
          text: 'fck',
          description: 'Concrete Strength',
          value: 35,
          unit: { name: 'MPa', text: 'MPa' }
        },
        gammac: {
          name: 'gammac',
          text: 'ϒc',
          description: 'Concrete Partial Factor',
          value: 1.5,
          unit: '_'
        },
        alphacc: {
          name: 'alphacc',
          text: 'αcc',
          description: 'Concrete Coefficient',
          value: 1,
          unit: '_'
        }
      },
      steel: {
        fyk: {
          name: 'fyk',
          text: 'fyk',
          description: 'Steel Strength',
          value: 500,
          unit: { name: 'MPa', text: 'MPa' }
        },
        gammas: {
          name: 'gammas',
          text: 'ϒs',
          description: 'Steel Partial Factor',
          value: 1.15,
          unit: '_'
        },
        Es: {
          name: 'Es',
          text: 'Es',
          description: 'Steel Young Modulus',
          value: 200000,
          unit: { name: 'MPa', text: 'MPa' }
        },
        ductilityClass: {
          name: 'ductilityClass',
          text: 'Ductility Class',
          description: 'Ductility Class',
          value: 'A',
          list: [
            { label: 'A', value: 'A' },
            { label: 'B', value: 'B' },
            { label: 'C', value: 'C' }
          ]
        }
      },
      reinforcement: {
        unit: { name: 'm', text: 'm' },
        unitDiameter: { name: 'm', text: 'm' },
        geometry: [
          {
            x: 0.8, y: 0.1, number: 1, diameter: 0.032
          },
          {
            x: 1.2, y: 0.1, number: 1, diameter: 0.032
          },
          {
            x: 0.1, y: 1.90, number: 1, diameter: 0.032
          },
          {
            x: 1.9, y: 1.90, number: 1, diameter: 0.032
          }
        ]
      },
      loads: {
        Nsls: {
          name: 'Nsls',
          text: 'N,sls',
          description: 'Axial Load at SLS',
          value: 0,
          unit: { name: 'kN', text: 'kN' }
        },
        Mxsls: {
          name: 'Mxsls',
          text: 'Mx,sls',
          description: 'Bending Moment around x axis at SLS.',
          value: 500,
          unit: { name: 'kNm', text: 'kN.m' }
        },
        Mysls: {
          name: 'Mysls',
          text: 'My,sls',
          description: 'Bending Moment around y axis at SLS',
          value: 0,
          unit: { name: 'kNm', text: 'kN.m' }
        },
        Nuls: {
          name: 'Nuls',
          text: 'N,uls',
          description: 'Axial Load at ULS',
          value: 0,
          unit: { name: 'kN', text: 'kN' }
        },
        Mxuls: {
          name: 'Mxuls',
          text: 'Mx,uls',
          description: 'Bending Moment around x axis at ULS ',
          value: 750,
          unit: { name: 'kNm', text: 'kN.m' }
        },
        Myuls: {
          name: 'Myuls',
          text: 'My,uls',
          description: 'Bending Moment around y axis at ULS',
          value: 0,
          unit: { name: 'kNm', text: 'kN.m' }
        }
      }
    }
  },
  iSection: {
    values: {
      options: {
        sectionname: {
          name: 'sectionname',
          text: 'Name',
          description: 'Project Name',
          value: 'Section Name',
          unit: '_'
        },
        unit: {
          name: 'unit',
          text: 'Units',
          description: 'Unit system',
          value: { label: 'm-MN-kg', value: 'mMNkg' },
          unit: '_'
        }
      },
      geometry: {
        concrete: {
          unit: { name: 'm', text: 'm' },
          b: {
            name: 'b',
            text: 'b',
            value: 0.5,
            description: 'Section Width',
            unit: 'm'
          },
          h: {
            name: 'h',
            text: 'h',
            value: 2,
            description: 'Section Height',
            unit: 'm'
          },
          b0: {
            name: 'b0',
            text: 'b0',
            value: 2,
            description: 'Width of the top flange of the section',
            unit: 'm'
          },
          h0: {
            name: 'h0',
            text: 'h0',
            value: 0.5,
            description: 'Height of the top flange of the section',
            unit: 'm'
          },
          b1: {
            name: 'b1',
            text: 'b1',
            value: 1,
            description: 'Width of the bottom flange of the section',
            unit: 'm'
          },
          h1: {
            name: 'h1',
            text: 'h1',
            value: 0.5,
            description: 'Height of the bottom flange of the section',
            unit: 'm'
          },
          sectionext: [
            { x: 0, y: 0 }
          ],
          sectionint: [
            { x: 0, y: 0 }
          ]
        }
      },
      concrete: {
        fck: {
          name: 'fck',
          text: 'fck',
          description: 'Concrete Strength',
          value: 35,
          unit: { name: 'MPa', text: 'MPa' }
        },
        gammac: {
          name: 'gammac',
          text: 'ϒc',
          description: 'Concrete Partial Factor',
          value: 1.5,
          unit: '_'
        },
        alphacc: {
          name: 'alphacc',
          text: 'αcc',
          description: 'Concrete Coefficient',
          value: 1,
          unit: '_'
        }
      },
      steel: {
        fyk: {
          name: 'fyk',
          text: 'fyk',
          description: 'Steel Strength',
          value: 500,
          unit: { name: 'MPa', text: 'MPa' }
        },
        gammas: {
          name: 'gammas',
          text: 'ϒs',
          description: 'Steel Partial Factor',
          value: 1.15,
          unit: '_'
        },
        Es: {
          name: 'Es',
          text: 'Es',
          description: 'Steel Young Modulus',
          value: 200000,
          unit: { name: 'MPa', text: 'MPa' }
        },
        ductilityClass: {
          name: 'ductilityClass',
          text: 'Ductility Class',
          description: 'Ductility Class',
          value: 'A',
          list: [
            { label: 'A', value: 'A' },
            { label: 'B', value: 'B' },
            { label: 'C', value: 'C' }
          ]
        }
      },
      reinforcement: {
        unit: { name: 'm', text: 'm' },
        unitDiameter: { name: 'm', text: 'm' },
        geometry: [
          {
            x: -0.2, y: 0.1, number: 1, diameter: 0.032
          },
          {
            x: 0.2, y: 0.1, number: 1, diameter: 0.032
          },
          {
            x: -0.8, y: 1.90, number: 1, diameter: 0.032
          },
          {
            x: 0.8, y: 1.90, number: 1, diameter: 0.032
          }
        ]
      },
      loads: {
        Nsls: {
          name: 'Nsls',
          text: 'N,sls',
          description: 'Axial Load at SLS',
          value: 0,
          unit: { name: 'kN', text: 'kN' }
        },
        Mxsls: {
          name: 'Mxsls',
          text: 'Mx,sls',
          description: 'Bending Moment around x axis at SLS.',
          value: 500,
          unit: { name: 'kNm', text: 'kN.m' }
        },
        Mysls: {
          name: 'Mysls',
          text: 'My,sls',
          description: 'Bending Moment around y axis at SLS',
          value: 0,
          unit: { name: 'kNm', text: 'kN.m' }
        },
        Nuls: {
          name: 'Nuls',
          text: 'N,uls',
          description: 'Axial Load at ULS',
          value: 0,
          unit: { name: 'kN', text: 'kN' }
        },
        Mxuls: {
          name: 'Mxuls',
          text: 'Mx,uls',
          description: 'Bending Moment around x axis at ULS ',
          value: 750,
          unit: { name: 'kNm', text: 'kN.m' }
        },
        Myuls: {
          name: 'Myuls',
          text: 'My,uls',
          description: 'Bending Moment around y axis at ULS',
          value: 0,
          unit: { name: 'kNm', text: 'kN.m' }
        }
      }
    }
  },
  circleSection: {
    values: {
      options: {
        sectionname: {
          name: 'sectionname',
          text: 'Name',
          description: 'Project Name',
          value: 'Section Name',
          unit: '_'
        },
        unit: {
          name: 'unit',
          text: 'Units',
          description: 'Unit system',
          value: { label: 'm-MN-kg', value: 'mMNkg' },
          unit: '_'
        }
      },
      geometry: {
        concrete: {
          unit: { name: 'm', text: 'm' },
          D: {
            name: 'D',
            text: 'D',
            value: 2,
            description: 'Diameter of the section',
            unit: 'm'
          },
          sectionext: [
            { x: 0, y: 0 }
          ],
          sectionint: [
            { x: 0, y: 0 }
          ]
        }
      },
      concrete: {
        fck: {
          name: 'fck',
          text: 'fck',
          description: 'Concrete Strength',
          value: 35,
          unit: { name: 'MPa', text: 'MPa' }
        },
        gammac: {
          name: 'gammac',
          text: 'ϒc',
          description: 'Concrete Partial Factor',
          value: 1.5,
          unit: '_'
        },
        alphacc: {
          name: 'alphacc',
          text: 'αcc',
          description: 'Concrete Coefficient',
          value: 1,
          unit: '_'
        }
      },
      steel: {
        fyk: {
          name: 'fyk',
          text: 'fyk',
          description: 'Steel Strength',
          value: 500,
          unit: { name: 'MPa', text: 'MPa' }
        },
        gammas: {
          name: 'gammas',
          text: 'ϒs',
          description: 'Steel Partial Factor',
          value: 1.15,
          unit: '_'
        },
        Es: {
          name: 'Es',
          text: 'Es',
          description: 'Steel Young Modulus',
          value: 200000,
          unit: { name: 'MPa', text: 'MPa' }
        },
        ductilityClass: {
          name: 'ductilityClass',
          text: 'Ductility Class',
          description: 'Ductility Class',
          value: 'A',
          list: [
            { label: 'A', value: 'A' },
            { label: 'B', value: 'B' },
            { label: 'C', value: 'C' }
          ]
        }
      },
      reinforcement: {
        unit: { name: 'm', text: 'm' },
        unitDiameter: { name: 'm', text: 'm' },
        geometry: [
          {
            x: 0.85, y: 0, number: 1, diameter: 0.032
          },
          {
            x: 0.776513639, y: 0.345726147, number: 1, diameter: 0.032
          },
          {
            x: 0.568761015, y: 0.631673102, number: 1, diameter: 0.032
          },
          {
            x: 0.262664445, y: 0.808398039, number: 1, diameter: 0.032
          },
          {
            x: -0.088849194, y: 0.845343611, number: 1, diameter: 0.032
          },
          {
            x: -0.425, y: 0.736121593, number: 1, diameter: 0.032
          },
          {
            x: -0.687664445, y: 0.499617464, number: 1, diameter: 0.032
          },
          {
            x: -0.831425461, y: 0.176724937, number: 1, diameter: 0.032
          },
          {
            x: -0.831425461, y: -0.176724937, number: 1, diameter: 0.032
          },
          {
            x: -0.687664445, y: -0.499617464, number: 1, diameter: 0.032
          },
          {
            x: -0.425, y: -0.736121593, number: 1, diameter: 0.032
          },
          {
            x: -0.088849194, y: -0.845343611, number: 1, diameter: 0.032
          },
          {
            x: 0.262664445, y: -0.808398039, number: 1, diameter: 0.032
          },
          {
            x: 0.568761015, y: -0.631673102, number: 1, diameter: 0.032
          },
          {
            x: 0.776513639, y: -0.345726147, number: 1, diameter: 0.032
          }
        ]
      },
      loads: {
        Nsls: {
          name: 'Nsls',
          text: 'N,sls',
          description: 'Axial Load at SLS',
          value: 0,
          unit: { name: 'kN', text: 'kN' }
        },
        Mxsls: {
          name: 'Mxsls',
          text: 'Mx,sls',
          description: 'Bending Moment around x axis at SLS.',
          value: 2000,
          unit: { name: 'kNm', text: 'kN.m' }
        },
        Mysls: {
          name: 'Mysls',
          text: 'My,sls',
          description: 'Bending Moment around y axis at SLS',
          value: 0,
          unit: { name: 'kNm', text: 'kN.m' }
        },
        Nuls: {
          name: 'Nuls',
          text: 'N,uls',
          description: 'Axial Load at ULS',
          value: 0,
          unit: { name: 'kN', text: 'kN' }
        },
        Mxuls: {
          name: 'Mxuls',
          text: 'Mx,uls',
          description: 'Bending Moment around x axis at ULS ',
          value: 3500,
          unit: { name: 'kNm', text: 'kN.m' }
        },
        Myuls: {
          name: 'Myuls',
          text: 'My,uls',
          description: 'Bending Moment around y axis at ULS',
          value: 0,
          unit: { name: 'kNm', text: 'kN.m' }
        }
      }
    }
  },
  hollowCircleSection: {
    values: {
      options: {
        sectionname: {
          name: 'sectionname',
          text: 'Name',
          description: 'Project Name',
          value: 'Section Name',
          unit: '_'
        },
        unit: {
          name: 'unit',
          text: 'Units',
          description: 'Unit system',
          value: { label: 'm-MN-kg', value: 'mMNkg' },
          unit: '_'
        }
      },
      geometry: {
        concrete: {
          unit: { name: 'm', text: 'm' },
          D: {
            name: 'D',
            text: 'D',
            value: 2,
            description: 'Diameter of the section',
            unit: 'm'
          },
          t: {
            name: 't',
            text: 't',
            value: 0.3,
            description: 'Thickness of the section',
            unit: 'm'
          },
          sectionext: [
            { x: 0, y: 0 }
          ],
          sectionint: [
            { x: 0, y: 0 }
          ]
        }
      },
      concrete: {
        fck: {
          name: 'fck',
          text: 'fck',
          description: 'Concrete Strength',
          value: 35,
          unit: { name: 'MPa', text: 'MPa' }
        },
        gammac: {
          name: 'gammac',
          text: 'ϒc',
          description: 'Concrete Partial Factor',
          value: 1.5,
          unit: '_'
        },
        alphacc: {
          name: 'alphacc',
          text: 'αcc',
          description: 'Concrete Coefficient',
          value: 1,
          unit: '_'
        }
      },
      steel: {
        fyk: {
          name: 'fyk',
          text: 'fyk',
          description: 'Steel Strength',
          value: 500,
          unit: { name: 'MPa', text: 'MPa' }
        },
        gammas: {
          name: 'gammas',
          text: 'ϒs',
          description: 'Steel Partial Factor',
          value: 1.15,
          unit: '_'
        },
        Es: {
          name: 'Es',
          text: 'Es',
          description: 'Steel Young Modulus',
          value: 200000,
          unit: { name: 'MPa', text: 'MPa' }
        },
        ductilityClass: {
          name: 'ductilityClass',
          text: 'Ductility Class',
          description: 'Ductility Class',
          value: 'A',
          list: [
            { label: 'A', value: 'A' },
            { label: 'B', value: 'B' },
            { label: 'C', value: 'C' }
          ]
        }
      },
      reinforcement: {
        unit: { name: 'm', text: 'm' },
        unitDiameter: { name: 'm', text: 'm' },
        geometry: [
          {
            x: 0.85, y: 0, number: 1, diameter: 0.032
          },
          {
            x: 0.776513639, y: 0.345726147, number: 1, diameter: 0.032
          },
          {
            x: 0.568761015, y: 0.631673102, number: 1, diameter: 0.032
          },
          {
            x: 0.262664445, y: 0.808398039, number: 1, diameter: 0.032
          },
          {
            x: -0.088849194, y: 0.845343611, number: 1, diameter: 0.032
          },
          {
            x: -0.425, y: 0.736121593, number: 1, diameter: 0.032
          },
          {
            x: -0.687664445, y: 0.499617464, number: 1, diameter: 0.032
          },
          {
            x: -0.831425461, y: 0.176724937, number: 1, diameter: 0.032
          },
          {
            x: -0.831425461, y: -0.176724937, number: 1, diameter: 0.032
          },
          {
            x: -0.687664445, y: -0.499617464, number: 1, diameter: 0.032
          },
          {
            x: -0.425, y: -0.736121593, number: 1, diameter: 0.032
          },
          {
            x: -0.088849194, y: -0.845343611, number: 1, diameter: 0.032
          },
          {
            x: 0.262664445, y: -0.808398039, number: 1, diameter: 0.032
          },
          {
            x: 0.568761015, y: -0.631673102, number: 1, diameter: 0.032
          },
          {
            x: 0.776513639, y: -0.345726147, number: 1, diameter: 0.032
          }
        ]
      },
      loads: {
        Nsls: {
          name: 'Nsls',
          text: 'N,sls',
          description: 'Axial Load at SLS',
          value: 0,
          unit: { name: 'kN', text: 'kN' }
        },
        Mxsls: {
          name: 'Mxsls',
          text: 'Mx,sls',
          description: 'Bending Moment around x axis at SLS.',
          value: 2000,
          unit: { name: 'kNm', text: 'kN.m' }
        },
        Mysls: {
          name: 'Mysls',
          text: 'My,sls',
          description: 'Bending Moment around y axis at SLS',
          value: 0,
          unit: { name: 'kNm', text: 'kN.m' }
        },
        Nuls: {
          name: 'Nuls',
          text: 'N,uls',
          description: 'Axial Load at ULS',
          value: 0,
          unit: { name: 'kN', text: 'kN' }
        },
        Mxuls: {
          name: 'Mxuls',
          text: 'Mx,uls',
          description: 'Bending Moment around x axis at ULS ',
          value: 3500,
          unit: { name: 'kNm', text: 'kN.m' }
        },
        Myuls: {
          name: 'Myuls',
          text: 'My,uls',
          description: 'Bending Moment around y axis at ULS',
          value: 0,
          unit: { name: 'kNm', text: 'kN.m' }
        }
      }
    }
  },
  randomSection: {
    values: {
      options: {
        sectionname: {
          name: 'sectionname',
          text: 'Name',
          description: 'Project Name',
          value: 'Section Name',
          unit: '_'
        },
        unit: {
          name: 'unit',
          text: 'Units',
          description: 'Unit system',
          value: { label: 'm-MN-kg', value: 'mMNkg' },
          unit: '_'
        }
      },
      geometry: {
        concrete: {
          unit: { name: 'm', text: 'm' },
          sectionext: [
            { x: 0, y: 0 },
            { x: 1, y: 0 },
            { x: 2, y: 2.75 },
            { x: 6, y: 2.75 },
            { x: 6, y: 3 },
            { x: 0, y: 3 },
            { x: -6, y: 3 },
            { x: -6, y: 2.75 },
            { x: -2, y: 2.75 },
            { x: -1, y: 0 },
            { x: 0, y: 0 }
          ],
          sectionint: [
            { x: 0, y: 0.5 },
            { x: 0.86259909334401, y: 0.5 },
            { x: 1.62623545698037, y: 2.6 },
            { x: -1.62623545698037, y: 2.6 },
            { x: -0.86259909334401, y: 0.5 },
            { x: 0, y: 0.5 }
          ]
        }
      },
      concrete: {
        fck: {
          name: 'fck',
          text: 'fck',
          description: 'Concrete Strength',
          value: 35,
          unit: { name: 'MPa', text: 'MPa' }
        },
        gammac: {
          name: 'gammac',
          text: 'ϒc',
          description: 'Concrete Partial Factor',
          value: 1.5,
          unit: '_'
        },
        alphacc: {
          name: 'alphacc',
          text: 'αcc',
          description: 'Concrete Coefficient',
          value: 1,
          unit: '_'
        }
      },
      steel: {
        fyk: {
          name: 'fyk',
          text: 'fyk',
          description: 'Steel Strength',
          value: 500,
          unit: { name: 'MPa', text: 'MPa' }
        },
        gammas: {
          name: 'gammas',
          text: 'ϒs',
          description: 'Steel Partial Factor',
          value: 1.15,
          unit: '_'
        },
        Es: {
          name: 'Es',
          text: 'Es',
          description: 'Steel Young Modulus',
          value: 200000,
          unit: { name: 'MPa', text: 'MPa' }
        },
        ductilityClass: {
          name: 'ductilityClass',
          text: 'Ductility Class',
          description: 'Ductility Class',
          value: 'A',
          list: [
            { label: 'A', value: 'A' },
            { label: 'B', value: 'B' },
            { label: 'C', value: 'C' }
          ]
        }
      },
      reinforcement: {
        unit: { name: 'm', text: 'm' },
        unitDiameter: { name: 'm', text: 'm' },
        geometry: [
          {
            x: -0.875, y: 0.05, number: 1, diameter: 0.032
          },
          {
            x: -0.625, y: 0.05, number: 1, diameter: 0.032
          },
          {
            x: -0.375, y: 0.05, number: 1, diameter: 0.032
          },
          {
            x: -0.125, y: 0.05, number: 1, diameter: 0.032
          },
          {
            x: 0.125, y: 0.05, number: 1, diameter: 0.032
          },
          {
            x: 0.375, y: 0.05, number: 1, diameter: 0.032
          },
          {
            x: 0.625, y: 0.05, number: 1, diameter: 0.032
          },
          {
            x: 0.875, y: 0.05, number: 1, diameter: 0.032
          },
          {
            x: -5.625, y: 2.95, number: 1, diameter: 0.020
          },
          {
            x: -4.875, y: 2.95, number: 1, diameter: 0.020
          },
          {
            x: -4.125, y: 2.95, number: 1, diameter: 0.020
          },
          {
            x: -3.375, y: 2.95, number: 1, diameter: 0.020
          },
          {
            x: -2.625, y: 2.95, number: 1, diameter: 0.020
          },
          {
            x: -1.875, y: 2.95, number: 1, diameter: 0.020
          },
          {
            x: -1.125, y: 2.95, number: 1, diameter: 0.020
          },
          {
            x: -0.375, y: 2.95, number: 1, diameter: 0.020
          },
          {
            x: 0.375, y: 2.95, number: 1, diameter: 0.020
          },
          {
            x: 1.125, y: 2.95, number: 1, diameter: 0.020
          },
          {
            x: 1.875, y: 2.95, number: 1, diameter: 0.020
          },
          {
            x: 2.625, y: 2.95, number: 1, diameter: 0.020
          },
          {
            x: 3.375, y: 2.95, number: 1, diameter: 0.020
          },
          {
            x: 4.125, y: 2.95, number: 1, diameter: 0.020
          },
          {
            x: 4.875, y: 2.95, number: 1, diameter: 0.020
          },
          {
            x: 5.625, y: 2.95, number: 1, diameter: 0.020
          }
        ]
      },
      loads: {
        Nsls: {
          name: 'Nsls',
          text: 'N,sls',
          description: 'Axial Load at SLS',
          value: 0,
          unit: { name: 'kN', text: 'kN' }
        },
        Mxsls: {
          name: 'Mxsls',
          text: 'Mx,sls',
          description: 'Bending Moment around x axis at SLS.',
          value: 4500,
          unit: { name: 'kNm', text: 'kN.m' }
        },
        Mysls: {
          name: 'Mysls',
          text: 'My,sls',
          description: 'Bending Moment around y axis at SLS',
          value: 0,
          unit: { name: 'kNm', text: 'kN.m' }
        },
        Nuls: {
          name: 'Nuls',
          text: 'N,uls',
          description: 'Axial Load at ULS',
          value: 0,
          unit: { name: 'kN', text: 'kN' }
        },
        Mxuls: {
          name: 'Mxuls',
          text: 'Mx,uls',
          description: 'Bending Moment around x axis at ULS ',
          value: 7500,
          unit: { name: 'kNm', text: 'kN.m' }
        },
        Myuls: {
          name: 'Myuls',
          text: 'My,uls',
          description: 'Bending Moment around y axis at ULS',
          value: 0,
          unit: { name: 'kNm', text: 'kN.m' }
        }
      }
    }
  },
  outputs: {
    sectionData: {
      xg: {
        name: 'xg',
        text: 'xg',
        value: 0,
        description: 'Section center of gravity x coordinate',
        unit: 'm'
      },
      yg: {
        name: 'yg',
        text: 'yg',
        value: 0,
        description: 'Section center of gravity y coordinate',
        unit: 'm'
      },
      area: {
        name: 'area',
        text: 'Area',
        value: 0,
        description: 'Area of the Section',
        unit: 'm2'
      },
      Pext: {
        name: 'Pext',
        text: 'Pext',
        value: 0,
        description: 'Outer Perimeter',
        unit: 'm'
      },
      Pint: {
        name: 'Pint',
        text: 'Pint',
        value: 0,
        description: 'Inside Perimeter',
        unit: 'm'
      },
      ixx: {
        name: 'ixx',
        text: 'Ixx',
        value: 0,
        description: 'Inertia at the Center of Gravity and related to x axis of the section',
        unit: 'm4'
      },
      iyy: {
        name: 'iyy',
        text: 'Iyy',
        value: 0,
        description: 'Inertia at the Center of Gravity and related to y axis of the section',
        unit: 'm4'
      },
      ixy: {
        name: 'ixy',
        text: 'Ixy',
        value: 0,
        description: 'Product moment of area at the Center of Gravity',
        unit: 'm4'
      }
    },
    sls: {
      resCalculSls: {
        A: '',
        B: '',
        C: '',
        accuracy: ''
      },
      resNeutralAxis: {
        naA: { x: 0, y: 0 },
        naB: { x: 0, y: 0 }
      },
      resExtremStress: {
        concrete: {
          sigmacmin: {
            name: 'sigmacmin',
            text: 'σc,min',
            description: 'Minimum stress in concrete',
            value: 0,
            unit: 'MPa',
            coordinate: { x: 0, y: 0, stress: 0 }
          },
          sigmacmax: {
            name: 'sigmacmax',
            text: 'σc,max',
            description: 'Maximum stress in concrete',
            value: 0,
            unit: 'MPa',
            coordinate: { x: 0, y: 0, stress: 0 }
          }
        },
        steel: {
          sigmasmax: {
            name: 'sigmasmax',
            text: 'σs,max',
            description: 'Maximum traction in steel reinforcement',
            value: 0,
            unit: 'MPa',
            coordinate: { x: 0, y: 0, stress: 0 }
          },
          sigmar: {
            unit: 'm',
            unitDiameter: 'mm',
            unitStress: 'MPa',
            geometry: [
              {
                x: 0, y: 0, number: 0, diameter: 0, stress: 0
              }
            ]
          }
        }
      }
    },
    uls: {
      resCalculUls: {
        A: '',
        B: '',
        C: '',
        accuracy: '',
        msg: ''
      },
      resNeutralAxis: {
        naA: { x: 0, y: 0 },
        naB: { x: 0, y: 0 }
      },
      resExtremStrain: {
        concrete: {
          epsicmin: {
            name: 'epsicmin',
            text: 'εc,min',
            description: 'Minimum strain in concrete',
            value: 0,
            unit: 'perThousand',
            coordinate: { x: 0, y: 0, strain: 0 }
          },
          epsicmax: {
            name: 'epsicmax',
            text: 'εc,max',
            description: 'Maximum strain in concrete',
            value: 0,
            unit: 'perThousand',
            coordinate: { x: 0, y: 0, strain: 0 }
          }
        },
        steel: {
          epsismax: {
            name: 'epsismax',
            text: 'εs,max',
            description: 'Maximum strain in steel reinforcement',
            value: 0,
            unit: 'perThousand',
            coordinate: { x: 0, y: 0, strain: 0 }
          },
          epsir: {
            unit: 'm',
            unitDiameter: 'mm',
            unitStrain: '‰',
            geometry: [
              {
                x: 0, y: 0, number: 0, diameter: 0, strain: 0
              }
            ]
          }
        }
      },
      interactionDiagram: {
        unitForce: { name: 'kN', text: 'kN' },
        unitMoment: { name: 'kNOnm', text: 'kN.m' },
        value: [{ x: 0, y: 0 }]
      }
    }
  }
};

export default initialState;
