import React from 'react';
import { Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import ConnectionBackground from '../../components/GraphicModules/Background/ConnectionBackground';
import FormSignIn from './components/FormSignIn';

import { postSignIn } from './actions';


const SignIn = ({ userSignIn, authMessage }) => {
  return (
    <div className="authentication-background">
      <ConnectionBackground className="connection-background" />
      <FormSignIn
        onSubmit={userSignIn}
        authMessage={authMessage}
      />
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.signin.user
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    userSignIn: (user) => { postSignIn(dispatch, user); }
  };
};


SignIn.propTypes = {
  userSignIn: PropTypes.func.isRequired,
  authMessage: PropTypes.string.isRequired
};

export default connect(mapStateToProps, mapDispatchToProps)(SignIn);
