import * as THREE from 'three';
import showLabel from '../ShowLabel';

const lengthForce = (valueForce) => {
  let result = 0;
  const absValue = Math.abs(valueForce);
  if (absValue === 0) {
    result = 0;
  } else {
    result = 1.3409 * Math.log(absValue);
    if (absValue < 10) {
      result = Math.max(1, 2.30258 * absValue / 10);
    }
  }
  if (valueForce < 0) {
    return -result;
  }
  return Number(result);
};

export default (name, {
  scene,
  labelMesh,
  memberLoadMesh,
  uiManagement,
  data
}) => {
  const listnodes = data.nodes.value;
  const listmembers = data.members.value;
  const listmemberloads = data.memberLoads.value;

  // Deleting all members mesh
  if (typeof memberLoadMesh !== 'undefined' && memberLoadMesh.length > 0) {
    for (let i = memberLoadMesh.length - 1; i >= 0; i -= 1) {
      scene.remove(memberLoadMesh[i]);
      // memberLoadMesh[i].geometry.dispose();
      // memberLoadMesh[i].material.dispose();
      memberLoadMesh.pop();
    }
  }

  const color = 'red';

  const material = new THREE.MeshPhongMaterial({
    color,
    side: THREE.DoubleSide,
    flatShading: true,
    wireframe: false,
    opacity: 0.1
  });
  material.transparent = true;

  let Ai = {};
  let Aj = {};
  if (uiManagement.showMemberLoad) {
    for (let i = 0; i < listmemberloads.length; i += 1) {
      for (let j = 0; j < listmembers.length; j += 1) {
        if (listmembers[j].name === listmemberloads[i].member) {
          for (let k = 0; k < listnodes.length; k += 1) {
            if (listnodes[k].name === listmembers[j].nodei) {
              // Ai = { X: listnodes[j].X, Y: listnodes[j].Y, Z: listnodes[j].Z };
              Ai = listnodes[k];
            }
            if (listnodes[k].name === listmembers[j].nodej) {
              Aj = listnodes[k];
            }
          }
          const Lij = ((((Aj.X - Ai.X) ** 2) + ((Aj.Y - Ai.Y) ** 2) + ((Aj.Z - Ai.Z) ** 2)) ** 0.5);
          // forceX
          const geometryFx = new THREE.Geometry();
          const lengthFx = lengthForce(listmemberloads[i].forceX);
          let colFX = Math.abs((Aj.X - Ai.X) / Lij);
          if (colFX !== 1) {
            colFX = 1;
          } else {
            colFX = 0;
          }
          geometryFx.vertices.push(new THREE.Vector3(Ai.X, Ai.Y, Ai.Z));
          geometryFx.vertices.push(new THREE.Vector3(Ai.X - lengthFx * colFX, Ai.Y, Ai.Z));
          geometryFx.vertices.push(new THREE.Vector3(Aj.X - lengthFx * colFX, Aj.Y, Aj.Z));
          geometryFx.vertices.push(new THREE.Vector3(Aj.X, Aj.Y, Aj.Z));
          geometryFx.faces.push(new THREE.Face3(0, 1, 2));
          geometryFx.faces.push(new THREE.Face3(2, 3, 0));
          const memberLoadFx = new THREE.Mesh(geometryFx, material);

          memberLoadMesh.push(memberLoadFx);
          scene.add(memberLoadFx);

          if (uiManagement.showMemberLoad && lengthFx !== 0) {
            const labelData = {
              scene,
              mesh: labelMesh,
              type: 'memberLoad',
              object: listmemberloads[i],
              text: `ML(FX=${listmemberloads[i].forceX})`,
              x: (Ai.X + Aj.X) / 2 - lengthFx * colFX,
              y: (Ai.Y + Aj.Y) / 2,
              z: (Ai.Z + Aj.Z) / 2
            };
            showLabel(labelData);
          }

          // forceY
          const geometryFy = new THREE.Geometry();
          const lengthFy = lengthForce(listmemberloads[i].forceY);
          let colFY = Math.abs((Aj.Y - Ai.Y) / Lij);
          if (colFY !== 1) {
            colFY = 1;
          } else {
            colFY = 0;
          }
          geometryFy.vertices.push(new THREE.Vector3(Ai.X, Ai.Y, Ai.Z));
          geometryFy.vertices.push(new THREE.Vector3(Ai.X, Ai.Y - lengthFy * colFY, Ai.Z));
          geometryFy.vertices.push(new THREE.Vector3(Aj.X, Aj.Y - lengthFy * colFY, Aj.Z));
          geometryFy.vertices.push(new THREE.Vector3(Aj.X, Aj.Y, Aj.Z));
          geometryFy.faces.push(new THREE.Face3(0, 1, 2));
          geometryFy.faces.push(new THREE.Face3(2, 3, 0));
          const memberLoadFy = new THREE.Mesh(geometryFy, material);

          memberLoadMesh.push(memberLoadFy);
          scene.add(memberLoadFy);

          if (uiManagement.showMemberLoad && lengthFy !== 0) {
            const labelData = {
              scene,
              mesh: labelMesh,
              type: 'memberLoad',
              object: listmemberloads[i],
              text: `ML(FY=${listmemberloads[i].forceY})`,
              x: (Ai.X + Aj.X) / 2,
              y: (Ai.Y + Aj.Y) / 2 - lengthFy * colFY,
              z: (Ai.Z + Aj.Z) / 2
            };
            showLabel(labelData);
          }

          // forceZ
          const geometryFz = new THREE.Geometry();
          const lengthFz = lengthForce(listmemberloads[i].forceZ);
          let colFZ = Math.abs((Aj.Z - Ai.Z) / Lij);
          if (colFZ !== 1) {
            colFZ = 1;
          } else {
            colFZ = 0;
          }
          geometryFz.vertices.push(new THREE.Vector3(Ai.X, Ai.Y, Ai.Z));
          geometryFz.vertices.push(new THREE.Vector3(Ai.X, Ai.Y, Ai.Z - lengthFz * colFZ));
          geometryFz.vertices.push(new THREE.Vector3(Aj.X, Aj.Y, Aj.Z - lengthFz * colFZ));
          geometryFz.vertices.push(new THREE.Vector3(Aj.X, Aj.Y, Aj.Z));
          geometryFz.faces.push(new THREE.Face3(0, 1, 2));
          geometryFz.faces.push(new THREE.Face3(2, 3, 0));
          const memberLoadFz = new THREE.Mesh(geometryFz, material);

          memberLoadMesh.push(memberLoadFz);
          scene.add(memberLoadFz);

          if (uiManagement.showMemberLoad && lengthFz !== 0) {
            const labelData = {
              scene,
              mesh: labelMesh,
              type: 'memberLoad',
              object: listmemberloads[i],
              text: `ML(FZ=${listmemberloads[i].forceZ})`,
              x: (Ai.X + Aj.X) / 2,
              y: (Ai.Y + Aj.Y) / 2,
              z: (Ai.Z + Aj.Z) / 2 - lengthFz * colFZ
            };
            showLabel(labelData);
          }

          // MomentX
          const R = 1;
          const lengthMx = Number(listmemberloads[i].momentX);
          if (lengthMx !== 0 && typeof lengthMx !== 'undefined') {
            const geometryMx = new THREE.Geometry();
            for (let k = 0; k < 50; k += 1) {
              const teta = Math.PI * k / 49;
              geometryMx.vertices.push(new THREE.Vector3(Ai.X, Ai.Y + R * Math.cos(teta), Ai.Z + R * Math.sin(teta)));
              geometryMx.vertices.push(new THREE.Vector3(Aj.X, Aj.Y + R * Math.cos(teta), Aj.Z + R * Math.sin(teta)));
            }
            for (let k = 0; k < 49; k += 1) {
              const j = 2 * k;
              geometryMx.faces.push(new THREE.Face3(j, j + 2, j + 1));
              geometryMx.faces.push(new THREE.Face3(j + 1, j + 2, j + 3));
            }
            const memberLoadMx = new THREE.Mesh(geometryMx, material);

            memberLoadMesh.push(memberLoadMx);
            scene.add(memberLoadMx);
            if (uiManagement.showMemberLoad && lengthMx !== 0) {
              const labelData = {
                scene,
                mesh: labelMesh,
                type: 'memberLoad',
                object: listmemberloads[i],
                text: `ML(MX=${listmemberloads[i].momentX})`,
                x: (Ai.X + Aj.X) / 2,
                y: (listmemberloads[i].momentX > 0 ? (Ai.Y + Aj.Y) / 2 - R : (Ai.Y + Aj.Y) / 2 + R),
                z: (Ai.Z + Aj.Z) / 2
              };
              showLabel(labelData);
            }
          }

          // MomentY
          const lengthMy = Number(listmemberloads[i].momentY);
          if (lengthMy !== 0 && typeof lengthMy !== 'undefined') {
            const geometryMy = new THREE.Geometry();
            for (let k = 0; k < 50; k += 1) {
              const teta = Math.PI * k / 49;
              geometryMy.vertices.push(new THREE.Vector3(Ai.X + R * Math.cos(teta), Ai.Y, Ai.Z + R * Math.sin(teta)));
              geometryMy.vertices.push(new THREE.Vector3(Aj.X + R * Math.cos(teta), Aj.Y, Aj.Z + R * Math.sin(teta)));
            }
            for (let k = 0; k < 49; k += 1) {
              const j = 2 * k;
              geometryMy.faces.push(new THREE.Face3(j, j + 2, j + 1));
              geometryMy.faces.push(new THREE.Face3(j + 1, j + 2, j + 3));
            }
            const memberLoadMy = new THREE.Mesh(geometryMy, material);

            memberLoadMesh.push(memberLoadMy);
            scene.add(memberLoadMy);
            if (uiManagement.showMemberLoad && lengthMy !== 0) {
              const labelData = {
                scene,
                mesh: labelMesh,
                type: 'memberLoad',
                object: listmemberloads[i],
                text: `ML(MY=${listmemberloads[i].momentY})`,
                x: (listmemberloads[i].momentY > 0 ? (Ai.X + Aj.X) / 2 + R : (Ai.X + Aj.X) / 2 - R),
                y: (Ai.Y + Aj.Y) / 2,
                z: (Ai.Z + Aj.Z) / 2
              };
              showLabel(labelData);
            }
          }

          // MomentZ
          const lengthMz = Number(listmemberloads[i].momentZ);
          if (lengthMz !== 0 && typeof lengthMz !== 'undefined') {
            const geometryMz = new THREE.Geometry();
            for (let k = 0; k < 50; k += 1) {
              const teta = Math.PI * k / 49;
              geometryMz.vertices.push(new THREE.Vector3(Ai.X + R * Math.cos(teta), Ai.Y + R * Math.sin(teta), Ai.Z));
              geometryMz.vertices.push(new THREE.Vector3(Aj.X + R * Math.cos(teta), Aj.Y + R * Math.sin(teta), Aj.Z));
            }
            for (let k = 0; k < 49; k += 1) {
              const j = 2 * k;
              geometryMz.faces.push(new THREE.Face3(j, j + 2, j + 1));
              geometryMz.faces.push(new THREE.Face3(j + 1, j + 2, j + 3));
            }
            const memberLoadMz = new THREE.Mesh(geometryMz, material);

            memberLoadMesh.push(memberLoadMz);
            scene.add(memberLoadMz);
            if (uiManagement.showMemberLoad && lengthMz !== 0) {
              const labelData = {
                scene,
                mesh: labelMesh,
                type: 'memberLoad',
                object: listmemberloads[i],
                text: `ML(MZ=${listmemberloads[i].momentZ})`,
                x: (listmemberloads[i].momentZ > 0 ? (Ai.X + Aj.X) / 2 + R : (Ai.X + Aj.X) / 2 - R),
                y: (Ai.Y + Aj.Y) / 2,
                z: (Ai.Z + Aj.Z) / 2
              };
              showLabel(labelData);
            }
          }
        }
      }
    }
  }
  return {
    mesh: memberLoadMesh
    // update
  };
};
