import React from 'react';
import PropTypes from 'prop-types';
import {
  Row,
  Col,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Button,
  Input,
  InputGroupButtonDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Tooltip
} from 'reactstrap';

class InputCalc extends React.Component {
  constructor(props) {
    super(props);
    this.toggle = this.toggle.bind(this);
    this.state = {
      tooltipOpen: false
      // inputValue: this.props.input.value
    };
  }

  toggle() {
    this.setState({
      tooltipOpen: !this.state.tooltipOpen
    });
  }

  render() {
    const {
      input,
      unitList,
    } = this.props;
    return (
      <div className="inputcalc">
        <InputGroup>
          <InputGroupAddon className="inputgroupaddon" addonType="prepend">
            <InputGroupText className="inputgrouptext">{input.text}</InputGroupText>
          </InputGroupAddon>
          <InputGroupAddon className="inputgroupaddon" addonType="prepend">
            <InputGroupText className="inputgroupinfo" id={input.name}>
              <i className="material-icons">info</i>
            </InputGroupText>
            <Tooltip placement="top" isOpen={this.state.tooltipOpen} target={input.name} toggle={this.toggle}>
              {input.description}
            </Tooltip>
          </InputGroupAddon>
          <Input
            className="output"
            type="number"
            name={input.name}
            value={Math.round(input.value * 1000) / 1000}
            readOnly
          />
          <InputGroupAddon addonType="append">
            <Input
              className="outputunits"
              type="select"
              name={input.name}
              defaultValue={input.unit}
              disabled
            >
              {
                unitList.map(item => (
                  <option
                    key={item.value}
                    value={item.value}
                  >
                    {item.label}
                  </option>
                ))
              }
            </Input>
          </InputGroupAddon>
        </InputGroup>
      </div>
    );
  }
}

export default InputCalc;
