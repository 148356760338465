import { useState, useEffect } from 'react';

function usePagination(fields) {
  const [paginatedFields, setPaginatedFields] = useState({
    todos: fields,
    currentPage: 1,
    todosPerPage: 10
  });

  function paginate() {
    const pageNumbers = [];

    const indexOfLastTodo = (paginatedFields.currentPage * paginatedFields.todosPerPage) - 1;
    const indexOfFirstTodo = (indexOfLastTodo - paginatedFields.todosPerPage) + 1;

    const currentTodos = fields && fields.map((todo, i) => {
      if (i >= indexOfFirstTodo && i <= indexOfLastTodo) {
        return todo;
      }
      return undefined;
    }).filter(item => item);

    for (let i = 1; i <= Math.ceil(fields.length / paginatedFields.todosPerPage); i += 1) {
      pageNumbers.push(i);
    }

    const isLastPage = paginatedFields.currentPage === Math.max.apply(null, pageNumbers);

    useEffect(() => {
      if (currentTodos.length < paginatedFields.todosPerPage && !isLastPage) {
        setPaginatedFields({ ...paginatedFields, currentPage: 1 });
      }
    }, [currentTodos.length]);

    return {
      pageNumbers,
      currentTodos
    };
  }

  const { pageNumbers, currentTodos } = paginate();
  const { todosPerPage } = paginatedFields;
  return {
    currentPage: paginatedFields.currentPage,
    pageNumbers,
    todosPerPage,
    currentTodos,
    paginatedFields,
    setPaginatedFields
  };
}

export default usePagination;
