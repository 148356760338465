const optionsMomentUnits = [{
  label: 'N.m',
  value: 'Nm'
}, {
  label: 'kN.m',
  value: 'kNm'
}, {
  label: 'MN.m',
  value: 'MNm'
}, {
  label: 'MN.cm',
  value: 'MNcm'
}, {
  label: 'MN.mm',
  value: 'MNmm'
}, {
  label: 'kip.inch',
  value: 'kipinch'
}, {
  label: 'lbf.inch',
  value: 'lbfinch'
}];

export default optionsMomentUnits;
