import { combineReducers } from 'redux';
import { reducer as formReducer } from 'redux-form';
import modal from '../components/UIComponents/Modal/reducer';
import sidebar from '../components/UIComponents/Bar/NavBarApp/reducer';
import dashboard from '../scenes/Dashboard/reducer';
import settings from '../scenes/Dashboard/scenes/Settings/reducer';
import applications from '../scenes/Dashboard/scenes/Applications/reducer';
import signin from '../scenes/SignIn/reducer';
import forgotPassword from '../scenes/ForgotPassword/reducer';
import unitsSystem from '../utils/units/reducer';
import concreteSectionCalculation from '../scenes/ConcreteSection/scenes/ConcreteSectionCalculation/reducer';
import steelSectionCalculation from '../scenes/SteelSection/scenes/SteelSectionCalculation/reducer';
import structure3DCalculation from '../scenes/Structure3D/scenes/Structure3DCalculation/reducer';
import structure3DProjects from '../scenes/Structure3D/scenes/Projects/reducer';
import sectionGeometry from '../scenes/SectionGeometry/reducer';
import structuralAnalysis from '../scenes/StructuralAnalysis/reducer';
import regression from '../scenes/Regression/reducer';
import waveCharacteristics from '../scenes/WaveCharacteristics/reducer';
import datBimApi from '../scenes/DatBim/scenes/API/reducer';
import selectedLanguage from '../intl/reducer';


const rootReducer = combineReducers({
  modal,
  dashboard,
  settings,
  applications,
  signin,
  sidebar,
  sectionGeometry,
  structuralAnalysis,
  regression,
  waveCharacteristics,
  unitsSystem,
  selectedLanguage,
  structure3DProjects,
  datBimApi,
  form: formReducer.plugin({
    concreteSection: concreteSectionCalculation,
    steelSection: steelSectionCalculation,
    structure3D: structure3DCalculation,
    forgotPassword
  })
});

export default rootReducer;
