import React, { useState } from 'react';
import {
  Button,
  Collapse,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavItem,
  Col,
  Row,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Spinner
} from 'reactstrap';
import { Link } from 'react-router-dom';
import { connect, useDispatch } from 'react-redux';
import { bindActionCreators } from 'redux';
import { logout } from './actions';
import { openModal } from '../../Modal/actions';
import Icons from '../../Icons';

import Structure3DSvg from '../../../../scenes/Dashboard/scenes/Applications/img/bridge.svg';
import SteelSectionSvg from '../../../../scenes/Dashboard/scenes/Applications/img/SteelSection.svg';
import ConcreteSectionSvg from '../../../../scenes/Dashboard/scenes/Applications/img/ConcreteSection.svg';
import SectionGeometrySvg from '../../../../scenes/Dashboard/scenes/Applications/img/SectionGeometry.svg';
import WaveCharacteristicsSvg from '../../../../scenes/Dashboard/scenes/Applications/img/WaveCharacteristics.svg';

function NavBarApp({ loadingCalculation, linkCalculation, linkError }) {
  const initialState = JSON.parse(localStorage.getItem('user'));
  const [isOpen, setIsOpen] = useState(false);
  const [user] = useState(initialState);
  const dispatch = useDispatch();
  const toggle = () => setIsOpen(!isOpen);
  // const [language, setLanguage] = useState('En');

  const {
    REACT_APP_LOGO,
    REACT_APP_COMPANY
  } = process.env;

  function logoutUser() {
    dispatch(logout());
  }

  return (
    <div>
      <Navbar className="navbarapp" dark color="light" expand="md" fixed="top">
        {/* <a onClick={this.toggleSideBar} className="navbar-brand" href="#menu-toggle" id="menu-toggle">
            <i className="material-icons d-inline-block">
              reorder
            </i>
          </a> */}
        {/* <NavbarToggler onClick={this.toggleSidebar} className="mr-2" /> */}
        <NavbarBrand
          id="navbarTitle1"
          tag={Link}
          to="/dashboard"
          style={{ backgroundImage: `url(${REACT_APP_LOGO})` }}
        >
          {`${REACT_APP_COMPANY}`}
        </NavbarBrand>
        <NavbarToggler onClick={toggle} style={{ color: 'white' }}>
          <span className="navbar-toggler-icon" />
        </NavbarToggler>
        <Collapse isOpen={isOpen} navbar>
          <Nav className="ml-auto" navbar>
            {/* <UncontrolledDropdown nav inNavbar>
                <DropdownToggle nav caret id="navbarTitle3">
                  {this.state.dropDownLanguageValue}
                </DropdownToggle>
                <DropdownMenu right className="languagedropdownmenu">
                  <DropdownItem className="languagedropdownitem">
                    <FormGroup row onClick={this.changeLanguageValue}>
                      <Col sm={4} style={{ maxWidth: '40px' }}>
                        <img src={frIcon} alt="frIcon" width="25" height="25" />
                      </Col>
                      <Label sm={8}>Fr</Label>
                    </FormGroup>
                  </DropdownItem>
                  <DropdownItem className="languagedropdownitem">
                    <FormGroup row onClick={this.changeLanguageValue}>
                      <Col sm={4} style={{ maxWidth: '40px' }}>
                        <img src={gbIcon} alt="gbIcon" width="25" height="25" />
                      </Col>
                      <Label sm={8}>En</Label>
                    </FormGroup>
                  </DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown> */}
            {/* <NavItem>
                <DropDownApp />
              </NavItem> */}
            <NavItem>
              {loadingCalculation &&
                <Link className="nav-link" to="#" id="navbarTitle3">
                  <Spinner type="grow" color="info" />
                  {'Calculation...'}
                </Link>
              }
              {(linkCalculation && !linkError) &&
                <Link className="nav-link" to={linkCalculation} id="navbarTitle3">
                  <i className="material-icons d-inline-block check-ok">check_circle_outline</i>
                  Outputs
                </Link>
              }
              {(linkCalculation && linkError) && 
                <Link 
                  className="nav-link"
                  to={linkCalculation}
                  id="navbarTitle3"
                >
                  <i className="material-icons d-inline-block check-ko">highlight_off</i>
                  Message
                </Link>
              }
            </NavItem>
            <NavItem>
              <Link className="nav-link" to="/dashboard" id="navbarTitle3">
                <i className="material-icons d-inline-block">dashboard</i>
                Dashboard
              </Link>
            </NavItem>
            <UncontrolledDropdown nav inNavbar>
              <DropdownToggle nav caret id="navbarTitle3">
                <i className="material-icons d-inline-block">apps</i>
                {'Applications'}
              </DropdownToggle>
              <DropdownMenu className="dropdownmenuapp" right>
                <Row>
                  <Col xs={6}>
                    <DropdownItem
                      //tag={Link}
                      //to="/structure3d"
                      className="dropdownitem"
                    >
                      <Icons
                        imgsrc={Structure3DSvg}
                        title="Structure 3D"
                        link="/structure3d/projects"
                      />
                    </DropdownItem>
                  </Col>
                  <Col xs={6}>
                    <DropdownItem
                      //tag={Link}
                      //to="/dashboard"
                      className="dropdownitem"
                    >
                      <Icons
                        imgsrc={SteelSectionSvg}
                        title="Steel Section"
                        link="/dashboard"
                      />
                    </DropdownItem>
                  </Col>
                </Row>
                <Row>
                  <Col xs={6}>
                    <DropdownItem
                      //tag={Link}
                      //to="/concretesection"
                    >
                      <Icons
                        imgsrc={ConcreteSectionSvg}
                        title="Concrete Section"
                        link="/concretesection"
                      />
                    </DropdownItem>
                  </Col>
                  <Col xs={6}>
                    <DropdownItem
                      //tag={Link}
                      //to="/sectiongeometry"
                    >
                      <Icons
                        imgsrc={SectionGeometrySvg}
                        title="Section Geometry"
                        link="/sectiongeometry"
                      />
                    </DropdownItem>
                  </Col>
                </Row>
                <Row>
                  <Col xs={6}>
                    <DropdownItem
                      //tag={Link}
                      //to="/wavecharacteristics"
                    >
                      <Icons
                        imgsrc={WaveCharacteristicsSvg}
                        title="Wave Characteristics"
                        link="/wavecharacteristics"
                      />
                    </DropdownItem>
                  </Col>
                  <Col xs={6} />
                </Row>
              </DropdownMenu>
            </UncontrolledDropdown>
            <UncontrolledDropdown nav inNavbar>
              <DropdownToggle nav caret id="navbarTitle3">
                <i className="material-icons d-inline-block">
                  perm_identity
                  </i>
                {user.firstname}
              </DropdownToggle>
              <DropdownMenu className="dropdownmenu" right>
                <DropdownItem className="dropdownitem" tag={Link} to="/account">
                  <i className="material-icons d-inline-block align-top">
                    account_circle
                    </i>
                  Account
                  </DropdownItem>
                <DropdownItem className="dropdownitem" tag={Link} to="/settings">
                  <i className="material-icons d-inline-block align-top">
                    settings
                    </i>
                  Settings
                  </DropdownItem>
                <DropdownItem className="dropdownitem" onClick={logoutUser}>
                  <i className="material-icons d-inline-block align-top">
                    power_settings_new
                  </i>
                  Sign Out
                </DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
          </Nav>
        </Collapse>
      </Navbar>
    </div >
  );
}

const mapStateToProps = (state) => {
  return {
    loadingCalculation: state.form.structure3D.outputs.links.loading,
    linkCalculation: state.form.structure3D.outputs.links.link,
    linkError: state.form.structure3D.outputs.links.error
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ openModal }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(NavBarApp);
