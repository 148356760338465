import * as types from './actionTypes';
import initialState from './initialState';

const modal = (state = initialState, action) => {
  switch (action.type) {
    // TODO CREATE MODAL COMPONENT, JUST FOR DEMO
    case types.OPEN_MODAL:
      return action.payload;
    default:
      return state;
  }
};

export default modal;
