import React from 'react';
import { Link } from 'react-router-dom';
import {
  Breadcrumb,
  BreadcrumbItem,
  Button,
  CardDeck,
  Card,
  CardImg,
  Row,
  Col
} from 'reactstrap';
import CardAddProject from '../../../../../../../../../../components/UIComponents/Card/CardAddProject';

import ConcreteSectionSvg from './img/ConcreteSection.svg';
import RectangularconcretesectionSvg from './img/rectangularconcretesection.svg';
import TconcretesectionSvg from './img/Tconcretesection.svg';
import IconcretesectionSvg from './img/Iconcretesection.svg';
import CircleconcretesectionSvg from './img/circleconcretesection.svg';
import HollowcircleconcretesectionSvg from './img/hollowcircleconcretesection.svg';
import RandomconcretesectionSvg from './img/randomconcretesection.svg';

const SectionTypes = ({
  changeView,
  changeSectionType
}) => (
  <div>
    <CardDeck className="projects">
      <Row>
        <Col xs={4} className="align-center">
          <Button
            className="negativeButton"
            outline
            color="primary"
            onClick={() => {
              changeView('Select');
            }}
          >
            {'Back'}
          </Button>
        </Col>
        <Col xs={4} className="align-center" />
        <Col xs={4} className="align-center" />
      </Row>
      <br />
      <Row>
        <Col xs={4}>
          <Card
            id="cardaddproject"
            onClick={() => {
              changeView('Geometry');
              changeSectionType('rectangularSection');
            }}
          >
            <CardImg className="cardaddimg" src={RectangularconcretesectionSvg} alt="Rectangular Section" />
          </Card>
        </Col>
        <Col xs={4}>
          <Card
            id="cardaddproject"
            onClick={() => {
              changeView('Geometry');
              changeSectionType('tSection');
            }}
          >
            <CardImg className="cardaddimg" src={TconcretesectionSvg} alt="T Section" />
          </Card>
        </Col>
        <Col xs={4}>
          <Card
            id="cardaddproject"
            onClick={() => {
              changeView('Geometry');
              changeSectionType('iSection');
            }}
          >
            <CardImg className="cardaddimg" src={IconcretesectionSvg} alt="I Section" />
          </Card>
        </Col>
      </Row>
      <Row>
        <Col xs={4}>
          <Card
            id="cardaddproject"
            onClick={() => {
              changeView('Geometry');
              changeSectionType('circleSection');
            }}
          >
            <CardImg className="cardaddimg" src={CircleconcretesectionSvg} alt="Circle Section" />
          </Card>
        </Col>
        <Col xs={4}>
          <Card
            id="cardaddproject"
            onClick={() => {
              changeView('Geometry');
              changeSectionType('hollowCircleSection');
            }}
          >
            <CardImg className="cardaddimg" src={HollowcircleconcretesectionSvg} alt="Hollow Circle Section" />
          </Card>
        </Col>
        <Col xs={4}>
          <Card
            id="cardaddproject"
            onClick={() => {
              changeView('Geometry');
              changeSectionType('randomSection');
            }}
          >
            <CardImg className="cardaddimg" src={RandomconcretesectionSvg} alt="Random Section" />
          </Card>
        </Col>
      </Row>
    </CardDeck>
  </div>
);

export default SectionTypes;
