import initialState from './initialState';
import * as types from './actionTypes';

export default (state = initialState, action) => {
  switch (action.type) {
    case types.AUTHENTICATION_SUCCESS:
      return {
        ...state,
        isAuth: action.isAuth
      };
    case types.AUTHENTICATION_FAILURE:
      return {
        ...state,
        isAuth: action.isAuth
      };
    default:
      return state;
  }
};
