const initialState = {
  settings: {
    plans: [],
    subscribedPlan: {
      nickname: ''
    }
  }
};

export default initialState;
