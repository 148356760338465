const initialState = {
  projects: [{
    options: {
      image: {},
      projectname: {
        name: null,
        description: null,
        _id: null
      }
    }
  }],
  loading: true
};

export default initialState;
