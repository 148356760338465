import React from 'react';
import { Tooltip, InputGroupText } from 'reactstrap';
import PropTypes from 'prop-types';

export default class ToolTips extends React.Component {
  constructor(props) {
    super(props);

    this.toggle = this.toggle.bind(this);
    this.state = {
      tooltipOpen: false
    };
  }

  toggle() {
    this.setState({
      tooltipOpen: !this.state.tooltipOpen
    });
  }

  render() {
    const { description, target } = this.props;
    return (
      <div className="tooltips">
        <div className="inputgroupinfo" id={target}>
          <i className="material-icons">info</i>
        </div>
        <Tooltip placement="top" isOpen={this.state.tooltipOpen} target={target} toggle={this.toggle}>
          {description}
        </Tooltip>
      </div>
    );
  }
}
