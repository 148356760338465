import React, { Fragment } from 'react';
import {
  Row,
  Col,
  Label,
  Button
} from 'reactstrap';
import SelectRF from '../../../../../../../../components/UIComponents/Fields/SelectRF';

const Select = ({
  name,
  active,
  list,
  showErrorMessage,
  changeView,
  getFields,
  removeFields
}) => {
  return (
    <Fragment>
      <Row>
        <Col xs={10}>
          <Label>{ `${list.description}:` }</Label>
          <SelectRF
            name={`${name}.active`}
            list={list.value}
          />
        </Col>
      </Row>
      <br />
      <Row>
        <Col xs={4} className="align-center">
          <Button
            className="negativeButton"
            outline
            color="primary"
            onClick={() => {
              const index = list.value.findIndex(item => item.name === list.active);
              getFields(name, list.value[index]);
              showErrorMessage('');
              changeView('Edit');
            }}
          >
            {'Edit'}
          </Button>
        </Col>
        <Col xs={4} className="align-center">
          <Button
            className="negativeButton"
            outline
            color="primary"
            onClick={() => {
              showErrorMessage('');
              changeView('Add');
            }}
          >
            {'Add'}
          </Button>
        </Col>
        <Col xs={4} className="align-center">
          <Button
            className="negativeButton"
            outline
            color="primary"
            onClick={() => {
              const index = list.value.findIndex(item => item.value === list.active);
              if (list.value.length > 1) {
                if (index !== 0) {
                  removeFields(name, index);
                }
              }
            }}
          >
            {'Delete'}
          </Button>
        </Col>
      </Row>
    </Fragment>
  );
};

export default Select;
