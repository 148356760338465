import axios from 'axios';
import {
  CS_CHANGE_GEOMETRY,
  CHANGE_GLOBAL_UNITS,
  FETCH_SECTION_DATA,
  LOAD_OUTPUTS
} from './actionTypes';
import { conversionValue2 } from '../../../../utils/units/conversionFunctions';
import optionsUnitsSystem from '../../../../utils/units/optionsUnitsSystem';
// import history from '../../../../history';
import { URL } from '../../../../config/config';
import URL_PATH from '../../../../config/default';
import { openModal } from '../../../../components/UIComponents/Modal/actions';
import sectionTypesArray from '../../../../utils/sectionGeometry/sectionTypesArray';

export const changeGeometry = (inputs, sectionType, inputValue, inputName) => {
  const newInputs = { ...inputs, [inputName]: { ...inputs[inputName], value: inputValue } };
  const {
    sectionext,
    sectionint
  } = sectionTypesArray(sectionType, newInputs);
  return {
    type: CS_CHANGE_GEOMETRY,
    payload: {
      sectionType,
      value: inputValue,
      name: inputName,
      sectionext,
      sectionint
    }
  };
};

export const fetchSectionData = (sectionType, geometry) => {
  const {
    sectionext,
    sectionint
  } = sectionTypesArray(sectionType, geometry.concrete);
  return {
    type: FETCH_SECTION_DATA,
    payload: {
      sectionType,
      geometry: {
        concrete: {
          sectionext,
          sectionint
        }
      }
    }
  };
};

const conversion = (oldValues, newUnit) => ({
  value: conversionValue2(oldValues.value, oldValues.unit.name, newUnit.name),
  unit: newUnit
});

const convertedState = (oldValues, unitSystem, concreteSectionType) => {
  const sectionExt = oldValues.geometry.concrete.sectionext;
  const sectionInt = oldValues.geometry.concrete.sectionint;
  const reinforcements = oldValues.reinforcement.geometry;

  const convertValueSection = sectionType => sectionType.reduce((acc, section) => {
    const cX = conversionValue2(
      section.x,
      oldValues.geometry.concrete.unit.name,
      unitSystem.type.unitLength.name
    );
    const cY = conversionValue2(
      section.y,
      oldValues.geometry.concrete.unit.name,
      unitSystem.type.unitLength.name
    );
    return acc.concat([{ x: cX, y: cY }]);
  }, []);

  const randomGeometryConvert = (unitSys) => {
    const convertedSectionExt = convertValueSection(sectionExt);
    const convertedSectionInt = convertValueSection(sectionInt);
    return {
      concrete: {
        sectionext: convertedSectionExt,
        sectionint: convertedSectionInt,
        unit: unitSys.type.unitLength
      }
    };
  };

  const simpleGeometryConvert = (unitSys) => {
    const convertedSectionExt = convertValueSection(sectionExt);
    const convertedSectionInt = convertValueSection(sectionInt);
    return {
      concrete: {
        unit: { name: unitSys.type.unitLength.name, text: unitSys.type.unitLength.text },
        b: {
          name: 'b',
          text: 'b',
          value: 10,
          description: 'Section Width',
          unit: unitSys.type.unitLength.text
        },
        h: {
          name: 'h',
          text: 'h',
          value: 100,
          description: 'Section Height',
          unit: unitSys.type.unitLength.text
        },
        sectionext: convertedSectionExt,
        sectionint: convertedSectionInt
      }
    };
  };

  const convertedReinforcement = reinforcements.reduce((acc, reinforcement) => {
    const cX = conversionValue2(
      reinforcement.x,
      oldValues.reinforcement.unit.name,
      unitSystem.type.unitLength.name
    );
    const cY = conversionValue2(
      reinforcement.y,
      oldValues.reinforcement.unit.name,
      unitSystem.type.unitLength.name
    );
    const cD = conversionValue2(
      reinforcement.diameter,
      oldValues.reinforcement.unitDiameter.name,
      unitSystem.type.unitLength.name
    );
    return acc.concat([{
      x: cX,
      y: cY,
      number: reinforcement.number,
      diameter: cD
    }]);
  }, []);

  return {
    options: {
      unit: {
        value: {
          name: unitSystem.name,
          text: unitSystem.text
        }
      }
    },
    concrete: {
      fck: conversion(oldValues.concrete.fck, unitSystem.type.unitStress)
    },
    geometry:
    // randomGeometryConvert(unitSystem),
    concreteSectionType === 'randomSection' ? randomGeometryConvert(unitSystem) : simpleGeometryConvert(unitSystem),
    reinforcement: {
      unit: unitSystem.type.unitLength,
      unitDiameter: unitSystem.type.unitLength,
      geometry: convertedReinforcement
    },
    steel: {
      fyk: conversion(oldValues.steel.fyk, unitSystem.type.unitStress),
      Es: conversion(oldValues.steel.Es, unitSystem.type.unitStress)
    },
    loads: {
      Nsls: conversion(oldValues.loads.Nsls, unitSystem.type.unitForce),
      Mxsls: conversion(oldValues.loads.Mxsls, unitSystem.type.unitMoment),
      Mysls: conversion(oldValues.loads.Mysls, unitSystem.type.unitMoment),
      Nuls: conversion(oldValues.loads.Nuls, unitSystem.type.unitForce),
      Mxuls: conversion(oldValues.loads.Mxuls, unitSystem.type.unitMoment),
      Myuls: conversion(oldValues.loads.Myuls, unitSystem.type.unitMoment)
    }
  };
};

export const changeGlobalUnits = (newUnit, oldValues, sectionType) => {
  const unit = ((unitValue) => {
    return {
      unitSystem: optionsUnitsSystem[unitValue].type,
      newValue: convertedState(oldValues, optionsUnitsSystem[unitValue], sectionType)
    };
  })(newUnit);

  return {
    type: CHANGE_GLOBAL_UNITS,
    payload: { newValue: unit.newValue, sectionType }
  };
};

export function postSectionData(sectiongeometry) {
  axios.post(`${URL}sectiondata`, sectiongeometry)
    .then((res) => {
      console.log(res.data);
    }).catch((err) => {
      console.log(err);
    });
}


export const postEc2Sls = (concreteSectionInputs, user) => {
  return async (dispatch) => {
    try {
      const resp = await axios.post(`${URL}${URL_PATH.concreteSection.ec2sls}`, concreteSectionInputs);
      dispatch(openModal({ status: true }));
      dispatch({
        type: LOAD_OUTPUTS,
        payload: {
          msg: resp.data.msg,
          data: resp.data.analysis
          // sectionType
        }
      });
      const userCalculationCounter = await axios.post(`${URL}${URL_PATH.payments.calculationCounter}`, user);
      const uCalculationCounter = userCalculationCounter.data.calculationCounter;
      const userUpdated = { ...user, calculationCounter: uCalculationCounter };
      localStorage.setItem('user', JSON.stringify(userUpdated));
      console.log('USER UPDATED', userUpdated);
      // history.push('/concretesection/rectangularconcretesection/output');
    } catch (err) {
      console.log('ERRRR', err);
    }
  };
};

export const getSectionState = (sectionType) => {
  axios.get(`${URL}concretesectionstate`, { params: sectionType })
    .then((res) => {
      console.log(res.data);
    }).catch((err) => {
      console.log(err);
    });
};
