import React, { Component } from 'react';
import {
  Card,
  CardHeader,
  CardBody,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Button,
  CardTitle,
  CardText,
  Row,
  Col,
  Container
} from 'reactstrap';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router';
import HorizontalScrollMenu from '../../../../../../components/UIComponents/Bar/HorizontalScrollMenu';
import Options from './components/Options';
import Geometry from './scenes/Geometry';
// import SimpleGeometry from './scenes/SimpleGeometry';
import Steel from './scenes/Steel';
import Loads from './scenes/Loads';
import { compose } from 'recompose';
import { reduxForm } from 'redux-form';
import InputField from '../../../../../../components/UIComponents/Fields/InputRF';
import SelectRF from '../../../../../../components/UIComponents/Fields/SelectRF';
import optionsUnitsSystem from '../../../../../../utils/units/optionsUnitsSystem';
import Outputs from '../Outputs';
import GoToPricingModal from '../../../../../../components/UIComponents/GoToPricingModal';
import { openModal } from '../../../../../../components/UIComponents/Modal/actions';
import {
  changeGeometry,
  fetchSectionData,
  changeGlobalUnits,
  postSectionData,
  postEc3Uls
} from '../../actions';
import validate from '../../validations/validate';

const itemScrollMenu = [
  {
    num: '1',
    icon: 'grid_on',
    title: 'Geometry'
  },
  {
    num: '2',
    icon: 'timeline',
    title: 'Steel'
  },
  {
    num: '3',
    icon: 'vertical_align_bottom',
    title: 'Loads'
  }
];

class Inputs extends React.Component {
  constructor(props) {
    super(props);
    this.toggle = this.toggle.bind(this);
    this.submitValues = this.submitValues.bind(this);
    this.state = {
      activeTab: '1',
      showOutputs: true
    };
  }

  componentDidMount() {
    const { fetchSectionData, match, steelSection } = this.props;
    fetchSectionData(match.params.sectionType, steelSection.geometry);
  }

  toggle(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab
      });
    }
  }

  submitValues(values) {
    const { postEc3Uls, openModal, match: { params: { sectionType } } } = this.props;
    const user = JSON.parse(localStorage.getItem('user'));
    if (!user.subscriptionId && user.calculationCounter > 4) {
      this.setState({ showOutputs: false });
      openModal({ status: true });
    } else {
      // this.setState({ showOutputs: true });
      postEc3Uls(values, sectionType, user);
      openModal({ status: true });
    }
  }

  render() {
    const {
      steelSection,
      changeGeometry,
      steelSection: {
        steel,
        loads,
        geometry
      },
      changeGlobalUnits,
      sectionType,
      handleSubmit,
      history,
      openModal
    } = this.props;
    const {
      activeTab,
      showOutputs
    } = this.state;
    console.log('STEEL SECTION', steelSection)
    return (
      <div>
        <form onSubmit={handleSubmit(this.submitValues)}>
          <Card id="card">
            <CardHeader id="headerinputs">
              {'Inputs Steel Section (EN 1993-1-1)'}
            </CardHeader>
            <Options steelSection={steelSection} sectionType={sectionType} />
            <HorizontalScrollMenu
              toggle={this.toggle}
              activeTab={activeTab}
              itemScrollMenu={itemScrollMenu}
            />
            <TabContent
              id="sectionsDataOptions"
              activeTab={activeTab}
            >
              <br />
              <TabPane tabId="1">
                {activeTab === "1" &&
                  <React.Fragment>
                    <Geometry sectionType={sectionType} geometry={geometry} changeGeometry={changeGeometry} />
                  </React.Fragment>
                }
              </TabPane>
              <TabPane tabId="2">
                {activeTab === "2" && <Steel steel={steel} />}
              </TabPane>
              <TabPane tabId="3">
                {activeTab === "3" &&
                  <Loads loads={loads} />
                }
              </TabPane>
            </TabContent>
          </Card>
        </form>
        <GoToPricingModal
          openModal={openModal}
          showOutputs={showOutputs}
          history={history}
        >
          <Outputs />
        </GoToPricingModal>
      </div>
    );
  }
}

const mapStateToProps = (state, props) => {
  const { sectionType } = props.match.params;
  return {
    form: `steelSection.${sectionType}`,
    steelSection: state.form.steelSection[sectionType].values,
    sectionType
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({
    changeGeometry,
    fetchSectionData,
    changeGlobalUnits,
    postSectionData,
    postEc3Uls,
    openModal
  }, dispatch);
};

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
  reduxForm({
    destroyOnUnmount: false,
    asyncBlurFields: [],
    validate
  }),
)(Inputs);
