
import Loadable from 'react-loadable';
import Loader from '../../../../../../components/UIComponents/Loader';

const LoadableOutputs = Loadable({
  loader: () => import('./Outputs'),
  loading: Loader
});

export default LoadableOutputs;
