import {
  WC_CHANGE_INPUT
} from './actionTypes';

import waveData from '../../utils/waveCharacteristics/waveData';


const calculation = (inputs) => {
  const L = waveData.waveLength(inputs);
  const waterLevel = waveData.waterLevel(inputs, L);
  return {
    L,
    waterLevel
  };
};

export function changeInputValue(inputs, inputField, inputValue, inputName) {
  const newInputs = { ...inputs };
  newInputs[inputName].value = inputValue;

  const {
    L,
    waterLevel
  } = calculation(inputs);

  return {
    type: WC_CHANGE_INPUT,
    payload: {
      inputs: {
        value: inputValue,
        name: inputName
      },
      outputs: {
        L,
        waterLevel
      }
    }
  };
}
