import React, { Fragment } from 'react';
import {
  Row,
  Col,
  Label,
  Button
} from 'reactstrap';
import SelectRF from '../../../../../../../../../../components/UIComponents/Fields/SelectRF';

const Select = ({
  name,
  active,
  sections,
  changeView,
  showErrorMessage,
  changeSectionType,
  removeFields
}) => {
  return (
    <Fragment>
      <Row>
        <Col xs={10}>
          <Label>{`${sections.description}:`}</Label>
          <SelectRF
            name={`sections.active`}
            list={sections.value}
          />
        </Col>
      </Row>
      <br />
      <Row>
        <Col xs={4} className="align-center">
          <Button
            className="negativeButton"
            outline
            color="primary"
            onClick={() => {
              changeView('Geometry');
              showErrorMessage('');
              changeSectionType('editSection');
            }}
            // type="submit"
          >
            {'Edit'}
          </Button>
        </Col>
        <Col xs={4} className="align-center">
          <Button
            className="negativeButton"
            outline
            color="primary"
            onClick={() => {
              changeView('SectionTypes');
              showErrorMessage('');
            }}
            // type="submit"
          >
            {'Add'}
          </Button>
        </Col>
        <Col xs={4} className="align-center">
          <Button
            className="negativeButton"
            outline
            color="primary"
            onClick={() => {
              const index = sections.value.findIndex(item => item.value === sections.active);
              if (sections.value.length > 1) {
                if (index !== 0) {
                  removeFields('sections', index);
                }
              }
            }}
          >
            {'Delete'}
          </Button>
        </Col>
      </Row>
    </Fragment>
  );
};

export default Select;
