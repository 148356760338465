import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { FieldArray } from 'redux-form';
import {
  Container,
  Col,
  Row,
  Table,
  Button,
  ButtonGroup
} from 'reactstrap';
import { Scatter } from 'react-chartjs-2';
import InputField from '../../../../../../../../components/UIComponents/Fields/InputRF';
import SelectRF from '../../../../../../../../components/UIComponents/Fields/SelectRF';

import chartOptions from './utils/chartOptions';
import chartData from './utils/chartData';

import ISection from './scenes/ISection';
import BoxSection from './scenes/BoxSection';

const Geometry = ({
  changeGeometry,
  sectionType,
  geometry,
  fields
}) => {
  return (
    <Container className="geometry">
      <Row>
        <Col md={6}>
          <div className="chartContainer">
            <Scatter
              className="chart"
              width={400}
              height={400}
              data={chartData(geometry.steel.sectionext, geometry.steel.sectionint)}
              options={chartOptions(geometry.steel.sectionext, geometry.steel.unit)}
            />
          </div>
        </Col>
        <Col md={6}>
          <h2 className="subtitle">Section dimensions</h2>
          {sectionType === 'iSectionSteel'
            && <ISection
              sectionType={sectionType}
              geometry={geometry}
              changeGeometry={changeGeometry}
            />}
          {sectionType === 'boxSection'
            && <BoxSection
              sectionType={sectionType}
              geometry={geometry}
              changeGeometry={changeGeometry}
            />}
          <br/>
          <h2 className="subtitle">Is the cross section Rolled or Welded ?</h2>
          <SelectRF
            // className="selectunits"
            name="geometry.steel.RolledOrWelded.value"
            list={geometry.steel.RolledOrWelded.list}
          />
          <br/>
        </Col>
      </Row>
    </Container>
  );
};

export default Geometry;
