const chartOptions = (interactionDiagram, loads) => {
  const findMinMax = (arr, field) => {
    let min = 0;
    let max = 0;
    if (typeof arr !== 'undefined' && arr.length > 1) {
      min = Number(arr[0][field]);
      max = Number(arr[0][field]);
      for (let i = 1; i < arr.length; i += 1) {
        const v = Number(arr[i][field]);
        min = (v < min) ? v : min;
        max = (v > max) ? v : max;
      }
    }
    return [min, max];
  };

  const Xminmax = findMinMax(interactionDiagram.value, 'x');
  const Yminmax = findMinMax(interactionDiagram.value, 'y');
  const Xmin = Math.min(Xminmax[0], loads.Nuls.value);
  let Xmax = Math.max(Xminmax[1], loads.Nuls.value);
  const Ymin = Math.min(Yminmax[0], loads.Mxuls.value);
  let Ymax = Math.max(Yminmax[1], loads.Mxuls.value);

  const DeltaX = Xmax - Xmin;
  const DeltaY = Ymax - Ymin;

  const DeltaMax = Math.max(DeltaX, DeltaY);

  Xmax = Xmin + DeltaMax;
  Ymax = Ymin + DeltaMax;

  return {
    showLines: true,
    animation: false,
    legend: {
      display: false
    },
    tooltips: {
      callbacks: {
        label: (tooltipItem, data) => {
          const numx = tooltipItem.xLabel.toFixed(3);
          const numy = tooltipItem.yLabel.toFixed(3);
          const dataLabel = data.datasets[tooltipItem.datasetIndex].label || '';
          const label = `${dataLabel}: N: ${numx} Mx : ${numy}`;
          return label;
        }
      }
    },
    elements: {
      point: { radius: 1 }
    },
    scales: {
      xAxes: [{
        type: 'linear',
        position: 'bottom',
        ticks: {
          min: Xmin,
          max: Xmax
          // stepSize: DeltaMax
        },
        gridLines: {
          display: true
        },
        scaleLabel: {
          display: true,
          labelString: `N(${interactionDiagram.unitForce.text})`
        }
      }],
      yAxes: [{
        type: 'linear',
        position: 'bottom',
        ticks: {
          min: Ymin,
          max: Ymax
          // stepSize: DeltaMax
        },
        scaleLabel: {
          display: true,
          labelString: `Mx(${interactionDiagram.unitMoment.text})`
        }
      }]
    }
  };
};

export default chartOptions;
