import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  Container,
  Button
} from 'reactstrap';
import { postEc2Sls } from '../../../../actions';

const Analysis = ({
  inputs,
  ec2Sls,
}) => {
  console.log('Analysis render');
  return (
    <Container>
      <Button onClick={() => ec2Sls(inputs.steel.fyk.value)}>Analysis</Button>
      {/* <button type="submit" disabled={isSubmitting}>
        { 'Analysis' }
      </button> */}
    </Container>
  );
}


// Analysis.propTypes = {
//   appear: PropTypes.bool.isRequired
// };

const mapStateToProps = (state) => {
  return {
    inputs: state.randomconcretesection.inputs
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    ec2Sls: (concretesectioninputs) => {
      postEc2Sls(dispatch, concretesectioninputs);
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Analysis);
