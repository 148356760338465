import update from 'immutability-helper';

import initialState from './initialState';
import * as types from './actionTypes';

const ConcreteSection = (state = initialState, action = {}) => {
  switch (action.type) {
    case types.FETCH_SECTION_DATA:
      return update(state, {
        [action.payload.sectionType]: {
          values: {
            geometry: {
              concrete: {
                sectionext: {
                  $set: action.payload.geometry.concrete.sectionext
                },
                sectionint: {
                  $set: action.payload.geometry.concrete.sectionint
                }
              }
            }
            // $merge: {
            //   steel: action.payload.steel
            // }
          }
        }
      });
    case types.CHANGE_GLOBAL_UNITS:
      return update(state, {
        [action.payload.sectionType]: {
          values: {
            options: {
              unit: {
                $merge: {
                  value: action.payload.newValue.options.unit.value
                }
              }
            },
            steel: {
              fyk: {
                $merge: {
                  value: action.payload.newValue.steel.fyk.value,
                  unit: action.payload.newValue.steel.fyk.unit
                }
              },
              Es: {
                $merge: {
                  value: action.payload.newValue.steel.Es.value,
                  unit: action.payload.newValue.steel.Es.unit
                }
              }
            },
            concrete: {
              fck: {
                $merge: {
                  value: action.payload.newValue.concrete.fck.value,
                  unit: action.payload.newValue.concrete.fck.unit
                }
              }
            },
            geometry: {
              concrete:
              {
                $set: action.payload.newValue.geometry.concrete
              }
            },
            reinforcement: {
              unit: {
                $set: action.payload.newValue.reinforcement.unit
              },
              unitDiameter: {
                $set: action.payload.newValue.reinforcement.unitDiameter
              },
              geometry: {
                $set: action.payload.newValue.reinforcement.geometry
              }
            },
            loads: {
              Nsls: {
                $merge: {
                  value: action.payload.newValue.loads.N.value,
                  unit: action.payload.newValue.loads.N.unit
                }
              },
              Mxsls: {
                $merge: {
                  value: action.payload.newValue.loads.Mx.value,
                  unit: action.payload.newValue.loads.Mx.unit
                }
              },
              Mysls: {
                $merge: {
                  value: action.payload.newValue.loads.My.value,
                  unit: action.payload.newValue.loads.My.unit
                }
              },
              Nuls: {
                $merge: {
                  value: action.payload.newValue.loads.N.value,
                  unit: action.payload.newValue.loads.N.unit
                }
              },
              Mxuls: {
                $merge: {
                  value: action.payload.newValue.loads.Mx.value,
                  unit: action.payload.newValue.loads.Mx.unit
                }
              },
              Myuls: {
                $merge: {
                  value: action.payload.newValue.loads.My.value,
                  unit: action.payload.newValue.loads.My.unit
                }
              }
            }
          }
        }
      });
    case types.CS_CHANGE_GEOMETRY:
      return update(state, {
        [action.payload.sectionType]: {
          values: {
            geometry: {
              concrete: {
                [action.payload.name]: {
                  value: {
                    $set: action.payload.value
                  }
                },
                sectionext: {
                  $set: action.payload.sectionext
                },
                sectionint: {
                  $set: action.payload.sectionint
                }
              }
            }
          }
        }
      });
    case types.LOAD_OUTPUTS:
      return update(state, {
        outputs: {
          sectionData: {
            xg: {
              value: {
                $set: action.payload.data.sectionData.xg
              }
            },
            yg: {
              value: {
                $set: action.payload.data.sectionData.yg
              }
            },
            area: {
              value: {
                $set: action.payload.data.sectionData.area
              }
            },
            ixx: {
              value: {
                $set: action.payload.data.sectionData.ixx
              }
            },
            iyy: {
              value: {
                $set: action.payload.data.sectionData.iyy
              }
            },
            ixy: {
              value: {
                $set: action.payload.data.sectionData.ixy
              }
            }
          },
          sls: {
            resCalculSls: {
              A: {
                $set: action.payload.data.sls.resCalculSls.A
              },
              B: {
                $set: action.payload.data.sls.resCalculSls.B
              },
              C: {
                $set: action.payload.data.sls.resCalculSls.C
              },
              accuracy: {
                $set: action.payload.data.sls.resCalculSls.accuracy
              }
            },
            resNeutralAxis: {
              $set: action.payload.data.sls.resNeutralAxis
            },
            resExtremStress: {
              concrete: {
                sigmacmax: {
                  value: {
                    $set: action.payload.data.sls.resExtremStress.concrete.sigmamax.stress
                  },
                  coordinate: {
                    $set: action.payload.data.sls.resExtremStress.concrete.sigmamax.coordinate
                  }
                },
                sigmacmin: {
                  value: {
                    $set: action.payload.data.sls.resExtremStress.concrete.sigmamin.stress
                  },
                  coordinate: {
                    $set: action.payload.data.sls.resExtremStress.concrete.sigmamin.coordinate
                  }
                }
              },
              steel: {
                sigmasmax: {
                  value: {
                    $set: action.payload.data.sls.resExtremStress.steel.sigma.stress
                  },
                  coordinate: {
                    $set: action.payload.data.sls.resExtremStress.steel.sigma.coordinate
                  }
                },
                sigmar: {
                  geometry: {
                    $set: action.payload.data.sls.resExtremStress.steel.sigmar
                  }
                }
              }
            }
          },
          uls: {
            resCalculUls: {
              A: {
                $set: action.payload.data.uls.resCalculUls.A
              },
              B: {
                $set: action.payload.data.uls.resCalculUls.B
              },
              C: {
                $set: action.payload.data.uls.resCalculUls.C
              },
              accuracy: {
                $set: action.payload.data.uls.resCalculUls.accuracy
              },
              msg: {
                $set: action.payload.data.uls.msg
              }
            },
            resNeutralAxis: {
              $set: action.payload.data.uls.resNeutralAxis
            },
            resExtremStrain: {
              concrete: {
                epsicmax: {
                  value: {
                    $set: action.payload.data.uls.resExtremStrain.concrete.epsimax.strain
                  },
                  coordinate: {
                    $set: action.payload.data.uls.resExtremStrain.concrete.epsimax.coordinate
                  }
                },
                epsicmin: {
                  value: {
                    $set: action.payload.data.uls.resExtremStrain.concrete.epsimin.strain
                  },
                  coordinate: {
                    $set: action.payload.data.uls.resExtremStrain.concrete.epsimin.coordinate
                  }
                }
              },
              steel: {
                epsismax: {
                  value: {
                    $set: action.payload.data.uls.resExtremStrain.steel.epsi.strain
                  },
                  coordinate: {
                    $set: action.payload.data.uls.resExtremStrain.steel.epsi.coordinate
                  }
                },
                epsir: {
                  geometry: {
                    $set: action.payload.data.uls.resExtremStrain.steel.epsir
                  }
                }
              }
            },
            interactionDiagram: {
              value: {
                $set: action.payload.data.uls.interactionDiagram
              }
            }
          }
        }
      });
    default:
      return state;
  }
};

export default ConcreteSection;
