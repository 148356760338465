import React from 'react';
import {
  Container,
  Row,
  Col,
  Label
} from 'reactstrap';
import SelectRF from '../../../../../../../../components/UIComponents/Fields/SelectRF';
import optionsForceUnits from '../../../../../../../../utils/units/optionsForceUnits2';
import optionsMomentUnits from '../../../../../../../../utils/units/optionsMomentUnits2';
import InputsUnit from '../../../../../../../../components/UIComponents/Fields/InputsUnit2/';

const Loads = ({
  loads
}) => {
  return (
    <Container>
      <Row>
        <Col md={6}>
          <InputsUnit
            selectList={optionsForceUnits}
            label={loads.Ned.text}
            inputName="loads.Ned.value"
            selectName="loads.Ned.unit.name"
            tooltipDescription={loads.Ned.description}
            tooltipTarget={loads.Ned.name}
          />
          <InputsUnit
            selectList={optionsForceUnits}
            label={loads.Ved.text}
            inputName="loads.Ved.value"
            selectName="loads.Ved.unit.name"
            tooltipDescription={loads.Ved.description}
            tooltipTarget={loads.Ved.name}
          />
          <InputsUnit
            selectList={optionsMomentUnits}
            label={loads.Mxed.text}
            inputName="loads.Mxed.value"
            selectName="loads.Mxed.unit.name"
            tooltipDescription={loads.Mxed.description}
            tooltipTarget={loads.Mxed.name}
          />
          <InputsUnit
            selectList={optionsMomentUnits}
            label={loads.Myed.text}
            inputName="loads.Myed.value"
            selectName="loads.Myed.unit.name"
            tooltipDescription={loads.Myed.description}
            tooltipTarget={loads.Myed.name}
          />
          <InputsUnit
            selectList={optionsMomentUnits}
            label={loads.Ted.text}
            inputName="loads.Ted.value"
            selectName="loads.Ted.unit.name"
            tooltipDescription={loads.Ted.description}
            tooltipTarget={loads.Ted.name}
          />
        </Col>
        <Col md={6}>
          <h2 className="subtitle">Bending Moment about x axis : </h2>
          {/* <select>
            <option value="Mdx1">Moment Diagram 1</option>
            <option value="Mdx2">Moment Diagram 2</option>
            <option value="Mdx3">Moment Diagram 3</option>
            <option value="Mdx4">Moment Diagram 4</option>
          </select> */}
          <SelectRF
            // className="selectunits"
            name="loads.MomentDiagramX.value"
            list={loads.MomentDiagramX.list}
          />
          <br/>
          <br/>
          <h2 className="subtitle">Bending Moment about y axis : </h2>
          <SelectRF
            // className="selectunits"
            name="loads.MomentDiagramY.value"
            list={loads.MomentDiagramY.list}
          />
        </Col>
      </Row>
      <Row>
        <Col sm={12}>
          <br />
          <h2 className="subtitle">Sign convention:</h2>
          <Label className="text">
            <p>Axial compression has <strong>positive sign(+).</strong></p>
            <p>Axial tension has <strong>negative sign(-).</strong></p>
            <p>Moment with <strong>positive sign (+)</strong> produces tension at bottom and compression at top.</p>
            <p>Moment with <strong>negative sign (-)</strong> produces tension at top and compression at bottom.</p>
          </Label>
        </Col>
      </Row>
    </Container>
  );
};

export default Loads;
