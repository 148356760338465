/* eslint react/prop-types: 0 */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  Container,
  Row,
  Col
} from 'reactstrap';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  getPlans,
  getEntrepriseQuotationDemand,
  selectPlan
} from '../../actions';
import Pricing from '../../components/Pricing';
import { openModal } from '../../../../../../components/UIComponents/Modal/actions';
import Modal from '../../../../../../components/UIComponents/Modal';
import EntrepriseQuotationDemand from '../../components/EntrepriseQuotationDemand';
import PlanSubscription from '../../components/PlanSubscription';

class Plans extends Component {
  constructor(props) {
    super(props);
    this.state = {
      planSelection: 'FREE'
    };
  }

  componentDidMount() {
    const { getPlans } = this.props;
    getPlans();
  }

  changeSubscriptionChoice = (value) => {
    const { selectPlan } = this.props;
    selectPlan(value);
  }

  gotEmail = async (e) => {
    e.preventDefault();
    const { getEntrepriseQuotationDemand, openModal } = this.props;
    await getEntrepriseQuotationDemand();
    openModal({ status: false });
  }

  onSelectPlan = (planName) => {
    this.setState({ planSelection: planName });
  }

  render() {
    const {
      user,
      plans,
      openModal,
      isStripeCustomer,
      subscribePlan
    } = this.props;
    const {
      planSelection
    } = this.state;
    return (
      <Container className="plans" fluid>
        <Row>
          <Col xs="12">
            <Row>
              <Pricing
                plansList={plans}
                onChoice={this.changeSubscriptionChoice}
                openModal={openModal}
                customerStripeID={user.customerStripeID}
                isStripeCustomer={isStripeCustomer}
                subscribePlan={subscribePlan}
                planSelection={planSelection}
                onSelectPlan={this.onSelectPlan}
              />
            </Row>
          </Col>
        </Row>
        <Modal>
          { (planSelection === 'ENTREPRISE')
            ? <EntrepriseQuotationDemand
              user={user}
              gotEmail={this.gotEmail}
            />
            : <PlanSubscription
              selectedPlan={planSelection}
              subscribePlan={subscribePlan}
              openModal={openModal}
            />
          }
        </Modal>
      </Container>
    );
  }
}

Plans.propTypes = {
  getPlans: PropTypes.func.isRequired,
  plans: PropTypes.arrayOf(PropTypes.shape({
    nickname: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired
  })).isRequired,
  selectPlan: PropTypes.string.isRequired
};


const mapStateToProps = (state) => {
  const userInfo = localStorage.getItem('user');
  return {
    user: userInfo ? JSON.parse(userInfo) : {},
    plans: state.settings.plans
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({
    getPlans,
    getEntrepriseQuotationDemand,
    selectPlan,
    openModal
  }, dispatch);
};


export default connect(mapStateToProps, mapDispatchToProps)(Plans);
