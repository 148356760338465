import React from 'react';
import {
  Media,
  Row,
  Col,
  Button
} from 'reactstrap';
import PropTypes from 'prop-types';
import DocumentationSvg from './img/documentation.svg';

const Title = ({ imgsrc, title, description, documentation }) => (
  <div>
    <Media className="titleComponent">
      <Media left href="#" className="img">
        <Media className="img" object src={imgsrc} alt="Generic placeholder image" />
      </Media>
      <Media body>
        <Media heading className="title">
          {title}
        </Media>
        <Row>
          <Col xs={8}>
            <div className="text">{description}</div>
          </Col>
          <Col xs={4}>
            {(documentation) &&
              <div className="documentation"> 
                <Button 
                  href={documentation.link}
                  target="_blank"
                  className="negativeButton">

                    {documentation.text}
                </Button>
              </div>
            }
          </Col>
        </Row>
      </Media>
    </Media>
    <hr />
  </div>
);

Title.propTypes = {
  title: PropTypes.string.isRequired,
  imgsrc: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired
};

export default Title;
