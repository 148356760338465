import * as types from './actionTypes';
import initialState from './initialState';

const sidebar = (state = initialState, action) => {
  switch (action.type) {
    case types.OPEN_SIDEBAR:
      return {
        ...state,
        isOpenSideBar: action.isOpenSideBar
      };
    default:
      return state;
  }
};

export default sidebar;
