import React, { Fragment } from 'react';
import {
  Button,
  Row,
  Col,
  Label
} from 'reactstrap';
import { compose } from 'recompose';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import InputsUnit from '../../../../../../../../components/UIComponents/Fields/InputsUnit2';
import {
  addFields,
  getFields,
  removeFields,
  editFields,
  setActive,
  changeName
} from '../../../../actions';
import optionsDensityUnits from '../../../../../../../../utils/units/optionsDensityUnits2';
import optionsStressUnits from '../../../../../../../../utils/units/optionsStressUnits2';
import Select from '../../components/Select';
import FormMaterial from './scenes/FormMaterial';

class Materials extends React.Component {
  constructor(props) {
    super(props);
    this.toggle = this.toggle.bind(this);
    this.showErrorMessage = this.showErrorMessage.bind(this);
    this.state = {
      activeView: 'Select',
      errorMessage: ''
    };
  }

  toggle(view) {
    if (this.state.activeView !== view) {
      this.setState({
        activeView: view
      });
    }
  }

  showErrorMessage(value) {
    this.setState({
      errorMessage: value
    });
  }

  render() {
    const {
      name,
      active,
      getFields,
      inputs,
      removeFields
    } = this.props;
    const {
      activeView,
      errorMessage
    } = this.state;
    return (
      <div>
        {activeView === 'Select'
          && <Select
            name={name}
            active={active}
            showErrorMessage={this.showErrorMessage}
            list={inputs}
            changeView={this.toggle}
            getFields={getFields}
            removeFields={removeFields}
          />
        }
        {activeView === 'Edit'
          && <FormMaterial
            view={activeView}
            errorMessage={errorMessage}
            showErrorMessage={this.showErrorMessage}
            materials={inputs}
            changeView={this.toggle}
            removeFields={removeFields}
          />
        }
        {activeView === 'Add'
          && <FormMaterial
            view={activeView}
            errorMessage={errorMessage}
            showErrorMessage={this.showErrorMessage}
            materials={inputs}
            changeView={this.toggle}
            removeFields={removeFields}
          />
        }
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({
    addFields,
    getFields,
    removeFields,
    editFields,
    setActive,
    changeName
  }, dispatch);
};

export default compose(
  connect(null, mapDispatchToProps),
)(Materials);
