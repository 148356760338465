import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { PropTypes } from 'prop-types';
import history from '../../history';

export default function (ComposedComponent) {
  class Authenticate extends React.Component {
    componentDidMount() {
      this.checkAndRedirect();
    }

    componentDidUpdate() {
      this.checkAndRedirect();
    }

    checkAndRedirect() {
      const { authenticate, isAuthentified } = this.props;
      const token = localStorage.getItem('token');
      if (!token && !isAuthentified) {
        history.push('/signin');
      } else {
        authenticate();
      }
    }

    render() {
      return (
        <div>
          <ComposedComponent {...this.props} />
        </div>
      );
    }
  }

  function mapStateToProps(state) {
    return {
      isAuthentified: state.signin.isAuth
    };
  }

  const mapDispatchToProps = dispatch => ({
    authenticate: () => dispatch({
      type: 'AUTHENTICATION_SUCCESS',
      isAuth: true
    })
  });

  Authenticate.propTypes = {
    authenticate: PropTypes.func.isRequired,
    isAuthentified: PropTypes.bool.isRequired
  };

  return compose(
    connect(mapStateToProps, mapDispatchToProps)
  )(Authenticate);
}
