const sectionTypesArray = (sectionType, inputs) => {
  switch (sectionType) {
    case 'rectangularSection': {
      const b = Number(inputs.b.value);
      const h = Number(inputs.h.value);
      return {
        sectionext: [
          { x: 0, y: 0 },
          { x: b, y: 0 },
          { x: b, y: h },
          { x: 0, y: h },
          { x: 0, y: 0 }
        ],
        sectionint: [
          { x: 0, y: 0 },
          { x: 0, y: 0 }
        ]
      };
    }
    case 'tSection': {
      const b = Number(inputs.b.value);
      const h = Number(inputs.h.value);
      const b0 = Number(inputs.b0.value);
      const h0 = Number(inputs.h0.value);
      return {
        sectionext: [
          { x: 0, y: h },
          { x: 0, y: h - h0 },
          { x: b / 2 - b0 / 2, y: h - h0 },
          { x: b / 2 - b0 / 2, y: 0 },
          { x: b / 2 + b0 / 2, y: 0 },
          { x: b / 2 + b0 / 2, y: h - h0 },
          { x: b, y: h - h0 },
          { x: b, y: h },
          { x: 0, y: h }
        ],
        sectionint: [
          { x: 0, y: 0 },
          { x: 0, y: 0 }
        ]
      };
    }
    case 'iSectionSteel': {
      const b = Number(inputs.b.value);
      const h = Number(inputs.h.value);
      const tw = Number(inputs.tw.value);
      const tf = Number(inputs.tf.value);
      const c = Number(inputs.c.value);
      return {
        sectionext: [
          { x: 0, y: 0 },
          { x: b / 2 - c, y: 0 },
          { x: b / 2 - c, y: tf - 2 * c },
          { x: tw / 2 - c, y: tf - 2 * c },
          { x: tw / 2 - c, y: h - tf },
          { x: b / 2 - c, y: h - tf },
          { x: b / 2 - c, y: h - 2 * c },
          { x: -b / 2 + c, y: h - 2 * c },
          { x: -b / 2 + c, y: h - tf },
          { x: -tw / 2 + c, y: h - tf },
          { x: -tw / 2 + c, y: tf - 2 * c },
          { x: -b / 2 + c, y: tf - 2 * c },
          { x: -b / 2 + c, y: 0 },
          { x: 0, y: 0 }
        ],
        sectionint: [
          { x: 0, y: 0 },
          { x: 0, y: 0 }
        ]
      };
    }
    case 'iSection': {
      const b = Number(inputs.b.value);
      const h = Number(inputs.h.value);
      const b0 = Number(inputs.b0.value);
      const h0 = Number(inputs.h0.value);
      const b1 = Number(inputs.b1.value);
      const h1 = Number(inputs.h1.value);
      return {
        sectionext: [
          { x: 0, y: 0 },
          { x: b1 / 2, y: 0 },
          { x: b1 / 2, y: h1 },
          { x: b / 2, y: h1 },
          { x: b / 2, y: h - h0 },
          { x: b0 / 2, y: h - h0 },
          { x: b0 / 2, y: h },
          { x: -b0 / 2, y: h },
          { x: -b0 / 2, y: h - h0 },
          { x: -b / 2, y: h - h0 },
          { x: -b / 2, y: h1 },
          { x: -b1 / 2, y: h1 },
          { x: -b1 / 2, y: 0 },
          { x: 0, y: 0 }
        ],
        sectionint: [
          { x: 0, y: 0 },
          { x: 0, y: 0 }
        ]
      };
    }
    case 'boxSection': {
      const b = Number(inputs.b.value);
      const h = Number(inputs.h.value);
      const d = Number(inputs.d.value);
      const tf = Number(inputs.tf.value);
      const tw = Number(inputs.tw.value);
      const c = Number(inputs.c.value);
      return {
        sectionext: [
          { x: 0, y: 0 },
          { x: b / 2 - c, y: 0 },
          { x: b / 2 - c, y: tf - 2 * c },
          { x: d / 2 + tw - c, y: tf - 2 * c },
          { x: d / 2 + tw - c, y: h - tf },
          { x: b / 2 - c, y: h - tf },
          { x: b / 2 - c, y: h - 2 * c },
          { x: -b / 2 + c, y: h - 2 * c },
          { x: -b / 2 + c, y: h - tf },
          { x: -d / 2 - tw + c, y: h - tf },
          { x: -d / 2 - tw + c, y: tf - 2 * c },
          { x: -b / 2 + c, y: tf - 2 * c },
          { x: -b / 2 + c, y: 0 },
          { x: 0, y: 0 }
        ],
        sectionint: [
          { x: 0, y: tf - 2 * c },
          { x: d / 2 + c, y: tf - 2 * c },
          { x: d / 2 + c, y: h - tf },
          { x: -d / 2 - c, y: h - tf },
          { x: -d / 2 - c, y: tf - 2 * c },
          { x: 0, y: tf - 2 * c }
        ]
      };
    }
    case 'circleSection': {
      const D = Number(inputs.D.value);
      const arr = [];
      for (let i = 0; i < 50; i += 1) {
        const xext = D / 2 * Math.cos(i * 2 * Math.PI / 49);
        const yext = D / 2 * Math.sin(i * 2 * Math.PI / 49);
        arr.push({ x: xext, y: yext });
      }
      return {
        sectionext: arr,
        sectionint: [
          { x: 0, y: 0 },
          { x: 0, y: 0 }
        ]
      };
    }
    case 'hollowCircleSection': {
      const D = Number(inputs.D.value);
      const t = Number(inputs.t.value);
      const arrExt = [];
      const arrInt = [];
      for (let i = 0; i < 50; i += 1) {
        const xext = D / 2 * Math.cos(i * 2 * Math.PI / 49);
        const yext = D / 2 * Math.sin(i * 2 * Math.PI / 49);
        const xint = (D / 2 - t) * Math.cos(i * 2 * Math.PI / 49);
        const yint = (D / 2 - t) * Math.sin(i * 2 * Math.PI / 49);
        arrExt.push({ x: xext, y: yext });
        arrInt.push({ x: xint, y: yint });
      }
      return {
        sectionext: arrExt,
        sectionint: arrInt
      };
    }
    case 'randomSection': {
      return {
        sectionext: [
          { x: 0, y: 0 },
          { x: 1, y: 0 },
          { x: 2, y: 2.75 },
          { x: 6, y: 2.75 },
          { x: 6, y: 3 },
          { x: 0, y: 3 },
          { x: -6, y: 3 },
          { x: -6, y: 2.75 },
          { x: -2, y: 2.75 },
          { x: -1, y: 0 },
          { x: 0, y: 0 }
        ],
        sectionint: [
          { x: 0, y: 0.5 },
          { x: 0.86259909334401, y: 0.5 },
          { x: 1.62623545698037, y: 2.6 },
          { x: -1.62623545698037, y: 2.6 },
          { x: -0.86259909334401, y: 0.5 },
          { x: 0, y: 0.5 }
        ]
      };
    }
    case 'editSection': {
      return {
        sectionext: inputs.sectionext,
        sectionint: inputs.sectionint
      };
    }
    default:
      return 'error';
  }
};

export default sectionTypesArray;
