const initialState = {
  label: 'm-MN-kg',
  value: {
    unitLength: 'm',
    unitArea: 'm²',
    unitVolume: 'm3',
    unitTime: 's',
    unitWeight: 'kg',
    unitForce: 'MN',
    unitMoment: 'MN.m',
    unitStress: 'MPa',
    unitTemperature: '°C'
  }
};

export default initialState;
