import * as THREE from 'three';

const matrixGlobalToLocal = (
  Ai,
  Aj,
) => {
  const Lij = ((((Aj.X - Ai.X) ** 2) + ((Aj.Y - Ai.Y) ** 2) + ((Aj.Z - Ai.Z) ** 2)) ** 0.5);
  const P = [];

  for (let i = 0; i < 3; i += 1) {
    P[i] = [];
    for (let j = 0; j < 3; j += 1) {
      P[i][j] = 0;
    }
  }

  // vecteur u1
  if (Lij !== 0) {
    P[0][0] = (Aj.X - Ai.X) / Lij;
    P[0][1] = (Aj.Y - Ai.Y) / Lij;
    P[0][2] = (Aj.Z - Ai.Z) / Lij;
  }
  // vecteur u2
  if (Math.abs(P[0][2]) === 1) {
    P[1][0] = 0;
    P[1][1] = 1 * P[0][2];
    P[1][2] = 0;
  } else {
    P[1][0] = -P[0][1] / (((P[0][0] ** 2) + (P[0][1] ** 2)) ** 0.5);
    P[1][1] = P[0][0] / (((P[0][0] ** 2) + (P[0][1] ** 2)) ** 0.5);
    P[1][2] = 0;
  }

  // vecteur u3
  const ProdVect = P[0][0] * P[1][0] + P[0][1] * P[1][1] + P[0][2] * P[1][2];
  P[2][0] = (P[0][1] * P[1][2] - P[0][2] * P[1][1]) / ((1 - (ProdVect ** 2)) ** 0.5);
  P[2][1] = (P[0][2] * P[1][0] - P[0][0] * P[1][2]) / ((1 - (ProdVect ** 2)) ** 0.5);
  P[2][2] = (P[0][0] * P[1][1] - P[0][1] * P[1][0]) / ((1 - (ProdVect ** 2)) ** 0.5);

  return {
    P,
    Lij
  };
};

const localCoordinate = (
  Ai,
  Aj,
  xi,
  yi,
  xj,
  yj
) => {
  const { P, Lij } = matrixGlobalToLocal(Ai, Aj);
  const Xi = Number(Ai.X) + P[1][0] * Number(xi) + P[2][0] * Number(yi);
  const Yi = Number(Ai.Y) + P[1][1] * Number(xi) + P[2][1] * Number(yi);
  const Zi = Number(Ai.Z) + P[1][2] * Number(xi) + P[2][2] * Number(yi);

  const Xj = P[0][0] * Lij + Number(Ai.X) + P[1][0] * Number(xj) + P[2][0] * Number(yj);
  const Yj = P[0][1] * Lij + Number(Ai.Y) + P[1][1] * Number(xj) + P[2][1] * Number(yj);
  const Zj = P[0][2] * Lij + Number(Ai.Z) + P[1][2] * Number(xj) + P[2][2] * Number(yj);
  return {
    Xi, Yi, Zi, Xj, Yj, Zj
  };
};

const sollicitations = (
  Mn0,
  Mn,
  xn0,
  yn0,
  xn,
  yn
) => {
  const sol = localCoordinate(Mn0, Mn, xn0, yn0, xn, yn);
  const lineGeo = new THREE.Geometry();
  const lineMat = new THREE.LineBasicMaterial({ color: 'red' });
  console.log('SOLLIC ', sol);
  lineGeo.vertices.push(
    new THREE.Vector3(Number(Mn0.X), Number(Mn0.Y), Number(Mn0.Z)),
    new THREE.Vector3(Number(sol.Xi), Number(sol.Yi), Number(sol.Zi)),
    new THREE.Vector3(Number(sol.Xj), Number(sol.Yj), Number(sol.Zj)),
    new THREE.Vector3(Number(Mn.X), Number(Mn.Y), Number(Mn.Z))
  );
  const memberSoll = new THREE.Line(lineGeo, lineMat);
  return memberSoll;
};

const ratio = (
  maxSollHeight,
  sollicitation
) => {
  if (sollicitation === 0) {
    return 0;
  }
  return maxSollHeight / sollicitation;
};

export default (name, {
  scene,
  // labelMesh,
  memberSollicitationMesh,
  uiManagement,
  data
}) => {
  const listMemberSoll = data.localForces;
  console.log('listMemberSoll ', listMemberSoll);
  // Deleting all members mesh
  if (typeof memberSollicitationMesh !== 'undefined' && memberSollicitationMesh.length > 0) {
    for (let i = memberSollicitationMesh.length - 1; i >= 0; i -= 1) {
      scene.remove(memberSollicitationMesh[i]);
      memberSollicitationMesh.pop();
    }
  }
  const maxSollHeight = 4;


  let N1abs = 0;
  let V2abs = 0;
  let V3abs = 0;
  let M1abs = 0;
  let M2abs = 0;
  let M3abs = 0;

  if (typeof listMemberSoll !== 'undefined' && listMemberSoll.length > 0) {
    for (let i = 0; i < listMemberSoll.length; i += 1) {
      const {
        N1min,
        N1max,
        V2min,
        V2max,
        V3min,
        V3max,
        M1min,
        M1max,
        M2min,
        M2max,
        M3min,
        M3max
      } = listMemberSoll[i];
      N1abs = Math.max(Math.max(Math.abs(N1min), Math.abs(N1max)), N1abs);
      V2abs = Math.max(Math.max(Math.abs(V2min), Math.abs(V2max)), V2abs);
      V3abs = Math.max(Math.max(Math.abs(V3min), Math.abs(V3max)), V3abs);
      M1abs = Math.max(Math.max(Math.abs(M1min), Math.abs(M1max)), M1abs);
      M2abs = Math.max(Math.max(Math.abs(M2min), Math.abs(M2max)), M2abs);
      M3abs = Math.max(Math.max(Math.abs(M3min), Math.abs(M3max)), M3abs);
    }


    for (let i = 0; i < listMemberSoll.length; i += 1) {
      if (typeof listMemberSoll[i].memberSollicitations !== 'undefined' && listMemberSoll[i].memberSollicitations.length > 0) {
        for (let j = 1; j < listMemberSoll[i].memberSollicitations.length; j += 1) {
          const Mn0 = listMemberSoll[i].memberSollicitations[j - 1];
          const Mn = listMemberSoll[i].memberSollicitations[j];

          if (uiManagement.showN1) {
            const memberSoll = sollicitations(Mn0, Mn, Mn0.N1 * ratio(maxSollHeight, N1abs), 0, Mn.N1 * ratio(maxSollHeight, N1abs), 0);
            memberSollicitationMesh.push(memberSoll);
            scene.add(memberSoll);
          }
          if (uiManagement.showV2) {
            const memberSoll = sollicitations(Mn0, Mn, Mn0.V2 * ratio(maxSollHeight, V2abs), 0, Mn.V2 * ratio(maxSollHeight, V2abs), 0);
            memberSollicitationMesh.push(memberSoll);
            scene.add(memberSoll);
          }
          if (uiManagement.showV3) {
            const memberSoll = sollicitations(Mn0, Mn, 0, Mn0.V3 * ratio(maxSollHeight, V3abs), 0, Mn.V3 * ratio(maxSollHeight, V3abs));
            memberSollicitationMesh.push(memberSoll);
            scene.add(memberSoll);
          }
          if (uiManagement.showM1) {
            const memberSoll = sollicitations(Mn0, Mn, Mn0.M1 * ratio(maxSollHeight, M1abs), 0, Mn.M1 * ratio(maxSollHeight, M1abs), 0);
            memberSollicitationMesh.push(memberSoll);
            scene.add(memberSoll);
          }
          if (uiManagement.showM2) {
            const memberSoll = sollicitations(Mn0, Mn, 0, Mn0.M2 * ratio(maxSollHeight, M2abs), 0, Mn.M2 * ratio(maxSollHeight, M2abs));
            memberSollicitationMesh.push(memberSoll);
            scene.add(memberSoll);
          }
          if (uiManagement.showM3) {
            const memberSoll = sollicitations(Mn0, Mn, Mn0.M3 * ratio(maxSollHeight, M3abs), 0, Mn.M3 * ratio(maxSollHeight, M3abs), 0);
            memberSollicitationMesh.push(memberSoll);
            scene.add(memberSoll);
          }
        }
      }
    }
  }

  return {
    mesh: memberSollicitationMesh
  };
};
