import update from 'immutability-helper';

import initialState from './initialState';
import * as types from './actionTypes';

const sectionGeometry = (state = initialState, action = {}) => {
  switch (action.type) {
    case types.SG_CHANGE_INPUT_VALUE:
      return update(state, {
        [action.payload.sectionType]: {
          inputs: {
            [action.payload.inputs.name]: {
              value: {
                $set: action.payload.inputs.value
              }
            }
          }
        },
        outputs: {
          sectionext: {
            $set: action.payload.outputs.sectionext
          },
          sectionint: {
            $set: action.payload.outputs.sectionint
          },
          xg: {
            value: {
              $set: action.payload.outputs.xg
            }
          },
          yg: {
            value: {
              $set: action.payload.outputs.yg
            }
          },
          area: {
            value: {
              $set: action.payload.outputs.area
            }
          },
          Pext: {
            value: {
              $set: action.payload.outputs.Pext
            }
          },
          Pint: {
            value: {
              $set: action.payload.outputs.Pint
            }
          },
          ixx: {
            value: {
              $set: action.payload.outputs.ixx
            }
          },
          iyy: {
            value: {
              $set: action.payload.outputs.iyy
            }
          }
        }
      });
    case types.SG_FETCH_SECTION_DATA:
      return update(state, {
        outputs: {
          sectionext: {
            $set: action.payload.outputs.sectionext
          },
          sectionint: {
            $set: action.payload.outputs.sectionint
          },
          xg: {
            value: {
              $set: action.payload.outputs.xg
            }
          },
          yg: {
            value: {
              $set: action.payload.outputs.yg
            }
          },
          area: {
            value: {
              $set: action.payload.outputs.area
            }
          },
          Pext: {
            value: {
              $set: action.payload.outputs.Pext
            }
          },
          Pint: {
            value: {
              $set: action.payload.outputs.Pint
            }
          },
          ixx: {
            value: {
              $set: action.payload.outputs.ixx
            }
          },
          iyy: {
            value: {
              $set: action.payload.outputs.iyy
            }
          }
        }
      });
    default:
      return state;
  }
};

export default sectionGeometry;
