const chartOptions = (inputs, waterLevel, L) => {
  return {
    showLines: true,
    animation: false,
    legend: {
      display: true
    },
    tooltips: {
      callbacks: {
        label: (t) => {
          const numx = t.xLabel.toFixed(3);
          const numy = t.yLabel.toFixed(3);
          const label = `X: ${numx} Y : ${numy}`;
          return label;
        }
      }
    },
    elements: {
      point: { radius: 0 }
    },
    scales: {
      xAxes: [{
        type: 'linear',
        position: 'bottom',
        ticks: {
          min: 0,
          max: L,
          stepSize: L / 4
        },
        scaleLabel: {
          display: true,
          labelString: `x(${inputs.H.unit})`
        }
      }],
      yAxes: [{
        type: 'linear',
        position: 'bottom',
        ticks: {
          min: -inputs.d.value,
          max: waterLevel[0].y
        },
        scaleLabel: {
          display: true,
          labelString: `y(${inputs.H.unit})`
        }
      }]
    }
  };
};

export default chartOptions;
