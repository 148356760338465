import React, { memo, useState, useEffect } from 'react';
import {
    Row,
    Col,
    Button,
    Tooltip
  } from 'reactstrap';


const DisplayButton = ({
    uiManagement,
    refreshScene,
    name,
    label,
    img
  }) => {
    const [toggle, setToggle] = useState(false);
    const [color, setColor] = useState({backgroundColor: "white" });

    return (
      <Row>
      <Col xs={12}>
        <Button
          id={`${name}`}
          style={color}
          className="alternativeButton"
          onClick={() => {
            refreshScene(name, uiManagement[name]);
            if(color.backgroundColor === "white") {
              setColor({backgroundColor: "LightGray" });
            } else {
              setColor({backgroundColor: "white" });
            }
          }}     
        >
          <img className="" src={img}></img>
        </Button>
        <Tooltip placement="right" isOpen={toggle} target={`${name}`} toggle={() => setToggle(!toggle)}>
          {`${label}`}
        </Tooltip>
      </Col>
    </Row>
    );
  };

  export default DisplayButton;