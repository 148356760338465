import React, { Fragment } from 'react';
import socketIOClient from 'socket.io-client';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';
import { compose } from 'recompose';
import { bindActionCreators } from 'redux';
import { Link, withRouter } from 'react-router-dom';
import {
  Breadcrumb,
  BreadcrumbItem,
  TabContent,
  TabPane,
  Row,
  Col,
  Container,
  Label,
  Input,
  Card,
  CardHeader,
  CardText,
  CardBody,
  Button
} from 'reactstrap';
import Draggable from 'react-draggable';
import html2canvas from 'html2canvas';
import ScrollMenu from './scenes/ScrollMenu';
import { LoadableStructure3D } from '../../../Structure3D';
import RenderTHREEScene from '../../components/threeScene';
import ObjectDescription from './scenes/ObjectDescription';
// import Inputs from '../../components/Inputs';
import Loader from '../../../../components/UIComponents/Loader';
import Inputs from './scenes/Inputs';
import Options from '../../../../components/UIComponents/Options';
import Modal from '../../../../components/UIComponents/Modal';
import GoToPricingModal from '../../../../components/UIComponents/GoToPricingModal';
import Outputs from './scenes/Outputs';
import GraphicUI from './scenes/GraphicUI';

import validate from './scenes/validations/validate';
import {
  linearAnalysis,
  graphicalUIManagement,
  fetchStructure3DProject,
  saveProject,
  sceneRefresh,
  takeScreenShot,
  resetUiManagement,
  activeObject
} from './actions';
import { openModal } from '../../../../components/UIComponents/Modal/actions';
import threeScene from '../../components/threeScene';

const itemScrollMenu = [
  {
    num: '1',
    icon: 'show_chart',
    title: 'Materials'
  },
  {
    num: '2',
    icon: 'grid_on',
    title: 'Sections'
  },
  {
    num: '3',
    icon: 'toll',
    title: 'Supports'
  },
  {
    num: '4',
    icon: 'scatter_plot',
    title: 'Nodes'
  },
  {
    num: '5',
    icon: 'remove',
    title: 'Members'
  },
  {
    num: '6',
    icon: 'play_for_work',
    title: 'Node Loads'
  },
  {
    num: '7',
    icon: 'vertical_align_bottom',
    title: 'Member Loads'
  },
  // {
  //   num: '8',
  //   icon: 'vertical_align_bottom',
  //   title: 'Analysis'
  // }
];
const isNewProject = (id) => id === 'new';

class Structure3DCalculation extends React.Component {
  constructor(props) {
    super(props);
    this.toggle = this.toggle.bind(this);
    this.extendTable = this.extendTable.bind(this);
    this.extendScene = this.extendScene.bind(this);
    this.refreshScene = this.refreshScene.bind(this);
    this.startRefreshScene = this.startRefreshScene.bind(this);
    this.stopRefreshScene = this.stopRefreshScene.bind(this);
    this.submitValues = this.submitValues.bind(this);
    this.saveStructure3DProject = this.saveStructure3DProject.bind(this);
    this.onClickObjectData = this.onClickObjectData.bind(this);
    this.onDisplayInputsData = this.onDisplayInputsData.bind(this);
    this.onDisplayUIOptions = this.onDisplayUIOptions.bind(this);
    this.mapTabIndexToCompName = this.mapTabIndexToCompName.bind(this);

    this.state = {
      activeTab: '1',
      tableLarge: false,
      sceneLarge: false,
      refreshingScene: false,
      showOutputs: true,
      displayInputsData: true,
      displayUIOptions: true,
      selectedName: 'materials',
      computeLinearAnalysis: false,
      saveProjectState: true
    };
  }

  componentDidMount = async () => {
    const { fetchStructure3DProject, sceneRefresh, resetUiManagement, match } = this.props;
    const { id } = match.params;
    await fetchStructure3DProject(id);
    resetUiManagement();
    sceneRefresh(true);
    sceneRefresh(false);
    // await this.startRefreshScene();
    // await this.stopRefreshScene();
    // const socket = socketIOClient('http://localhost:8080/api/');
    // socket.on('message', (message) => {
    //   console.log('MESSAGE: ' + message.content);
    // });
  }

  toggle(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab
      });
      this.mapTabIndexToCompName(tab)
    }
  }

  extendTable(val) {
    this.setState({
      tableLarge: !(val)
    });
  }

  extendScene(val) {
    this.setState({
      sceneLarge: !(val)
    });
  }

  startRefreshScene() {
    const { sceneRefresh } = this.props;
    this.setState({ refreshingScene: true })
    setTimeout(() => {
      sceneRefresh(true);
    }, 500);
  }

  stopRefreshScene() {
    //UNCOMMENT TO ADD REFRESH SCENE ON APPLY
    const { sceneRefresh } = this.props;
    sceneRefresh(false);
    this.setState({ refreshingScene: false })
  }

  //TODO: move to Options component
  saveStructure3DProject = async (structure3D) => {
    this.setState({ saveProjectState: false });
    const { history, match, saveProject } = this.props;
    const { id: pageId } = match.params;

    // const canvas = await html2canvas(document.querySelector("#structure3D"));

    const project = {
      ...structure3D,
      _id: !isNewProject(pageId) && pageId,
      options: {
        ...structure3D.options,
        image: await takeScreenShot()
      }
    };

    const projectId = await saveProject(project);
    if (isNewProject(pageId)) {
      history.replace(`/structure3D/projects/${projectId}`);
    }
    this.setState({ saveProjectState: true });
  }

  async refreshScene(UIType, isActive) {
    const {
      sceneRefresh,
      graphicalUIManagement,
      refreshing
    } = this.props;
    this.setState({ refreshingScene: true })
    await graphicalUIManagement(UIType, isActive);
    setTimeout(() => {
      sceneRefresh(true);
      sceneRefresh(false)
      this.setState({ refreshingScene: false });
    }, 500);
  }

  onClickObjectData = (clickObject) => {
    const { activeObject } = this.props;
    if (clickObject.data && clickObject.data.name) {
      activeObject({
        clickObjectData: {
          _id: clickObject.data._id,
          type: clickObject.type
        },
        displayObjectInfo: true
      })
    }
  }

  onDisplayInputsData = (value) => {
    this.setState({ displayInputsData: value });
  }

  onDisplayUIOptions = () => {
    this.setState({ displayUIOptions: false });
  }

  mapTabIndexToCompName(tab) {
    switch (tab) {
      case '1':
        this.setState({ selectedName: 'materials' });
        break;
      case '2':
        this.setState({ selectedName: 'sections' });
        break;
      case '3':
        this.setState({ selectedName: 'supports' });
        break;
      case '4':
        this.setState({ selectedName: 'nodes' });
        break;
      case '5':
        this.setState({ selectedName: 'members' });
        break;
      case '6':
        this.setState({ selectedName: 'nodeLoads' });
        break;
      case '7':
        this.setState({ selectedName: 'memberLoads' });
        break;
      default:
        this.setState({ selectedName: 'materials' });
    }
  }

  async submitValues(structure3D) {
    const { linearAnalysis, openModal, history, match } = this.props;
    const { id } = match.params;
    // const socket = socketIOClient('http://localhost:8080/api/linearanalysis/');
    // socket.on('structure3DLinearAnalysis', (progress) => {
    //   console.log('PROGRESS: ' + progress.percentage);
    // });
    // this.setState({ computeLinearAnalysis: true });

    const user = JSON.parse(localStorage.getItem('user'));
    if (!user.subscriptionId && user.calculationCounter > 4) {
      this.setState({ showOutputs: false });
      openModal({ status: true });
    } else {
      this.setState({ showOutputs: true });
      const project = {
        ...structure3D,
        _id: id,
        options: {
          ...structure3D.options,
          image: await takeScreenShot()
        }
      };
      await linearAnalysis(project, user);
      // this.setState({ computeLinearAnalysis: false });
    }
  }

  render() {
    const {
      structure3D,
      handleSubmit,
      graphicalUIManagement,
      uiManagement,
      history,
      loadingProject,
      optionName,
      sceneRefresh,
      refreshing,
      linkError,
      errorMessage,
      match
    } = this.props;
    const {
      activeTab,
      tableLarge,
      sceneLarge,
      displayInputsData,
      selectedName,
      refreshingScene,
      computeLinearAnalysis,
      saveProjectState
    } = this.state;
    const { params } = match;

    return (
      <Fragment>
        <LoadableStructure3D>
          <Fragment>
            {refreshingScene && <Loader refreshScene />}
          </Fragment>
          {
            loadingProject || computeLinearAnalysis ?
              <Loader />
              :
              <form
                className="inputs"
                onSubmit={handleSubmit(this.submitValues)}
              >
                <Options
                  optionName={optionName}
                  saveStructure3DProject={this.saveStructure3DProject}
                  openModal={openModal}
                  history={history}
                  isNewProject={isNewProject(params.id)}
                  saveProjectState={saveProjectState}
                />
                <ScrollMenu
                  toggle={this.toggle}
                  activeTab={activeTab}
                  itemScrollMenu={itemScrollMenu}
                  functionMenu={this.onDisplayInputsData}
                />
                <Col lg={12} className="inputsContainer">
                  <TabContent
                    activeTab={activeTab}
                  >
                    {
                      displayInputsData &&
                      <Inputs
                        name={selectedName}
                        tableLarge={tableLarge}
                        extendTable={this.extendTable}
                        onDisplayInputsData={this.onDisplayInputsData}
                        startRefreshScene={this.startRefreshScene}
                        refreshScene={this.refreshScene}
                        uiManagement={uiManagement}
                      />
                    }
                    {/* {
                      clickObjectData.name !== '' && displayObjectInfo && */}
                    <ObjectDescription
                      startRefreshScene={this.startRefreshScene}
                    />
                    {/* } */}
                    {tableLarge === false &&
                      <GraphicUI
                        uiManagement={uiManagement}
                        refreshScene={this.refreshScene}
                      />
                    }
                    <Col md={sceneLarge === false ? 6 : 12} style={{ display: tableLarge ? 'none' : '' }}>
                      {/* <Card id="card" style={{ display: tableLarge ? 'none' : '' }}> */}
                      {/* <CardHeader id="headerinputs">
                          <Row>
                            <Col xs={10}>
                              <Label className="headerCard">Structure View</Label>
                            </Col>
                            <Col xs={2}>
                              <div className="controlCard">
                                <i
                                  className="material-icons"
                                  onClick={() => this.extendScene(sceneLarge)}
                                >
                                  {sceneLarge === true ? 'crop' : 'crop_free'}
                                </i>
                              </div>
                            </Col>
                          </Row>
                        </CardHeader> */}
                      {/* <CardBody> */}
                      <RenderTHREEScene
                        graphicalUIManagement={graphicalUIManagement}
                        stopRefreshScene={this.stopRefreshScene}
                        onClickObjectData={this.onClickObjectData}
                      />
                    </Col>
                  </TabContent>
                </Col>
              </form>
          }
          <GoToPricingModal
            modal={{ status: linkError }}
            openModal={openModal}
            className="goToPricingModal"
          >
            <Card className="card">
              <CardHeader className="title">
                {'Upgrade your plan'}
              </CardHeader>
              <CardBody>
                <CardText className="subtitle">{errorMessage}</CardText>
                <CardText className="subtitle">In order to perform the Analysis, you need to <strong>Upgrade your plan</strong>:</CardText>
                <Button
                  className="positiveButton"
                  onClick={async () => {
                    await openModal({ status: false });
                    history.push('/settings');
                  }}
                >
                  Go Pricing
                </Button>
              </CardBody>
            </Card>
            {errorMessage}
          </GoToPricingModal>
          {/* <GoToPricingModal
            openModal={openModal}
            showOutputs={showOutputs}
            history={history}
          >
            <Outputs/>
          </GoToPricingModal> */}
        </LoadableStructure3D>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    loadingProject: state.form.structure3D.loading,
    optionName: state.form.structure3D.inputs.values.options.projectname.value,
    refreshing: state.form.structure3D.inputs.sceneRefresh,
    linkError: state.form.structure3D.outputs.links.error,
    errorMessage: state.form.structure3D.outputs.logs.message
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({
    linearAnalysis,
    graphicalUIManagement,
    fetchStructure3DProject,
    openModal,
    sceneRefresh,
    resetUiManagement,
    saveProject,
    activeObject
  }, dispatch);
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  reduxForm({
    form: 'structure3D.inputs',
    destroyOnUnmount: false,
    enableReinitialize: true,
    asyncBlurFields: [],
    // validate(values) {
    //   const errors = {
    //     materials: {
    //       outputs: {}
    //     }
    //   };
    //   if (!values.materials.outputs.name) {
    //     errors.materials.outputs = { name: 'Obligatoire' };
    //   }
    //   return errors;
    // }
  }),
  withRouter,
)(Structure3DCalculation);
