import axios from 'axios';
import { FETCH_STRUCTURE3D_PROJECTS } from './actionTypes';
import { URL } from '../../../../config/config';
import URL_PATH from '../../../../config/default';

const fetchProjects = (userId) => {
  return async (dispatch) => {
    try {
      const res = await axios.get(`${URL}${URL_PATH.structure3D.userProjects}/${userId}`);
      const options = res.data;
      dispatch({
        type: FETCH_STRUCTURE3D_PROJECTS,
        payload: { data: options, loading: false }
      });
    } catch (error) {
      console.log(error);
    }
  };
};

const deleteProject = (projectId) => {
  return async (dispatch) => {
    try {
      const res = await axios.delete(`${URL}${URL_PATH.structure3D.project}/${projectId}`);
      const options = res.data;
      dispatch({
        type: FETCH_STRUCTURE3D_PROJECTS,
        payload: { data: options, loading: false }
      });
    } catch (error) {
      console.log('ERROR REMOVE', error);
    }
  };
};

export {
  fetchProjects,
  deleteProject
};
