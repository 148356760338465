import React, { Component } from 'react';
import {
  Card,
  CardHeader,
  CardBody,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Button,
  CardTitle,
  CardText,
  Row,
  Col,
  Container,
  Badge
} from 'reactstrap';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { compose } from 'recompose';
import HorizontalScrollMenu from '../../../../../../components/UIComponents/Bar/HorizontalScrollMenu';

import ResultsSls from './scenes/ResultsSls';
import ResultsUls from './scenes/ResultsUls';
import InteractionDiagramUls from './scenes/InteractionDiagramUls';
import SectionData from './scenes/SectionData';

// const TitleMessage = (title, status) => (
//   <Row>
//     <Col xs={6}>
//       <div className="subtitle">
//         {title}
//       </div>
//     </Col>
//     <Col xs={6}>
//       { status === 'Coming Soon' &&
//         <div className="label">
//           <Badge color="primary" pill>{status}</Badge>
//         </div>
//       }
//       { status === 'Beta' &&
//         <div className="label">
//           <Badge color="warning" pill>{status}</Badge>
//         </div>
//       }
//       { status === 'New' &&
//         <div className="label">
//           <Badge color="success" pill>{status}</Badge>
//         </div>
//       }
//     </Col>
//   </Row>
// );

const itemScrollMenu = [
  {
    num: '1',
    icon: 'view_quilt',
    title: 'Section Data',
    status: 'Beta'
  },
  {
    num: '2',
    icon: 'list_alt',
    title: 'Results SLS',
    status: 'Beta'
  },
  {
    num: '3',
    icon: 'toll',
    title: 'Results ULS',
    status: 'Beta'
  },
  {
    num: '4',
    icon: 'polymer',
    title: 'Interaction Diagram ULS',
    status: 'Beta'
  }
];

class Outputs extends React.Component {
  constructor(props) {
    super(props);
    this.toggle = this.toggle.bind(this);
    this.state = {
      activeTab: '1'
    };
  }

  toggle(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab
      });
    }
  }

  render() {
    const {
      outputs,
      concreteSection
    } = this.props;
    console.log('OUTPUTS', outputs);
    return (
      <div className="outputs">
        <Card id="card">
          <CardHeader id="headerinputs">
            {'Outputs Concrete Section'}
          </CardHeader>
          <HorizontalScrollMenu
            toggle={this.toggle}
            activeTab={this.state.activeTab}
            itemScrollMenu={itemScrollMenu}
          />
          <TabContent
            id="sectionsDataOptions"
            activeTab={this.state.activeTab}
          >
            <TabPane tabId="1">
              <SectionData
                sectionData={outputs.sectionData}
                concrete={concreteSection.geometry.concrete}
              />
            </TabPane>
            <TabPane tabId="2">
              <ResultsSls
                sls={outputs.sls}
                concrete={concreteSection.geometry.concrete}
                reinforcement={concreteSection.reinforcement}
              />
            </TabPane>
            <TabPane tabId="3">
              <ResultsUls
                uls={outputs.uls}
                concrete={concreteSection.geometry.concrete}
                reinforcement={concreteSection.reinforcement}
              />
            </TabPane>
            <TabPane tabId="4">
              <InteractionDiagramUls
                uls={outputs.uls}
                loads={concreteSection.loads}
              />
            </TabPane>
          </TabContent>
        </Card>

      </div>
    );
  }
}

const mapStateToProps = (state, props) => {
  const { sectionType } = props.match.params;
  return {
    form: `concreteSection.${sectionType}`,
    concreteSection: state.form.concreteSection[sectionType].values,
    sectionType,
    outputs: state.form.concreteSection.outputs
  };
};

export default compose(
  withRouter,
  connect(mapStateToProps)
)(Outputs);
