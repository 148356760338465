const chartData = (sectionext, sectionint, xg, yg) => {
  const sectionintgeo = () => {
    const next = sectionext.length;
    const nint = sectionint.length;
    const sectionintg = [...sectionint];
    if (nint <= 0) {
      sectionintg.push({ x: 0, y: 0 });
    }
    if (nint < next) {
      for (let i = 0; i < next - nint; i += 1) {
        sectionintg.push(sectionint[nint - 1]);
      }
    }

    return sectionintg;
  };
  return {
    labels: ['Scatter'],
    datasets: [{
      label: 'Section Ext',
      type: 'scatter',
      data: sectionext,
      backgroundColor: [
        'rgba(112,112,112, 0.2)'
      ],
      borderColor: [
        'rgba(112,112,112,1)'
      ],
      borderWidth: 3,
      lineTension: 0,
      fill: 1
    },
    {
      label: 'Section Int',
      type: 'scatter',
      data: sectionintgeo(),
      backgroundColor: [
        'rgba(0,0,0,0)'
      ],
      fillColor: [
        'rgba(0,0,0,0)'
      ],
      borderColor: [
        'rgba(112,112,112,1)'
      ],
      borderWidth: 3,
      lineTension: 0,
      fill: 2
    },
    {
      label: 'Center of Gragity',
      type: 'scatter',
      data: [
        { x: xg, y: yg }
      ],
      backgroundColor: [
        'rgba(0, 0, 255, 0.2)'
      ],
      borderColor: [
        'rgba(255, 0, 0,1)'
      ],
      borderWidth: 3,
      lineTension: 0,
      fill: 1
    }]
  };
};

export default chartData;
