/* eslint global-require: 0 */
import 'raf/polyfill';
import 'babel-polyfill';
import 'core-js/es6/map';
import 'core-js/es6/set';
import React from 'react';
import ReactDOM from 'react-dom';
// import './index.css';
import { Provider } from 'react-redux';
import App from './App';
import registerServiceWorker from './registerServiceWorker';
import configureStore from './store/configureStore';
import 'bootstrap/dist/css/bootstrap.css';
import IntlReduxProvider from './intl/IntlReduxProvider';
import './styles/main.scss';

// const { REACT_APP_COMPANY } = process.env;

// if (REACT_APP_COMPANY === 'Eiffage') {
//   require('./styles/main_eiffage.scss');
// } else {
//   require('./styles/main.scss');
// }

const store = configureStore();

ReactDOM.render(
  <Provider store={store}>
    <IntlReduxProvider>
      <App />
    </IntlReduxProvider>
  </Provider>,
  document.getElementById('root'),
);

registerServiceWorker();


store.subscribe(() => { });
