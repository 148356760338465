import React from 'react';
import PropTypes from 'prop-types';
import {
  Row,
  Col,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Input,
  Tooltip
} from 'reactstrap';

class InputCalc extends React.Component {
  constructor(props) {
    super(props);
    this.toggle = this.toggle.bind(this);
    this.state = {
      tooltipOpen: false
    };
  }

  toggle() {
    this.setState({
      tooltipOpen: !this.state.tooltipOpen
    });
  }

  render() {
    const {
      input,
      inputs,
      changeInput,
      field,
      // unitType,
      unitList,
      // changeUnit
    } = this.props;
    return (
      <div className="inputcalc">
        <InputGroup>
          <InputGroupAddon className="inputgroupaddon" addonType="prepend">
            <InputGroupText className="inputgrouptext">{input.text}</InputGroupText>
          </InputGroupAddon>
          <InputGroupAddon className="inputgroupaddon" addonType="prepend">
            <InputGroupText className="inputgroupinfo" id={input.name}>
              <i className="material-icons">info</i>
            </InputGroupText>
            <Tooltip placement="top" isOpen={this.state.tooltipOpen} target={input.name} toggle={this.toggle}>
              {input.description}
            </Tooltip>
          </InputGroupAddon>
          <Input
            className="input"
            type="number"
            name={input.name}
            defaultValue={input.value}
            onChange={(e) => {
              const val = Number(e.target.value);
              changeInput(inputs, field, val, input.name);
            }}
          />
          <InputGroupAddon addonType="append">
            <Input
              className="inputunits"
              type="select"
              name={input.name}
              defaultValue={input.unit}
              disabled
            >
              {
                unitList.map(item => (
                  <option
                    key={item.value}
                    value={item.value}
                  >
                    {item.label}
                  </option>
                ))
              }
            </Input>
          </InputGroupAddon>
        </InputGroup>
      </div>
    );
  }
}

export default InputCalc;
