import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { FieldArray } from 'redux-form';
import {
  Container,
  Col,
  Row,
  Table,
  Button,
  ButtonGroup
} from 'reactstrap';
import { Scatter } from 'react-chartjs-2';
import InputField from '../../../../../../../../components/UIComponents/Fields/InputRF';

import chartOptions from './utils/chartOptions';
import chartData from './utils/chartData';

import './styles/Geometry.css';

import RandomSection from './scenes/RandomSection';
import RectangularSection from './scenes/RectangularSection';
import TSection from './scenes/TSection';
import ISection from './scenes/ISection';
import CircleSection from './scenes/CircleSection';
import HollowCircleSection from './scenes/HollowCircleSection';

const Geometry = ({
  changeGeometry,
  sectionType,
  geometry,
  fields
}) => {
  return (
    <Container className="geometry">
      <Row>
        <Col md={6}>
          <div className="chartContainer">
            <Scatter
              className="chart"
              width={400}
              height={400}
              data={chartData(geometry.concrete.sectionext, geometry.concrete.sectionint)}
              options={chartOptions(geometry.concrete.sectionext, geometry.concrete.unit)}
            />
          </div>
        </Col>
        <Col md={6}>
          <h2 className="subtitle">Section dimensions</h2>
          {sectionType === 'rectangularSection'
            && <RectangularSection
              sectionType={sectionType}
              geometry={geometry}
              changeGeometry={changeGeometry}
            />}
          {sectionType === 'tSection'
            && <TSection
              sectionType={sectionType}
              geometry={geometry}
              changeGeometry={changeGeometry}
            />}
          {sectionType === 'iSection'
            && <ISection
              sectionType={sectionType}
              geometry={geometry}
              changeGeometry={changeGeometry}
            />}
          {sectionType === 'circleSection'
            && <CircleSection
              sectionType={sectionType}
              geometry={geometry}
              changeGeometry={changeGeometry}
            />}
          {sectionType === 'hollowCircleSection'
            && <HollowCircleSection
              sectionType={sectionType}
              geometry={geometry}
              changeGeometry={changeGeometry}
            />}
          {sectionType === 'randomSection'
            && <RandomSection
              unit={geometry.concrete.unit}
              fields={fields}
            />}
        </Col>
      </Row>
    </Container>
  );
};

export default Geometry;
