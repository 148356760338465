const inititalState = {
  inputs: {
    sceneRefresh: false,
    ui: {
      show3DStructure: false,
      showGlobalAxis: false,
      showLocalAxis: false,
      showGrid: false,
      showNode: false,
      showMember: false,
      showNodeLoad: false,
      showMemberLoad: false,
      showPlanXY: false,
      showPlanYZ: false,
      showPlanXZ: false,
      showNodeDisplacement: false,
      showReaction: false,
      showN1: false,
      showV2: false,
      showV3: false,
      showM1: false,
      showM2: false,
      showM3: false
    },
    values: {
      options: {
        projectname: {
          name: 'projectname',
          text: 'Name',
          description: 'Project Name',
          value: 'Project Name',
          unit: '_'
        },
        unit: {
          name: 'unit',
          text: 'Units',
          description: 'Unit system',
          value: { label: 'm-MN-kg', value: 'mMNkg' },
          unit: '_'
        }
      },
      materials: {
        active: 'Steel',
        text: 'Materials',
        description: 'List of materials',
        value: [
          {
            name: 'Steel',
            text: 'Material',
            value: 'Steel',
            label: 'Steel',
            description: 'Name of the material',
            characteristics: {
              density: {
                name: 'density',
                text: 'ρ',
                description: 'Density of the material',
                value: 7850,
                unit: { name: 'kgOnM3', text: 'kg/m3' }
              },
              eYoung: {
                name: 'eYoung',
                text: 'E',
                description: 'Young Modulus of the material',
                value: 210000000,
                unit: { name: 'kPa', text: 'kPa' }
              },
              poisson: {
                name: 'poisson',
                text: 'ν',
                description: 'Poisson coefficient of the material',
                value: 0.3,
                unit: { name: '_', text: '_' }
              },
              thermal: {
                name: 'thermal',
                text: 'Thermal',
                description: 'Thermal coefficient of the material',
                value: 0.0000117,
                unit: { name: '_', text: '_' }
              },
              shearModulus: {
                name: 'shearModulus',
                text: 'G',
                description: 'Shear Modulus of the material',
                value: 80769230,
                unit: { name: 'kPa', text: 'kPa' }
              }
            }
          },
          {
            name: 'Concrete',
            text: 'Material',
            value: 'Concrete',
            label: 'Concrete',
            description: 'Name of the material',
            characteristics: {
              density: {
                name: 'density',
                text: 'ρ',
                description: 'Density of the material',
                value: 2400,
                unit: { name: 'kgOnM3', text: 'kg/m3' }
              },
              eYoung: {
                name: 'eYoung',
                text: 'E',
                description: 'Young Modulus of the material',
                value: 30000000,
                unit: { name: 'kPa', text: 'kPa' }
              },
              poisson: {
                name: 'poisson',
                text: 'ν',
                description: 'Poisson coefficient of the material',
                value: 0.17,
                unit: { name: '_', text: '_' }
              },
              thermal: {
                name: 'thermal',
                text: 'Thermal',
                description: 'Thermal coefficient of the material',
                value: 0.0000099,
                unit: { name: '_', text: '_' }
              },
              shearModulus: {
                name: 'shearModulus',
                text: 'G',
                description: 'Shear Modulus of the material',
                value: 12820513,
                unit: { name: 'kPa', text: 'kPa' }
              }
            }
          }
        ],
        outputs: {
          name: 'MaterialName',
          text: 'Material',
          description: 'Name of the material',
          characteristics: {
            density: {
              name: 'density',
              text: 'ρ',
              description: 'Density of the material',
              value: 7850,
              unit: { name: 'kgOnM3', text: 'kg/m3' }
            },
            eYoung: {
              name: 'eYoung',
              text: 'E',
              description: 'Young Modulus of the material',
              value: 210000000,
              unit: { name: 'kPa', text: 'kPa' }
            },
            poisson: {
              name: 'poisson',
              text: 'ν',
              description: 'Poisson coefficient of the material',
              value: 0.3,
              unit: { name: '_', text: '_' }
            },
            thermal: {
              name: 'thermal',
              text: 'Thermal',
              description: 'Thermal coefficient of the material',
              value: 0.0000117,
              unit: { name: '_', text: '_' }
            },
            shearModulus: {
              name: 'shearModulus',
              text: 'G',
              description: 'Shear Modulus of the material',
              value: 80769230,
              unit: { name: 'kPa', text: 'kPa' }
            }
          }
        }
      },
      sections: {
        active: 'Rectangular',
        text: 'Sections',
        description: 'List of sections',
        value: [
          {
            name: 'Rectangular',
            text: 'Name',
            value: 'Rectangular',
            label: 'Rectangular',
            description: 'Name of the section',
            characteristics: {
              color: 'steelblue',
              material: {
                name: 'material',
                text: 'material',
                label: 'Steel',
                value: 'Steel',
                description: 'Section material'
              },
              xg: {
                name: 'xg',
                text: 'xg',
                value: 1,
                description: 'Section center of gravity x coordinate',
                unit: 'm'
              },
              yg: {
                name: 'yg',
                text: 'yg',
                value: 1,
                description: 'Section center of gravity y coordinate',
                unit: 'm'
              },
              area: {
                name: 'area',
                text: 'Area',
                value: 4,
                description: 'Area of the Section',
                unit: 'm2'
              },
              ixx: {
                name: 'ixx',
                text: 'Ixx',
                value: 1.333,
                description: 'Inertia at the Center of Gravity and related to x axis of the section',
                unit: 'm4'
              },
              iyy: {
                name: 'iyy',
                text: 'Iyy',
                value: 1.333,
                description: 'Inertia at the Center of Gravity and related to y axis of the section',
                unit: 'm4'
              },
              ixy: {
                name: 'ixy',
                text: 'Ixy',
                value: 0,
                description: 'Product moment of area at the Center of Gravity',
                unit: 'm4'
              },
              unit: { name: 'm', text: 'm' },
              sectionext: [
                { x: 0, y: 0 },
                { x: 2, y: 0 },
                { x: 2, y: 2 },
                { x: 0, y: 2 },
                { x: 0, y: 0 }
              ],
              sectionint: [
                { x: 0, y: 0 },
                { x: 0, y: 0 }
              ]
            }
          }
        ],
        listSectionTypes: {
          unit: { name: 'm', text: 'm' },
          sectionext: [
            { x: 0, y: 0 }
          ],
          sectionint: [
            { x: 0, y: 0 }
          ],
          rectangularSection: {
            inputs: {
              unit: 'm',
              b: {
                name: 'b',
                text: 'b',
                value: 2,
                description: 'Section Width',
                unit: 'm'
              },
              h: {
                name: 'h',
                text: 'h',
                value: 2,
                description: 'Section Height',
                unit: 'm'
              }
            }
          },
          tSection: {
            inputs: {
              unit: 'm',
              b: {
                name: 'b',
                text: 'b',
                value: 2,
                description: 'Section Width',
                unit: 'm'
              },
              h: {
                name: 'h',
                text: 'h',
                value: 2,
                description: 'Section Height',
                unit: 'm'
              },
              b0: {
                name: 'b0',
                text: 'b0',
                value: 0.5,
                description: 'Section Web Width',
                unit: 'm'
              },
              h0: {
                name: 'h0',
                text: 'h0',
                value: 0.5,
                description: 'Height of the top flange of the section',
                unit: 'm'
              }
            }
          },
          iSection: {
            inputs: {
              unit: 'm',
              b: {
                name: 'b',
                text: 'b',
                value: 0.5,
                description: 'Section Width',
                unit: 'm'
              },
              h: {
                name: 'h',
                text: 'h',
                value: 2,
                description: 'Section Height',
                unit: 'm'
              },
              b0: {
                name: 'b0',
                text: 'b0',
                value: 2,
                description: 'Width of the top flange of the section',
                unit: 'm'
              },
              h0: {
                name: 'h0',
                text: 'h0',
                value: 0.5,
                description: 'Height of the top flange of the section',
                unit: 'm'
              },
              b1: {
                name: 'b1',
                text: 'b1',
                value: 1,
                description: 'Width of the bottom flange of the section',
                unit: 'm'
              },
              h1: {
                name: 'h1',
                text: 'h1',
                value: 0.5,
                description: 'Height of the bottom flange of the section',
                unit: 'm'
              }
            }
          },
          circleSection: {
            inputs: {
              unit: 'm',
              D: {
                name: 'D',
                text: 'D',
                value: 1,
                description: 'Diameter of the section',
                unit: 'm'
              }
            }
          },
          hollowCircleSection: {
            inputs: {
              unit: 'm',
              D: {
                name: 'D',
                text: 'D',
                value: 1,
                description: 'Diameter of the section',
                unit: 'm'
              },
              t: {
                name: 't',
                text: 't',
                value: 0.1,
                description: 'Thickness of the section',
                unit: 'm'
              }
            }
          },
          randomSection: {
            inputs: {
              unit: 'm'
            }
          }
        },
        outputs: {
          name: 'SectionName',
          text: 'Name',
          value: 'SectionName',
          label: 'SectionName',
          description: 'Name of the section',
          characteristics: {
            color: 'grey',
            material: {
              name: 'material',
              text: 'material',
              label: 'Steel',
              value: 'Steel',
              description: 'Section material'
            },
            xg: {
              name: 'xg',
              text: 'xg',
              value: 0,
              description: 'Section center of gravity x coordinate',
              unit: 'm'
            },
            yg: {
              name: 'yg',
              text: 'yg',
              value: 0,
              description: 'Section center of gravity y coordinate',
              unit: 'm'
            },
            area: {
              name: 'area',
              text: 'Area',
              value: 0,
              description: 'Area of the Section',
              unit: 'm2'
            },
            ixx: {
              name: 'ixx',
              text: 'Ixx',
              value: 0,
              description: 'Inertia at the Center of Gravity and related to x axis of the section',
              unit: 'm4'
            },
            iyy: {
              name: 'iyy',
              text: 'Iyy',
              value: 0,
              description: 'Inertia at the Center of Gravity and related to y axis of the section',
              unit: 'm4'
            },
            ixy: {
              name: 'ixy',
              text: 'Ixy',
              value: 0,
              description: 'Product moment of area at the Center of Gravity',
              unit: 'm4'
            },
            unit: { name: 'm', text: 'm' },
            sectionext: [
              { x: 0, y: 0 }
            ],
            sectionint: [
              { x: 0, y: 0 }
            ]
          }
        }
      },
      supports: {
        active: 'Free',
        text: 'Supports',
        description: 'List of supports',
        value: [
          {
            name: 'Free',
            text: 'Name',
            value: 'Free',
            label: 'Free',
            description: 'Name of the support',
            characteristics: {
              dispX: {
                name: 'dispX',
                text: 'Ux',
                description: 'Translation in x direction',
                value: true,
                unit: { name: 'm', text: 'm' }
              },
              dispY: {
                name: 'dispY',
                text: 'Uy',
                description: 'Translation in y direction',
                value: true,
                unit: { name: 'm', text: 'm' }
              },
              dispZ: {
                name: 'dispZ',
                text: 'Uz',
                description: 'Translation in z direction',
                value: true,
                unit: { name: 'm', text: 'm' }
              },
              rotX: {
                name: 'rotX',
                text: 'Rx',
                description: 'Rotation around x direction',
                value: true,
                unit: { name: 'rad', text: 'rad' }
              },
              rotY: {
                name: 'rotY',
                text: 'Ry',
                description: 'Rotation around y direction',
                value: true,
                unit: { name: 'rad', text: 'rad' }
              },
              rotZ: {
                name: 'rotZ',
                text: 'Rz',
                description: 'Rotation around z direction',
                value: true,
                unit: { name: 'rad', text: 'rad' }
              }
            }
          },
          {
            name: 'Fixed',
            text: 'Name',
            value: 'Fixed',
            label: 'Fixed',
            description: 'Name of the support',
            characteristics: {
              dispX: {
                name: 'dispX',
                text: 'Ux',
                description: 'Translation in x direction',
                value: false,
                unit: { name: 'm', text: 'm' }
              },
              dispY: {
                name: 'dispY',
                text: 'Uy',
                description: 'Translation in y direction',
                value: false,
                unit: { name: 'm', text: 'm' }
              },
              dispZ: {
                name: 'dispZ',
                text: 'Uz',
                description: 'Translation in z direction',
                value: false,
                unit: { name: 'm', text: 'm' }
              },
              rotX: {
                name: 'rotX',
                text: 'Rx',
                description: 'Rotation around x direction',
                value: false,
                unit: { name: 'rad', text: 'rad' }
              },
              rotY: {
                name: 'rotY',
                text: 'Ry',
                description: 'Rotation around y direction',
                value: false,
                unit: { name: 'rad', text: 'rad' }
              },
              rotZ: {
                name: 'rotZ',
                text: 'Rz',
                description: 'Rotation around z direction',
                value: false,
                unit: { name: 'rad', text: 'rad' }
              }
            }
          }
        ],
        outputs: {
          name: 'SupportName',
          text: 'Name',
          description: 'Name of the support',
          characteristics: {
            dispX: {
              name: 'dispX',
              text: 'Ux',
              description: 'Translation in x direction',
              value: true,
              unit: { name: 'm', text: 'm' }
            },
            dispY: {
              name: 'dispY',
              text: 'Uy',
              description: 'Translation in y direction',
              value: true,
              unit: { name: 'm', text: 'm' }
            },
            dispZ: {
              name: 'dispZ',
              text: 'Uz',
              description: 'Translation in z direction',
              value: true,
              unit: { name: 'm', text: 'm' }
            },
            rotX: {
              name: 'rotX',
              text: 'Rx',
              description: 'Rotation around x direction',
              value: true,
              unit: { name: 'rad', text: 'rad' }
            },
            rotY: {
              name: 'rotY',
              text: 'Ry',
              description: 'Rotation around y direction',
              value: true,
              unit: { name: 'rad', text: 'rad' }
            },
            rotZ: {
              name: 'rotZ',
              text: 'Rz',
              description: 'Rotation around z direction',
              value: true,
              unit: { name: 'rad', text: 'rad' }
            }
          }
        }
      },
      nodes: {
        text: 'Nodes',
        description: 'List of nodes',
        unit: { name: 'm', text: 'm' },
        value: [
          {
            name: 'A',
            value: 'A',
            label: 'A',
            support: 'Fixed',
            X: 0,
            Y: 0,
            Z: 0
          },
          {
            name: 'B',
            value: 'B',
            label: 'B',
            support: 'Free',
            X: 0,
            Y: 0,
            Z: 10
          }
        ]
      },
      members: {
        text: 'Members',
        description: 'List of members',
        value: [
          {
            name: 'AB',
            value: 'AB',
            label: 'AB',
            nodei: 'A',
            nodej: 'B',
            section: 'Rectangular'
          }
        ]
      },
      nodeLoads: {
        text: 'Node Loads',
        description: 'List of node loads',
        unit: {
          force: { name: 'kN', text: 'kN' },
          moment: { name: 'kNm', text: 'kN.m' }
        },
        value: [
          {
            name: 'LoadName',
            value: 'LoadName',
            label: 'LoadName',
            node: 'B',
            forceX: 0,
            forceY: 0,
            forceZ: 0,
            momentX: 0,
            momentY: 0,
            momentZ: 0
          }
        ]
      },
      memberLoads: {
        text: 'Member Loads',
        description: 'List of member loads',
        unit: {
          force: { name: 'kN', text: 'kN' },
          moment: { name: 'kNm', text: 'kN.m' }
        },
        value: [
          {
            name: 'Wind',
            value: 'Wind',
            label: 'Wind',
            member: 'AB',
            forceX: 0,
            forceY: 0,
            forceZ: 0,
            momentX: 0,
            momentY: 0,
            momentZ: 0
          }
        ]
      }
    },
    loading: false
  },
  outputs: {
    logs: {
      nodeDefinition: '',
      nodeAssociation: '',
      materialAssociation: '',
      sectionAssociation: '',
      memberAssociation: '',
      nodeLoadAssociation: '',
      memberLoadAssociation: '',
      supportAssociation: '',
      structureStability: '',
      message: ''
    },
    nodeDisplacements: [
      {
        name: 'A',
        UX: 0,
        UY: 0,
        UZ: 0,
        RX: 0,
        RY: 0,
        RZ: 0
      }
    ],
    reactions: [
      {
        name: 'A',
        FX: 0,
        FY: 0,
        FZ: 0,
        MX: 0,
        MY: 0,
        MZ: 0
      }
    ],
    localForces: [
      {
        Name: 'AB',
        Nodei: 'A',
        Nodej: 'B',
        N1i: 0,
        V2i: 0,
        V3i: 0,
        M1i: 0,
        M2i: 0,
        M3i: 0,
        N1j: 0,
        V2j: 0,
        V3j: 0,
        M1j: 0,
        M2j: 0,
        M3j: 0
      }
    ],
    activeMember: {
      name: 'AB',
      nodei: 'A',
      nodej: 'B',
      memberSollicitations: [0]
    },
    links: {
      link: null,
      loading: false,
      error: false
    }
  },
  loading: true
};

export default inititalState;
