import axios from 'axios';
import { toast } from 'react-toastify';
import { URL } from '../../config/config';
import URL_PATH from '../../config/default';

const postSignUp = async (dispatch, user) => {
  try {
    const res = await axios.post(`${URL}${URL_PATH.authentication.signup}`, user);
    toast.success(res.data.msg);
  } catch (err) {
    let msgErr = 'Connection error';
    if (err.response) {
      msgErr = err.response.data.msg;
    }
    toast.error(msgErr);
  }
};

export {
  postSignUp
};
