function useFilter(fields, input) {
  function filter() {
    const isInputPresentInName = (name, input) => name.includes(input);

    const matchedFields = fields.reduce((result, field, index) => {
      const { name } = fields.get(index);
      if (isInputPresentInName(name, input)) {
        result.push(field);
      }
      return result;
    }, []);

    return {
      matchedFields
    };
  }
  const { matchedFields } = filter();
  return {
    matchedFields
  };
}

export default useFilter;
