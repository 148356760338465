import React from 'react';
import {
  Card,
  CardBody,
  Form,
  Label,
  FormGroup,
  CardHeader,
  Input,
  Button,
  Col,
  Row
} from 'reactstrap';

const EntrepriseQuotationDemand = ({
  user,
  gotEmail
}) => {
  return (
    <Card className="card">
      <CardHeader className="cardHeader">Contact Us</CardHeader>
      <CardBody>
        <Form id="contact-form" onSubmit={gotEmail}>
          <Row>
            <Col md={6}>
              <FormGroup>
                <Label className="title">
                  First Name
                </Label>
                <Input defaultValue={user.firstname} type="text" name="firstname" id="firstname" placeholder="first name" required />
              </FormGroup>
            </Col>
            <Col md={6}>
              <FormGroup>
                <Label className="title">
                  Last Name
                </Label>
                <Input defaultValue={user.lastname} type="text" name="lastname" id="lastname" placeholder="lastname" required />
              </FormGroup>
            </Col>
          </Row>
          <FormGroup>
            <Label className="title">Company</Label>
            <Input defaultValue={user.company} type="text" name="company" id="company" placeholder="company" required />
          </FormGroup>
          <FormGroup>
            <Label className="title">Email</Label>
            <Input defaultValue={user.email} type="email" name="email" id="email" placeholder="email" required />
          </FormGroup>
          <FormGroup>
            <Label className="title">Message</Label>
            <Input type="textarea" name="message" id="message" placeholder="Message" required />
          </FormGroup>
          <Button
            type="submit"
            className="positiveButton"
          > 
            Send
          </Button>
        </Form>  
      </CardBody>    
    </Card>
  );
};

export default EntrepriseQuotationDemand;
