import React, { Fragment } from 'react';

function SearchInput({ onSearch }) {
    return (
        <div className="searchInput">
            <input
                name='searchFields'
                type="text"
                onChange={(e) => {
                    onSearch(e.target.value);
                }}
            />
            <i
                className="material-icons search-icon"
            >
                {'search'}
            </i>
        </div>
    )
}

export default SearchInput;