import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  Container,
  Breadcrumb,
  BreadcrumbItem,
  Card,
  CardBody,
  CardTitle,
  Button,
  Row,
  Col
} from 'reactstrap';
import { Link } from 'react-router-dom';
import { LoadableStructuralAnalysis } from '../../../StructuralAnalysis';
import InputCalc from '../../../../components/UIComponents/Fields/InputCalc';
import OutputCalc from '../../../../components/UIComponents/Fields/OutputCalc';
import optionsLengthUnits from '../../../../utils/units/optionsLengthUnits2';
import optionsInertiaUnits from '../../../../utils/units/optionsInertiaUnits';
import optionsStressUnits from '../../../../utils/units/optionsStressUnits2';
import optionsForceUnits from '../../../../utils/units/optionsForceUnits2';
import optionsMomentUnits from '../../../../utils/units/optionsMomentUnits2';
import { Scatter } from 'react-chartjs-2';

import {
  changeInputValue
} from '../../actions';

import Inputs from './scenes/Inputs';

class BeamFormulas extends React.Component {
  constructor(props) {
    super(props);
  }

  // componentDidMount() {
  //   const { fetchStructuralAnalysisData, sectionType, inputs } = this.props;
  //   fetchStructuralAnalysisData(sectionType, inputs);
  // }

  render() {
    const {
      inputs,
      outputs,
      changeInputValue
    } = this.props;
    return (
      <LoadableStructuralAnalysis>
        <Container className="structuralAnalysis">
          <Breadcrumb>
            <BreadcrumbItem><Link to="/dashboard">Dashboard</Link></BreadcrumbItem>
            <BreadcrumbItem><Link to="/structuralanalysis/beamformulas">Beam Formulas</Link></BreadcrumbItem>
          </Breadcrumb>
          <Card className="card">
            <CardBody className="header">
              <Row>
                <Col lg={12} className="align-center">
                  <CardTitle className="title">
                    {`Beam Formulas`}
                  </CardTitle>
                </Col>
              </Row>
            </CardBody>
            <CardBody>
              <Row>
                <Col md={6}>
                  <h2 className="subtitle">Inputs</h2>
                  <Inputs
                    inputs={inputs}
                    changeInputValue={changeInputValue}
                    optionsLengthUnits={optionsLengthUnits}
                    optionsStressUnits={optionsStressUnits}
                    optionsInertiaUnits={optionsInertiaUnits}
                    optionsForceUnits={optionsForceUnits}
                  />
                </Col>
                <Col md={6}>
                  <h2 className="subtitle">Outputs</h2>
                    <OutputCalc
                      input={outputs.MA}
                      unitList={optionsMomentUnits}
                    />
                    <OutputCalc
                      input={outputs.dispB}
                      unitList={optionsLengthUnits}
                    />
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Container>
      </LoadableStructuralAnalysis>
    );
  }
}

const mapStateToProps = (state, props) => {
  // const { sectionType } = props.match.params;
  return {
    // sectionType,
    // sectionValues: state.sectionGeometry[sectionType],
    inputs: state.structuralAnalysis.beamFormulas.inputs,
    outputs: state.structuralAnalysis.beamFormulas.outputs
    // outputs: state.sectionGeometry.outputs
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({
    // fetchSectionData,
    changeInputValue
  }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(BeamFormulas);
