import React from 'react';
import PropTypes from 'prop-types';
import {
  Container,
  Table,
  Row,
  Col,
  Input
} from 'reactstrap';
import { Scatter } from 'react-chartjs-2';

import chartOptions from './utils/chartOptions';
import chartData from './utils/chartData';
import OutputCalc from '../../../../../../../../components/UIComponents/Fields/OutputCalc';
import optionsLengthUnits from '../../../../../../../../utils/units/optionsLengthUnits2';
import optionsAreaUnits from '../../../../../../../../utils/units/optionsAreaUnits2';
import optionsInertiaUnits from '../../../../../../../../utils/units/optionsInertiaUnits';

const SectionData = ({
  sectionData,
  concrete
}) => {
  return (
    <Container className="geometry">
      <br />
      <Row>
        <Col md={6}>
          <div className="chartContainer">
            <Scatter
              className="chart"
              width={500}
              height={500}
              data={chartData(concrete.sectionext, concrete.sectionint, sectionData)}
              options={chartOptions(concrete.sectionext, concrete.unit)}
            />
          </div>
        </Col>
        <Col md={6}>
          <h2 className="subtitle">Section Data</h2>
          <OutputCalc
            input={sectionData.xg}
            unitList={optionsLengthUnits}
          />
          <OutputCalc
            input={sectionData.yg}
            unitList={optionsLengthUnits}
          />
          <OutputCalc
            input={sectionData.area}
            unitList={optionsAreaUnits}
          />
          <OutputCalc
            input={sectionData.ixx}
            unitList={optionsInertiaUnits}
          />
          <OutputCalc
            input={sectionData.iyy}
            unitList={optionsInertiaUnits}
          />
          <OutputCalc
            input={sectionData.ixy}
            unitList={optionsInertiaUnits}
          />
        </Col>
      </Row>
    </Container>
  );
};


export default SectionData;
