import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  Container,
  Row,
  Col,
  Button
} from 'reactstrap';
import SelectRF from '../../../../../../../components/UIComponents/Fields/SelectRF';
import { compose } from 'recompose';
import { reduxForm } from 'redux-form';
import optionsUnitsSystem from '../../../../../../../utils/units/optionsUnitsSystem';
import InputField from '../../../../../../../components/UIComponents/Fields/InputRF';
import {
  changeGlobalUnits,
  getSectionState
} from '../../../actions';


const Options = ({
  concreteSection,
  changeGlobalUnits,
  getSectionState,
  sectionType
}) => (
    <Container className="horizontalScrollMenu">
      <Row>
        <Col md={6}>
          {/* <Row>
          <Col xs={6}>
            <InputField 
              name="options.sectionname.value" />
          </Col>
          <Col xs={6} className="align-center">
            <SelectRF
              name="options.unit.value.name"
              list={optionsUnitsSystem.optionsUnits}
              onChange={(e) => {
                const val = e.target.value;
                changeGlobalUnits(val, concreteSection, sectionType);
              }}
            />
          </Col>
          </Row> */}
        </Col>
        <Col md={6} className="align-right">
          <Row>
            <Col xs={6} className="align-right">
              {/* <Button
                className="negativeButton"
                outline
                color="primary"
                onClick={() => {
                  getSectionState('rectangular');
                }}
              >
                {'Save'}
              </Button> */}
            </Col>
            <Col xs={6} className="align-right">
              <Button
                className="positiveButton"
                outline
                color="primary"
                type="submit"
              >
                {'Analysis'}
              </Button>
            </Col>
          </Row>
        </Col>
      </Row>
    </Container>
  )

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({
    changeGlobalUnits,
    getSectionState
  }, dispatch);
};

export default compose(
  connect(null, mapDispatchToProps)
)(Options);
