import React from 'react';
import { compose } from 'recompose';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import SelectRF from '../../../../../../../../components/UIComponents/Fields/SelectRF';
import InputsUnit from '../../../../../../../../components/UIComponents/Fields/InputsUnit2';
import InputField from '../../../../../../../../components/UIComponents/Fields/InputRF';
import {
  addFields,
  editFields,
  setActive,
  removeFields
} from '../../../../actions';
import optionsDensityUnits from '../../../../../../../../utils/units/optionsDensityUnits2';
import optionsStressUnits from '../../../../../../../../utils/units/optionsStressUnits2';

import Select from './scenes/Select';
import SectionTypes from './scenes/SectionTypes';
import Geometry from './scenes/Geometry';
import SectionData from './scenes/SectionData';

const incrementSection = (sectionList, index) => `${sectionList[index].name.slice(0, -1) + (parseInt(sectionList[index].name.slice(-1)) + 1)}`;

class Sections extends React.Component {
  constructor(props) {
    super(props);
    this.toggle = this.toggle.bind(this);
    this.changeSectionType = this.changeSectionType.bind(this);
    this.showErrorMessage = this.showErrorMessage.bind(this);
    this.state = {
      activeView: 'Select',
      sectionType: 'rectangularSection',
      errorMessage: ''
    };
  }

  toggle(view) {
    if (this.state.activeView !== view) {
      this.setState({
        activeView: view
      });
    }
  }

  changeSectionType(sectionType) {
    console.log(sectionType);
    if (this.state.sectionType !== sectionType) {
      this.setState({
        sectionType
      });
    }
  }

  showErrorMessage(value) {
    this.setState({
      errorMessage: value
    });
  }

  render() {
    const { 
      name,
      active,
      structure3D,
      sectionList,
      removeFields,
      startRefreshScene
    } = this.props;
    const {
      activeView,
      sectionType,
      errorMessage
    } = this.state;
    return (
      <div>
        {activeView === 'Select' &&
          <Select
            name={name}
            active={active}
            sections={structure3D.sections}
            showErrorMessage={this.showErrorMessage}
            changeView={this.toggle}
            changeSectionType={this.changeSectionType}
            removeFields={removeFields}
          />
        }
        {activeView === 'SectionTypes' &&
          <SectionTypes
            changeView={this.toggle}
            changeSectionType={this.changeSectionType}
          />
        }
        {activeView === 'Geometry' &&
          <Geometry
            structure3D={structure3D}
            changeView={this.toggle}
            changeSectionType={this.changeSectionType}
            sectionType={sectionType}
          />
        }
        {activeView === 'SectionData' &&
          <SectionData
            sections={structure3D.sections}
            changeView={this.toggle}
            changeSectionType={this.changeSectionType}
            errorMessage={errorMessage}
            showErrorMessage={this.showErrorMessage}
            sectionType={sectionType}
            structure3D={structure3D}
            startRefreshScene={startRefreshScene}
          />
        }
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({
    addFields,
    editFields,
    setActive,
    removeFields
  }, dispatch);
};

export default compose(
  connect(null, mapDispatchToProps),
)(Sections);
