import React from 'react';
import PropTypes from 'prop-types';
import { InputGroup, InputGroupAddon, InputGroupText, Input } from 'reactstrap';
import { renderComponent } from 'recompose';

const InputField = ({
  type,
  name,
  value,
  onChange,
  defaultValue 
}) => {
  return (
    <Input
      type={type}
      name={name}
      value={value}
      onChange={onChange}
      defaultValue={defaultValue}
    />
  );
};

// class InputField extends React.PureComponent {
//   render() {
//     const {
//       type,
//       name,
//       value,
//       onChange
//     } = this.props;
//     console.log(`Input ${name} render`);

//     return (
//       <InputGroup>
//         <input
//           type={type}
//           name={name}
//           value={value}
//           onChange={onChange}
//           // defaultValue={defaultValue}
//         />
//       </InputGroup>
//     );
//   }
// }

export default InputField;
