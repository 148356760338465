import * as THREE from 'three';

const OrbitControls = require('three-orbit-controls')(THREE);

export default (
  camera,
  sceneClassName,
  renderScene
) => {
  const controls = new OrbitControls(camera, document.querySelector(sceneClassName));
  controls.addEventListener('change', renderScene);
  controls.target.set(0, 0, 0);

  return controls;
};
