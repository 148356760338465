const validate = (values) => {
  const errors = {
    geometry: {
      concrete: {
        sectionext: {}
      }
    }
  };

  if (!values.geometry.concrete || !values.geometry.concrete.sectionext || !values.geometry.concrete.sectionext.length) {
    errors.sectionext = { _error: 'At least one member must be entered' };
  } else {
    const membersArrayErrors = [];
    values.geometry.concrete.sectionext.forEach((member, memberIndex) => {
      const memberErrors = {};

      if (!member || member.x === undefined) {
        memberErrors.x = 'Obligatoire';
        membersArrayErrors[memberIndex] = memberErrors;
      }

      if (!member || member.y === undefined) {
        memberErrors.y = 'Obligatoire';
        membersArrayErrors[memberIndex] = memberErrors;
      }

      if (membersArrayErrors.length) {
        errors.geometry.concrete.sectionext = membersArrayErrors;
      }
    });
  }

  return errors;
};

export default validate;
