import * as THREE from 'three';

export default (
  scene
) => {
  const light1 = new THREE.PointLight(0xffffff, 0.8);
  light1.position.set(1000, 1000, 1000);
  scene.add(light1);

  const light2 = new THREE.PointLight(0xffffff, 0.8);
  light2.position.set(-1000, -1000, -1000);
  scene.add(light2);

  const light3 = new THREE.PointLight(0xffffff, 0.8);
  light3.position.set(-1000, -1000, 1000);
  scene.add(light3);

  const light4 = new THREE.PointLight(0xffffff, 0.8);
  light4.position.set(1000, 1000, -1000);
  scene.add(light4);


  const light5 = new THREE.DirectionalLight(0xffffff, 0.2);
  light5.position.set(-1, 0, -0);
  scene.add(light5);

  const light6 = new THREE.DirectionalLight(0xffffff, 0.2);
  light6.position.set(1, 0, 0);
  scene.add(light6);

  // this.light = new THREE.DirectionalLight(0xffffff, 0.2);
  // this.light.position.set(0, 1, 0);
  // this.scene.add(this.light);

  // this.light = new THREE.DirectionalLight(0xffffff, 0.2);
  // this.light.position.set(0, -1, 0);
  // this.scene.add(this.light);

  // this.light = new THREE.AmbientLight(0xffffff, 0.2);
  // this.light.position.set(10, -10, 10);
  // this.scene.add(this.light);
};
