import React from 'react';
import PropTypes from 'prop-types';
import { InputGroup, InputGroupAddon, InputGroupText, Input, Label } from 'reactstrap';
import { renderComponent } from 'recompose';
import { Field } from 'redux-form';

const renderSelectField = ({ input, meta: { touched, error }, children, ...custom }) => (
  <React.Fragment>
    <Input type="input" {...(touched ? { valid: !error } : {})} {...input} {...custom}>
      {children}
    </Input>
    {touched && error &&
      <span className="error">{error}</span>
    }
  </React.Fragment>
);

const InputRF = ({
  type,
  name,
  value,
  onChange,
  defaultValue,
  validate,
  parse,
  label,
  placeholder
}) => {
  return (
    <React.Fragment>
      {label && <Label>{label}</Label>}
      <Field
        className="fieldInput"
        type={type}
        name={name}
        value={value}
        onChange={onChange}
        component={renderSelectField}
        validate={validate}
        parse={parse}
        placeholder={placeholder}
      // defaultValue={defaultValue}
      />
    </React.Fragment>

  );
};

export default InputRF;
