import React from 'react';
import PropTypes from 'prop-types';
import {
  Container,
  Row,
  Col,
  FormGroup,
  Button
} from 'reactstrap';
import { reduxForm, Form } from 'redux-form';
import { Link } from 'react-router-dom';
import InputField from '../../../../components/UIComponents/Fields/InputRF';
import { required, passwordConfirmation } from '../../../../utils/validations';
import { FormattedMessage } from 'react-intl';

const { REACT_APP_LOGO } = process.env;


const FormSignUp = ({
  values,
  errors,
  touched,
  isSubmitting,
  handleSubmit,
  onSubmit
   }) => (
    <Container className="authentication-container" fluid>
      <Row>
        <Col md="6" className="col-md-6 box">
          <h2>
            <FormattedMessage id="signup.title" defaultMessage="Welcome to " />
            <img src={REACT_APP_LOGO} alt="TriAzurIcon" width="auto" height="60" />
          </h2>
          <Form onSubmit={handleSubmit(onSubmit)}>
            <Row>
              <Col md="6">
                <FormattedMessage id="signup.firstname" defaultMessage="Firstname ">
                  {
                    firstname => (
                      <InputField label={firstname} name="firstname" placeholder={firstname} validate={[required]} />
                    )
                  }
                </FormattedMessage>
              </Col>
              <Col md="6">
                <FormattedMessage id="signup.lastname" defaultMessage="Lastname ">
                  {
                    lastname => (
                      <InputField label={lastname} name="lastname" placeholder={lastname} validate={[required]} />
                    )
                  }
                </FormattedMessage>
              </Col>
            </Row>
            <Row>
              <Col md="6">
                <FormattedMessage id="signup.pwd" defaultMessage="Password ">
                  {
                    pwd => (
                      <InputField label={pwd} placeholder={pwd} type="password" name="password" validate={[required, passwordConfirmation]} />
                    )
                  }
                </FormattedMessage>
              </Col>
              <Col md="6">
                <InputField type="password" name="passwordConfirmation" label="Password Confirmation" placeholder="Confirmation" validate={[required]} />
              </Col>
            </Row>
            <Row>
              <Col md="12">
                <InputField type="email" name="email" label="Email" placeholder="Email" validate={[required]} />
              </Col>
              <Col md="12">
                <FormattedMessage id="signup.company" defaultMessage="Company ">
                  {
                    company => (
                      <InputField label={company} placeholder={company} name="company" validate={[required]} />
                    )
                  }
                </FormattedMessage>
              </Col>
            </Row>
            <Row className="submit-actions">
              <Col sm="1" />
              <Col sm="4">
                <Button disabled={isSubmitting} type="button" className="btn-return">
                  <a href="https://www.triazur.com">
                    <FormattedMessage id="signup.back" defaultMessage="Back" />
                  </a>
                </Button>
              </Col>
              <Col sm="2" />
              <Col sm="4">
                <FormGroup>
                  <Button disabled={isSubmitting} className="btn-submit" type="submit">
                    <FormattedMessage id="signup.signup" defaultMessage="Sign Up" />
                  </Button>
                </FormGroup>
              </Col>
              <Col sm="1" />
            </Row>
            <FormGroup>
              <FormattedMessage id="signup.already.accountp" defaultMessage="I already have an account?" />
              <Link to="/signin" className="ForgetPwd">
                <FormattedMessage id="signup.gotosignin" defaultMessage=" sign in" />
              </Link>
            </FormGroup>
          </Form>
        </Col>
      </Row>
    </Container>
  );

FormSignUp.propTypes = {
  onSubmit: PropTypes.func.isRequired
};

export default reduxForm({
  form: 'signUp',
  enableReinitialize: true
})(FormSignUp);
