import update from 'immutability-helper';

import initialState from './initialState';
import * as types from './actionTypes';

const SteelSection = (state = initialState, action = {}) => {
  switch (action.type) {
    case types.SS_FETCH_SECTION_DATA:
      return update(state, {
        [action.payload.sectionType]: {
          values: {
            geometry: {
              steel: {
                sectionext: {
                  $set: action.payload.geometry.steel.sectionext
                },
                sectionint: {
                  $set: action.payload.geometry.steel.sectionint
                }
              }
            }
            // $merge: {
            //   steel: action.payload.steel
            // }
          }
        }
      });
    case types.SS_CHANGE_GLOBAL_UNITS:
      return update(state, {
        [action.payload.sectionType]: {
          values: {
            options: {
              unit: {
                $merge: {
                  value: action.payload.newValue.options.unit.value
                }
              }
            },
            steel: {
              fyk: {
                $merge: {
                  value: action.payload.newValue.steel.fyk.value,
                  unit: action.payload.newValue.steel.fyk.unit
                }
              },
              Es: {
                $merge: {
                  value: action.payload.newValue.steel.Es.value,
                  unit: action.payload.newValue.steel.Es.unit
                }
              }
            },
            geometry: {
              steel:
              {
                $set: action.payload.newValue.geometry.steel
              }
            },
            loads: {
              Nsls: {
                $merge: {
                  value: action.payload.newValue.loads.N.value,
                  unit: action.payload.newValue.loads.N.unit
                }
              },
              Mxsls: {
                $merge: {
                  value: action.payload.newValue.loads.Mx.value,
                  unit: action.payload.newValue.loads.Mx.unit
                }
              },
              Mysls: {
                $merge: {
                  value: action.payload.newValue.loads.My.value,
                  unit: action.payload.newValue.loads.My.unit
                }
              },
              Nuls: {
                $merge: {
                  value: action.payload.newValue.loads.N.value,
                  unit: action.payload.newValue.loads.N.unit
                }
              },
              Mxuls: {
                $merge: {
                  value: action.payload.newValue.loads.Mx.value,
                  unit: action.payload.newValue.loads.Mx.unit
                }
              },
              Myuls: {
                $merge: {
                  value: action.payload.newValue.loads.My.value,
                  unit: action.payload.newValue.loads.My.unit
                }
              }
            }
          }
        }
      });
    case types.SS_CHANGE_GEOMETRY:
      return update(state, {
        [action.payload.sectionType]: {
          values: {
            geometry: {
              steel: {
                [action.payload.name]: {
                  value: {
                    $set: action.payload.value
                  }
                },
                sectionext: {
                  $set: action.payload.sectionext
                },
                sectionint: {
                  $set: action.payload.sectionint
                }
              }
            }
          }
        }
      });
    case types.SS_LOAD_OUTPUTS:
      return update(state, {
        outputs: {
          sectionData: {
            xg: {
              value: {
                $set: action.payload.xg
              }
            },
            yg: {
              value: {
                $set: action.payload.yg
              }
            },
            A: {
              value: {
                $set: action.payload.A
              }
            },
            Av: {
              value: {
                $set: action.payload.Av
              }
            },
            Ix: {
              value: {
                $set: action.payload.Ix
              }
            },
            Iy: {
              value: {
                $set: action.payload.Iy
              }
            },
            It: {
              value: {
                $set: action.payload.It
              }
            },
            Iw: {
              value: {
                $set: action.payload.Iw
              }
            },
            Welx: {
              value: {
                $set: action.payload.Welx
              }
            },
            Wely: {
              value: {
                $set: action.payload.Wely
              }
            },
            Wplx: {
              value: {
                $set: action.payload.Wplx
              }
            },
            Wply: {
              value: {
                $set: action.payload.Wply
              }
            }
          },
          bending: {
            CL: {
              value: {
                $set: action.payload.CL
              }
            },
            NplRd: {
              value: {
                $set: action.payload.NplRd
              }
            },
            MelxRd: {
              value: {
                $set: action.payload.MelxRd
              }
            },
            MelyRd: {
              value: {
                $set: action.payload.MelyRd
              }
            },
            MplxRd: {
              value: {
                $set: action.payload.MplxRd
              }
            },
            MplyRd: {
              value: {
                $set: action.payload.MplyRd
              }
            },
            khix: {
              value: {
                $set: action.payload.khix
              }
            },
            khiy: {
              value: {
                $set: action.payload.khiy
              }
            },
            khiLT: {
              value: {
                $set: action.payload.khiLT
              }
            },
            kxx: {
              value: {
                $set: action.payload.kxx
              }
            },
            kxy: {
              value: {
                $set: action.payload.kxy
              }
            },
            kyx: {
              value: {
                $set: action.payload.kyx
              }
            },
            kyy: {
              value: {
                $set: action.payload.kyy
              }
            },
            Condition1: {
              value: {
                $set: action.payload.Condition1
              }
            },
            Condition2: {
              value: {
                $set: action.payload.Condition2
              }
            },
            Condition3: {
              value: {
                $set: action.payload.Condition3
              }
            },
            SF: {
              value: {
                $set: action.payload.SF
              }
            },
            Condition1p: {
              value: {
                $set: action.payload.Condition1p
              }
            },
            Condition2p: {
              value: {
                $set: action.payload.Condition2p
              }
            },
            Condition3p: {
              value: {
                $set: action.payload.Condition3p
              }
            },
            SFp: {
              value: {
                $set: action.payload.SFp
              }
            }
          },
          shear: {
            VplRd: {
              value: {
                $set: action.payload.VplRd
              }
            },
            VedVplRd: {
              value: {
                $set: action.payload.VedVplRd
              }
            },
            UnMoinsRho: {
              value: {
                $set: action.payload.UnMoinsRho
              }
            }
          },
          torsion: {
            Tt: {
              value: {
                $set: action.payload.Tt
              }
            },
            Am: {
              value: {
                $set: action.payload.Am
              }
            },
            thotweb: {
              value: {
                $set: action.payload.thotweb
              }
            },
            Tw: {
              value: {
                $set: action.payload.Tw
              }
            },
            Sw: {
              value: {
                $set: action.payload.Sw
              }
            },
            w: {
              value: {
                $set: action.payload.w
              }
            },
            sigmaw: {
              value: {
                $set: action.payload.sigmaw
              }
            },
            thowweb: {
              value: {
                $set: action.payload.thowweb
              }
            },
            Aw: {
              value: {
                $set: action.payload.Aw
              }
            },
            thoshearweb: {
              value: {
                $set: action.payload.thoshearweb
              }
            },
            thoEdweb: {
              value: {
                $set: action.payload.thoEdweb
              }
            },
            SFt: {
              value: {
                $set: action.payload.SFt
              }
            }
          }
        }
      });
    default:
      return state;
  }
};

export default SteelSection;
