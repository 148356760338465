const chartData = (sectionext, sectionint, uls) => {
  const sectionintgeo = () => {
    const next = sectionext.length;
    const nint = sectionint.length;
    const sectionintg = [...sectionint];
    if (nint <= 0) {
      sectionintg.push({ x: 0, y: 0 });
    }
    if (nint < next) {
      for (let i = 0; i < next - nint; i += 1) {
        sectionintg.push(sectionint[nint - 1]);
      }
    }

    return sectionintg;
  };

  return {
    labels: ['Scatter'],
    datasets: [{
      label: 'Section Ext',
      type: 'scatter',
      data: sectionext,
      backgroundColor: [
        'rgba(112,112,112, 0.2)'
      ],
      borderColor: [
        'rgba(112,112,112,1)'
      ],
      borderWidth: 3,
      lineTension: 0,
      fill: 1,
      pointRadius: 0
    },
    {
      label: 'Section Int',
      type: 'scatter',
      data: sectionintgeo(),
      backgroundColor: [
        'rgba(0,0,0,0)'
      ],
      fillColor: [
        'rgba(0,0,0,0)'
      ],
      borderColor: [
        'rgba(112,112,112,1)'
      ],
      borderWidth: 3,
      lineTension: 0,
      fill: 2,
      pointRadius: 0
    },
    {
      label: 'Reinforcement',
      type: 'scatter',
      data: uls.resExtremStrain.steel.epsir.geometry,
      backgroundColor: [
        '#ff0000'
      ],
      fillColor: [
        'rgba(0,0,0,0)'
      ],
      borderColor: [
        'rgba(112,112,112,1)'
      ],
      showLine: false,
      lineTension: 0,
      pointRadius: 3
    },
    {
      label: 'Max Strain in Reinforcement',
      type: 'scatter',
      data: [uls.resExtremStrain.steel.epsismax.coordinate],
      borderColor: [
        'rgba(0,0,255,1)'
      ],
      showLine: false,
      lineTension: 0,
      pointRadius: 6
    },
    {
      label: 'Max Strain in Concrete',
      type: 'scatter',
      data: [uls.resExtremStrain.concrete.epsicmax.coordinate],
      borderColor: [
        'rgba(0,255,0,1)'
      ],
      showLine: false,
      lineTension: 0,
      pointRadius: 6
    },
    {
      label: 'Neutral Axis',
      type: 'line',
      data: [
        {
          x: uls.resNeutralAxis.naA.x,
          y: uls.resNeutralAxis.naA.y
        }, {
          x: uls.resNeutralAxis.naB.x,
          y: uls.resNeutralAxis.naB.y
        }
      ],
      // backgroundColor: [
      //   'rgba(255,0,0,0.2)'
      // ],
      // fillColor: [
      //   'rgba(0,0,0,1)'
      // ],
      borderColor: [
        'red'
      ],
      fill: false,
      showLine: true,
      lineTension: 0,
      pointRadius: 2
    }]
  };
};

export default chartData;
