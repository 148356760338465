import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
  Card,
  CardText,
  CardBody,
  CardLink,
  Col,
  Row,
  Media,
  Badge
} from 'reactstrap';
import history from '../../../../history';
import YoutubeImg from './img/youtube (1).svg';
import PlayVideo from './img/play-button (1).svg';

const CardApp = ({
  colnum,
  imgsrc,
  title,
  subtitle,
  description,
  link,
  tags,
  href,
  status,
  logoCompany,
  company,
  video,
  openModal
}) => (
  <Col lg={colnum}>
    <Card className="cardAddComponent" >
      <div className={company}>
        <div className="imgContainer">
          <Media
            className="titleComponent"
            onClick={() => {
              if (link === '') {
                window.open(href, '_blank');
              } else {
                history.push(link);
              }
            }}
          >
            <Media left>
              <Media className="logoApp" object src={imgsrc} alt={title} title={title} />
            </Media>
            <Media body>
              <Media heading className="title">
                {title}
              </Media>
              <img className="logoCompany" src={logoCompany} alt={company} title={company} />
              <Row>
                <Col xs={6}>
                  <div className="subtitle">
                    {subtitle}
                  </div>
                </Col>
                <Col xs={6}>
                  { status === 'Coming Soon' &&
                    <div className="label">
                      <Badge color="primary" pill>{status}</Badge>
                    </div>
                  }
                  { status === 'Beta' &&
                    <div className="label">
                      <Badge color="warning" pill>{status}</Badge>
                    </div>
                  }
                  { status === 'New' &&
                    <div className="label">
                      <Badge color="success" pill>{status}</Badge>
                    </div>
                  }
                </Col>
              </Row>
              <Row>
                <Col xs={12}>
                  {(tags) && tags.map((tag, index) => (
                    <Badge color="light" pill key={index}>
                      <a href="#">{tag}</a>
                    </Badge>
                  ))}
                </Col>
              </Row>
            </Media>
          </Media>
        </div>
      </div>
      <CardBody
        className="body"
        onClick={() => {
          if (link === '') {
            window.open(href, '_blank');
          } else {
            history.push(link);
          }
        }}
      >
        <CardText className="description">
          {description}
        </CardText>
      </CardBody>
      <Row>
        <Col xs={2} className="video-link">
          { video === ''
            ? ''
            : <a href={video} target="_blank" rel="noopener noreferrer"><i className="material-icons">play_circle_outline</i></a>
            // : <i
            //   className="material-icons"
            //   onClick={() => {
            //     openModal();
            //   }}
            // >play_circle_outline</i>
          }
        </Col>
        <Col xs={10} className="company">
          {`powered by ${company}`}
        </Col>
      </Row>
    </Card>
  </Col>
);

CardApp.propTypes = {
  colnum: PropTypes.number.isRequired,
  imgsrc: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  link: PropTypes.string.isRequired
};

export default CardApp;
