import React from 'react';
import { Link } from 'react-router-dom';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { injectIntl, FormattedMessage } from 'react-intl';
import {
  Collapse,
  Button,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavItem,
  Input
} from 'reactstrap';
import Select from '../../Fields/Select';
import { changeLanguage } from '../../../../intl/actions';
// import TriAzurIcon from './img/TriAzurIcon.png';
import SignUpSvg from './img/SignUp.svg';
import SignInSvg from './img/SignIn.svg';
import frIcon from './img/fr.svg';
import gbIcon from './img/gb.svg';

const languages = [
  { value: 'fr', label: 'Français' },
  { value: 'en', label: 'Anglais' }
];

// const NavBarConnection = ({ intl, changeLanguage }) => (
//   <div className="navbarconnection">
//     <nav className="navbar fixed-top navbar-expand-lg navbar-dark bg-dark">
//       <a className="navbar-brand" id="navbarTitle1" href="/" target="_self" style={{ backgroundImage: `url(${TriAzurIcon})` }}> TriAzur</a>
//       <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarControl" aria-controls="navbarText" aria-expanded="false" aria-label="Toggle navigation">
//         <span className="navbar-toggler-icon" />
//       </button>

//       <div className="collapse navbar-collapse justify-content-between" id="navbarControl">
//         <ul className="navbar-nav ml-auto">
//           <li className="nav-item">
//             <Input
//               type="select"
//               placeholder=""
//               name="language"
//               onChange={changeLanguage}
//               selectedOption={intl.locale}
//             >
//               {
//                 languages.map(item => (
//                   <option
//                     key={item.label}
//                     value={item.value}
//                     data={item.value}
//                   >
//                     {item.label}
//                   </option>
//                 ))
//               }
//             </Input>
//           </li>
//           <li className="nav-item">
//             <Link className="nav-link" to="/signup" id="navbarTitle3">
//               <i className="material-icons d-inline-block align-top">exit_to_app</i> Sign Up
//             </Link>
//           </li>
//           <li className="nav-item">
//             <Link className="nav-link" to="/signin" id="navbarTitle3">
//               <i className="material-icons d-inline-block align-top">perm_identity</i> Sign In
//             </Link>
//           </li>
//         </ul>
//       </div>
//     </nav>
//   </div >
// );

// const mapDispatchToProps = (dispatch) => {
//   return bindActionCreators({ changeLanguage }, dispatch);
// };

// export default compose(
//   injectIntl,
//   connect(null, mapDispatchToProps)
// )(NavBarConnection);

class NavBarConnection extends React.Component {
  constructor(props) {
    super(props);

    this.toggle = this.toggle.bind(this);
    this.state = {
      isOpen: false,
      dropDownLanguageValue: 'En'
    };
  }

  toggle() {
    this.setState({
      isOpen: !this.state.isOpen
    });
  }

  render() {
    const { intl } = this.props;
    const { 
      REACT_APP_LOGO,
      REACT_APP_COMPANY
    } = process.env;
    console.log('REACT_APP_LOGO', REACT_APP_LOGO)
    return (
      <div>
        <Navbar className="navbarapp-connection" dark expand="md" fixed="top">
          <NavbarBrand
            href="https://www.triazur.com"
            className="title1"
            style={{ backgroundImage: `url(${REACT_APP_LOGO})` }}
          >
            {`${REACT_APP_COMPANY}`}
          </NavbarBrand>
          <NavbarToggler aria-label="toggler" onClick={this.toggle} style={{ color: 'white' }}>
            <span className="navbar-toggler-icon" />
          </NavbarToggler>
          <Collapse isOpen={this.state.isOpen} navbar>
            <Nav className="ml-auto" navbar>
              <NavItem className="nav-item">
                <Link className="nav-link" to="/signin" id="link">
                  <img alt="Sign In" src={SignInSvg} className="material-icons d-inline-block align-top" />
                  <FormattedMessage id="signIn" defaultMessage="Sign In" />
                </Link>
              </NavItem>
              <NavItem className="nav-item">
                <Button
                  tag={Link}
                  to="/signup"
                  className="btn-signup"
                >
                  <img alt="Sign Up" src={SignUpSvg} className="material-icons d-inline-block align-top" />
                  <FormattedMessage id="signUp" defaultMessage="Sign Up" />
                </Button>
              </NavItem>
            </Nav>
          </Collapse>
        </Navbar>
      </div>
    );
  }
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ changeLanguage }, dispatch);
};

export default compose(
  injectIntl,
  connect(null, mapDispatchToProps)
)(NavBarConnection);