import React from 'react';
import PropTypes from 'prop-types';
import {
  Container,
  Table,
  Row,
  Col,
  Input
} from 'reactstrap';
import { Scatter } from 'react-chartjs-2';

/*import chartOptions from './utils/chartOptions';
import chartData from './utils/chartData';*/
import OutputCalc from '../../../../../../../../components/UIComponents/Fields/OutputCalc';
import optionsAutreUnits from '../../../../../../../../utils/units/optionsAutreUnits';
import optionsInertiaUnits from '../../../../../../../../utils/units/optionsInertiaUnits';
import optionsMomentUnits from '../../../../../../../../utils/units/optionsMomentUnits2';
import optionsStressUnits from '../../../../../../../../utils/units/optionsStressUnits2';
import optionsAreaUnits from '../../../../../../../../utils/units/optionsAreaUnits2';

const Torsion = ({
  torsion,
  steel
}) => {
  return (
    <Container className="geometry">
      <br />
      <Row>
        <Col md={6}>
          {/* <div className="chartContainer">
            <Scatter
              className="chart"
              width={500}
              height={500}
              data={chartData(steel.sectionext, steel.sectionint, sectionData)}
              options={chartOptions(steel.sectionext, steel.unit)}
            />
          </div> */}
        </Col>
        <Col md={6}>
          <h2 className="subtitle">Torsion</h2>
          <OutputCalc
            input={torsion.Tt}
            unitList={optionsMomentUnits}
          />
          <OutputCalc
            input={torsion.Am}
            unitList={optionsAreaUnits}
          />
          <OutputCalc
            input={torsion.thotweb}
            unitList={optionsStressUnits}
          />
          <OutputCalc
            input={torsion.Tw}
            unitList={optionsMomentUnits}
          />
          <OutputCalc
            input={torsion.Sw}
            unitList={optionsInertiaUnits}
          />
          <OutputCalc
            input={torsion.w}
            unitList={optionsAreaUnits}
          />
          <OutputCalc
            input={torsion.sigmaw}
            unitList={optionsStressUnits}
          />
          <OutputCalc
            input={torsion.thowweb}
            unitList={optionsStressUnits}
          />
          <OutputCalc
            input={torsion.Aw}
            unitList={optionsAreaUnits}
          />
          <OutputCalc
            input={torsion.thoshearweb}
            unitList={optionsStressUnits}
          />
          <OutputCalc
            input={torsion.thoEdweb}
            unitList={optionsStressUnits}
          />
          <OutputCalc
            input={torsion.SFt}
            unitList={optionsAutreUnits}
          />
        </Col>
      </Row>
    </Container>
  );
};


export default Torsion;