import React from 'react';
import Title from '../../../../components/UIComponents/Title';
import ConcreteSectionSvg from '../../img/ConcreteSection.svg';
import { LoadableConcreteSection } from '../../../ConcreteSection';

const Documentation = () => (
  <LoadableConcreteSection>
    <Title
      title="Concrete Section Documentation"
      imgsrc={ConcreteSectionSvg}
      description="Technical documentation about Concrete Section application"
    />
  </LoadableConcreteSection>
);

export default Documentation;
