import React from 'react';
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  FormGroup,
  Label,
  Input,
  Button
} from 'reactstrap';

const Pricing = ({
  plansList,
  openModal,
  onChoice,
  customerStripeID,
  isStripeCustomer,
  subscribePlan,
  planSelection,
  onSelectPlan
}) => {
  return (
    <Container className="pricing" fluid>
      <Row>
        <Col lg={3}>
          <Card
            className={planSelection === 'FREE' ? 'selected-plan' : ''}
            onClick={() => {
              onSelectPlan('FREE');
            }}
          >
            <CardBody>
              <h5 className="card-title text-muted text-uppercase text-center">Free</h5>
              <h6 className="card-price text-center">0eur<span className="period"> /month</span></h6>
              <hr />
              <ul>
                <li><i className="material-icons check">done</i>Single User</li>
                <li><i className="material-icons check">done</i>Free Applications Access</li>
                <li><i className="material-icons check">done</i>Unlimited Public Projects</li>
                <li className="text-muted"><i className="material-icons not-check">clear</i>Access to all Applications</li>
                <li className="text-muted"><i className="material-icons not-check">clear</i>Unlimited Private Projects</li>
                <li className="text-muted"><i className="material-icons not-check">clear</i>Email/Chat Support</li>
                <li className="text-muted"><i className="material-icons not-check">clear</i>Dedicated Phone Support</li>
              </ul>
              <Col xs={12} className="align-center">
                <Button
                  className="transparentButton"
                >
                  Subscribe
                </Button>
              </Col>
            </CardBody>
          </Card>
          <br />
        </Col>
        {
          plansList.map(
            plan => (
              <Col lg={3}>
                <Card
                  className={planSelection === plan.nickname ? 'selected-plan' : ''}
                  onClick={() => {
                    onSelectPlan(plan.nickname);
                    onChoice(plan.id);
                    // onChoice(plan.fromStripe.id);
                  }}
                >
                  <CardBody>
                    <h5 className="card-title text-muted text-uppercase text-center">{plan.nickname}</h5>
                    <h6 className="card-price text-center">
                      {`${plan.amount / 100}${plan.currency}`}
                      <span className="period"> /{plan.interval}</span></h6>
                    <hr />
                    <ul>
                      {
                        Object.keys(plan.metadata).map((metaKey, i) => (
                          <li>
                            <i className={`material-icons ${plan.metadata[metaKey] === 'true' ? 'check' : 'not-check'}`}>
                              {plan.metadata[metaKey] === 'true' ? 'done' : 'clear'}
                            </i>
                            {metaKey}
                          </li>                   
                        ))
                      }
                    </ul>
                    <Col xs="12 align-center">
                      {
                        (!customerStripeID && !isStripeCustomer)
                          ? <Button
                            className="transparentButton"
                          >
                            Subscribe
                            </Button>
                          : <Button
                            className="positiveButton"
                            onClick={() => {
                              onSelectPlan(plan.nickname);
                              onChoice(plan.id);
                              openModal({ status: true });
                            }}
                          >
                            Subscribe
                            </Button>
                      }
                    </Col>
                  </CardBody>
                </Card>
                <br />
              </Col>
            )
          )
        }
        <Col lg={3}>
          <Card
            className={planSelection === 'ENTREPRISE' ? 'selected-plan' : ''}
            onClick={() => {
              onSelectPlan('ENTREPRISE');
            }}
          >
            <CardBody>
              <h5 className="card-title text-muted text-uppercase text-center">Entreprise</h5>
              <h6 className="card-price text-center">On quotation</h6>
              <hr />
              <ul>
                <li className="specific"><i className="material-icons check">done</i>Many Users</li>
                <li className="specific"><i className="material-icons check">done</i>Specific Applications</li>
                <li><i className="material-icons check">done</i>Unlimited Public Projects</li>
                <li><i className="material-icons check">done</i>Access to all Applications</li>
                <li><i className="material-icons check">done</i>Unlimited Private Projects</li>
                <li><i className="material-icons check">done</i>Email/Chat Support</li>
                <li className="specific"><i className="material-icons check">done</i>Dedicated Phone Support</li>
              </ul>
              <Col xs={12} className="align-center">
                <Button
                  className="positiveButton"
                  outline
                  color="primary"
                  onClick={() => {
                    openModal({ status: true });
                  }}
                >
                  {'Contact Us'}
                </Button>
              </Col>
            </CardBody>
          </Card>
          <br />
        </Col>
      </Row>
    </Container>
  );
};

export default Pricing;
