import React from 'react';
import InputsUnit from '../../../../../../../../../../../../components/UIComponents/Fields/InputsUnit2';
import optionsLengthUnits from '../../../../../../../../../../../../utils/units/optionsLengthUnits2';

const RectangularSection = ({
  sectionType,
  sections,
  changeGeometry
}) => {
  return (
    <div>
      <InputsUnit
        label={sections.listSectionTypes.rectangularSection.inputs.b.text}
        inputName="sections.listSectionTypes.rectangularSection.inputs.b.value"
        selectList={optionsLengthUnits}
        selectName="sections.listSectionTypes.rectangularSection.inputs.b.unit"
        tooltipDescription={sections.listSectionTypes.rectangularSection.inputs.b.description}
        tooltipTarget={sections.listSectionTypes.rectangularSection.inputs.b.name}
        onChange={(e) => {
          const val = e.target.value;
          changeGeometry(sections.listSectionTypes.rectangularSection.inputs, sectionType, val, sections.listSectionTypes.rectangularSection.inputs.b.name);
        }}
      />
      <InputsUnit
        label={sections.listSectionTypes.rectangularSection.inputs.h.text}
        inputName="sections.listSectionTypes.rectangularSection.inputs.h.value"
        selectList={optionsLengthUnits}
        selectName="sections.listSectionTypes.rectangularSection.inputs.h.unit"
        tooltipDescription={sections.listSectionTypes.rectangularSection.inputs.h.description}
        tooltipTarget={sections.listSectionTypes.rectangularSection.inputs.h.name}
        onChange={(e) => {
          const val = e.target.value;
          changeGeometry(sections.listSectionTypes.rectangularSection.inputs, sectionType, val, sections.listSectionTypes.rectangularSection.inputs.h.name);
        }}
      />
    </div>
  );
};

export default RectangularSection;
