import React from 'react';
import PropTypes from 'prop-types';
import InputsUnit from '../../../../../../../../../../components/UIComponents/Fields/InputsUnit2';
import optionsLengthUnits from '../../../../../../../../../../utils/units/optionsLengthUnits2';

const RectangularSection = ({
  sectionType,
  geometry,
  changeGeometry
}) => {
  return (
    <div>
      <InputsUnit
        label={geometry.concrete.b.text}
        inputName="geometry.concrete.b.value"
        selectList={optionsLengthUnits}
        selectName="geometry.concrete.b.unit"
        tooltipDescription={geometry.concrete.b.description}
        tooltipTarget={geometry.concrete.b.name}
        onChange={(e) => {
          const val = e.target.value;
          changeGeometry(geometry.concrete, sectionType, val, geometry.concrete.b.name);
        }}
      />
      <InputsUnit
        label={geometry.concrete.h.text}
        inputName="geometry.concrete.h.value"
        selectList={optionsLengthUnits}
        selectName="geometry.concrete.h.unit"
        tooltipDescription={geometry.concrete.h.description}
        tooltipTarget={geometry.concrete.h.name}
        onChange={(e) => {
          const val = e.target.value;
          changeGeometry(geometry.concrete, sectionType, val, geometry.concrete.h.name);
        }}
      />
    </div>
  );
};

export default RectangularSection;
