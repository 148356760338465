// import axios from 'axios';
// import { toast } from 'react-toastify';
// import {
//   SEARCH_APP
// } from './actionTypes';
// import { URL } from '../../../../config/config';
// import URL_PATH from '../../../../config/default';
import { openModal } from '../../../../components/UIComponents/Modal/actions';

const showVideo = () => {
  return (dispatch) => {
    dispatch(openModal({ status: true }));
  };
};

export {
  showVideo
};
