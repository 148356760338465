import React from 'react';
import PropTypes from 'prop-types';
import {
  Row,
  Col,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Label,
  // Tooltip
} from 'reactstrap';
import ToolTips from '../../ToolTips';
import InputField from '../InputRF';

const Checkbox = ({
  label,
  value,
  onChange,
  // selectList,
  inputName,
  // selectName,
  tooltipDescription,
  tooltipTarget,
  disabled,
  message
}) => (
  <div className="inputunit">
    <InputGroup>
      <InputGroupAddon className="inputgroupaddon" addonType="prepend">
        <InputGroupText className="inputgrouptext">{label}</InputGroupText>
        <ToolTips
          description={tooltipDescription}
          target={tooltipTarget}
        />
      </InputGroupAddon>
      <InputGroupAddon className="inputgroupaddon">
        <InputGroupText className="inputgrouptextSwitch">
          <Label className="switch">
            <InputField
              type="checkbox"
              name={inputName}
              id="threedimensionsstructure"
            />
            <span className="slider round" />
          </Label>
        </InputGroupText>
      </InputGroupAddon>
      <InputGroupAddon className="inputgroupaddon" addonType="append">
        <InputGroupText className="inputgrouptextSwitch">
          <Label>{message}</Label>
        </InputGroupText>
      </InputGroupAddon>
    </InputGroup>
  </div>
);

export default Checkbox;
