import React from 'react';
import Particles from 'react-particles-js';
import './styles/ConnectionBackground.scss';
import { AnimateBackground } from '../AnimateBackground';
import backsvg from './img/backsvg3.svg';

const { REACT_APP_COLOR } = process.env;

const background = {
  eiffage: {
    backgroundColor: 'darkgrey'
  },
  triazur: {
    backgroundImage: `url(${backsvg})`
  }
};

export default class ConnectionBackground extends React.Component {
  render() {
    return (
      <div>
        <div
          id="bg-node"
          className="bg-node-garden"
          // style={{ backgroundImage: `url(${REACT_APP_LOGO})` }}
          style={background[`${REACT_APP_COLOR}`]}
        />
        <Particles
          params={{
            particles: {
              number: {
                value: 70,
                density: {
                  enable: true,
                  value_area: 2500
                },
                line_linked: {
                  shadow: {
                    enable: true,
                    color: 'red',
                    blur: 5
                  }
                }
              }
            },
            interactivity: {
              detect_on: 'window',
              events: {
                onhover: {
                  enable: true,
                  mode: 'grab'
                }
              },
              resize: true
            }
          }}

          style={{
            width: '100%',
            height: '100%',
            minHeight: '100%',
            position: 'fixed',
            opacity: '0.8'
          }}
        />
      </div>
    );
  }
}
