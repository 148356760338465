import React from 'react';
import PropTypes from 'prop-types';
import {
  Nav,
  NavItem,
  NavLink,
  Badge
} from 'reactstrap';

const HorizontalScrollMenu = ({ toggle, activeTab, itemScrollMenu }) => (
  <div className="horizontalScrollMenu">
    <Nav tabs className="scrollMenu">
      {
        itemScrollMenu.map(item => (
          <NavItem
            key={item.num}
            id="acard"
            className={activeTab === item.num ? 'active' : ''}
          // style={{ width: `${100 / parseInt(itemScrollMenu.length, 10)}%` }}
          >
            <NavLink
              className="item"
              // className={classnames({ active: activeTab === item.num })}
              href="#"
              onClick={(e) => {
                toggle(item.num, item.title);
              }}
              disabled={item.status === 'Coming Soon'}
            >
              <i className="material-icons">{item.icon}</i>
              {item.title}
              {item.status === 'Coming Soon' &&
                <div className="label">
                  <Badge color="primary" pill>{item.status}</Badge>
                </div>
              }
              {item.status === 'Beta' &&
                <div className="label">
                  <Badge color="warning" pill>{item.status}</Badge>
                </div>
              }
              {item.status === 'New' &&
                <div className="label">
                  <Badge color="success" pill>{item.status}</Badge>
                </div>
              }
            </NavLink>
          </NavItem>
        ))
      }
    </Nav>
  </div>

);

HorizontalScrollMenu.propTypes = {
  toggle: PropTypes.func.isRequired,
  activeTab: PropTypes.string.isRequired,
  itemScrollMenu: PropTypes.arrayOf(PropTypes.object).isRequired
};

export default HorizontalScrollMenu;
