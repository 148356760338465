import DatBimIcon from './img/DatBimIcon.png';
import ClassesSvg from './img/Classes.svg';
import ClassificationsSvg from './img/Classifications.svg';
import ObjectsSvg from './img/Objects.svg';
import OrganizationsSvg from './img/Organizations.svg';
import PortalsSvg from './img/Portals.svg';

const initialState = {
  categories: {
    categoriesList: [
      {
        imgsrc: ClassesSvg,
        title: 'Classes',
        category: 'BIM',
        description: 'List of all datBIM Classes',
        link: '/datbim/classes',
        status: 'Beta',
        tags: [ ],
        logoCompany: DatBimIcon,
        company: 'datBIM',
        video: ''
      },
      {
        imgsrc: ClassificationsSvg,
        title: 'Classifications',
        category: 'BIM',
        description: 'List of all datBIM Classifications',
        link: '/datbim/classifications',
        status: 'Beta',
        tags: [ ],
        logoCompany: DatBimIcon,
        company: 'datBIM',
        video: ''
      },
      {
        imgsrc: ObjectsSvg,
        title: 'Objects',
        category: 'BIM',
        description: 'List of all datBIM Objects',
        link: '/datbim/objects',
        status: 'Beta',
        tags: [ ],
        logoCompany: DatBimIcon,
        company: 'datBIM',
        video: ''
      },
      {
        imgsrc: OrganizationsSvg,
        title: 'Organizations',
        category: 'BIM',
        description: 'List of all datBIM Organizations',
        link: '/datbim/organizations',
        status: 'Beta',
        tags: [ ],
        logoCompany: DatBimIcon,
        company: 'datBIM',
        video: ''
      },
      {
        imgsrc: PortalsSvg,
        title: 'Portals',
        category: 'BIM',
        description: 'List of all datBIM Portals',
        link: '/datbim/portals',
        status: 'Beta',
        tags: [ ],
        logoCompany: DatBimIcon,
        company: 'datBIM',
        video: ''
      },
    ]
  },
  classes : [],
  classifications: [],
  objects: [],
  organizations: [],
  portals: [],
  loading: true
};

export default initialState;
