import axios from 'axios';
import {
  SS_CHANGE_GEOMETRY,
  SS_CHANGE_GLOBAL_UNITS,
  SS_FETCH_SECTION_DATA,
  SS_LOAD_OUTPUTS
} from './actionTypes';
import { conversionValue2 } from '../../../../utils/units/conversionFunctions';
import optionsUnitsSystem from '../../../../utils/units/optionsUnitsSystem';
// import history from '../../../../history';
import { URL } from '../../../../config/config';
// import URL_PATH from '../../../../config/default';
import { openModal } from '../../../../components/UIComponents/Modal/actions';
import sectionTypesArray from '../../../../utils/sectionGeometry/sectionTypesArray';
import sectionData from '../../../../utils/sectionGeometry/sectionData';
import ec3 from '../../../../utils/structure/ec3';

export const changeGeometry = (inputs, sectionType, inputValue, inputName) => {
  const newInputs = { ...inputs, [inputName]: { ...inputs[inputName], value: inputValue } };
  const {
    sectionext,
    sectionint
  } = sectionTypesArray(sectionType, newInputs);
  return {
    type: SS_CHANGE_GEOMETRY,
    payload: {
      sectionType,
      value: inputValue,
      name: inputName,
      sectionext,
      sectionint
    }
  };
};

export const fetchSectionData = (sectionType, geometry) => {
  const {
    sectionext,
    sectionint
  } = sectionTypesArray(sectionType, geometry.steel);
  return {
    type: SS_FETCH_SECTION_DATA,
    payload: {
      sectionType,
      geometry: {
        steel: {
          sectionext,
          sectionint
        }
      }
    }
  };
};

const conversion = (oldValues, newUnit) => ({
  value: conversionValue2(oldValues.value, oldValues.unit.name, newUnit.name),
  unit: newUnit
});

const convertedState = (oldValues, unitSystem, steelSectionType) => {
  const sectionExt = oldValues.geometry.steel.sectionext;
  const sectionInt = oldValues.geometry.steel.sectionint;

  const convertValueSection = sectionType => sectionType.reduce((acc, section) => {
    const cX = conversionValue2(
      section.x,
      oldValues.geometry.steel.unit.name,
      unitSystem.type.unitLength.name
    );
    const cY = conversionValue2(
      section.y,
      oldValues.geometry.steel.unit.name,
      unitSystem.type.unitLength.name
    );
    return acc.concat([{ x: cX, y: cY }]);
  }, []);

  const randomGeometryConvert = (unitSys) => {
    const convertedSectionExt = convertValueSection(sectionExt);
    const convertedSectionInt = convertValueSection(sectionInt);
    return {
      steel: {
        sectionext: convertedSectionExt,
        sectionint: convertedSectionInt,
        unit: unitSys.type.unitLength
      }
    };
  };

  const simpleGeometryConvert = (unitSys) => {
    const convertedSectionExt = convertValueSection(sectionExt);
    const convertedSectionInt = convertValueSection(sectionInt);
    return {
      steel: {
        unit: { name: unitSys.type.unitLength.name, text: unitSys.type.unitLength.text },
        b: {
          name: 'b',
          text: 'b',
          value: 10,
          description: 'Section Width',
          unit: unitSys.type.unitLength.text
        },
        h: {
          name: 'h',
          text: 'h',
          value: 100,
          description: 'Section Height',
          unit: unitSys.type.unitLength.text
        },
        sectionext: convertedSectionExt,
        sectionint: convertedSectionInt
      }
    };
  };

  return {
    options: {
      unit: {
        value: {
          name: unitSystem.name,
          text: unitSystem.text
        }
      }
    },
    geometry:
    // randomGeometryConvert(unitSystem),
    steelSectionType === 'randomSection' ? randomGeometryConvert(unitSystem) : simpleGeometryConvert(unitSystem),
    steel: {
      fyk: conversion(oldValues.steel.fyk, unitSystem.type.unitStress),
      Es: conversion(oldValues.steel.Es, unitSystem.type.unitStress),
      G: conversion(oldValues.steel.G, unitSystem.type.unitStress)
    },
    loads: {
      Ned: conversion(oldValues.loads.Ned, unitSystem.type.unitForce),
      Ved: conversion(oldValues.loads.Ved, unitSystem.type.unitForce),
      Mxed: conversion(oldValues.loads.Mxed, unitSystem.type.unitMoment),
      Myed: conversion(oldValues.loads.Myed, unitSystem.type.unitMoment),
      Ted: conversion(oldValues.loads.Ted, unitSystem.type.unitMoment),
      Mdx: conversion(oldValues.loads.Mdx, unitSystem.type.unitMoment),
      Mdy: conversion(oldValues.loads.Mdy, unitSystem.type.unitMoment)
    }
  };
};

export const changeGlobalUnits = (newUnit, oldValues, sectionType) => {
  const unit = ((unitValue) => {
    return {
      unitSystem: optionsUnitsSystem[unitValue].type,
      newValue: convertedState(oldValues, optionsUnitsSystem[unitValue], sectionType)
    };
  })(newUnit);

  return {
    type: SS_CHANGE_GLOBAL_UNITS,
    payload: { newValue: unit.newValue, sectionType }
  };
};

export function postSectionData(sectiongeometry) {
  axios.post(`${URL}sectiondata`, sectiongeometry)
    .then((res) => {
      console.log(res.data);
    }).catch((err) => {
      console.log(err);
    });
}


export const postEc3Uls = (steelSectionInputs, sectionType/* , user */) => {
  return async (dispatch) => {
    try {
      // const resp = await axios.post(`${URL}${URL_PATH.calculs.ec2sls}`, steelSectionInputs);
      dispatch(openModal({ status: true }));
      const geometry = steelSectionInputs.geometry.steel;
      const { steel } = steelSectionInputs;
      const { loads } = steelSectionInputs;
      // const {
      //   sectionext,
      //   sectionint
      // } = geometry;

      dispatch({
        type: SS_LOAD_OUTPUTS,
        payload: {
          xg: sectionData.SteelSection.xg(),
          yg: sectionData.SteelSection.yg(geometry),
          A: sectionData.SteelSection.A(geometry, sectionType),
          Av: sectionData.SteelSection.Av(geometry, sectionType),
          Ix: sectionData.SteelSection.Ix(geometry, sectionType),
          Iy: sectionData.SteelSection.Iy(geometry, sectionType),
          It: sectionData.SteelSection.It(geometry, sectionType),
          Iw: sectionData.SteelSection.Iw(geometry, sectionType),
          Welx: sectionData.SteelSection.Welx(geometry, sectionType),
          Wely: sectionData.SteelSection.Wely(geometry, sectionType),
          Wplx: sectionData.SteelSection.Wplx(geometry, sectionType),
          Wply: sectionData.SteelSection.Wply(geometry, sectionType),
          CL: ec3.CL(geometry, steel, loads, sectionType),
          NplRd: ec3.NplRd(geometry, steel, loads, sectionData.SteelSection, sectionType),
          MelxRd: ec3.MelxRd(geometry, steel, loads, sectionData.SteelSection, sectionType),
          MelyRd: ec3.MelyRd(geometry, steel, loads, sectionData.SteelSection, sectionType),
          MplxRd: ec3.MplxRd(geometry, steel, loads, sectionData.SteelSection, sectionType),
          MplyRd: ec3.MplyRd(geometry, steel, loads, sectionData.SteelSection, sectionType),
          khix: ec3.khix(geometry, steel, loads, sectionData.SteelSection, sectionType),
          khiy: ec3.khiy(geometry, steel, loads, sectionData.SteelSection, sectionType),
          khiLT: ec3.khiLT(geometry, steel, sectionData.SteelSection, sectionType),
          kxx: ec3.kxx(geometry, steel, loads, sectionData.SteelSection, sectionType),
          kxy: ec3.kxy(geometry, steel, loads, sectionData.SteelSection, sectionType),
          kyx: ec3.kyx(geometry, steel, loads, sectionData.SteelSection, sectionType),
          kyy: ec3.kyy(geometry, steel, loads, sectionData.SteelSection, sectionType),
          Condition1: ec3.Condition1(geometry, steel, loads, sectionData.SteelSection, sectionType),
          Condition2: ec3.Condition2(geometry, steel, loads, sectionData.SteelSection, sectionType),
          Condition3: ec3.Condition3(geometry, steel, loads, sectionData.SteelSection, sectionType),
          SF: ec3.SF(geometry, steel, loads, sectionData.SteelSection, sectionType),
          Condition1p: ec3.Condition1p(geometry, steel, loads, sectionData.SteelSection, sectionType),
          Condition2p: ec3.Condition2p(geometry, steel, loads, sectionData.SteelSection, sectionType),
          Condition3p: ec3.Condition3p(geometry, steel, loads, sectionData.SteelSection, sectionType),
          SFp: ec3.SFp(geometry, steel, loads, sectionData.SteelSection, sectionType),
          VplRd: ec3.VplRd(geometry, steel, loads, sectionData.SteelSection, sectionType),
          VedVplRd: ec3.VedVplRd(geometry, loads, steel, sectionData.SteelSection, sectionType),
          UnMoinsRho: ec3.UnMoinsRho(geometry, loads, steel, sectionData.SteelSection, sectionType),
          Tt: ec3.Tt(loads, sectionType),
          Am: ec3.Am(geometry, sectionType),
          thotweb: ec3.thotweb(geometry, loads, sectionType),
          Tw: ec3.Tw(loads, sectionType),
          Sw: ec3.Sw(geometry, sectionType),
          w: ec3.w(geometry, sectionType),
          sigmaw: ec3.sigmaw(geometry, loads, sectionData.SteelSection, sectionType),
          thowweb: ec3.thowweb(geometry, loads, sectionData.SteelSection, sectionType),
          Aw: ec3.Aw(geometry, sectionType),
          thoshearweb: ec3.thoshearweb(geometry, loads, sectionType),
          thoEdweb: ec3.thoEdweb(geometry, loads, sectionData.SteelSection, sectionType),
          SFt: ec3.SFt(geometry, steel, loads, sectionData.SteelSection, sectionType)
        }
      });
    } catch (err) {
      console.log('ERRRR', err);
    }
  };
};

export const getSectionState = (sectionType) => {
  axios.get(`${URL}steelsectionstate`, { params: sectionType })
    .then((res) => {
      console.log(res.data);
    }).catch((err) => {
      console.log(err);
    });
};
