import { OPEN_MODAL } from './actionTypes';

function openModal({ status, object }) {
  return {
    type: OPEN_MODAL,
    payload: {
      status,
      object
    }
  };
}

export {
  openModal
};
