import React, { Fragment } from 'react';
import {
  Button,
  Container,
  Row,
  Col,
  Label,
  Table,
  FormGroup
} from 'reactstrap';
import { compose } from 'recompose';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import SelectRF from '../../../../../../../../../components/UIComponents/Fields/SelectRF';
import InputField from '../../../../../../../../../components/UIComponents/Fields/InputRF';
import {
  changeName,
  changeListSelection
} from '../../../../../actions';

const NodesModal = ({
  fields,
  clickObjectData,
  nodesList,
  supportsList,
  changeName,
  changeListSelection,
}) => (
    <Container>
      {
        fields.map((node, index) => (
          nodesList[index].active &&
          <div key={index}>
            <Row>
              <Col xs={3}>
                <Label className="label">Name</Label>
              </Col>
              <Col xs={9}>
                <InputField
                  name={`${node}.name`}
                  type="text"
                  onChange={(e) => {
                    const val = e.target.value;
                    changeName('nodes', index, val);
                    const nodeList = fields.getAll();
                    changeListSelection('members', 'nodei', nodeList, index, val);
                    changeListSelection('members', 'nodej', nodeList, index, val);
                    changeListSelection('nodeLoads', 'node', nodeList, index, val);
                  }}
                />
              </Col>
            </Row>
            <Row>
              <Col xs={3}>
                <Label className="label">Supp</Label>
              </Col>
              <Col xs={9}>
                <SelectRF
                  name={`${node}.support`}
                  list={supportsList}
                />
              </Col>
            </Row>
            <Row>
              <Col xs={3}>
                <Label className="label">X</Label>
              </Col>
              <Col xs={9}>
                <InputField name={`${node}.X`} />
              </Col>
            </Row>
            <Row>
              <Col xs={3}>
                <Label className="label">Y</Label>
              </Col>
              <Col xs={9}>
                <InputField name={`${node}.Y`} />
              </Col>
            </Row>
            <Row>
              <Col xs={3}>
                <Label className="label">Z</Label>
              </Col>
              <Col xs={9}>
                <InputField name={`${node}.Z`} />
              </Col>
            </Row>
          </div>
        ))
      }
    </Container >
  );

const mapStateToProps = (state, props) => {
  return {
    supportsList: state.form.structure3D.inputs.values.supports.value,
    nodesList: state.form.structure3D.inputs.values.nodes.value
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({
    changeName,
    changeListSelection
  }, dispatch);
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
)(NodesModal);
