import React from 'react';
import PropTypes from 'prop-types';
import {
  Container,
  Table,
  Row,
  Col,
  Input
} from 'reactstrap';
import { Scatter } from 'react-chartjs-2';

/*import chartOptions from './utils/chartOptions';
import chartData from './utils/chartData';*/
import OutputCalc from '../../../../../../../../components/UIComponents/Fields/OutputCalc';
import optionsAutreUnits from '../../../../../../../../utils/units/optionsAutreUnits';
import optionsForceUnits from '../../../../../../../../utils/units/optionsForceUnits2';
import optionsMomentUnits from '../../../../../../../../utils/units/optionsMomentUnits2';

const Bending = ({
  bending,
  steel
}) => {
  return (
    <Container className="geometry">
      <br />
      <Row>
        <Col md={6}>
          {/* <div className="chartContainer">
            <Scatter
              className="chart"
              width={500}
              height={500}
              data={chartData(steel.sectionext, steel.sectionint, sectionData)}
              options={chartOptions(steel.sectionext, steel.unit)}
            />
          </div> */}
        </Col>
        <Col md={6}>
          <h2 className="subtitle">Bending</h2>
          <OutputCalc
            input={bending.CL}
            unitList={optionsAutreUnits}
          />
          <OutputCalc
            input={bending.NplRd}
            unitList={optionsForceUnits}
          />
          <OutputCalc
            input={bending.MelxRd}
            unitList={optionsMomentUnits}
          />
          <OutputCalc
            input={bending.MelyRd}
            unitList={optionsMomentUnits}
          />
          <OutputCalc
            input={bending.khix}
            unitList={optionsAutreUnits}
          />
          <OutputCalc
            input={bending.khiy}
            unitList={optionsAutreUnits}
          />
          <OutputCalc
            input={bending.khiLT}
            unitList={optionsAutreUnits}
          />
          <OutputCalc
            input={bending.kxx}
            unitList={optionsAutreUnits}
          />
          <OutputCalc
            input={bending.kxy}
            unitList={optionsAutreUnits}
          />
          <OutputCalc
            input={bending.kyx}
            unitList={optionsAutreUnits}
          />
          <OutputCalc
            input={bending.kyy}
            unitList={optionsAutreUnits}
          />
          <OutputCalc
            input={bending.Condition1}
            unitList={optionsAutreUnits}
          />
          <OutputCalc
            input={bending.Condition2}
            unitList={optionsAutreUnits}
          />
          <OutputCalc
            input={bending.Condition3}
            unitList={optionsAutreUnits}
          />
          <OutputCalc
            input={bending.SF}
            unitList={optionsAutreUnits}
          />
        </Col>
      </Row>
    </Container>
  );
};


export default Bending;