import { connect } from 'react-redux';
import { IntlProvider, addLocaleData } from 'react-intl';
import En from 'react-intl/locale-data/en';
import Fr from 'react-intl/locale-data/fr';
import fr from './langs/fr';
import en from './langs/en';

addLocaleData([...En, ...Fr]);
const languages = {
  en,
  fr
};

function mapStateToProps(state) {
  const {
    lang,
    messages,
    signin
  } = languages[state.selectedLanguage.lang];

  return {
    locale: lang,
    key: lang,
    messages,
    signin
  };
}

export default connect(mapStateToProps)(IntlProvider);
