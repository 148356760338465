const optionsVolumeUnits = [{
  label: 'm³',
  value: [
    { unit: 'm', pow: 3 }
  ]
}, {
  label: 'cm³',
  value: [
    { unit: 'cm', pow: 3 }
  ]
}, {
  label: 'mm³',
  value: [
    { unit: 'mm', pow: 3 }
  ]
}, {
  label: 'inch³',
  value: [
    { unit: 'inch', pow: 3 }
  ]
}, {
  label: 'ft³',
  value: [
    { unit: 'ft', pow: 3 }
  ]
}];

export default optionsVolumeUnits;
