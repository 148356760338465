const initialState = {
  inputs: {
    H: {
      name: 'H',
      text: 'H',
      value: 5,
      description: 'Wave height',
      unit: 'm'
    },
    T: {
      name: 'T',
      text: 'T',
      value: 12,
      description: 'Wave period',
      unit: 's'
    },
    d: {
      name: 'd',
      text: 'd',
      value: 20,
      description: 'Water depth',
      unit: 'm'
    }
  },
  outputs: {
    L: {
      name: 'L',
      text: 'L',
      value: 152,
      description: 'Wave length',
      unit: 'm'
    },
    waterLevel: {
      name: 'waterLevel',
      text: 'Water Level',
      description: 'Water Level',
      unit: 'm',
      value: [
        { x: 0, y: 2.5 },
        { x: 1.52359, y: 2.495066821081949 },
        { x: 3.04718, y: 2.4802867533311863 },
        { x: 4.5707699999999996, y: 2.455718126922621 },
        { x: 6.09436, y: 2.421457903000127 },
        { x: 7.61795, y: 2.3776412910152107 },
        { x: 9.141539999999999, y: 2.3244412151170755 },
        { x: 10.665130000000001, y: 2.2620676317000097 },
        { x: 12.18872, y: 2.1907667008014173 },
        { x: 13.71231, y: 2.1108198146206156 },
        { x: 15.2359, y: 2.022542486992382 },
        { x: 16.75949, y: 1.9262831081979943 },
        { x: 18.283079999999998, y: 1.8224215700279585 },
        { x: 19.80667, y: 1.7113677665226716 },
        { x: 21.330260000000003, y: 1.5935599763079122 },
        { x: 22.85385, y: 1.4694631329093353 },
        { x: 24.37744, y: 1.3395669898722615 },
        { x: 25.901030000000002, y: 1.2043841879281838 },
        { x: 27.42462, y: 1.064448231836009 },
        { x: 28.948210000000003, y: 0.9203113848825144 },
        { x: 30.4718, y: 0.7725424893514645 },
        { x: 31.99539, y: 0.6217247215630006 },
        { x: 33.51898, y: 0.4684532903431401 },
        { x: 35.04257, y: 0.31333308800647025 },
        { x: 36.566159999999996, y: 0.1569763031225348 },
        { x: 38.08975, y: 4.487241287302015e-9 },
        { x: 39.61334, y: -0.1569762941657613 },
        { x: 41.13693, y: -0.31333307910275415 },
        { x: 42.660520000000005, y: -0.4684532815276208 },
        { x: 44.184110000000004, y: -0.6217247128704685 },
        { x: 45.7077, y: -0.772542480816225 },
        { x: 47.23129, y: -0.920311376538252 },
        { x: 48.75488, y: -1.0644482237156547 },
        { x: 50.278470000000006, y: -1.2043841800637851 },
        { x: 51.802060000000004, y: -1.3395669822948555 },
        { x: 53.32565, y: -1.4694631256488269 },
        { x: 54.84924, y: -1.5935599693929552 },
        { x: 56.37283, y: -1.7113677599805555 },
        { x: 57.896420000000006, y: -1.822421563884503 },
        { x: 59.420010000000005, y: -1.9262831024774443 },
        { x: 60.9436, y: -2.0225424817173137 },
        { x: 62.46719, y: -2.1108198098118476 },
        { x: 63.99078, y: -2.1907666964779278 },
        { x: 65.51437000000001, y: -2.2620676278788614 },
        { x: 67.03796, y: -2.324441211813349 },
        { x: 68.56155000000001, y: -2.3776412882419433 },
        { x: 70.08514, y: -2.4214579007682637 },
        { x: 71.60873000000001, y: -2.4557181252409706 },
        { x: 73.13231999999999, y: -2.4802867522063856 },
        { x: 74.65591, y: -2.4950668205184368 },
        { x: 76.1795, y: -2.5 },
        { x: 77.70309, y: -2.4950668216454615 },
        { x: 79.22668, y: -2.4802867544559875 },
        { x: 80.75027, y: -2.455718128604271 },
        { x: 82.27386, y: -2.42145790523199 },
        { x: 83.79745000000001, y: -2.3776412937884777 },
        { x: 85.32104000000001, y: -2.324441218420803 },
        { x: 86.84463, y: -2.2620676355211584 },
        { x: 88.36822000000001, y: -2.190766705124907 },
        { x: 89.89181, y: -2.1108198194293837 },
        { x: 91.4154, y: -2.02254249226745 },
        { x: 92.93899000000002, y: -1.9262831139185432 },
        { x: 94.46258, y: -1.822421576171414 },
        { x: 95.98617, y: -1.711367773064788 },
        { x: 97.50976, y: -1.5935599832228697 },
        { x: 99.03335000000001, y: -1.469463140169843 },
        { x: 100.55694000000001, y: -1.3395669974496671 },
        { x: 102.08053, y: -1.2043841957925832 },
        { x: 103.60412000000001, y: -1.064448239956363 },
        { x: 105.12771000000001, y: -0.9203113932267777 },
        { x: 106.6513, y: -0.7725424978867036 },
        { x: 108.17489000000002, y: -0.6217247302555311 },
        { x: 109.69848, y: -0.4684532991586588 },
        { x: 111.22207, y: -0.3133330969101858 },
        { x: 112.74566, y: -0.15697631207930884 },
        { x: 114.26925000000001, y: -1.3461723306794532e-8 },
        { x: 115.79284000000001, y: 0.1569762852089895 },
        { x: 117.31643, y: 0.3133330701990386 },
        { x: 118.84002000000001, y: 0.46845327271210263 },
        { x: 120.36361000000001, y: 0.6217247041779362 },
        { x: 121.8872, y: 0.7725424722809843 },
        { x: 123.41079000000002, y: 0.9203113681939902 },
        { x: 124.93438, y: 1.0644482155953003 },
        { x: 126.45797, y: 1.2043841721993869 },
        { x: 127.98156, y: 1.3395669747174495 },
        { x: 129.50515000000001, y: 1.469463118388319 },
        { x: 131.02874000000003, y: 1.5935599624779992 },
        { x: 132.55233, y: 1.7113677534384408 },
        { x: 134.07592, y: 1.8224215577410465 },
        { x: 135.59951, y: 1.9262830967568945 },
        { x: 137.12310000000002, y: 2.022542476442247 },
        { x: 138.64669, y: 2.1108198050030795 },
        { x: 140.17028, y: 2.190766692154437 },
        { x: 141.69387, y: 2.2620676240577122 },
        { x: 143.21746000000002, y: 2.3244412085096218 },
        { x: 144.74105, y: 2.3776412854686755 },
        { x: 146.26463999999999, y: 2.4214578985364006 },
        { x: 147.78823, y: 2.45571812355932 },
        { x: 149.31182, y: 2.480286751081585 },
        { x: 150.83541000000002, y: 2.4950668199549244 },
        { x: 152.359, y: 2.5 }
      ]
    }
  }
};

export default initialState;
