import TriAzurIcon from './img/TriAzurIcon.png';
// import EiffageIcon from './img/EiffageIcon.jpg';
// import Structure3DSvg from './img/Structure3D.svg';
import Structure3DSvg from './img/bridge.svg';
import ConcreteSectionSvg from './img/ConcreteSection.svg';
import SteelSectionSvg from './img/SteelSection.svg';
import SectionGeometrySvg from './img/SectionGeometry.svg';
import StructuralAnalysisSvg from './img/StructuralAnalysis1.svg';
import WaveCharacteristicsSvg from './img/WaveCharacteristics.svg';
import RegressionSvg from './img/Regression.svg';
import BIMViewerSvg from './img/BimViewer.svg';
import DatBimPng from './img/datBim.png';
import DevKitWebAppSvg from './img/DevKitWebApp.svg';

const initialState = {
  applications: {
    categoriesList: [
      { label: 'Structure', value: 'Structure' },
      { label: 'Hydraulic', value: 'Hydraulic' },
      { label: 'Artificial Intelligence', value: 'AI' },
      { label: 'BIM', value: 'BIM' },
      { label: 'Develop', value: 'Dev' }
      // { label: 'Geotechnic', value: 'Geotechnic' }
    ],
    applicationsList: [
      {
        imgsrc: Structure3DSvg,
        title: 'Structure 3D',
        category: 'Structure',
        description: 'Structure 3D allows you to analyse, the stress and displacements of a 3D structure subjected to external forces',
        link: '/structure3d/projects',
        status: 'Beta',
        tags: ['#structure', '#3D'],
        logoCompany: TriAzurIcon,
        company: 'TriAzur',
        video: 'https://www.youtube.com/watch?v=dEpGTg1ZSjU&list=PLnyZofGl5v2aDpxV0t7ks6T8oOAAEOUEn'
      },
      {
        imgsrc: SteelSectionSvg,
        title: 'Steel Section',
        category: 'Structure',
        description: 'Steel Section allows you to design steel sections with international codes (Eurocode 3, API, AISC,...).',
        link: '/steelsection',
        status: 'Beta',
        tags: ['#structure', '#steel'],
        logoCompany: TriAzurIcon,
        company: 'TriAzur',
        video: ''
      },
      {
        imgsrc: ConcreteSectionSvg,
        title: 'Concrete Section',
        category: 'Structure',
        description: 'Concrete Section allows you to design concrete sections with international codes (Eurocode 2, ACI, BAEL, ...).',
        link: '/concretesection',
        status: 'Beta',
        tags: ['#structure', '#concrete'],
        logoCompany: TriAzurIcon,
        company: 'TriAzur',
        video: ''
      },
      {
        imgsrc: SectionGeometrySvg,
        title: 'Section Geometry',
        category: 'Structure',
        description: 'Section Geometry allows you to get section data like area, center of gravity or inertia',
        link: '/sectiongeometry',
        status: 'New',
        tags: ['#structure', '#geometry'],
        logoCompany: TriAzurIcon,
        company: 'TriAzur',
        video: ''
      },
      {
        imgsrc: StructuralAnalysisSvg,
        title: 'Structural Analysis',
        category: 'Structure',
        description: 'Structural Analysis allows you to get reactions, moments and deflections for simple structure',
        link: '/structuralanalysis/beamformulas',
        status: 'New',
        tags: ['#structure'],
        logoCompany: TriAzurIcon,
        company: 'TriAzur',
        video: ''
      },
      {
        imgsrc: WaveCharacteristicsSvg,
        title: 'Wave Characteristics',
        category: 'Hydraulic',
        description: 'Wave Characteristics allows you to get wave data like wave length, velocity or acceleration',
        link: '/wavecharacteristics',
        status: 'New',
        tags: ['#hydraulic','#wave'],
        logoCompany: TriAzurIcon,
        company: 'TriAzur',
        video: ''
      },
      {
        imgsrc: RegressionSvg,
        title: 'Regression',
        category: 'AI',
        description: 'Regression is the task of approximating a mapping function (f) from input variables (X) to a continuous output variable (y)',
        link: '/regression',
        status: 'Beta',
        tags: ['#ia'],
        logoCompany: TriAzurIcon,
        company: 'TriAzur',
        video: ''
      },
      {
        imgsrc: BIMViewerSvg,
        title: 'BIM Viewer',
        category: 'BIM',
        description: 'Display 2D and 3D views of your designs on your website by embedding the Viewer in a web page',
        link: '',
        href: 'https://bim-viewer.herokuapp.com',
        status: 'Beta',
        tags: ['#bim'],
        logoCompany: TriAzurIcon,
        company: 'TriAzur',
        video: ''
      },
      {
        imgsrc: DatBimPng,
        title: 'Open-datBIM',
        category: 'BIM',
        description: 'Open-datBIM, la bibliothèque de contenus BIM de référence disponible directement en ligne',
        link: '/datbim',
        href: '',
        status: 'Beta',
        tags: ['#bim', '#database'],
        logoCompany: DatBimPng,
        company: 'datBim',
        video: ''
      },
      {
        imgsrc: DevKitWebAppSvg,
        title: 'Kit Web App',
        category: 'Dev',
        description: 'This kit helps you to develop your own Web Application and perform calculations directly in the web browser',
        link: '',
        href: 'https://gitlab.com/triazur/triazur-webapp-kit-serverless',
        status: 'Beta',
        tags: ['#dev','#webapp'],
        logoCompany: TriAzurIcon,
        company: 'TriAzur',
        video: ''
      }
    ]
  }
};

export default initialState;
