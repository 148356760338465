import axios from 'axios';
import fileDownload from 'react-file-download';
import { toast } from 'react-toastify';
import {
  SG_CHANGE_INPUT_VALUE,
  SG_FETCH_SECTION_DATA
} from './actionTypes';
import { URL } from '../../config/config';
import URL_PATH from '../../config/default';

import sectionData from '../../utils/sectionGeometry/sectionData';
import sectionTypesArray from '../../utils/sectionGeometry/sectionTypesArray';
import { openModal } from '../../components/UIComponents/Modal/actions';

const calculation = (sectionType, inputs) => {
  const {
    sectionext,
    sectionint
  } = sectionTypesArray(sectionType, inputs);
  const area = sectionData.S(sectionext, sectionint);
  const Pext = sectionData.P(sectionext, sectionint).pExt;
  const Pint = sectionData.P(sectionext, sectionint).pInt;
  const xg = sectionData.Xg(sectionext, sectionint, area);
  const yg = sectionData.Yg(sectionext, sectionint, area);
  const ixx = sectionData.Ixx(sectionext, sectionint, area, yg).IxxG;
  const iyy = sectionData.Iyy(sectionext, sectionint, area, xg).IyyG;
  const ixy = sectionData.Iyy(sectionext, sectionint, area, xg).IxyG;

  return {
    sectionext,
    sectionint,
    Pext,
    Pint,
    xg,
    yg,
    area,
    ixx,
    iyy,
    ixy
  };
};

export function changeInputValue(inputs, sectionType, inputValue, inputName) {
  const newInputs = { ...inputs };
  newInputs[inputName].value = inputValue;

  const {
    sectionext,
    sectionint,
    Pext,
    Pint,
    xg,
    yg,
    area,
    ixx,
    iyy,
    ixy
  } = calculation(sectionType, inputs);

  return {
    type: SG_CHANGE_INPUT_VALUE,
    payload: {
      sectionType,
      inputs: {
        value: inputValue,
        name: inputName
      },
      outputs: {
        sectionext,
        sectionint,
        Pext,
        Pint,
        xg,
        yg,
        area,
        ixx,
        iyy,
        ixy
      }
    }
  };
}

export const fetchSectionData = (sectionType, inputs) => {
  const {
    sectionext,
    sectionint,
    Pext,
    Pint,
    xg,
    yg,
    area,
    ixx,
    iyy,
    ixy
  } = calculation(sectionType, inputs);
  return {
    type: SG_FETCH_SECTION_DATA,
    payload: {
      outputs: {
        sectionext,
        sectionint,
        Pext,
        Pint,
        xg,
        yg,
        area,
        ixx,
        iyy,
        ixy
      }
    }
  };
};

// export const exportToCSV = async (sectionType, sectionValues, outputs, actualOutputs) => { //TODO: sectionValues === { inputs, outputs }

//   const finalOutputs = Object.keys(outputs)
//     .reduce((acc, key) => {
//       return { ...acc, [key]: { ...outputs[key], value: actualOutputs[key] } };
//     }, {});

//   try {
//     const res = await axios.post(`${URL}${URL_PATH.calculs.sectionGeometryCSV}`, { sectionType, sectionValues, outputs: finalOutputs });
//     fileDownload(res.data, 'geometry.csv');
//     toast.success('File downloaded');
//   } catch (err) {
//     toast.error('No access to the server');
//   }
// };

export const exportToCSV = async (sectionType, sectionValues, outputs) => {
  try {
    const res = await axios.post(`${URL}${URL_PATH.calculs.sectionGeometryCSV}`, { sectionType, sectionValues, outputs });
    fileDownload(res.data, 'geometry.csv');
    toast.success('File downloaded');
  } catch (err) {
    toast.error('No access to the server');
  }
};

export const exportToXLSX = async (sectionType, sectionValues, outputs) => {
  try {
    const res = await axios.post(`${URL}${URL_PATH.calculs.sectionGeometryXLSX}`, { sectionType, sectionValues, outputs }, { responseType: 'arraybuffer' });
    fileDownload(res.data, 'geometry.xlsx');
    toast.success('File downloaded');
  } catch (err) {
    toast.error('No access to the server');
  }
};

export const selectFileFormat = () => {
  return (dispatch) => {
    dispatch(openModal({ status: true }));
  };
};
