import * as THREE from 'three';
import showLabel from '../ShowLabel';

export default (name, {
  scene,
  labelMesh,
  nodeMesh,
  uiManagement,
  data
}) => {
  const listnodes = data.nodes.value;
  const listsupports = data.supports.value;

  // Deleting all nodes mesh and label mesh
  if (typeof nodeMesh !== 'undefined' && nodeMesh.length > 0) {
    for (let i = nodeMesh.length - 1; i >= 0; i -= 1) {
      scene.remove(nodeMesh[i]);
      // nodeMesh[i].geometry.dispose();
      // nodeMesh[i].material.dispose();
      nodeMesh.pop();
    }
  }

  if (typeof labelMesh !== 'undefined' && labelMesh.length > 0) {
    for (let i = labelMesh.length - 1; i >= 0; i -= 1) {
      scene.remove(labelMesh[i]);
      labelMesh.pop();
    }
  }
  // const elements = document.getElementsByClassName('node-label');
  // while (elements.length > 0) {
  //   elements[0].parentNode.removeChild(elements[0]);
  // }

  // Drawing all nodes "Fixed" = SupportId = 1 = Box geometry, "Free" = Sphere geometry
  // const spherege = new THREE.SphereGeometry(0.3, 32, 32);
  // const materialsphere = new THREE.MeshBasicMaterial({ color: 'blue' });
  // const boxge = new THREE.BoxGeometry(0.6, 0.6, 0.6);
  // const materialbox = new THREE.MeshBasicMaterial({ color: 'skyblue' });

  // for (let j = 0; j < 100; j += 1) {
  for (let i = 0; i < listnodes.length; i += 1) {
    let node = {};
    // const support = listsupports.find(item => item.name === listnodes[i].support);
    // console.log(support);
    // if (support.characteristics.dispX.value === 0
    // && support.characteristics.dispY.value === 0
    // && support.characteristics.dispZ.value === 0
    // && support.characteristics.rotX.value === 0
    // && support.characteristics.rotY.value === 0
    // && support.characteristics.rotZ.value === 0
    // ) {
    //   node = new THREE.Mesh(boxge, materialbox);
    // } else {
    //   node = new THREE.Mesh(spherege, materialsphere);
    // }
    // node = new THREE.Mesh(spherege, materialsphere);
    for (let j = 0; j < listsupports.length; j += 1) {
      if (listsupports[j].name === listnodes[i].support) {
        const support = listsupports[j].characteristics;
        if ((support.dispX.value === false || support.dispX.value === 0)
          && (support.dispY.value === false || support.dispY.value === 0)
          && (support.dispZ.value === false || support.dispZ.value === 0)
          && (support.rotX.value === false || support.rotX.value === 0)
          && (support.rotY.value === false || support.rotY.value === 0)
          && (support.rotZ.value === false || support.rotZ.value === 0)
        ) {
          const boxge = new THREE.BoxGeometry(0.2, 0.2, 0.2);
          const materialbox = new THREE.MeshBasicMaterial({ color: 'skyblue' });
          node = new THREE.Mesh(boxge, materialbox);
          node.position.set(Number(listnodes[i].X), Number(listnodes[i].Y), Number(listnodes[i].Z));
          node.userData = {
            data: listnodes[i],
            type: 'node',
            color: 'skyblue'
          };
          nodeMesh.push(node);
          scene.add(node);
        } else if ((support.dispX.value === true || support.dispX.value === 1)
          && (support.dispY.value === true || support.dispY.value === 1)
          && (support.dispZ.value === true || support.dispZ.value === 1)
          && (support.rotX.value === true || support.rotX.value === 1)
          && (support.rotY.value === true || support.rotY.value === 1)
          && (support.rotZ.value === true || support.rotZ.value === 1)) {
          if (uiManagement.showNode) {
            const spherege = new THREE.SphereGeometry(0.1, 32, 32);
            const materialsphere = new THREE.MeshBasicMaterial({ color: 'blue' });
            node = new THREE.Mesh(spherege, materialsphere);
            node.position.set(Number(listnodes[i].X), Number(listnodes[i].Y), Number(listnodes[i].Z));
            node.userData = {
              data: listnodes[i],
              type: 'node',
              color: 'blue'
            };
            nodeMesh.push(node);
            scene.add(node);
          }
        } else {
          const boxge = new THREE.ConeGeometry(0.2, 0.3, 32);
          const materialbox = new THREE.MeshBasicMaterial({ color: 'skyblue' });
          node = new THREE.Mesh(boxge, materialbox);
          node.rotation.set(0, Math.PI / 2, Math.PI / 2);
          node.position.set(Number(listnodes[i].X), Number(listnodes[i].Y), Number(listnodes[i].Z) - 0.15);
          node.userData = {
            data: listnodes[i],
            type: 'node',
            color: 'skyblue'
          };
          nodeMesh.push(node);
          scene.add(node);
        }
      }
    }
    if (uiManagement.showNode) {
      const labelData = {
        scene,
        mesh: labelMesh,
        type: 'node',
        object: listnodes[i],
        text: listnodes[i].name,
        x: Number(listnodes[i].X),
        y: Number(listnodes[i].Y),
        z: Number(listnodes[i].Z)
      };
      showLabel(labelData);
    }
  }

  return {
    mesh: nodeMesh
    // update
  };
};
