const optionsDensityUnits = [
  {
    label: 'kg/m³',
    value: 'kgOnM3'
  },
  {
    label: 't/m³',
    value: 'tOnM3'
  }
];

export default optionsDensityUnits;
