import React, { Component } from 'react';
import {
  CardDeck,
  Container,
  Breadcrumb,
  BreadcrumbItem,
  Col,
  Row,
  Input
} from 'reactstrap';
import { Link, withRouter } from 'react-router-dom';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Title from '../../../../components/UIComponents/Title';
import CardProject from '../../../../components/UIComponents/Card/CardProject';
import AddProjectSvg from './img/AddProject.svg';
import Structure3DSvg from '../../img/bridge.svg';
import DefaultImgSvg from './img/structure.svg';
import { LoadableConcreteSection } from '../../../ConcreteSection';
import DeleteProjectModal from '../../../../components/UIComponents/DeleteProjectModal';
import Loader from '../../../../components/UIComponents/Loader';
import { openModal } from '../../../../components/UIComponents/Modal/actions';
import SearchIconSvg from './img/SearchIcon.svg';
import {
  fetchProjects,
  deleteProject
} from './actions';


class Projects extends Component {
  constructor(props) {
    super(props);
    this.selectProjectId = this.selectProjectId.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.state = {
      projectId: '',
      filteredProjectList: []
    };
  }
  
  componentDidMount() {
    const { fetchProjects, user } = this.props;
    const userId = user._id;
    fetchProjects(userId);
  }

  componentWillReceiveProps(nextProps) {
    this.setState({
      filteredProjectList: nextProps.projects
    });
  }
  

  selectProjectId = (id) => {
    this.setState({ projectId: id });
  }

  handleChange(e) {
    const {
      projects
    } = this.props;
    let currentProjectList = [];
    let newProjectList = [];
    if (e.target.value !== '') {
      currentProjectList = projects;
      newProjectList = currentProjectList.filter((project) => {
        const projectText = `
        ${project.options.projectname.value} 
        `;
        // const lc = app.description.toLowerCase();
        const filter = e.target.value.toLowerCase();

        return projectText.toLowerCase().includes(filter);
      });
    } else {
      newProjectList = projects;
    }
    this.setState({
      filteredProjectList: newProjectList
    });
  }

  render() {
    const { 
      projects,
      deleteProject,
      openModal,
      user,
      loading
    } = this.props;

    const {
      projectId,
      filteredProjectList
    } = this.state;

    console.log('PROJECTS', filteredProjectList)
    return (
      <LoadableConcreteSection>
        <Title
          title="Structure 3D Projects"
          imgsrc={Structure3DSvg}
          description="Create or Select a Structure 3D Project"
          documentation={{
            link:"https://www.triazur.com/documentation/en/structure3d/getting-started/",
            text:"documentation"
          }}
        />
        <Breadcrumb>
          <BreadcrumbItem><Link to="/dashboard">Dashboard</Link></BreadcrumbItem>
          <BreadcrumbItem active>Structure 3D Projects</BreadcrumbItem>
        </Breadcrumb>
        <Col md={12} className="projects">
          {
            loading
              ? <Loader className="loaderProjects" />
              : <div> 
              <Row>
                <Input
                  className="searchApp"
                  type="text"
                  id="mySearch"
                  style={{backgroundImage: `url(${SearchIconSvg})`}}
                  // onKeyUp={searchapp}
                  onChange={this.handleChange}
                  placeholder="Search for project.."
                />
              </Row>
              <Row>
                <CardProject
                  colnum={3}
                  imgsrc={AddProjectSvg}
                  link="/structure3D/projects/new"
                  title="Add project"
                  subtitle="Structure 3D"
                  project=""
                />
                {
                  filteredProjectList.map((project, index) => {
                    return (
                      <CardProject
                        key={index}
                        colnum={3}
                        imgsrc={(!project.options.image) ? DefaultImgSvg : project.options.image}
                        title={project.options.projectname.value}
                        subtitle="Structure 3D"
                        link={`/structure3D/projects/${project._id}`}
                        project={project}
                        openModal={openModal}
                        selectProjectId={this.selectProjectId}
                      />
                    );
                  })
                }          
            </Row>
            </div>
            }
        </Col>
        <div className="deletProjectModal">
          <DeleteProjectModal
            openModal={openModal}
            deleteProject={deleteProject}
            projectId={projectId}
            userId={user._id}
          />
        </div>
      </LoadableConcreteSection>
    );
  }
}

const mapStateToProps = (state) => {
  const userInfo = localStorage.getItem('user');
  return {
    user: userInfo ? JSON.parse(userInfo) : {},
    projects: state.structure3DProjects.projects,
    loading: state.structure3DProjects.loading
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({
    fetchProjects,
    deleteProject,
    openModal
  }, dispatch);
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withRouter
)(Projects);
