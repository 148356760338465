const sectionData = {
  S: (secext, sectint) => {
    let areaExt = 0;
    for (let i = 0; i < secext.length - 1; i += 1) {
      const areai = 0.5 * (secext[i + 1].y - secext[i].y) * (secext[i + 1].x + secext[i].x);
      areaExt += areai;
    }
    let areaInt = 0;
    for (let i = 0; i < sectint.length - 1; i += 1) {
      const areai = 0.5 * (sectint[i + 1].y - sectint[i].y) * (sectint[i + 1].x + sectint[i].x);
      areaInt += areai;
    }
    let area = areaExt - areaInt;
    area = Math.round(area * 1000) / 1000;
    return area;
  },
  P: (secext, sectint) => {
    let pExt = 0;
    for (let i = 0; i < secext.length - 1; i += 1) {
      const pi = (((secext[i + 1].x - secext[i].x) ** 2) + ((secext[i + 1].y - secext[i].y) ** 2)) ** 0.5;
      pExt += pi;
    }
    let pInt = 0;
    for (let i = 0; i < sectint.length - 1; i += 1) {
      const pi = (((sectint[i + 1].x - sectint[i].x) ** 2) + ((sectint[i + 1].y - sectint[i].y) ** 2)) ** 0.5;
      pInt += pi;
    }
    pExt = Math.round(pExt * 1000) / 1000;
    pInt = Math.round(pInt * 1000) / 1000;
    return {
      pExt,
      pInt
    };
  },
  Xg: (secext, sectint, area) => {
    let xgExt = 0;
    for (let i = 0; i < secext.length - 1; i += 1) {
      const xgi = (secext[i + 1].y - secext[i].y) * ((secext[i + 1].x ** 2) + secext[i + 1].x * secext[i].x + (secext[i].x ** 2));
      xgExt += xgi;
    }
    let xgInt = 0;
    for (let i = 0; i < sectint.length - 1; i += 1) {
      const xgi = (sectint[i + 1].y - sectint[i].y) * ((sectint[i + 1].x ** 2) + sectint[i + 1].x * sectint[i].x + (sectint[i].x ** 2));
      xgInt += xgi;
    }
    let xg = 0;
    if (area !== 0) {
      xg = (xgExt - xgInt) / (6 * area);
    }
    xg = Math.round(xg * 1000) / 1000;
    return xg;
  },
  Yg: (secext, sectint, area) => {
    let ygExt = 0;
    for (let i = 0; i < secext.length - 1; i += 1) {
      const ygi = (secext[i + 1].x - secext[i].x) * ((secext[i + 1].y ** 2) + secext[i + 1].y * secext[i].y + (secext[i].y ** 2));
      ygExt += ygi;
    }
    let ygInt = 0;
    for (let i = 0; i < sectint.length - 1; i += 1) {
      const ygi = (sectint[i + 1].x - sectint[i].x) * ((sectint[i + 1].y ** 2) + sectint[i + 1].y * sectint[i].y + (sectint[i].y ** 2));
      ygInt += ygi;
    }
    let yg = 0;
    if (area !== 0) {
      yg = -(ygExt - ygInt) / (6 * area);
    }
    yg = Math.round(yg * 1000) / 1000;
    return yg;
  },
  Ixx: (secext, sectint, area, yg) => {
    let IxxOExt = 0;
    for (let i = 0; i < secext.length - 1; i += 1) {
      const IxxOi = (secext[i + 1].x - secext[i].x) * ((secext[i + 1].y ** 3) + (secext[i + 1].y ** 2) * secext[i].y + secext[i + 1].y * (secext[i].y ** 2) + (secext[i].y ** 3));
      IxxOExt += IxxOi;
    }
    let IxxOInt = 0;
    for (let i = 0; i < sectint.length - 1; i += 1) {
      const IxxOi = (sectint[i + 1].x - sectint[i].x) * ((sectint[i + 1].y ** 3) + (sectint[i + 1].y ** 2) * sectint[i].y + sectint[i + 1].y * (sectint[i].y ** 2) + (sectint[i].y ** 3));
      IxxOInt += IxxOi;
    }
    let IxxO = (IxxOExt - IxxOInt) * (-1 / 12);
    let IxxG = IxxO - area * (yg ** 2);

    IxxO = Math.round(IxxO * 1000) / 1000;
    IxxG = Math.round(IxxG * 1000) / 1000;

    return {
      IxxO,
      IxxG
    };
  },
  Iyy: (secext, sectint, area, xg) => {
    let IyyOExt = 0;
    for (let i = 0; i < secext.length - 1; i += 1) {
      const IyyOi = (secext[i + 1].y - secext[i].y) * ((secext[i + 1].x ** 3) + (secext[i + 1].x ** 2) * secext[i].x + secext[i + 1].x * (secext[i].x ** 2) + (secext[i].x ** 3));
      IyyOExt += IyyOi;
    }
    let IyyOInt = 0;
    for (let i = 0; i < sectint.length - 1; i += 1) {
      const IyyOi = (sectint[i + 1].y - sectint[i].y) * ((sectint[i + 1].x ** 3) + (sectint[i + 1].x ** 2) * sectint[i].x + sectint[i + 1].x * (sectint[i].x ** 2) + (sectint[i].x ** 3));
      IyyOInt += IyyOi;
    }
    let IyyO = (IyyOExt - IyyOInt) * (1 / 12);
    let IyyG = IyyO - area * (xg ** 2);

    IyyO = Math.round(IyyO * 1000) / 1000;
    IyyG = Math.round(IyyG * 1000) / 1000;

    return {
      IyyO,
      IyyG
    };
  },
  Ixy: (secext, sectint, area, xg, yg) => {
    let IxyOExt = 0;
    for (let i = 0; i < secext.length - 1; i += 1) {
      const IxyOi = (secext[i + 1].x * secext[i + 1].y) * (secext[i + 1].x * secext[i + 1].y + secext[i + 1].x * secext[i].y + (secext[i + 1].x + secext[i + 1].x) * (secext[i + 1].y + secext[i].y));
      IxyOExt += IxyOi;
    }
    let IxyOInt = 0;
    for (let i = 0; i < sectint.length - 1; i += 1) {
      const IxyOi = (sectint[i + 1].x * sectint[i + 1].y) * (sectint[i + 1].x * sectint[i + 1].y + sectint[i + 1].x * sectint[i].y + (sectint[i + 1].x + sectint[i + 1].x) * (sectint[i + 1].y + sectint[i].y));
      IxyOInt += IxyOi;
    }
    let IxyO = (IxyOExt - IxyOInt) * (1 / 24);
    let IxyG = IxyO - area * xg * yg;

    IxyO = Math.round(IxyO * 1000) / 1000;
    IxyG = Math.round(IxyG * 1000) / 1000;

    return {
      IxyO,
      IxyG
    };
  },
  SteelSection: {
    xg: () => {
      return 0;
    },

    yg: (inputs) => {
      const h = inputs.h.value;
      const c = inputs.c.value;
      return (h - 2 * c) / 2;
    },

    A: (inputs, sectiontype) => {
      const tf = inputs.tf.value;
      const c = inputs.c.value;
      const b = inputs.b.value;
      const h = inputs.h.value;
      const tw = inputs.tw.value;
      switch (sectiontype) {
        case 'iSectionSteel':
          return 2 * (tf - 2 * c) * (b - 2 * c) + (h - 2 * (tf - c)) * (tw - 2 * c);
        case 'boxSection':
          return 2 * (tf - 2 * c) * (b - 2 * c) + 2 * (h - 2 * (tf - c)) * (tw - 2 * c);
        default:
          return 0;
      }
    },

    Av: (inputs, sectiontype) => {
      const tf = inputs.tf.value;
      const c = inputs.c.value;
      const b = inputs.b.value;
      const h = inputs.h.value;
      const tw = inputs.tw.value;
      switch (sectiontype) {
        case 'iSectionSteel':
          return 2 * (tf - 2 * c) * (b - 2 * c) + (h - 2 * (tf - c)) * (tw - 2 * c) - 2 * (b - 2 * c) * (tf - 2 * c);
        case 'boxSection':
          return 2 * (tf - 2 * c) * (b - 2 * c) + 2 * (h - 2 * (tf - c)) * (tw - 2 * c) - 2 * (b - 2 * c) * (tf - 2 * c);
        default:
          return 0;
      }
    },

    Ix: (inputs, sectiontype) => {
      const tf = inputs.tf.value;
      const c = inputs.c.value;
      const b = inputs.b.value;
      const h = inputs.h.value;
      const tw = inputs.tw.value;
      switch (sectiontype) {
        case 'iSectionSteel':
          return ((b - 2 * c) * ((h - 2 * c) ** 3) - (b - tw) * ((h - 2 * (tf - c)) ** 3)) / 12;
        case 'boxSection':
          return (b - 2 * c) * ((h - 2 * c) ** 3) / 12 - (b - 2 * (tw - c)) * ((h - 2 * (tf - c)) ** 3) / 12;
        default:
          return 0;
      }
    },

    Iy: (inputs, sectiontype) => {
      const tf = inputs.tf.value;
      const c = inputs.c.value;
      const b = inputs.b.value;
      const h = inputs.h.value;
      const tw = inputs.tw.value;
      switch (sectiontype) {
        case 'iSectionSteel':
          return (2 * (tf - 2 * c) * ((b - 2 * c) ** 3) + ((tw - 2 * c) ** 3) * (h - 2 * (tf - c))) / 12;
        case 'boxSection': {
          const d = inputs.d.value;
          return (tf - 2 * c) * ((b - 2 * c) ** 3) / 6 + 2 * ((h - 2 * (tf - c)) * ((tw - 2 * c) ** 3) + (h - 2 * (tf - c)) * (tw - 2 * c) * ((d / 2 + tw / 2) ** 2));
        }
        default:
          return 0;
      }
    },

    It: (inputs, sectiontype) => {
      const tf = inputs.tf.value;
      const c = inputs.c.value;
      const b = inputs.b.value;
      const h = inputs.h.value;
      const tw = inputs.tw.value;
      switch (sectiontype) {
        case 'iSectionSteel':
          return (2 * (b - 2 * c) * ((tf - 2 * c) ** 3) + ((tw - 2 * c) ** 3) * (h - 2 * (tf - c))) / 3;
        case 'boxSection': {
          const d = inputs.d.value;
          return 2 * (tf - 2 * c) * (tw - 2 * c) * ((d + tw) ** 2) * ((h - tf) ** 2) / ((tw - 2 * c) * (d + tw) + (tf - 2 * c) * (h - tf));
        }
        default:
          return 0;
      }
    },

    Iw: (inputs, sectiontype) => {
      const tf = inputs.tf.value;
      const c = inputs.c.value;
      const b = inputs.b.value;
      const h = inputs.h.value;
      const tw = inputs.tw.value;
      switch (sectiontype) {
        case 'iSectionSteel':
          return (2 * (tf - 2 * c) * ((b - 2 * c) ** 3) + ((tw - 2 * c) ** 3) * (h - 2 * (tf - c))) / 48 * ((h - tf) ** 2);
        case 'boxSection': {
          const d = inputs.d.value;
          return (tf - 2 * c) * ((b - 2 * c) ** 3) * ((h - tf) ** 2) / 24 + (tw - 2 * c) * ((d + tw) ** 2) * ((h - 2 * (tf - c)) ** 3) / 24;
        }
        default:
          return 0;
      }
    },

    Welx: (inputs, sectiontype) => {
      const tf = inputs.tf.value;
      const c = inputs.c.value;
      const b = inputs.b.value;
      const h = inputs.h.value;
      const tw = inputs.tw.value;
      switch (sectiontype) {
        case 'iSectionSteel':
          return ((b - 2 * c) * ((h - 2 * c) ** 3) - (b - tw) * ((h - 2 * (tf - c)) ** 3)) / (6 * (h - 2 * c));
        case 'boxSection':
          return 2 * ((b - 2 * c) * ((h - 2 * c) ** 3) / 12 - (b - 2 * (tw - c)) * ((h - 2 * (tf - c)) ** 3) / 12) / (h - 2 * c);
        default:
          return 0;
      }
    },

    Wely: (inputs, sectiontype) => {
      const tf = inputs.tf.value;
      const c = inputs.c.value;
      const b = inputs.b.value;
      const h = inputs.h.value;
      const tw = inputs.tw.value;
      switch (sectiontype) {
        case 'iSectionSteel':
          return (2 * (tf - 2 * c) * ((b - 2 * c) ** 3) + ((tw - 2 * c) ** 3) * (h - 2 * (tf - c))) / (6 * (b - 2 * c));
        case 'boxSection': {
          const d = inputs.d.value;
          return 2 * ((tf - 2 * c) * ((b - 2 * c) ** 3) / 6 + 2 * ((h - 2 * (tf - c)) * ((tw - 2 * c) ** 3) + (h - 2 * (tf - c)) * (tw - 2 * c) * ((d / 2 + tw / 2) ** 2))) / (b - 2 * c);
        }
        default:
          return 0;
      }
    },

    Wplx: (inputs, sectiontype) => {
      const tf = inputs.tf.value;
      const c = inputs.c.value;
      const b = inputs.b.value;
      const h = inputs.h.value;
      const tw = inputs.tw.value;
      switch (sectiontype) {
        case 'iSectionSteel':
          return ((tw - 2 * c) * ((h - 2 * c) ** 2)) / 4 + (tf - 2 * c) * (h - tf) * (b - tw);
        case 'boxSection':
          return (tw - 2 * c) * ((h - 2 * c) ** 2) / 2 + (tf - 2 * c) * (h - tf) * (b - 2 * (tw - c));
        default:
          return 0;
      }
    },

    Wply: (inputs, sectiontype) => {
      const tf = inputs.tf.value;
      const c = inputs.c.value;
      const b = inputs.b.value;
      const h = inputs.h.value;
      const tw = inputs.tw.value;
      switch (sectiontype) {
        case 'iSectionSteel':
          return ((b - 2 * c) ** 2) * (tf - 2 * c) / 2 + (h - 2 * (tf - c)) * ((tw - 2 * c) ** 2) / 4;
        case 'boxSection': {
          const d = inputs.d.value;
          return (tf - 2 * c) * ((b - 2 * c) ** 2) / 2 + (tw - 2 * c) * (h - 2 * (tf - c)) * (d + tw);
        }
        default:
          return 0;
      }
    }
  }
};

export default sectionData;
