import React from 'react';
import PropTypes from 'prop-types';
import InputsUnit from '../../../../../../../../../../../../components/UIComponents/Fields/InputsUnit2';
import optionsLengthUnits from '../../../../../../../../../../../../utils/units/optionsLengthUnits2';

const CircleSection = ({
  sectionType,
  sections,
  changeGeometry
}) => {
  return (
    <div>
      <InputsUnit
        label={sections.listSectionTypes.circleSection.inputs.D.text}
        inputName="sections.listSectionTypes.circleSection.inputs.D.value"
        selectList={optionsLengthUnits}
        selectName="sections.listSectionTypes.circleSection.inputs.D.unit"
        tooltipDescription={sections.listSectionTypes.circleSection.inputs.D.description}
        tooltipTarget={sections.listSectionTypes.circleSection.inputs.D.name}
        onChange={(e) => {
          const val = e.target.value;
          changeGeometry(sections.listSectionTypes.circleSection.inputs, sectionType, val, sections.listSectionTypes.circleSection.inputs.D.name);
        }}
      />
    </div>
  );
};

export default CircleSection;
