import React from 'react';
import PropTypes from 'prop-types';
import {
  Container,
  Col,
  Row,
  Table,
  Button
} from 'reactstrap';
import { FieldArray } from 'redux-form';
import { Scatter } from 'react-chartjs-2';
import InputField from '../../../../../../../../components/UIComponents/Fields/InputRF';
import chartOptions from './utils/chartOptions';
import chartData from './utils/chartData';

const renderCoordinateList = ({ fields, meta: { error, submitFailed } }) => (
  <React.Fragment>
    {fields.map((coordinate, index) => (
      <tr key={index}>
        <td className="tableTd">
          <InputField name={`${coordinate}.x`} type="text" />
          {submitFailed && error && <span>{error}</span>}
        </td>
        <td className="tableTd">
          <InputField name={`${coordinate}.y`} type="text" />
        </td>
        <td className="tableTd">
          <InputField name={`${coordinate}.number`} />
          {submitFailed && error && <span>{error}</span>}
        </td>
        <td className="tableTd">
          <InputField name={`${coordinate}.diameter`} type="text" />
        </td>
        <td className="tableTd">
          <i
            className="material-icons"
            onClick={() => fields.insert(index + 1, {
              x: 0,
              y: 0,
              number: 0,
              diameter: 0
            })}
          >
            { 'add_circle' }
          </i>
        </td>
        <td className="tableTd">
          {
            fields.length > 1 &&
            <i
              className="material-icons"
              onClick={() => fields.remove(index)}
            >
              { 'remove_circle' }
            </i>
          }
        </td>
      </tr>
    ))}
  </React.Fragment>
)

const Reinforcement = ({ reinforcement, concrete }) => (
  <Container className="reinforcement">
    <Row>
      <Col md={6}>
        <div className="chartContainer">
          <Scatter
            className="chart"
            width={400}
            height={400}
            data={chartData(concrete.sectionext, concrete.sectionint, reinforcement.geometry)}
            options={chartOptions(concrete.sectionext, reinforcement.unit, reinforcement.geometry)}
          />
        </div>
      </Col>
      <Col md={6}>
        <div id="scrollmenu">
          <Table responsive>
            <thead>
              <tr className="column">
                <th>{`x(${reinforcement.unit.text})`}</th>
                <th>{`y(${reinforcement.unit.text})`}</th>
                <th>number</th>
                <th>{`diameter(${reinforcement.unitDiameter.text})`}</th>
                <th>
                </th>
                <th>
                </th>
              </tr>
            </thead>
            <tbody>
              <FieldArray name="reinforcement.geometry" component={renderCoordinateList} />
            </tbody>
          </Table>
        </div>
        <Row />
      </Col>
    </Row>
  </Container>
);

Reinforcement.propTypes = {
  reinforcement: PropTypes.object.isRequired,
  concrete: PropTypes.object.isRequired,
};

export default Reinforcement;
