
import Loadable from 'react-loadable';
import Loader from '../../../../../../components/UIComponents/Loader';

const LoadableErrorMessage = Loadable({
  loader: () => import('./ErrorMessage'),
  loading: Loader
});

export default LoadableErrorMessage;
