import React, { Fragment } from 'react';
import {
  Card,
  CardHeader,
  CardBody,
  CardText,
  Button
} from 'reactstrap';
import Modal from '../Modal';

const GoToPricingModal = ({
  children,
  openModal,
  history,
  showOutputs
}) => (
  <div className={(!showOutputs) ? 'goToPricingModal' : ''}>
    {
      (!showOutputs)
        ? <Modal>
          <Card className="card">
            <CardHeader className="title">
              {'Upgrade your plan'}
            </CardHeader>
            <CardBody>
              <CardText className="subtitle">You've reach the limit of <strong>FREE plan</strong>:</CardText>
              <CardText className="subtitle">In order to perform the Analysis, you need to <strong>Upgrade your plan</strong>:</CardText>
              <Button
                className="positiveButton"
                onClick={async () => {
                  await openModal({ status: false });
                  history.push('/settings');
                }}
              >
                Go Pricing
              </Button>
            </CardBody>
          </Card>
        </Modal>
        : <Modal>
          {children}
        </Modal>
    }
  </div>
);

export default GoToPricingModal;
