import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
  Card,
  CardImg, CardText, CardBody,
  CardTitle, CardSubtitle,
  Button, Col,
  Media,
  Row
} from 'reactstrap';

const Icons = ({
  colnum,
  imgsrc,
  title,
  subtitle,
  description,
  link
}) => (
  <Card tag={Link} to={link} className="iconsComponent">
    <Row>
      <Col xs={12}>
        <div className="imgContainer">
          <CardImg top src={imgsrc} alt={title} />
        </div>
      </Col>
    </Row>
    <Row>
      <Col xs={12}>
        <div className="title">{title}</div>
      </Col>
    </Row>
  </Card>
);

export default Icons;
