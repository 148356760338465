const initialState = {
  inputs: {
    status: 'Loading data...',
    baselineStatus: 'Baseline not computed...',
    inspectionHeadlineElement: '',
    modelStatus: []
  }
};

export default initialState;
