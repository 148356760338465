import React from 'react';
import PropTypes from 'prop-types';
import {
  Container,
  Col,
  Row,
  InputGroup,
  InputGroupAddon,
  InputGroupText
} from 'reactstrap';
import { Scatter } from 'react-chartjs-2';
import SelectRF from '../../../../../../../../components/UIComponents/Fields/SelectRF';
import ToolTips from '../../../../../../../../components/UIComponents/ToolTips';
import chartOptions from './utils/chartOptions';
import chartData from './utils/chartData';
import InputsUnit from '../../../../../../../../components/UIComponents/Fields/InputsUnit2/';
import optionsStressUnits from '../../../../../../../../utils/units/optionsStressUnits2';


const Steel = ({
  steel
}) => {
  return (
    <Container className="steel">
      <Row>
        <Col md={6}>
          <div className="chartContainer">
            <Scatter
              className="chart"
              width={400}
              height={400}
              data={chartData(steel.fyk.value, steel.gammaM0.value, steel.Es.value)}
              options={chartOptions(steel.fyk)}
            />
          </div>
        </Col>
        <Col md={6}>
          <h2 className="subtitle">Steel material:</h2>
          <InputsUnit
            label={steel.fyk.text}
            inputName="steel.fyk.value"
            selectList={optionsStressUnits}
            selectName="steel.fyk.unit.name"
            tooltipDescription={steel.fyk.description}
            tooltipTarget={steel.fyk.name}
          />
          <InputsUnit
            label={steel.Es.text}
            inputName="steel.Es.value"
            selectList={optionsStressUnits}
            selectName="steel.Es.unit.name"
            tooltipDescription={steel.Es.description}
            tooltipTarget={steel.Es.name}
          />
          <InputsUnit
            label={steel.G.text}
            inputName="steel.G.value"
            selectList={optionsStressUnits}
            selectName="steel.G.unit.name"
            tooltipDescription={steel.G.description}
            tooltipTarget={steel.G.name}
          />
          <InputsUnit
            label={steel.gammaM0.text}
            inputName="steel.gammaM0.value"
            tooltipDescription={steel.gammaM0.description}
            tooltipTarget={steel.gammaM0.name}
          />
          <InputsUnit
            label={steel.gammaM1.text}
            inputName="steel.gammaM1.value"
            tooltipDescription={steel.gammaM1.description}
            tooltipTarget={steel.gammaM1.name}
          />
        </Col>
      </Row>
    </Container>
  );
};

export default Steel;
