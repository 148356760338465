import initialState from './initialState';
import * as types from './actionTypes';
import optionsUnits from './optionsUnits';

const unitsSystem = (state = initialState, action = {}) => {
  switch (action.type) {
    case types.CHANGE_UNITS_SYSTEM: {
      let indexUnitsSystem = 0;
      for (let i = 0; i < optionsUnits.length; i += 1) {
        if (action.unitsSystem === optionsUnits[i].label) {
          indexUnitsSystem = i;
        }
      }

      return {
        ...state,
        label: optionsUnits[indexUnitsSystem].label,
        value: optionsUnits[indexUnitsSystem].value
      };
    }
    default: {
      return state;
    }
  }
};

export default unitsSystem;
