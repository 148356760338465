import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import {
  Breadcrumb,
  BreadcrumbItem,
  Row,
  Col,
  Button,
  CardDeck
} from 'reactstrap';
import Inputs from './scenes/Inputs';
import { LoadableSteelSection } from '../../../SteelSection';
// import Modal from '../../../../components/UIComponents/Modal';

const sectionName = (section) => {
  switch (section) {
    case 'boxSection':
      return 'Box';
    case 'iSectionSteel':
      return 'I';
  }
};

const SteelSectionCalculation = ({ match: { params: { sectionType } } }) => (
  <LoadableSteelSection>
    <Breadcrumb>
      <BreadcrumbItem><Link to="/dashboard">Dashboard</Link></BreadcrumbItem>
      {/* <BreadcrumbItem><Link to="/steelsection/projects">Steel Section</Link></BreadcrumbItem>
      <BreadcrumbItem><Link to="/steelsection/sectiontypes">Section Types</Link></BreadcrumbItem> */}
      <BreadcrumbItem><Link to="/steelsection">Steel Section</Link></BreadcrumbItem>
      <BreadcrumbItem active>{sectionName(sectionType)} Steel Section</BreadcrumbItem>
    </Breadcrumb>
    <Row>
      <Col lg={12}>
        <Inputs />
      </Col>
    </Row>
    <br />
  </LoadableSteelSection>
);

export default SteelSectionCalculation;
