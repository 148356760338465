import {
  OPEN_SIDEBAR
} from './actionTypes';

export function openSideBar(isOpenSideBar) {
  return (dispatch) => {
    dispatch({
      type: OPEN_SIDEBAR,
      isOpenSideBar: !(isOpenSideBar)
    });
  };
}

export function logout() {
  return (dispatch) => {
    localStorage.removeItem('token');
    localStorage.removeItem('user');
    dispatch({
      type: 'AUTHENTICATION_FAILURE',
      isAuth: false
    });
  };
}
