import React from 'react';
import PropTypes from 'prop-types';
import InputCalc from '../../../../../../components/UIComponents/Fields/InputCalc';


const TSection = ({
  inputs,
  changeInputValue,
  sectionType,
  optionsLengthUnits
}) => {
  return (
    <div>
      <InputCalc
        input={inputs.b}
        inputs={inputs}
        field={sectionType}
        changeInput={changeInputValue}
        unitList={optionsLengthUnits}
      />
      <InputCalc
        input={inputs.h}
        inputs={inputs}
        field={sectionType}
        changeInput={changeInputValue}
        unitList={optionsLengthUnits}
      />
      <InputCalc
        input={inputs.b0}
        inputs={inputs}
        field={sectionType}
        changeInput={changeInputValue}
        unitList={optionsLengthUnits}
      />
      <InputCalc
        input={inputs.h0}
        inputs={inputs}
        field={sectionType}
        changeInput={changeInputValue}
        unitList={optionsLengthUnits}
      />
    </div>
  );
};

export default TSection;
