import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router';
import {
  Container,
  Breadcrumb,
  BreadcrumbItem,
  Card,
  CardBody,
  CardTitle,
  Row,
  Col,
  Label
} from 'reactstrap';

import { Scatter } from 'react-chartjs-2';
import chartOptions from './utils/chartOptions';
import chartData from './utils/chartData';

import { LoadableWaveCharacteristics } from '../../../WaveCharacteristics';
import InputCalc from '../../../../components/UIComponents/Fields/InputCalc';
import OutputCalc from '../../../../components/UIComponents/Fields/OutputCalc';
import optionsLengthUnits from '../../../../utils/units/optionsLengthUnits2';
import optionsTimeUnits from '../../../../utils/units/optionsTimeUnits2';
import {
  changeInputValue
} from '../../actions';


const WaveCharacteristicsCalculation = ({
  inputs,
  outputs,
  changeInputValue
}) => {
  return (
    <LoadableWaveCharacteristics>
      <Container className="waveCharacteristics">
        <Breadcrumb>
          <BreadcrumbItem><Link to="/dashboard">Dashboard</Link></BreadcrumbItem>
          <BreadcrumbItem active>Wave Characteristics</BreadcrumbItem>
        </Breadcrumb>
        <Card className="card">
          <CardBody className="header">
            <CardTitle className="title">
              { 'Wave Characteristics' }
            </CardTitle>
          </CardBody>
          <CardBody>
            <Row>
              <Col md={6}>
                <h2 className="subtitle">Inputs</h2>
                <InputCalc
                  input={inputs.H}
                  inputs={inputs}
                  field="wave"
                  changeInput={changeInputValue}
                  unitList={optionsLengthUnits}
                />
                <InputCalc
                  input={inputs.d}
                  inputs={inputs}
                  field="wave"
                  changeInput={changeInputValue}
                  unitList={optionsLengthUnits}
                />
                <InputCalc
                  input={inputs.T}
                  inputs={inputs}
                  field="wave"
                  changeInput={changeInputValue}
                  unitList={optionsTimeUnits}
                />
              </Col>
              <Col md={6}>
                <h2 className="subtitle">Outputs</h2>
                <OutputCalc
                  input={outputs.L}
                  // val={L}
                  unitList={optionsLengthUnits}
                />
                <br />
                <Row>
                  <Label>
                    { 'Water Elevation with ' }
                    <a
                      rel="noopener noreferrer"
                      href="https://wikiwaves.org/Linear_Theory_of_Ocean_Surface_Waves" 
                      target="_blank"
                    >
                      { 'linear theory' }
                    </a>
                    { '(linear theory is applicable in deep water: d >> L):' }
                  </Label>
                </Row>
                <br />
                <div className="chartContainer">
                  <Scatter
                    className="chart"
                    data={chartData(inputs, outputs.waterLevel.value)}
                    options={chartOptions(inputs, outputs.waterLevel.value, outputs.L.value)}
                  />
                </div>
              </Col>
            </Row>
          </CardBody>
        </Card>
      </Container>
    </LoadableWaveCharacteristics>
  );
};

const mapStateToProps = (state) => {
  return {
    inputs: state.waveCharacteristics.inputs,
    outputs: state.waveCharacteristics.outputs
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({
    changeInputValue
  }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(WaveCharacteristicsCalculation);
