import React from 'react';
import CardProject from '../../../../components/UIComponents/Card/CardProject';
import ProjectScreenshotPng from './img/ProjectScreenshot.png';
import { LoadableDashboard } from '../../../Dashboard';
import TriAzurIcon from './img/TriAzurIcon.png';
import Title from '../../../../components/UIComponents/Title';

const Projects = (
  projectState
) => (
  <LoadableDashboard>
    <div className="projects">
      <Title
        title="Projects Hub"
        imgsrc={TriAzurIcon}
        description="Your hub for all your applications"
      />
      <CardProject
        colnum={3}
        imgsrc={ProjectScreenshotPng}
        title="Nom du Project"
        subtitle="Concrete Section"
        link="/concretesection/nomduproject"
      />
    </div>
  </LoadableDashboard>
);

export default Projects;
