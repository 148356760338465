import createGlobalAxis from '../../../../../components/GraphicModules/GlobalAxis';
import createGrid from '../../../../../components/GraphicModules/Grid';
import createNode from '../../../../../components/GraphicModules/Nodes';
import createMember from '../../../../../components/GraphicModules/Members';
import createNodeLoad from '../../../../../components/GraphicModules/NodeLoads';
import createMemberLoad from '../../../../../components/GraphicModules/MemberLoads';
import createMemberSollicitation from '../../../../../components/GraphicModules/MemberSollicitations';

const createElements = {
  globalAxis: (options) => {
    const globalAxis = createGlobalAxis('globalAxis', options);
    return globalAxis;
  },
  grid: (options) => {
    const grid = createGrid('grid', options);
    return grid;
  },
  nodes: (options) => {
    const nodes = createNode('nodes', options);
    return nodes;
  },
  members: (options) => {
    const members = createMember('members', options);
    return members;
  },
  nodeLoads: (options) => {
    const nodeLoads = createNodeLoad('nodeLoads', options);
    return nodeLoads;
  },
  memberLoads: (options) => {
    const memberLoads = createMemberLoad('memberLoads', options);
    return memberLoads;
  },
  memberSollicitations: (options) => {
    const memberSollicitations = createMemberSollicitation('memberSollicitations', options);
    return memberSollicitations;
  }
};

export default createElements;
