import React, { Component } from 'react';
import {
  Card,
  CardHeader,
  CardBody,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Button,
  CardTitle,
  CardText,
  Row,
  Col,
  Container
} from 'reactstrap';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router';
import HorizontalScrollMenu from '../../../../../../components/UIComponents/Bar/HorizontalScrollMenu';
import Options from './components/Options';
import Geometry from './scenes/Geometry';
// import SimpleGeometry from './scenes/SimpleGeometry';
import Concrete from './scenes/Concrete';
import Steel from './scenes/Steel';
import Reinforcement from './scenes/Reinforcement';
import Loads from './scenes/Loads';
import Analysis from './scenes/Analysis';
import { compose } from 'recompose';
import { reduxForm } from 'redux-form';
import InputField from '../../../../../../components/UIComponents/Fields/InputRF';
import SelectRF from '../../../../../../components/UIComponents/Fields/SelectRF';
import optionsUnitsSystem from '../../../../../../utils/units/optionsUnitsSystem';
import Outputs from '../Outputs';
import GoToPricingModal from '../../../../../../components/UIComponents/GoToPricingModal';
import { openModal } from '../../../../../../components/UIComponents/Modal/actions';
import {
  changeGeometry,
  fetchSectionData,
  changeGlobalUnits,
  postSectionData,
  postEc2Sls
} from '../../actions';
import validate from '../../validations/validate';

import './styles/Inputs.css';

const itemScrollMenu = [
  {
    num: '1',
    icon: 'grid_on',
    title: 'Geometry'
  },
  {
    num: '2',
    icon: 'show_chart',
    title: 'Concrete'
  },
  {
    num: '3',
    icon: 'timeline',
    title: 'Steel'
  },
  {
    num: '4',
    icon: 'settings_input_svideo',
    title: 'Reinforcement'
  },
  {
    num: '5',
    icon: 'vertical_align_bottom',
    title: 'Loads'
  }
];

class Inputs extends React.Component {
  constructor(props) {
    super(props);
    this.toggle = this.toggle.bind(this);
    this.submitValues = this.submitValues.bind(this);
    this.state = {
      activeTab: '1',
      showOutputs: true
    };
  }

  componentDidMount() {
    const { fetchSectionData, match, concreteSection } = this.props;
    fetchSectionData(match.params.sectionType, concreteSection.geometry);
  }

  toggle(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab
      });
    }
  }

  submitValues(values) {
    const { postEc2Sls, openModal, match: { params: { sectionType } } } = this.props;
    const user = JSON.parse(localStorage.getItem('user'));
    if (!user.subscriptionId && user.calculationCounter > 4) {
      this.setState({ showOutputs: false });
      openModal({ status: true });
    } else {
      this.setState({ showOutputs: true });
      postEc2Sls(values, user);
    }
  }

  render() {
    const {
      concreteSection,
      changeGeometry,
      concreteSection: {
        concrete,
        steel,
        loads,
        geometry,
        reinforcement
      },
      changeGlobalUnits,
      sectionType,
      handleSubmit,
      history,
      openModal
    } = this.props;
    const {
      activeTab,
      showOutputs
    } = this.state;

    return (
      <div>
        <form onSubmit={handleSubmit(this.submitValues)}>
          <Card id="card">
            <CardHeader id="headerinputs">
              {'Inputs Concrete Section (EN 1992-1-1)'}
            </CardHeader>
            <Options concreteSection={concreteSection} sectionType={sectionType} />
            <HorizontalScrollMenu
              toggle={this.toggle}
              activeTab={activeTab}
              itemScrollMenu={itemScrollMenu}
            />
            <TabContent
              id="sectionsDataOptions"
              activeTab={activeTab}
            >
              <br />
              <TabPane tabId="1">
                {activeTab === "1" &&
                  <React.Fragment>
                    <Geometry sectionType={sectionType} geometry={geometry} changeGeometry={changeGeometry} />
                  </React.Fragment>
                }
              </TabPane>
              <TabPane tabId="2">
                {activeTab === "2" &&
                  <Concrete concrete={concrete} />
                }
              </TabPane>
              <TabPane tabId="3">
                {activeTab === "3" && <Steel steel={steel} />}
              </TabPane>
              <TabPane tabId="4">
                {activeTab === "4" &&
                  <Reinforcement reinforcement={reinforcement} concrete={geometry.concrete} />
                }
              </TabPane>
              <TabPane tabId="5">
                {activeTab === "5" &&
                  <Loads loads={loads} />
                }
              </TabPane>
            </TabContent>
          </Card>
        </form>
        <GoToPricingModal
          openModal={openModal}
          showOutputs={showOutputs}
          history={history}
        >
          <Outputs />
        </GoToPricingModal>
      </div>
    );
  }
}

const mapStateToProps = (state, props) => {
  const { sectionType } = props.match.params;
  console.log('INPUTS', state);
  return {
    form: `concreteSection.${sectionType}`,
    concreteSection: state.form.concreteSection[sectionType].values,
    sectionType
    // initialValues: state.form.randomconcretesection.values,
    // oldFyk: state.randomconcretesection.values.steel.fyk,
    // gammas: state.randomconcretesection.inputs.steel.gammas,
    // Es: state.randomconcretesection.inputs.steel.Es,
    // ductilityClass: state.randomconcretesection.inputs.steel.ductilityClass,
    // unitStress: state.unitsSystem.value.unitStress
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({
    changeGeometry,
    fetchSectionData,
    changeGlobalUnits,
    postSectionData,
    postEc2Sls,
    openModal
  }, dispatch);
};

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
  reduxForm({
    destroyOnUnmount: false,
    asyncBlurFields: [],
    validate
  }),
)(Inputs);
