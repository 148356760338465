import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  Row,
  Col,
  Label,
  Card
} from 'reactstrap';
import CardApp from '../../../../../../components/UIComponents/Card/CardApp';
import Modal from '../../../../../../components/UIComponents/Modal';
import TriAzurIcon from '../../img/TriAzurIcon.png';
import EiffageIcon from '../../img/EiffageIcon.jpg';
// import ApplicationRestrictions from '../../../../../../components/HOC/ApplicationAuthorization';
import Structure3DSvg from './img/Structure3D.svg';
import ConcreteSectionSvg from './img/ConcreteSection.svg';
import SteelSectionSvg from './img/SteelSection.svg';
import SectionGeometrySvg from './img/SectionGeometry.svg';
import StructuralAnalysisSvg from './img/StructuralAnalysis1.svg';
import { showVideo } from '../../../../actions';

const { REACT_APP_COMPANY } = process.env;

const applicationsList = [
  {
    imgsrc: Structure3DSvg,
    title: 'Structure 3D',
    category: 'Structure',
    description: 'Structure 3D allows you to analyse, the stress and displacements of a 3D structure subjected to external forces',
    link: '/structure3d',
    status: 'Beta',
    logoCompany: TriAzurIcon,
    company: 'TriAzur',
    video: 'https://www.youtube.com/watch?v=dEpGTg1ZSjU&list=PLnyZofGl5v2aDpxV0t7ks6T8oOAAEOUEn'
  },
  {
    imgsrc: SteelSectionSvg,
    title: 'Steel Section',
    category: 'Structure',
    description: 'Steel Section allows you to design steel sections with international codes (Eurocode 3, API, AISC,...).',
    link: '/dashboard',
    status: 'Coming Soon',
    logoCompany: TriAzurIcon,
    company: 'TriAzur',
    video: ''
  },
  {
    imgsrc: ConcreteSectionSvg,
    title: 'Concrete Section',
    category: 'Structure',
    description: 'Concrete Section allows you to design concrete sections with international codes (Eurocode 2, ACI, BAEL, ...).',
    link: '/concretesection',
    status: 'Beta',
    logoCompany: TriAzurIcon,
    company: 'TriAzur',
    video: ''
  },
  {
    imgsrc: SectionGeometrySvg,
    title: 'Section Geometry',
    category: 'Structure',
    description: 'Section Geometry allows you to get section data like area, center of gravity or inertia',
    link: '/sectiongeometry',
    status: 'New',
    logoCompany: TriAzurIcon,
    company: 'TriAzur',
    video: ''
  },
  {
    imgsrc: StructuralAnalysisSvg,
    title: 'Structural Analysis',
    category: 'Structure',
    description: 'Structural Analysis allows you to get reactions, moments and deflections for simple structure',
    link: '/structuralanalysis/beamformulas',
    status: 'New',
    logoCompany: TriAzurIcon,
    company: 'TriAzur',
    video: ''
  }
];

const StructureApp = ({
  appGroupTitle,
  colnum,
  showVideo
}) => (
  <div className="structureapp">
    <Row>
      <h4 className="appgrouptitle">{appGroupTitle}</h4>
    </Row>
    <Row>
      <CardApp
        colnum={colnum}
        imgsrc={Structure3DSvg}
        title="Structure 3D"
        subtitle={appGroupTitle}
        description="Structure 3D allows you to analyse, the stress and displacements of a 3D structure subjected to external forces"
        link="/structure3d/projects"
        status="Beta"
        logoCompany={TriAzurIcon}
        company="TriAzur"
      />
      <CardApp
        colnum={colnum}
        imgsrc={SteelSectionSvg}
        title="Steel Section"
        subtitle={appGroupTitle}
        description="Steel Section allows you to design steel sections with international codes (Eurocode 3, API, AISC,...)."
        link="/dashboard"
        status="Coming Soon"
        logoCompany={TriAzurIcon}
        company="TriAzur"
      />
      <CardApp
        colnum={colnum}
        imgsrc={ConcreteSectionSvg}
        title="Concrete Section"
        subtitle={appGroupTitle}
        description="Concrete Section allows you to design concrete sections with international codes (Eurocode 2, ACI, BAEL, ...)."
        link="/concretesection"
        status="Beta"
        logoCompany={TriAzurIcon}
        company="TriAzur"
      />
      { applicationsList.map((app, index) => (
        <CardApp
          key={index}
          colnum={colnum}
          imgsrc={app.imgsrc}
          title={app.title}
          subtitle={app.category}
          description={app.description}
          link={app.link}
          status={app.status}
          logoCompany={app.logoCompany}
          company={app.company}
          video={app.video}
          openModal={showVideo}
        />
      ))}
    </Row>
    <Row>
      {/* <CardApp
        colnum={colnum}
        imgsrc={SectionGeometrySvg}
        title="Structural Analysis"
        subtitle={appGroupTitle}
        description="Structural Analysis allows you to get reactions, moments and deflections for simple structure"
        link="/structuralanalysis/beamformulas"
        status="New"
        logoCompany={TriAzurIcon}
        company="TriAzur"
      /> */}
      { REACT_APP_COMPANY === 'Eiffage'
        ? <CardApp
          colnum={colnum}
          imgsrc={SectionGeometrySvg}
          title="Eiffage App"
          subtitle={appGroupTitle}
          description="Application spécificique Eiffage GC Marine à développer (ex: fiche de pieux, fenders,...)"
          link="/sectiongeometry"
          status="New"
          logoCompany={EiffageIcon}
          company="Eiffage GC Marine"
          video=""
        />
        : ''
      }
      <Modal>
        <Card>
          <iframe width="560" height="315" src="https://www.youtube.com/embed/dEpGTg1ZSjU" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen />
        </Card>
      </Modal>
      {/* <Modal>
        <Label>Hello</Label>
      </Modal>
      <ApplicationRestrictions applicationName="Structure">
        <CardApp
          colnum={colnum}
          imgsrc={Structure3DSvg}
          title="Structure 3D"
          subtitle={appGroupTitle}
          description="Structure 3D allows you to analyse, the stress and displacements of a 3D structure subjected to external forces"
          link="/dashboard"
          status="Coming Soon"
        />
      </ApplicationRestrictions>
      <ApplicationRestrictions applicationName="Steel">
        <CardApp
          colnum={colnum}
          imgsrc={SteelSectionSvg}
          title="Steel Section"
          subtitle={appGroupTitle}
          description="Steel Section allows you to design steel sections with international codes (Eurocode 3, API, AISC,...)."
          link="/dashboard"
          status="Coming Soon"
        />
      </ApplicationRestrictions>
      <ApplicationRestrictions applicationName="Concrete">
        <CardApp
          colnum={colnum}
          imgsrc={ConcreteSectionSvg}
          title="Concrete Section"
          subtitle={appGroupTitle}
          description="Concrete Section allows you to design concrete sections with international codes (Eurocode 2, ACI, BAEL, ...)."
          link="/concretesection"
          status="Beta"
        />
      </ApplicationRestrictions>
    </Row>
    <Row>
      <ApplicationRestrictions applicationName="Geometry">
        <CardApp
          colnum={colnum}
          imgsrc={SectionGeometrySvg}
          title="Section Geometry"
          subtitle={appGroupTitle}
          description="Section Geometry allows you to get section data like area, center of gravity or inertia"
          link="/sectiongeometry"
          status="New"
        />
      </ApplicationRestrictions> */}
    </Row>
  </div>
);

StructureApp.propTypes = {
  appGroupTitle: PropTypes.string.isRequired,
  colnum: PropTypes.number.isRequired
};

const mapStateToProps = (state) => {
  return {
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({
    showVideo
  }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(StructureApp);
