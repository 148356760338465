import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { withRouter } from 'react-router';
import {
  Container,
  Breadcrumb,
  BreadcrumbItem,
  Card,
  CardBody,
  CardTitle,
  Button
} from 'reactstrap';
import { LoadableSectionGeometry } from '../../../SectionGeometry';
import { exportToCSV } from '../../actions';
import Calculation from './scenes/Calculation';

const SectionGeometryCalculation = ({
  match: { params: { sectionType } }
}) => {
  const sectionName = (section) => {
    switch (section) {
      case 'rectangularSection':
        return 'Rectangular';
      case 'tSection':
        return 'T';
      case 'iSection':
        return 'I';
      case 'circleSection':
        return 'Circle';
      case 'hollowCircleSection':
        return 'Hollow Circle';
      case 'randomSection':
        return 'Random';
      default:
        return 'Random';
    }
  };
  return (
    <LoadableSectionGeometry>
      <Container className="sectionGeometry">
        <Breadcrumb>
          <BreadcrumbItem><Link to="/dashboard">Dashboard</Link></BreadcrumbItem>
          <BreadcrumbItem><Link to="/sectiongeometry/sectiontypes">Section Types</Link></BreadcrumbItem>
          <BreadcrumbItem active>{`${sectionName(sectionType)} Section Geometry`}</BreadcrumbItem>
        </Breadcrumb>
        <Calculation sectionName={sectionName(sectionType)} />
      </Container>
    </LoadableSectionGeometry>
  );
};

export default SectionGeometryCalculation;
