import React from 'react';
import { Link } from 'react-router-dom';
import {
  CardDeck,
  Breadcrumb,
  BreadcrumbItem
} from 'reactstrap';
import CardAddProject from '../../../../components/UIComponents/Card/CardAddProject';
import Title from '../../../../components/UIComponents/Title';
import SectionGeometrySvg from './img/SectionGeometry.svg';
import RectangularSectionGeometrySvg from './img/RectangularSectionGeometry.svg';
import TSectionGeometrySvg from './img/TSectionGeometry.svg';
import ISectionGeometrySvg from './img/ISectionGeometry.svg';
import CircleSectionGeometrySvg from './img/CircleSectionGeometry.svg';
import HollowcircleSectionGeometrySvg from './img/HollowCircleSectionGeometry.svg';
import RandomSectionGeometrySvg from './img/RandomSectionGeometry.svg';
import { LoadableSectionGeometry } from '../../../SectionGeometry';

const SectionTypes = () => (
  <LoadableSectionGeometry>
    <Title
      title="Section Geometry"
      imgsrc={SectionGeometrySvg}
      description="Characteristics of the most common sections"
    />
    <Breadcrumb>
      <BreadcrumbItem><Link to="/dashboard">Dashboard</Link></BreadcrumbItem>
      <BreadcrumbItem active>Section Types</BreadcrumbItem>
    </Breadcrumb>
    <CardDeck className="projects">
      <CardAddProject
        colnum={3}
        imgsrc={RectangularSectionGeometrySvg}
        link="/sectiongeometry/calculation/rectangularSection"
      />
      <CardAddProject
        colnum={3}
        imgsrc={TSectionGeometrySvg}
        link="/sectiongeometry/calculation/tSection"
      />
      <CardAddProject
        colnum={3}
        imgsrc={ISectionGeometrySvg}
        link="/sectiongeometry/calculation/iSection"
      />
      <CardAddProject
        colnum={3}
        imgsrc={CircleSectionGeometrySvg}
        link="/sectiongeometry/calculation/circleSection"
      />
      <CardAddProject
        colnum={3}
        imgsrc={HollowcircleSectionGeometrySvg}
        link="/sectiongeometry/calculation/hollowCircleSection"
      />
      {/* <CardAddProject
        colnum={3}
        imgsrc={RandomSectionGeometrySvg}
        link="/sectiongeometry/calculation/randomSection"
      /> */}
    </CardDeck>
  </LoadableSectionGeometry>
);

export default SectionTypes;
