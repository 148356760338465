import ec2functions from '../../../../../../../utils/ec2/ec2-functions';

const horizontalLaw = (fyk, gammaM0, Es) => {
  const fyd = ec2functions.fyd(fyk, gammaM0);
  return [
    { x: 0, y: 0 },
    { x: fyd / Es, y: fyd },
    { x: 0.01, y: fyd }
  ];
};

// const inclinedLaw = (fyk, gammas, Es, ductilityClass) => {
//   const fyd = ec2functions.fyd(fyk, gammas);
//   const epsiyd = ec2functions.epsiud(fyd, gammas);
//   const epsiuk = ec2functions.epsiuk(ductilityClass);
//   const epsiud = ec2functions.epsiud(epsiuk);
//   const k = ec2functions.k(ductilityClass);
//   // const A = ec2functions.A(fyd, k, epsiyd, epsiuk);
//   const f = (epsiud - epsiyd) * (k * fyd - fyd) / (epsiuk - epsiyd) + fyd;
//   return [
//     { x: 0, y: 0 },
//     { x: fyd / Es, y: fyd },
//     { x: epsiud, y: f },
//     { x: epsiuk, y: k * fyd }
//   ];
// };

const chartData = (fyk, gammaM0, Es) => {
  return {
    labels: ['Scatter'],
    datasets: [{
      label: 'Steel Law',
      type: 'scatter',
      data: horizontalLaw(fyk, gammaM0, Es),
      backgroundColor: [
        'rgba(50,200,230, 1)'
      ],
      borderColor: [
        'rgba(50,200,230, 1)'
      ],
      borderWidth: 3,
      lineTension: 0,
      fill: 1
    }]
  };
};

export default chartData;
