import React from 'react';
import {
  Container,
  Row,
  Col,
  Label
} from 'reactstrap';
import optionsForceUnits from '../../../../../../../../utils/units/optionsForceUnits2';
import optionsMomentUnits from '../../../../../../../../utils/units/optionsMomentUnits2';
import InputsUnit from '../../../../../../../../components/UIComponents/Fields/InputsUnit2/';

const Loads = ({
  loads
}) => {
  return (
    <Container>
      <Row>
        <Col md={6}>
          <h2 className="subtitle">Serviceability Limit State Forces:</h2>
          <InputsUnit
            selectList={optionsForceUnits}
            label={loads.Nsls.text}
            inputName="loads.Nsls.value"
            selectName="loads.Nsls.unit.name"
            tooltipDescription={loads.Nsls.description}
            tooltipTarget={loads.Nsls.name}
          />
          <InputsUnit
            selectList={optionsMomentUnits}
            label={loads.Mxsls.text}
            inputName="loads.Mxsls.value"
            selectName="loads.Mxsls.unit.name"
            tooltipDescription={loads.Mxsls.description}
            tooltipTarget={loads.Mxsls.name}
          />
          <InputsUnit
            selectList={optionsMomentUnits}
            label={loads.Mysls.text}
            inputName="loads.Mysls.value"
            selectName="loads.Mysls.unit.name"
            tooltipDescription={loads.Mysls.description}
            tooltipTarget={loads.Mysls.name}
          />
        </Col>
        <Col md={6}>
          <h2 className="subtitle">Ultimate Limit State Forces:</h2>
          <InputsUnit
            selectList={optionsForceUnits}
            label={loads.Nuls.text}
            inputName="loads.Nuls.value"
            selectName="loads.Nuls.unit.name"
            tooltipDescription={loads.Nuls.description}
            tooltipTarget={loads.Nuls.name}
          />
          <InputsUnit
            selectList={optionsMomentUnits}
            label={loads.Mxuls.text}
            inputName="loads.Mxuls.value"
            selectName="loads.Mxuls.unit.name"
            tooltipDescription={loads.Mxuls.description}
            tooltipTarget={loads.Mxuls.name}
          />
          <InputsUnit
            selectList={optionsMomentUnits}
            label={loads.Myuls.text}
            inputName="loads.Myuls.value"
            selectName="loads.Myuls.unit.name"
            tooltipDescription={loads.Myuls.description}
            tooltipTarget={loads.Myuls.name}
          />
        </Col>
      </Row>
      <Row>
        <Col sm={12}>
          <br />
          <h2 className="subtitle">Sign convention:</h2>
          <Label className="text">
            <p>Axial compression has <strong>positive sign(+).</strong></p>
            <p>Axial tension has <strong>negative sign(-).</strong></p>
            <p>Moment with <strong>positive sign (+)</strong> produces tension at bottom and compression at top.</p>
            <p>Moment with <strong>negative sign (-)</strong> produces tension at top and compression at bottom.</p>
          </Label>
        </Col>
      </Row>
    </Container>
  );
};

export default Loads;
