import * as THREE from 'three';
import showLabel from '../ShowLabel';

export default (name, {
  scene,
  globalAxisMesh,
  labelMesh
  // uiManagement,
  // data
}) => {
  if (typeof labelMesh !== 'undefined' && labelMesh.length > 0) {
    for (let i = labelMesh.length - 1; i >= 0; i -= 1) {
      scene.remove(labelMesh[i]);
      labelMesh.pop();
    }
  }
  const coneLength = 0.4;
  const coneWidth = 0.2;

  const origin = new THREE.Vector3(0, 0, 0);

  // GLOBAL AXIS UX
  const UX = new THREE.Vector3(1, 0, 0);
  const directionX = UX.clone().sub(origin);
  const lengthX = directionX.length();
  const AxisX = new THREE.ArrowHelper(directionX.normalize(), origin, lengthX, 'red', coneLength, coneWidth);
  globalAxisMesh.push(AxisX);
  scene.add(AxisX);

  const labelDataX = {
    scene,
    mesh: labelMesh,
    type: 'globalAxis',
    object: AxisX,
    text: 'X',
    x: 1,
    y: 0,
    z: 0
  };

  showLabel(labelDataX);

  // GLOBAL AXIS UY
  const UY = new THREE.Vector3(0, 1, 0);
  const directionY = UY.clone().sub(origin);
  const lengthY = directionY.length();
  const AxisY = new THREE.ArrowHelper(directionY.normalize(), origin, lengthY, 'green', coneLength, coneWidth);
  globalAxisMesh.push(AxisY);
  scene.add(AxisY);

  const labelDataY = {
    scene,
    mesh: labelMesh,
    type: 'globalAxis',
    object: AxisY,
    text: 'Y',
    x: 0,
    y: 1,
    z: 0
  };

  showLabel(labelDataY);

  // GLOBAL AXIS UZ
  const UZ = new THREE.Vector3(0, 0, 1);
  const directionZ = UZ.clone().sub(origin);
  const lengthZ = directionZ.length();
  const AxisZ = new THREE.ArrowHelper(directionZ.normalize(), origin, lengthZ, 'blue', coneLength, coneWidth);
  globalAxisMesh.push(AxisZ);
  scene.add(AxisZ);

  const labelDataZ = {
    scene,
    mesh: labelMesh,
    type: 'globalAxis',
    object: AxisZ,
    text: 'Z',
    x: 0,
    y: 0,
    z: 1
  };

  showLabel(labelDataZ);

  return {
    mesh: globalAxisMesh
  };
};
