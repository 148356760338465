import React from 'react';
import {
  CustomInput,
  Card,
  CardBody,
  CardText,
  Form,
  Label,
  FormGroup,
  CardHeader,
  Input,
  Button,
  Col,
  Row
} from 'reactstrap';

class PlanSubscription extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      agreeTermsAndConditions: false
    };
  }

  render() {
    const {
      selectedPlan,
      subscribePlan,
      openModal
    } = this.props;
    const {
      agreeTermsAndConditions
    } = this.state;
    return (
      <Card className="card">
        <CardHeader className="cardHeader">
          { `Subscription to ${selectedPlan} Plan`}
        </CardHeader>
        <CardBody>
          <CardText>{ `You are going to subscribe to the ${selectedPlan} Plan.`}</CardText>
          <Form>
            <FormGroup check>
              <Label check>
                <Input
                  type="checkbox"
                  value={agreeTermsAndConditions}
                  onClick={() => {
                    this.setState({
                      agreeTermsAndConditions: !agreeTermsAndConditions
                    });
                  }}
                />
                {' '}
                I agree to the <a href="https://www.triazur.com/legal/en-termsandconditions" target="_blank" rel="noopener noreferrer">Terms and Conditions</a> and to the <a href="https://www.triazur.com/legal/en-privacypolicy" target="_blank" rel="noopener noreferrer">Privacy Policy</a>
              </Label>
            </FormGroup>
            <br />
            <Button
              disabled={!(agreeTermsAndConditions)}
              className="positiveButton"
              onClick={async () => {
                await subscribePlan();
                openModal({ status: false });
              }}
            > 
              Submit Payment
            </Button>
          </Form>
        </CardBody>
      </Card>
    );
  }
};

export default PlanSubscription;
