const optionsUnitsSystem = {
  optionsUnits: [{
    label: 'm-kN-kg',
    value: 'mkNkg'
  },
  {
    label: 'm-MN-kg',
    value: 'mMNkg'
  },
  {
    label: 'cm-MN-kg',
    value: 'cmMNkg'
  },
  {
    label: 'mm-MN-kg',
    value: 'mmMNkg'
  },
  {
    label: 'inch-kip-kg',
    value: 'inchkipkg'
  }],
  mkNkg: {
    name: 'mkNkg',
    text: 'm-kN-kg',
    type: {
      unitLength: { text: 'm', name: 'm' },
      unitArea: { text: 'm²', name: 'm2' },
      unitVolume: { text: 'm³', name: 'm3' },
      unitTime: { text: 's', name: 's' },
      unitWeight: { text: 'kg', name: 'kg' },
      unitForce: { text: 'kN', name: 'kN' },
      unitMoment: { text: 'kN.m', name: 'kNm' },
      unitStress: { text: 'kPa', name: 'kPa' },
      unitTemperature: { text: '°C', name: 'degreeC' }
    }
  },
  mMNkg: {
    name: 'mMNkg',
    text: 'm-MN-kg',
    type: {
      unitLength: { text: 'm', name: 'm' },
      unitArea: { text: 'm²', name: 'm2' },
      unitVolume: { text: 'm³', name: 'm3' },
      unitTime: { text: 's', name: 's' },
      unitWeight: { text: 'kg', name: 'kg' },
      unitForce: { text: 'MN', name: 'MN' },
      unitMoment: { text: 'MN.m', name: 'MNm' },
      unitStress: { text: 'MPa', name: 'MPa' },
      unitTemperature: { text: '°C', name: 'degreeC' }
    }
  },
  cmMNkg: {
    name: 'cmMNkg',
    text: 'cm-MN-kg',
    type: {
      unitLength: { text: 'cm', name: 'cm' },
      unitArea: { text: 'cm²', name: 'cm2' },
      unitVolume: { text: 'cm³', name: 'cm3' },
      unitTime: { text: 's', name: 's' },
      unitWeight: { text: 'kg', name: 'kg' },
      unitForce: { text: 'MN', name: 'MN' },
      unitMoment: { text: 'MN.cm', name: 'MNcm' },
      unitStress: { text: 'MPa', name: 'MPa' },
      unitTemperature: { text: '°C', name: 'degreeC' }
    }
  },
  mmMNkg: {
    name: 'mmMNkg',
    text: 'mm-MN-kg',
    type: {
      unitLength: { text: 'mm', name: 'mm' },
      unitArea: { text: 'mm²', name: 'mm2' },
      unitVolume: { text: 'mm³', name: 'mm3' },
      unitTime: { text: 's', name: 's' },
      unitWeight: { text: 'kg', name: 'kg' },
      unitForce: { text: 'MN', name: 'MN' },
      unitMoment: { text: 'MN.mm', name: 'MNmm' },
      unitStress: { text: 'MPa', name: 'MPa' },
      unitTemperature: { text: '°C', name: 'degreeC' }
    }
  },
  inchkipkg: {
    name: 'inchkipkg',
    text: 'inch-kip-kg',
    type: {
      unitLength: { text: 'inch', name: 'inch' },
      unitArea: { text: 'inch²', name: 'inch2' },
      unitVolume: { text: 'inch³', name: 'inch3' },
      unitTime: { text: 's', name: 's' },
      unitWeight: { text: 'kg', name: 'kg' },
      unitForce: { text: 'kip', name: 'kip' },
      unitMoment: { text: 'kip.inch', name: 'kipinch' },
      unitStress: { text: 'psi', name: 'psi' },
      unitTemperature: { text: '°C', name: 'degreeC' }
    }
  }
};

export default optionsUnitsSystem;
