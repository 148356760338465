import ec2functions from '../../../../../../../utils/ec2/ec2-functions';

const chartData = (fck, gammac, alphacc) => {
  const fcd = ec2functions.fcd(alphacc, fck, gammac);
  const epsic2 = ec2functions.epsic2(fck);

  const concreteLaw = () => {
    const lawData = [];
    for (let i = 0; i < 20; i += 1) {
      const epsi = (epsic2 * i) / 19;
      const sigma = (fcd / (epsic2 ** 2)) * epsi * ((2 * epsic2) - epsi);
      lawData.push({ x: epsi, y: sigma });
    }
    for (let i = 0; i < 20; i += 1) {
      const epsi = (0.0035 - epsic2) * i / 19 + epsic2;
      const sigma = fcd;
      lawData.push({ x: epsi, y: sigma });
    }
    return lawData;
  };


  return {
    labels: ['Scatter'],
    datasets: [{
      label: 'Loi béton',
      type: 'scatter',
      data: concreteLaw(),
      backgroundColor: [
        'rgba(112,112,112, 0.2)'
      ],
      borderColor: [
        'rgba(112,112,112,1)'
      ],
      borderWidth: 3,
      lineTension: 0,
      fill: 1
    }]
  };
};

export default chartData;
