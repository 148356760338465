import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { withRouter } from 'react-router';
import {
  Col,
  Row,
  Card,
  CardTitle,
  CardHeader,
  CardBody,
  Button,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem
} from 'reactstrap';
import { Scatter } from 'react-chartjs-2';
import { FaFileExcel, FaFileCsv } from 'react-icons/fa';

import chartOptions from './utils/chartOptions';
import chartData from './utils/chartData';
import OutputCalc from '../../../../../../components/UIComponents/Fields/OutputCalc';
import optionsLengthUnits from '../../../../../../utils/units/optionsLengthUnits2';
import optionsAreaUnits from '../../../../../../utils/units/optionsAreaUnits2';
import optionsInertiaUnits from '../../../../../../utils/units/optionsInertiaUnits';

import {
  changeInputValue,
  fetchSectionData,
  exportToCSV,
  exportToXLSX,
  selectFileFormat
} from '../../../../actions';


import RectangularSection from '../RectangularSection';
import TSection from '../TSection';
import ISection from '../ISection';
import CircleSection from '../CircleSection';
import HollowCircleSection from '../HollowCircleSection';

class Calculation extends React.Component {
  constructor(props) {
    super(props);

    this.toggle = this.toggle.bind(this);
    this.state = {
      dropdownOpen: false
    };
  }

  componentDidMount() {
    const { fetchSectionData, sectionType, inputs } = this.props;
    fetchSectionData(sectionType, inputs);
  }

  toggle() {
    this.setState(prevState => ({
      dropdownOpen: !prevState.dropdownOpen
    }));
  }

  render() {
    const {
      sectionName,
      sectionValues,
      sectionType,
      inputs,
      outputs,
      changeInputValue,
      selectFileFormat
    } = this.props;
    return (
      <React.Fragment>
        <Card className="card">
          <CardBody className="header">
            <Row>
              <Col lg={12} className="align-center">
                <CardTitle className="title">
                  {`${sectionName} Section Geometry`}
                </CardTitle>
              </Col>
            </Row>
            <Row>
              <Col xs={12} className="align-right">
                <Dropdown
                  color="primary"
                  isOpen={this.state.dropdownOpen}
                  toggle={this.toggle}
                >
                  <DropdownToggle
                    className="negativeButton"
                    caret
                  >
                    <i class="material-icons">
                      vertical_align_bottom
                    </i>
                    { ' Export' }
                  </DropdownToggle>
                  <DropdownMenu right>
                    <DropdownItem
                      onClick={() => exportToCSV(sectionType, sectionValues, outputs)}
                    >
                      <FaFileCsv />
                      { '  CSV' }
                    </DropdownItem>
                    <DropdownItem divider />
                    <DropdownItem
                      onClick={() => exportToXLSX(sectionType, sectionValues, outputs)}
                    >
                      <FaFileExcel />
                      { '  XLSX' }
                    </DropdownItem>
                  </DropdownMenu>
                </Dropdown>
              </Col>
            </Row>
          </CardBody>
          <CardBody>
            <Row>
              <Col md={6}>
                <div className="chartContainer">
                  <Scatter
                    className="chart"
                    width={400}
                    height={400}
                    data={chartData(outputs.sectionext, outputs.sectionint, outputs.xg.value, outputs.yg.value)}
                    options={chartOptions(outputs.sectionext, inputs.unit)}
                  />
                </div>
              </Col>
              <Col md={6}>
                <h2 className="subtitle">Inputs</h2>
                {sectionType === 'rectangularSection'
                  && <RectangularSection
                    inputs={inputs}
                    changeInputValue={changeInputValue}
                    sectionType={sectionType}
                    optionsLengthUnits={optionsLengthUnits}
                  />}
                {sectionType === 'tSection'
                  && <TSection
                    inputs={inputs}
                    changeInputValue={changeInputValue}
                    sectionType={sectionType}
                    optionsLengthUnits={optionsLengthUnits}
                  />}
                {sectionType === 'iSection'
                  && <ISection
                    inputs={inputs}
                    changeInputValue={changeInputValue}
                    sectionType={sectionType}
                    optionsLengthUnits={optionsLengthUnits}
                  />}
                {sectionType === 'circleSection'
                  && <CircleSection
                    inputs={inputs}
                    changeInputValue={changeInputValue}
                    sectionType={sectionType}
                    optionsLengthUnits={optionsLengthUnits}
                  />}
                {sectionType === 'hollowCircleSection'
                  && <HollowCircleSection
                    inputs={inputs}
                    changeInputValue={changeInputValue}
                    sectionType={sectionType}
                    optionsLengthUnits={optionsLengthUnits}
                  />}
                <br />
                <h2 className="subtitle">Outputs</h2>
                <OutputCalc
                  input={outputs.xg}
                  unitList={optionsLengthUnits}
                />
                <OutputCalc
                  input={outputs.yg}
                  unitList={optionsLengthUnits}
                />
                <OutputCalc
                  input={outputs.area}
                  unitList={optionsAreaUnits}
                />
                <OutputCalc
                  input={outputs.ixx}
                  unitList={optionsInertiaUnits}
                />
                <OutputCalc
                  input={outputs.iyy}
                  unitList={optionsInertiaUnits}
                />
              </Col>
            </Row>
          </CardBody>
        </Card>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state, props) => {
  const { sectionType } = props.match.params;
  return {
    sectionType,
    sectionValues: state.sectionGeometry[sectionType],
    inputs: state.sectionGeometry[sectionType].inputs,
    outputs: state.sectionGeometry.outputs
  };
};


const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({
    fetchSectionData,
    changeInputValue,
    selectFileFormat
  }, dispatch);
};

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(Calculation);
