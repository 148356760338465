const optionsAreaUnits = [{
  label: 'm²',
  value: 'm2'
}, {
  label: 'cm²',
  value: 'cm2'
}, {
  label: 'mm²',
  value: 'mm2'
}, {
  label: 'inch²',
  value: 'inch2'
}, {
  label: 'ft²',
  value: 'ft2'
}];

export default optionsAreaUnits;
