const beamFormulas = {
  MA: (inputs) => {
    const P = inputs.P.value;
    const a = inputs.a.value;
    return P * a;
  },
  dispB: (inputs) => {
    const P = inputs.P.value;
    const L = inputs.L.value;
    const I = inputs.I.value;
    const E = inputs.E.value;
    const disp = P * (L ** 3) / (3 * E * I);

    return disp;
  }
};

export default beamFormulas;
