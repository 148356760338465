import React, { memo, Fragment } from 'react';
import Draggable from 'react-draggable';
import { FieldArray } from 'redux-form';
import {
  Row,
  Col,
  Card,
  Label,
  CardHeader,
  Button,
  CardBody
} from 'reactstrap';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import NodesModal from '../Inputs/scenes/Nodes/scenes/NodesModal';
import MembersModal from '../Inputs/scenes/Members/scenes/MembersModal';
import { activeObject } from '../../actions';

function ObjectDescription({
  startRefreshScene,
  nodeValues,
  memberValues,
  activeObject
}) {

  function closeObjectInfo(element) {
    activeObject({
      clickObjectData: {
        name: element.name,
        type: element.type
      },
      displayObjectInfo: false
    })
  }

  return (
    <Fragment>
      {nodeValues.map((node) => node.active &&
        <Draggable
          defaultPosition={{ x: 0, y: 0 }}
          handle=".handle"
          bounds='.sceneThreejs'
        >
          <Card id="card" className="objectDescription">
            <CardHeader id="headerinputs" className="handle">
              <Row>
                <Col xs={2} />
                <Col xs={8}>
                  <Label className="headerCard">{`Name: ${node.type} ${node.name}`}</Label>
                </Col>
                <Col xs={2}>
                  <div className="controlCard">
                    <i
                      className="material-icons"
                      onClick={() => closeObjectInfo(node)}
                    >
                      {'clear'}
                    </i>
                  </div>
                </Col>
              </Row>
            </CardHeader>
            <CardBody>
              <FieldArray
                name={`nodes.value`}
                component={NodesModal}
              />
              <br />
              <Row>
                <Col xs={6} className="align-center">
                  <Button
                    className="negativeButton"
                    outline
                    color="primary"
                    onClick={() => closeObjectInfo(node)}
                  >
                    {'Close'}
                  </Button>
                </Col>
                <Col xs={6} className="align-center">
                  <Button
                    className="negativeButton"
                    outline
                    color="primary"
                    onClick={() => {
                      startRefreshScene();
                    }}
                  >
                    {'Apply'}
                  </Button>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Draggable>
      )}
      {memberValues.map((member) => member.active &&
        <Draggable
          defaultPosition={{ x: 0, y: 0 }}
          handle=".handle"
          bounds='.sceneThreejs'
        >
          <Card id="card" className="objectDescription">
            <CardHeader id="headerinputs" className="handle">
              <Row>
                <Col xs={2} />
                <Col xs={8}>
                  <Label className="headerCard">{`Name: ${member.type} ${member.name}`}</Label>
                </Col>
                <Col xs={2}>
                  <div className="controlCard">
                    <i
                      className="material-icons"
                      onClick={() => closeObjectInfo(member)}
                    >
                      {'clear'}
                    </i>
                  </div>
                </Col>
              </Row>
            </CardHeader>
            <CardBody>
              <FieldArray
                name={`members.value`}
                component={MembersModal}
              />
              <br />
              <Row>
                <Col xs={6} className="align-center">
                  <Button
                    className="negativeButton"
                    outline
                    color="primary"
                    onClick={() => closeObjectInfo(member)}
                  >
                    {'Close'}
                  </Button>
                </Col>
                <Col xs={6} className="align-center">
                  <Button
                    className="negativeButton"
                    outline
                    color="primary"
                    onClick={() => {
                      startRefreshScene();
                    }}
                  >
                    {'Apply'}
                  </Button>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Draggable>
      )}
    </Fragment>
  )
}

const mapStateToProps = (state) => {
  return {
    nodeValues: state.form.structure3D.inputs.values.nodes.value,
    memberValues: state.form.structure3D.inputs.values.members.value
  };
};


const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({
    activeObject
  }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(memo(ObjectDescription));