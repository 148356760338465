import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
  Card, CardImg, Col
} from 'reactstrap';

import './styles/CardAddProject.css';

const CardAddProject = ({ colnum, imgsrc, link }) => (
  <Col md={colnum}>
    <Card id="cardaddproject">
      <Link to={link}>
        <CardImg className="cardaddimg" src={imgsrc} alt="Add Project" />
      </Link>
    </Card>
  </Col>
);

CardAddProject.propTypes = {
  colnum: PropTypes.number.isRequired,
  imgsrc: PropTypes.string.isRequired,
  link: PropTypes.string.isRequired
};

export default CardAddProject;
