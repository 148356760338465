const optionsLengthUnits = [{
  label: 'm',
  value: 'm'
}, {
  label: 'cm',
  value: 'cm'
}, {
  label: 'mm',
  value: 'mm'
}, {
  label: 'inch',
  value: 'inch'
}, {
  label: 'ft',
  value: 'ft'
}];

export default optionsLengthUnits;
