import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  Row,
  Col,
  Badge,
  ListGroup,
  ListGroupItem,
  Nav,
  NavItem,
  NavLink,
  Input,
  CardDeck
} from 'reactstrap';
import Title from '../../../../components/UIComponents/Title';
import CardApp from '../../../../components/UIComponents/Card/CardApp';
import { LoadableDashboard } from '../../../Dashboard';
import StructureApp from './scenes/StructureApp';
import HydraulicApp from './scenes/HydraulicApp';
import GeotechnicApp from './scenes/GeotechnicApp';
import AIApp from './scenes/AIApp';
import TriAzurIcon from './img/TriAzurIcon.png';
import SearchIconSvg from './img/SearchIcon.svg';
import { searchapp } from './utils/searchapp';
import { showVideo } from './actions';


const {
  REACT_APP_COMPANY,
  REACT_APP_LOGO
} = process.env;

class Applications extends React.Component {
  constructor(props) {
    super(props);
    this.handleChange = this.handleChange.bind(this);
    this.handleSelectChange = this.handleSelectChange.bind(this);
    this.state = {
      filteredCatList: [],
      filteredAppList: []
    };
  }

  componentDidMount() {
    const {
      categoriesList,
      applicationsList
    } = this.props;
    this.setState({
      filteredCatList: categoriesList,
      filteredAppList: applicationsList
    });
  }

  componentWillReceiveProps(nextProps) {
    this.setState({
      filteredCatList: nextProps.categoriesList,
      filteredAppList: nextProps.applicationsList
    });
  }
  
  handleSelectChange(e) {
    const {
      categoriesList
    } = this.props;
    let currentCatList = [];
    let newCatList = [];
    if (e.target.value !== '' && e.target.value !== 'All') {
      currentCatList = categoriesList;
      newCatList = currentCatList.filter((cat) => {
        const lc = cat.value.toLowerCase();
        return lc.toLowerCase().includes(e.target.value.toLowerCase());
      });
    } else {
      newCatList = categoriesList;
    }
    this.setState({
      filteredCatList: newCatList
    });
  }
  
  handleChange(e) {
    const {
      applicationsList
    } = this.props;
    let currentAppList = [];
    let newAppList = [];

    if (e.target.value !== '') {
      currentAppList = applicationsList;
      newAppList = currentAppList.filter((app) => {
        const appText = `
        ${app.title} 
        ${app.category}
        ${app.description}
        ${app.tags}
        ${app.link}
        ${app.status}
        ${app.company}
        `;
        // const lc = app.description.toLowerCase();
        const filter = e.target.value.toLowerCase();

        return appText.toLowerCase().includes(filter);
      });
    } else {
      newAppList = applicationsList;
    }

    this.setState({
      filteredAppList: newAppList
    });
  }

  render() {
    const {
      categoriesList,
      applicationsList,
      showVideo
    } = this.props;
    const {
      filteredCatList,
      filteredAppList
    } = this.state;
    console.log(this.state)
    return (
      <LoadableDashboard>
        <div className="applications">
          <Title
            title={`${REACT_APP_COMPANY} Applications`}
            imgsrc={REACT_APP_LOGO}
            description="Your hub for all applications for Civil Engineering calculation"
          />
          <Row>
            <Col xs={12}>
              <Input
                className="searchApp"
                type="text"
                id="mySearch"
                style={{backgroundImage: `url(${SearchIconSvg})`}}
                // onKeyUp={searchapp}
                onChange={this.handleChange}
                placeholder="Search for application.."
              />
            </Col>
            {/*<Col xs={4}>
              <Input
                className="selectCat"
                type="select"
                onChange={this.handleSelectChange}
              >
                <option value="All">All</option>
                { categoriesList.map(category => (
                  <option value={category.value}>{category.label}</option>
                ))}
                </Input>
            </Col>*/}
          </Row>
          <Row className="applicationsrow">
            <Col lg={12}>
              <div className="listapp">
                { filteredCatList.map((category, i) => (
                  <div key={i}>
                    <Row>
                      <h4 className="appgrouptitle">{category.label}</h4>
                    </Row>
                    <CardDeck>
                      { filteredAppList.map((app, index) => (
                        <React.Fragment key={index}>
                          { (category.value === app.category)
                            && <CardApp
                              key={index}
                              colnum={4}
                              imgsrc={app.imgsrc}
                              title={app.title}
                              subtitle={app.category}
                              description={app.description}
                              link={app.link}
                              tags={app.tags}
                              href={app.href}
                              status={app.status}
                              logoCompany={app.logoCompany}
                              company={app.company}
                              video={app.video}
                              openModal={showVideo}
                            />
                          }
                        </React.Fragment>
                      ))}
                    </CardDeck>
                  </div>
                ))}
              </div>
            </Col>
          </Row>
        </div>
      </LoadableDashboard>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    categoriesList: state.applications.categoriesList,
    applicationsList: state.applications.applicationsList
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({
    showVideo
  }, dispatch);
};


export default connect(mapStateToProps, mapDispatchToProps)(Applications);
