import React from 'react';
import PropTypes from 'prop-types';
import {
  Row,
  Col,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Button,
  Input,
  InputGroupButtonDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Tooltip
} from 'reactstrap';
import InputField from '../Input';
import Select from '../Select';
import './styles/InputUnit.css';
import { conversionValue } from '../../../../utils/units/conversionFunctions';

class InputUnit extends React.Component {
  constructor(props) {
    super(props);
    this.toggle = this.toggle.bind(this);
    this.state = {
      tooltipOpen: false,
      unitValue: this.props.unitType
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.unitType !== this.props.unitType) {
      let oldUnit = [];
      let newUnit = [];
      for (let i = 0; i < this.props.unitList.length; i += 1) {
        if (this.state.unitValue === this.props.unitList[i].label) {
          oldUnit = this.props.unitList[i].value;
        }
        if (this.props.unitType === this.props.unitList[i].label) {
          newUnit = this.props.unitList[i].value;
        }
      }

      const converted = conversionValue(this.props.input.value, oldUnit, newUnit);
      this.props.changeUnit(this.props.unitType, converted, this.props.category, this.props.input.name);
      this.setState({ unitValue: this.props.unitType });
    }
  }


  toggle() {
    this.setState({
      tooltipOpen: !this.state.tooltipOpen
    });
  }

  render() {
    const {
      input,
      category,
      changeInput,
      unitType,
      unitList,
      changeUnit
    } = this.props;
    console.log(input.name + ' render');
    return (
      <div className="inputunit">
        <InputGroup>
          <InputGroupAddon className="inputgroupaddon" addonType="prepend">
            <InputGroupText className="inputgrouptext">{input.text}</InputGroupText>
          </InputGroupAddon>
          <InputGroupAddon className="inputgroupaddon" addonType="prepend">
            <InputGroupText className="inputgroupinfo" id={input.name}>
              <i className="material-icons">info</i>
            </InputGroupText>
            <Tooltip placement="top" isOpen={this.state.tooltipOpen} target={input.name} toggle={this.toggle}>
              {input.description}
            </Tooltip>
          </InputGroupAddon>
          <InputField
            className="input"
            type="number"
            name={input.name}
            value={input.value}
            onChange={(e) => {
              const val = Number(e.target.value);
              changeInput(val, category, input.name);
            }}
          />
          <InputGroupAddon addonType="append">
            <Select
              name={input.name}
              value={this.state.unitValue}
              // selectedOption={unitType}
              defaultValue={input.unit}
              list={unitList}
              onChange={(e) => {
                // console.log(e.target.value);
                let unitInput = e.target.options[e.target.selectedIndex].getAttribute('data');
                unitInput = JSON.parse(unitInput);
                let oldUnit = unitInput;
                for (let i = 0; i < unitList.length; i += 1) {
                  if (input.unit === unitList[i].label) {
                    oldUnit = unitList[i].value;
                  }
                }
                const newUnit = unitInput;
                const converted = conversionValue(input.value, oldUnit, newUnit);
                this.setState({ unitValue: e.target.value });
                // changeInput(converted, category, input.name);
                changeUnit(e.target.value, converted, category, input.name);
              }}
            />
          </InputGroupAddon>
        </InputGroup>
      </div>
    );
  }
}

export default InputUnit;
