import React, { Component, Fragment } from 'react';
import {
  Container,
  Row,
  Col,
  Alert,
  Button,
  Card,
  CardTitle,
  CardText,
  CardSubtitle,
  CardHeader,
  CardBody,
  CardFooter,
  Label,
  Input,
  FormGroup
} from 'reactstrap';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import history from '../../../../history';
import TriAzurIcon from './img/TriAzurIcon.png';
import Modal from '../../../../components/UIComponents/Modal';
import Title from '../../../../components/UIComponents/Title';
import { LoadableDashboard } from '../../../Dashboard';
import { deleteAccount, subscribeToPlan } from '../Settings/actions';
import { openModal } from '../../../../components/UIComponents/Modal/actions';

class Account extends Component {
  state = {
    isSubscribing: false,
    isStripeCustomer: false,
    plan: '',
    isDelete: false
  }

  componentDidMount() {
    const { user } = this.props;

    if (user.subscriptionId) {
      this.setState(
        state => ({
          isSubscribing: false,
          plan: user.subscriptionId
        })
      );
    }
  }

  async deleteUser() {
    const {
      user,
      deleteAccount,
      openModal
    } = this.props;
    try {
      await deleteAccount(user);
      await openModal({ status: false });
      history.push('/');
    } catch (error) { }
  }

  render() {
    const { 
      user,
      openModal,
      selectedPlan,
      subscribedPlan
    } = this.props;
    const {
      isDelete
    } = this.state;
    const userInformations = [
      {
        label: 'First Name',
        value: user.firstname
      },
      {
        label: 'Last Name',
        value: user.lastname
      },
      {
        label: 'Email',
        value: user.email
      },
      {
        label: 'Company',
        value: user.company
      }
    ];
    console.log('USER',user)
    return (
      <LoadableDashboard>
        <Container className="account" fluid>
          <Title
            title="Account"
            imgsrc={TriAzurIcon}
            description="All your account settings in one place"
          />
          {/* <h2 className="title">User details</h2> */}
          <Label className="subtitle">General information about your account</Label>
          <Card className="card">
            <CardHeader className="cardHeader">User details</CardHeader>
            <CardBody>
              {
                userInformations.map(item => (
                  <FormGroup row>
                    <Label sm={3}>{ `${item.label} :` }</Label>
                    <Label sm={9}><strong>{item.value}</strong></Label>
                  </FormGroup>
                ))
              }
            </CardBody>
          </Card>
          <br />
          {/* <h2 className="title">User details</h2> */}
          <Label className="subtitle">General information about your selected plan</Label>
          <Card className="card">
            <CardHeader className="cardHeader">Plan details</CardHeader>
            <CardBody>
              <FormGroup row>
                <Label sm={3}>Your actual plan is:</Label>
                <Label sm={9}><strong>{subscribedPlan.nickname === '' ? 'FREE' : subscribedPlan.nickname}</strong></Label>
              </FormGroup>
              <CardText>Change your subscription plan:</CardText>
              <Button className="positiveButton" tag={Link} to="/settings">
                Go to Pricing
              </Button>
            </CardBody>
          </Card>
          <br />
          <Label className="subtitle">Irreversible and destructive actions. Tread lightly</Label>
          <Card className="card">
            <CardHeader className="cardHeader">Danger zone</CardHeader>
            <CardBody>
              <CardText>Once you delete an account, there is no going back.</CardText>
              <Button
                className="deleteButton"
                onClick={() => {
                  openModal({ status: true });
                }}
              >
                Delete this account
              </Button>
            </CardBody>
          </Card>
          <br />
          <Modal>
            <Card className="card">
              <CardHeader className="cardHeader">Delete Account ?</CardHeader>
              <CardBody>
                <CardText className="subtitle">
                  You're about to delete your account.
                </CardText>
                <CardText className="subtitle">
                  Deletion is an irreversible process. You will lose all your projects and cancel your subscription.
                </CardText>
                <CardText>
                  Type <strong>DELETE</strong> to confirm:
                </CardText>
                <CardText>
                  <Input
                    type="text"
                    placeholder="DELETE"
                    onChange={(e) => {
                      if (e.target.value === 'DELETE') {
                        this.setState({ isDelete: true });
                      } else {
                        this.setState({ isDelete: false });
                      }
                    }}
                  />
                </CardText>
              </CardBody>
              <CardFooter>
                <Row>
                  <Col xs={6}>
                    <Button
                      disabled={!(isDelete)}
                      className="deleteButton"
                      onClick={() => {
                        if (isDelete) {
                          this.deleteUser();
                        }
                      }}
                    >
                      Delete
                    </Button>
                  </Col>
                </Row>
              </CardFooter>
            </Card>
          </Modal>
        </Container>
      </LoadableDashboard>
    );
  }
}

const mapStateToProps = (state) => {
  const userInfo = localStorage.getItem('user');
  return {
    user: userInfo ? JSON.parse(userInfo) : {},
    selectedPlan: state.settings.plan,
    subscribedPlan: state.settings.subscribedPlan
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({
    deleteAccount,
    openModal
  }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(Account);
