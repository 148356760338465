import React from 'react';
import { FormGroup, Input } from 'reactstrap';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';

const renderSelectField = ({ input, meta: { touched, error }, children, ...custom }) => (
  <Input type="select" {...(touched ? { valid: !error } : {}) } {...input} {...custom}>
    {children}
  </Input>
);

const SelectRF = ({
  onChange,
  name,
  selectedOption,
  placeholder,
  disabled,
  list,
  color
}) => (
  <Field
    className="fieldInput"
    onChange={onChange}
    name={name}
    component={renderSelectField}
    disabled={disabled}
    style={{ backgroundColor: color }}
  >
    {
      list.map(item => (
        <option
          key={item.value}
          value={item.value}
          style={{ backgroundColor: item.color }}
        >
          {item.label}
        </option>
      ))
    }
  </Field>
);

// const Select = ({
//   onChange,
//   name,
//   selectedOption,
//   value,
//   // placeholder,
//   defaultValue,
//   list
// }) => (
//   <Input
//     type="select"
//     name={name}
//     onChange={onChange}
//     // selectedOption={selectedOption}
//     // placeholder={placeholder}
//     defaultValue={defaultValue}
//     value={value}
//   >
//     {
//       list.map(item => (
//         <option
//           key={JSON.stringify(item.value)}
//           value={item.label}
//           data={JSON.stringify(item.value)}
//         >
//           {item.label}
//         </option>
//       ))
//     }
//   </Input>
// );

SelectRF.propTypes = {
  name: PropTypes.string.isRequired,
  defaultValue: PropTypes.string.isRequired,
  // selectedOption: PropTypes.string.isRequired,
  // placeholder: PropTypes.string.isRequired,
  list: PropTypes.arrayOf(PropTypes.object).isRequired,
  onChange: PropTypes.func.isRequired
};

export default SelectRF;
