import React from 'react';
import PropTypes from 'prop-types';
import {
  Button
} from 'reactstrap';
import StripeCheckout from 'react-stripe-checkout';
import { connect } from 'react-redux';
import TriAzurIconSvg from './img/TriAzurIcon.svg';

const StripeBtn = ({
  onToken,
  user
}) => {
  const publishableKey = 'pk_live_E5jhkfWu4ROmA0fyTQMoGdTJ';

  return (
    <StripeCheckout
      label="Register" // Component button text
      name="TriAzur SAS" // Modal Header
      image={TriAzurIconSvg}
      description="Secure Credit Card registration"
      panelLabel="Register" // Submit button in modal
      token={onToken}
      stripeKey={publishableKey}
      email={user.email}
      billingAddress={false}
    >
      <Button
        className="positiveButton"
      >
        Register
      </Button>
    </StripeCheckout>
  );
};

const mapStateToProps = () => {
  const userInfo = localStorage.getItem('user');
  return {
    user: userInfo ? JSON.parse(userInfo) : {}
  };
};

StripeBtn.propTypes = {
  onToken: PropTypes.func.isRequired,
  user: PropTypes.shape({
    email: PropTypes.string.isRequired
  }).isRequired
};

export default connect(mapStateToProps)(StripeBtn);
