import * as types from './actionTypes';
import initialState from './initialState';

const Settings = (state = initialState.settings, action) => {
  switch (action.type) {
    case types.GET_PLANS:
      return {
        ...state,
        plans: action.payload
      };
    case types.SELECT_PLAN:
      return {
        ...state,
        plan: action.payload
      };
    case types.SUBSCRIBED_PLAN:
      return {
        ...state,
        subscribedPlan: action.payload.data.plan
      };
    case types.UNSUBSCRIBED_PLAN:
      return {
        ...state,
        subscribedPlan: {
          ...state.subscribedPlan,
          nickname: action.payload
        }
      };
    default:
      return state;
  }
};

export default Settings;
