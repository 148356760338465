import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import {
  Breadcrumb,
  BreadcrumbItem,
  Row,
  Col,
  Button,
  CardDeck
} from 'reactstrap';
import Inputs from './scenes/Inputs';
// import Outputs from './scenes/Outputs';
import { LoadableConcreteSection } from '../../../ConcreteSection';
// import Modal from '../../../../components/UIComponents/Modal';

const sectionName = (section) => {
  switch (section) {
    case 'rectangularSection':
      return 'Rectangular';
    case 'tSection':
      return 'T';
    case 'iSection':
      return 'I';
    case 'circleSection':
      return 'Circle';
    case 'hollowCircleSection':
      return 'Hollow Circle';
    case 'randomSection':
      return 'Random';
    default:
      return 'Random';
  }
};

const ConcreteSectionCalculation = ({ match: { params: { sectionType } } }) => (
  <LoadableConcreteSection>
    <Breadcrumb>
      <BreadcrumbItem><Link to="/dashboard">Dashboard</Link></BreadcrumbItem>
      {/* <BreadcrumbItem><Link to="/concretesection/projects">Concrete Section</Link></BreadcrumbItem>
      <BreadcrumbItem><Link to="/concretesection/sectiontypes">Section Types</Link></BreadcrumbItem> */}
      <BreadcrumbItem><Link to="/concretesection">Concrete Section</Link></BreadcrumbItem>
      <BreadcrumbItem active>{sectionName(sectionType)} Concrete Section</BreadcrumbItem>
    </Breadcrumb>
    <Row>
      <Col lg={12}>
        <Inputs />
      </Col>
    </Row>
    <br />
  </LoadableConcreteSection>
);

export default ConcreteSectionCalculation;
