const chartData = (uls, loads) => {
  const sortTable = (type, array) => {
    const resConcrete = [];
    const resReinforcement = [];
    for (let i = 0; i < array.length; i += 1) {
      if (i < 175) {
        resConcrete.push(array[i]);
      } else {
        resReinforcement.push(array[i]);
      }
    }
    if (type === 'Concrete Failure') {
      return resConcrete;
    }
    return resReinforcement;
  };

  return {
    labels: ['Scatter'],
    datasets: [{
      label: 'N-Mx - Concrete Failure',
      type: 'scatter',
      data: sortTable('Concrete Failure', uls.interactionDiagram.value),
      backgroundColor: [
        'rgba(112,112,112, 0)'
      ],
      borderColor: [
        'rgba(255,0,0,1)'
      ],
      borderWidth: 3,
      lineTension: 0,
      fill: 1,
      pointRadius: 0
    },
    {
      label: 'N-Mx - Steel Failure',
      type: 'scatter',
      data: sortTable('Steel Failure', uls.interactionDiagram.value),
      backgroundColor: [
        'rgba(112,112,112, 0)'
      ],
      borderColor: [
        'rgba(255,0,0,1)'
      ],
      borderWidth: 3,
      lineTension: 0,
      fill: 1,
      pointRadius: 0
    },
    {
      label: 'ULS Loads',
      type: 'scatter',
      data: [{ x: loads.Nuls.value, y: loads.Mxuls.value }],
      backgroundColor: [
        '#ff0000'
      ],
      fillColor: [
        'rgba(0,0,0,0)'
      ],
      borderColor: [
        'rgba(112,112,112,1)'
      ],
      showLine: false,
      lineTension: 0,
      pointRadius: 3
    }]
  };
};

export default chartData;
