import React from 'react';
import PropTypes from 'prop-types';
import InputsUnit from '../../../../../../../../../../../../components/UIComponents/Fields/InputsUnit2';
import optionsLengthUnits from '../../../../../../../../../../../../utils/units/optionsLengthUnits2';

const ISection = ({
  sectionType,
  sections,
  changeGeometry
}) => {
  return (
    <div>
      <InputsUnit
        label={sections.listSectionTypes.iSection.inputs.b.text}
        inputName="sections.listSectionTypes.iSection.inputs.b.value"
        selectList={optionsLengthUnits}
        selectName="sections.listSectionTypes.iSection.inputs.b.unit"
        tooltipDescription={sections.listSectionTypes.iSection.inputs.b.description}
        tooltipTarget={sections.listSectionTypes.iSection.inputs.b.name}
        onChange={(e) => {
          const val = e.target.value;
          changeGeometry(sections.listSectionTypes.iSection.inputs, sectionType, val, sections.listSectionTypes.iSection.inputs.b.name);
        }}
      />
      <InputsUnit
        label={sections.listSectionTypes.iSection.inputs.h.text}
        inputName="sections.listSectionTypes.iSection.inputs.h.value"
        selectList={optionsLengthUnits}
        selectName="sections.listSectionTypes.iSection.inputs.h.unit"
        tooltipDescription={sections.listSectionTypes.iSection.inputs.h.description}
        tooltipTarget={sections.listSectionTypes.iSection.inputs.h.name}
        onChange={(e) => {
          const val = e.target.value;
          changeGeometry(sections.listSectionTypes.iSection.inputs, sectionType, val, sections.listSectionTypes.iSection.inputs.h.name);
        }}
      />
      <InputsUnit
        label={sections.listSectionTypes.iSection.inputs.b0.text}
        inputName="sections.listSectionTypes.iSection.inputs.b0.value"
        selectList={optionsLengthUnits}
        selectName="sections.listSectionTypes.iSection.inputs.b0.unit"
        tooltipDescription={sections.listSectionTypes.iSection.inputs.b0.description}
        tooltipTarget={sections.listSectionTypes.iSection.inputs.b0.name}
        onChange={(e) => {
          const val = e.target.value;
          changeGeometry(sections.listSectionTypes.iSection.inputs, sectionType, val, sections.listSectionTypes.iSection.inputs.b0.name);
        }}
      />
      <InputsUnit
        label={sections.listSectionTypes.iSection.inputs.h0.text}
        inputName="sections.listSectionTypes.iSection.inputs.h0.value"
        selectList={optionsLengthUnits}
        selectName="sections.listSectionTypes.iSection.inputs.h0.unit"
        tooltipDescription={sections.listSectionTypes.iSection.inputs.h0.description}
        tooltipTarget={sections.listSectionTypes.iSection.inputs.h0.name}
        onChange={(e) => {
          const val = e.target.value;
          changeGeometry(sections.listSectionTypes.iSection.inputs, sectionType, val, sections.listSectionTypes.iSection.inputs.h0.name);
        }}
      />
      <InputsUnit
        label={sections.listSectionTypes.iSection.inputs.b1.text}
        inputName="sections.listSectionTypes.iSection.inputs.b1.value"
        selectList={optionsLengthUnits}
        selectName="sections.listSectionTypes.iSection.inputs.b1.unit"
        tooltipDescription={sections.listSectionTypes.iSection.inputs.b1.description}
        tooltipTarget={sections.listSectionTypes.iSection.inputs.b1.name}
        onChange={(e) => {
          const val = e.target.value;
          changeGeometry(sections.listSectionTypes.iSection.inputs, sectionType, val, sections.listSectionTypes.iSection.inputs.b1.name);
        }}
      />
      <InputsUnit
        label={sections.listSectionTypes.iSection.inputs.h1.text}
        inputName="sections.listSectionTypes.iSection.inputs.h1.value"
        selectList={optionsLengthUnits}
        selectName="sections.listSectionTypes.iSection.inputs.h1.unit"
        tooltipDescription={sections.listSectionTypes.iSection.inputs.h1.description}
        tooltipTarget={sections.listSectionTypes.iSection.inputs.h1.name}
        onChange={(e) => {
          const val = e.target.value;
          changeGeometry(sections.listSectionTypes.iSection.inputs, sectionType, val, sections.listSectionTypes.iSection.inputs.h1.name);
        }}
      />
    </div>
  );
};

export default ISection;
