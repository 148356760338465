import React, { useState } from 'react';
import {
  Card,
  CardHeader,
  CardBody,
  CardText,
  CardFooter,
  Button,
  Col,
  Row
} from 'reactstrap';
import Modal from '../Modal';

const DeleteProjectModal = ({
  openModal,
  deleteProject,
  projectId,
  userId
}) => { 
  const [isDisable, setIsDisable] = useState(false);
  return (
  <Modal>
    <Card className="card">
      <CardHeader className="cardHeader">Delete this Project ?</CardHeader>
      <CardBody>
        <CardText className="subtitle">
          You're about to delete this project.
        </CardText>
        <CardText className="subtitle">
          Deletion is an irreversible process. You will lose all your data about this project.
        </CardText>
      </CardBody>
      <CardFooter>
        <Row>
          <Col xs={6} className="align-center">
            <Button
              className="deleteButton"
              onClick={async () => {
                setIsDisable({ isDisable: true })
                await deleteProject(projectId, userId);
                openModal({ status: false });
              }}
              disabled={isDisable}
            >
              Delete
            </Button>
          </Col>
          <Col xs={6} className="align-center">
            <Button
              className="negativeButton"
              onClick={() => {
                openModal({ status: false });
              }}
            >
              Cancel
            </Button>
          </Col>
        </Row>
      </CardFooter>
    </Card>
  </Modal>
  )
};

export default DeleteProjectModal;
