import React from 'react';
import { Link } from 'react-router-dom';
import {
  CardDeck,
  Breadcrumb,
  BreadcrumbItem
} from 'reactstrap';
import Title from '../../../../components/UIComponents/Title';
import CardProject from '../../../../components/UIComponents/Card/CardProject';
import CardAddProject from '../../../../components/UIComponents/Card/CardAddProject';
import AddProjectSvg from './img/AddProject.svg';
import ConcreteSectionSvg from '../../img/ConcreteSection.svg';
import ProjectScreenshotPng from './img/ProjectScreenshot.png';
import { LoadableConcreteSection } from '../../../ConcreteSection';

const Projects = () => (
  <LoadableConcreteSection>
    <Title
      title="Concrete Section Projects"
      imgsrc={ConcreteSectionSvg}
      description="Create or Select a Concrete Section Project"
    />
    <Breadcrumb>
      <BreadcrumbItem><Link to="/dashboard">Dashboard</Link></BreadcrumbItem>
      <BreadcrumbItem active>Concrete Section Projects</BreadcrumbItem>
    </Breadcrumb>
    <CardDeck className="projects">
      <CardAddProject
        colnum={3}
        imgsrc={AddProjectSvg}
        link="/concretesection/sectiontypes"
      />
      <CardProject
        colnum={3}
        imgsrc={ProjectScreenshotPng}
        title="Nom du Project"
        subtitle="Concrete Section"
        link="/concretesection/nomduproject"
      />
    </CardDeck>
  </LoadableConcreteSection>
);

export default Projects;
