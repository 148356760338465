import React from 'react';
import PropTypes from 'prop-types';
import InputsUnit from '../../../../../../../../../../components/UIComponents/Fields/InputsUnit2';
import optionsLengthUnits from '../../../../../../../../../../utils/units/optionsLengthUnits2';

const ISection = ({
  sectionType,
  geometry,
  changeGeometry
}) => {
  return (
    <div>
      <InputsUnit
        label={geometry.concrete.b.text}
        inputName="geometry.concrete.b.value"
        selectList={optionsLengthUnits}
        selectName="geometry.concrete.b.unit"
        tooltipDescription={geometry.concrete.b.description}
        tooltipTarget={geometry.concrete.b.name}
        onChange={(e) => {
          const val = e.target.value;
          changeGeometry(geometry.concrete, sectionType, val, geometry.concrete.b.name);
        }}
      />
      <InputsUnit
        label={geometry.concrete.h.text}
        inputName="geometry.concrete.h.value"
        selectList={optionsLengthUnits}
        selectName="geometry.concrete.h.unit"
        tooltipDescription={geometry.concrete.h.description}
        tooltipTarget={geometry.concrete.h.name}
        onChange={(e) => {
          const val = e.target.value;
          changeGeometry(geometry.concrete, sectionType, val, geometry.concrete.h.name);
        }}
      />
      <InputsUnit
        label={geometry.concrete.b0.text}
        inputName="geometry.concrete.b0.value"
        selectList={optionsLengthUnits}
        selectName="geometry.concrete.b0.unit"
        tooltipDescription={geometry.concrete.b0.description}
        tooltipTarget={geometry.concrete.b0.name}
        onChange={(e) => {
          const val = e.target.value;
          changeGeometry(geometry.concrete, sectionType, val, geometry.concrete.b0.name);
        }}
      />
      <InputsUnit
        label={geometry.concrete.h0.text}
        inputName="geometry.concrete.h0.value"
        selectList={optionsLengthUnits}
        selectName="geometry.concrete.h0.unit"
        tooltipDescription={geometry.concrete.h0.description}
        tooltipTarget={geometry.concrete.h0.name}
        onChange={(e) => {
          const val = e.target.value;
          changeGeometry(geometry.concrete, sectionType, val, geometry.concrete.h0.name);
        }}
      />
      <InputsUnit
        label={geometry.concrete.b1.text}
        inputName="geometry.concrete.b1.value"
        selectList={optionsLengthUnits}
        selectName="geometry.concrete.b1.unit"
        tooltipDescription={geometry.concrete.b1.description}
        tooltipTarget={geometry.concrete.b1.name}
        onChange={(e) => {
          const val = e.target.value;
          changeGeometry(geometry.concrete, sectionType, val, geometry.concrete.b1.name);
        }}
      />
      <InputsUnit
        label={geometry.concrete.h1.text}
        inputName="geometry.concrete.h1.value"
        selectList={optionsLengthUnits}
        selectName="geometry.concrete.h1.unit"
        tooltipDescription={geometry.concrete.h1.description}
        tooltipTarget={geometry.concrete.h1.name}
        onChange={(e) => {
          const val = e.target.value;
          changeGeometry(geometry.concrete, sectionType, val, geometry.concrete.h1.name);
        }}
      />
    </div>
  );
};

export default ISection;
