const optionsForceUnits = [{
  label: 'N',
  value: 'N'
}, {
  label: 'kN',
  value: 'kN'
}, {
  label: 'MN',
  value: 'MN'
}, {
  label: 'kip',
  value: 'kip'
}, {
  label: 'lbf',
  value: 'lbf'
}];

export default optionsForceUnits;
