import React, { useState, useEffect } from 'react';
import {
  Table
} from 'reactstrap';
import { compose } from 'recompose';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import SelectRF from '../../../../../../../../components/UIComponents/Fields/SelectRF';
import InputField from '../../../../../../../../components/UIComponents/Fields/InputRF';
import {
  addFields,
  editFields,
  setActive,
  changeName,
  changeListSelection
} from '../../../../actions';
import useFilter from '../../../../../../../../utils/DataTable/useFilter';
import usePagination from '../../../../../../../../utils/DataTable/usePagination';
import Pagination from '../../../../../../../../components/UIComponents/Pagination';
import SearchInput from '../../../../../../../../components/UIComponents/Fields/SearchInput';

function Members({
  fields,
  sectionsList,
  nodesList,
  changeName,
  changeListSelection,
  addFields,
  setActive
}) {
  const [filteredFields, setFilteredFields] = useState(fields);

  useEffect(() => {
    setFilteredFields(fields);
  }, [fields]);

  const onSearch = input => setFilteredFields(useFilter(fields, input).matchedFields);

  const {
    pageNumbers,
    currentTodos,
    paginatedFields,
    setPaginatedFields
  } = usePagination(filteredFields);

  const findIndex = (type) => {
    const squareBracketsRegex = /\[(.*?)\]/;
    const matchField = type.match(squareBracketsRegex)[0];
    const index = matchField.replace(/^\[(.+)\]$/, '$1');
    return parseInt(index);
  };

  return (
    <div>
      <div>
        <SearchInput onSearch={onSearch} />
      </div>
      <Table responsive>
        <thead>
          <tr className="column">
            <th>name</th>
            <th>section</th>
            <th>node i</th>
            <th>node j</th>
            <th />
            <th />
          </tr>
        </thead>
        <tbody>
          {currentTodos.map((type, index) => (
            <tr key={index}>
              <td className="tableTd">
                <InputField
                  name={`${type}.name`}
                  type="text"
                  onChange={(e) => {
                    const val = e.target.value;
                    changeName('members', findIndex(type), val);
                    const memberList = fields.getAll();
                    changeListSelection('memberLoads', 'member', memberList, index, val);
                  }}
                />
              </td>
              <td className="tableTd">
                <SelectRF
                  name={`${type}.section`}
                  list={sectionsList}
                />
              </td>
              <td className="tableTd">
                <SelectRF
                  name={`${type}.nodei`}
                  list={nodesList}
                />
                {/* <InputField name={`${type}.nodei`} type="text" /> */}
              </td>
              <td className="tableTd">
                <SelectRF
                  name={`${type}.nodej`}
                  list={nodesList}
                />
                {/* <InputField name={`${type}.nodej`} type="text" /> */}
              </td>
              <td className="tableTd">
                <i
                  className="material-icons"
                  role="button"
                  onClick={() => {
                    const itemIndex = findIndex(type);
                    fields.insert(itemIndex + 1, {
                      name: `Member${index + 1}`,
                      label: `Member${index + 1}`,
                      value: `Member${index + 1}`,
                      section: sectionsList[0].name,
                      nodei: nodesList[0].name,
                      nodej: nodesList[0].name
                    })
                  }}
                >
                  {'add_circle'}
                </i>
              </td>
              <td className="tableTd">
                {fields.length > 1
                  && <i
                    className="material-icons"
                    onClick={() => {
                      const itemIndex = findIndex(type);
                      fields.remove(itemIndex)
                    }}
                  >
                    {'remove_circle'}
                  </i>
                }
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
      <Pagination
        pageNumbers={pageNumbers}
        currentTodos={currentTodos}
        paginatedFields={paginatedFields}
        setPaginatedFields={setPaginatedFields}
      />
    </div>
  )
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({
    addFields,
    editFields,
    setActive,
    changeName,
    changeListSelection
  }, dispatch);
};

export default compose(
  connect(null, mapDispatchToProps),
)(Members);
