import React from 'react';
import PropTypes from 'prop-types';
import InputsUnit from '../../../../../../../../../../components/UIComponents/Fields/InputsUnit2';
import optionsLengthUnits from '../../../../../../../../../../utils/units/optionsLengthUnits2';

const CircleSection = ({
  sectionType,
  geometry,
  changeGeometry
}) => {
  return (
    <div>
      <InputsUnit
        label={geometry.concrete.D.text}
        inputName="geometry.concrete.D.value"
        selectList={optionsLengthUnits}
        selectName="geometry.concrete.D.unit"
        tooltipDescription={geometry.concrete.D.description}
        tooltipTarget={geometry.concrete.D.name}
        onChange={(e) => {
          const val = e.target.value;
          changeGeometry(geometry.concrete, sectionType, val, geometry.concrete.D.name);
        }}
      />
    </div>
  );
};

export default CircleSection;
