import React, { Component, useState, useEffect } from 'react';
import {
  CardDeck,
  Card,
  CardBody,
  CardText,
  CardTitle,
  CardSubtitle,
  CardImg,
  Button,
  Container,
  Breadcrumb,
  BreadcrumbItem,
  Col,
  Row,
  Input
} from 'reactstrap';
import { Link, withRouter } from 'react-router-dom';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Title from '../../../../../../components/UIComponents/Title';
import { LoadableConcreteSection } from '../../../../../ConcreteSection';
import Loader from '../../../../../../components/UIComponents/Loader';
import SearchIconSvg from './img/SearchIcon.svg';
import {
  fetchCategories
} from '../../actions';

import Classe from './components/Classe';

class Category extends Component {
  constructor(props) {
    super(props);
    this.selectCategoryId = this.selectCategoryId.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.state = {
      categoryId: '',
      filteredCategoryList: []
    };
  }
  
  componentDidMount() {
    const { fetchCategories, user, match } = this.props;
    const userId = user._id;
    const { category } = match.params;
    fetchCategories(category);
  }

  componentWillReceiveProps(nextProps) {
    this.setState({
      filteredCategoryList: nextProps.categories
    });
  }
  

  selectCategoryId = (id) => {
    this.setState({ categoryId: id });
  }

  handleChange(e) {
    const {
      categories
    } = this.props;
    let currentCategoryList = [];
    let newCategoryList = [];
    if (e.target.value !== '') {
      currentCategoryList = categories;
      newCategoryList = currentCategoryList.filter((category) => {
        const categoryText = `
        ${category.groupname} 
        `;
        // const lc = app.description.toLowerCase();
        const filter = e.target.value.toLowerCase();

        return categoryText.toLowerCase().includes(filter);
      });
    } else {
      newCategoryList = categories;
    }
    this.setState({
      filteredCategoryList: newCategoryList
    });
  }

  render() {
    const { 
      categories,
      user,
      match,
      loading
    } = this.props;
    const { category } = match.params;
    const {
      categoryId,
      filteredCategoryList,
      filteredFields
    } = this.state;
    console.log('STATE', filteredCategoryList)

    return (
      <LoadableConcreteSection>
        <Title
          title="datBIM"
          imgsrc={SearchIconSvg}
          description={`Select datBIM ${category}`}
          documentation={{
            link:"https://www.datbim.com",
            text:"documentation"
          }}
        />
        <Breadcrumb>
          <BreadcrumbItem><Link to="/dashboard">Dashboard</Link></BreadcrumbItem>
          <BreadcrumbItem><Link to="/datbim">datBIM Categories</Link></BreadcrumbItem>
          <BreadcrumbItem active>{`${category}`}</BreadcrumbItem>
        </Breadcrumb>
        <Col md={12} className="projects">
          

        </Col>
        <Col md={12} className="projects">
          {
            loading
              ? <Loader className="loaderProjects" />
              : <div> 
              <Row>
                <Input
                  className="searchApp"
                  type="text"
                  id="mySearch"
                  style={{backgroundImage: `url(${SearchIconSvg})`}}
                  // onKeyUp={searchapp}
                  onChange={this.handleChange}
                  placeholder="Search for object.."
                />
              </Row>
              <Row>
                <CardDeck>
                {
                  filteredCategoryList.map((cat, index) => {
                    return (
                      <Col md={3}>
                        { category === "classes" &&
                          <Classe 
                            key={index}
                            classe={cat}

                          />
                        }
                      </Col>
                    );
                  })
                }    
                </CardDeck>     
            </Row>
            </div>
            }
        </Col>
      </LoadableConcreteSection>
    );
  }
}

const mapStateToProps = (state, props) => {
  const userInfo = localStorage.getItem('user');
  const { category } = props.match.params;
  return {
    user: userInfo ? JSON.parse(userInfo) : {},
    categories: state.datBimApi[category],
    loading: state.datBimApi.loading
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({
    fetchCategories
  }, dispatch);
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withRouter
)(Category);