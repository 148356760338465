import * as types from './actionTypes';
import initialState from './initialState';

const Structure3DProjects = (state = initialState, action) => {
  switch (action.type) {
    case types.FETCH_STRUCTURE3D_PROJECTS:
      return {
        projects: action.payload.data,
        loading: action.payload.loading
      };
    default:
      return state;
  }
};

export default Structure3DProjects;
