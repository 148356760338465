import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import {
  Container, 
  Row,
  Col,
  Button 
} from 'reactstrap';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { withRouter } from 'react-router';
import { reduxForm, Form } from 'redux-form';
import { injectIntl } from 'react-intl';
import { confirmEmail, changePassword } from './actions';
import InputField from '../../components/UIComponents/Fields/InputRF';
import { required, passwordConfirmation } from '../../utils/validations';
import ConnectionBackground from '../../components/GraphicModules/Background/ConnectionBackground';

class ForgotPassword extends Component {
  constructor() {
    super();
    this.state = {
      validateEmail: false
    };
  }

  componentDidMount() {
    //REGEX VERIFY TOKEN
    const { match } = this.props;
    if (match.params.token) {
      this.setState({
        validateEmail: true
      });
    }
  }

  onSubmit(values) {
    const { email, password } = values;
    const { match: { params: { token } }, confirmEmail, changePassword } = this.props;
    console.log('VALUEEE', email)
    console.log('TOKENNNN', token)
    if (email && !token) {
      confirmEmail(email);
    } else {
      changePassword(password, token);
    }

  }

  render() {
    const { validateEmail } = this.state;
    const { handleSubmit } = this.props;
    return (
      <div className="authentication-background">
        <ConnectionBackground />
        <Container className="authentication-container" fluid>
        <Row>
          <Col md="6 box">
            <h2>
              Change the password
            </h2>
            {
              validateEmail
                ?
                <Form onSubmit={handleSubmit(this.onSubmit.bind(this))}>
                  <Row>
                    <Col md="12">
                      <InputField label="Password" name="password" type="password" validate={[required, passwordConfirmation]} />
                    </Col>
                    <Col md="12">
                      <InputField label="Confirmation Password" name="passwordConfirmation" type="password" validate={[required]} />
                    </Col>
                  </Row>
                  <Row className="submit-actions align-right">
                    {/* <Col md="12 align-right">
                      <Button type="submit" className="btnSubmit">
                        {'Send'}
                      </Button>
                    </Col> */}
                    <Col sm={1} />
                    <Col sm={4}>
                      <Link to="/signin">
                        <Button type="button" className="btn-return">
                            Back
                        </Button>
                      </Link>
                    </Col>
                    <Col sm={2} />
                    <Col sm={4}>
                      <Button type="submit" className="btn-submit">
                        {'Send'}
                      </Button>
                    </Col>
                    <Col sm={1} />
                  </Row>
                </Form>
                :
                <Form onSubmit={handleSubmit(this.onSubmit.bind(this))}>
                  <Row>
                    <Col md="12">
                      <InputField label="Email" name="email" type="email" validate={[required]} />
                    </Col>
                  </Row>
                  <Row className="submit-actions align-right">
                    <Col sm={1} />
                    <Col sm={4}>
                      <Link to="/signin">
                        <Button type="button" className="btn-return">
                            Back
                        </Button>
                      </Link>
                    </Col>
                    <Col sm={2} />
                    <Col sm={4}>
                      <Button type="Submit" className="btn-submit">
                        {'Send'}
                      </Button>
                    </Col>
                    <Col sm={1} />
                  </Row>
                </Form>
            }
          </Col>
        </Row>
      </Container>
      </div>
    )
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    confirmEmail: (email) => { confirmEmail(dispatch, email); },
    changePassword: (password, token) => { changePassword(dispatch, password, token); }
  };
};

const mapStateToProps = ({ form }) => {
  return {
    validateEmail: form.forgotPassword.validateEmail
  };
};

export default compose(
  injectIntl,
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
  reduxForm({
    form: 'forgotPassword',
    enableReinitialize: true
  })
)(ForgotPassword);
