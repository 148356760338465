import React from 'react';
import Title from '../../../../components/UIComponents/Title';
import SteelSectionSvg from '../../img/SteelSection.svg';
import { LoadableSteelSection } from '../../../SteelSection';

const Documentation = () => (
  <LoadableSteelSection>
    <Title
      title="Steel Section Documentation"
      imgsrc={SteelSectionSvg}
      description="Technical documentation about Steel Section application"
    />
  </LoadableSteelSection>
);

export default Documentation;
