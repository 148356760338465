import React from 'react';
import PropTypes from 'prop-types';
import {
  Container,
  Table,
  Row,
  Col,
  Input,
  Button,
  ButtonGroup,
  Label
} from 'reactstrap';
import { Scatter } from 'react-chartjs-2';

import chartOptions from './utils/chartOptions';
import chartData from './utils/chartData';
import OutputCalc from '../../../../../../../../components/UIComponents/Fields/OutputCalc';
import optionsForceUnits from '../../../../../../../../utils/units/optionsForceUnits2';
import optionsMomentUnits from '../../../../../../../../utils/units/optionsMomentUnits2';

class InteractionDiagramUls extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      graphLarge: 1
    };
  }

  render() {
    const {
      uls,
      loads
    } = this.props;

    const {
      graphLarge
    } = this.state;
    return (
      <Container className="geometry">
        <br />
        <div id="scrollmenu">
          <Row>
            <Col md={graphLarge === 1 ? 6 : 12}>
              <div className="controlChart">
                <i
                  className="material-icons"
                  onClick={() => this.setState({ graphLarge: -graphLarge })}
                >
                  {graphLarge === 1 ? 'crop_free' : 'crop'}
                </i>
              </div>
              <div className="chartLargeContainer">
                <Scatter
                  className="chart"
                  width={1000}
                  height={1000}
                  data={chartData(uls, loads)}
                  options={chartOptions(uls.interactionDiagram, loads)}
                />
                <br />
              </div>
            </Col>
            <Col md={graphLarge === 1 ? 6 : 12}>
              <h2 className="subtitle">Interaction Diagram Assumptions</h2>
              <Label>Assumption 1: this calculation assumes that My = 0 </Label>
              <Label>Assumption 2: this calculation assumes that the section is symetrical about y axis</Label>
              <Table responsive>
                <thead>
                  <tr className="column">
                    <th>{`N(${uls.interactionDiagram.unitForce.text})`}</th>
                    <th>{`Mx(${uls.interactionDiagram.unitMoment.text})`}</th>
                  </tr>
                </thead>
                <tbody>
                  {uls.interactionDiagram.value.map((coordinate, index) => (
                    <tr key={index}>
                      <td className="tableTd">
                        <Input value={Math.round(coordinate.x * 1000) / 1000} type="number" />
                      </td>
                      <td className="tableTd">
                        <Input value={Math.round(coordinate.y * 1000) / 1000} type="number" />
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </Col>
          </Row>
        </div>
      </Container>
    );
  }
}

export default InteractionDiagramUls;
