import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  Container,
  Col,
  Row
} from 'reactstrap';
import { Scatter } from 'react-chartjs-2';
import InputUnit from '../../../../../../../../components/UIComponents/Fields/InputUnit';
import InputsUnit from '../../../../../../../../components/UIComponents/Fields/InputsUnit2';
import optionsStressUnits from '../../../../../../../../utils/units/optionsStressUnits2';

import chartOptions from './utils/chartOptions';
import chartData from './utils/chartData';

const Concrete = ({
  concrete
}) => {
  return (
    <Container>
      <Row>
        <Col md={6}>
          <div className="chartContainer">
            <Scatter
              className="chart"
              width={400}
              height={400}
              data={chartData(concrete.fck.value, concrete.gammac.value, concrete.alphacc.value)}
              options={chartOptions(concrete.fck)}
            />
          </div>
        </Col>
        <Col md={6}>
          <h2 className="subtitle">Concrete material:</h2>
          <InputsUnit
            label={concrete.fck.text}
            inputName="concrete.fck.value"
            selectList={optionsStressUnits}
            selectName="concrete.fck.unit.name"
            tooltipDescription={concrete.fck.description}
            tooltipTarget={concrete.fck.name}
          />
          <InputsUnit
            label={concrete.gammac.text}
            inputName="concrete.gammac.value"
            tooltipDescription={concrete.gammac.description}
            tooltipTarget={concrete.gammac.name}
          />
          <InputsUnit
            label={concrete.alphacc.text}
            inputName="concrete.alphacc.value"
            tooltipDescription={concrete.alphacc.description}
            tooltipTarget={concrete.alphacc.name}
          />
        </Col>
      </Row>
    </Container>
  );
};

export default Concrete;
