import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { TwitterPicker, CirclePicker, SliderPicker, HuePicker } from 'react-color';
import {
  Container,
  Row,
  Col,
  Label,
  Card,
  CardHeader,
  Button,
  Input
} from 'reactstrap';
import InputsUnit from '../../../../../../../../../../components/UIComponents/Fields/InputsUnit2';
import SelectRF from '../../../../../../../../../../components/UIComponents/Fields/SelectRF';
import InputField from '../../../../../../../../../../components/UIComponents/Fields/InputRF';
import optionsDensityUnits from '../../../../../../../../../../utils/units/optionsDensityUnits2';
import optionsStressUnits from '../../../../../../../../../../utils/units/optionsStressUnits2';
import optionsLengthUnits from '../../../../../../../../../../utils/units/optionsLengthUnits2';
import optionsAreaUnits from '../../../../../../../../../../utils/units/optionsAreaUnits2';
import optionsInertiaUnits from '../../../../../../../../../../utils/units/optionsInertiaUnits';
import {
  addFields,
  editFields,
  setActive,
  changeGeometry,
  changeListSelection,
  fetchSectionData,
  sectionDataCalculation
} from '../../../../../../actions';

const colorList = [
  { value: 'darkred', label: '', color: 'darkred' },
  { value: 'lightcoral', label: '', color: 'lightcoral' },
  { value: 'lightsalmon', label: '', color: 'lightsalmon' },
  { value: 'darkorange', label: '', color: 'darkorange' },
  { value: 'steelblue', label: '', color: 'steelblue' },
  { value: 'lightblue', label: '', color: 'lightblue' },
  { value: 'mediumblue', label: '', color: 'mediumblue' },
  { value: 'gold', label: '', color: 'gold' },
  { value: 'darkgreen', label: '', color: 'darkgreen' },
  { value: 'lightslategrey', label: '', color: 'lightslategrey' },
  { value: 'grey', label: '', color: 'grey' },
];

const SectionData = ({
  sections,
  changeView,
  changeSectionType,
  showErrorMessage,
  errorMessage,
  addFields,
  editFields,
  sectionType,
  structure3D,
  startRefreshScene,
  changeListSelection
}) => {
  return (
    <div>
      <Row>
        <Col xs={9}>
          <Label>{ `Name of the section:` }</Label>
        </Col>
      </Row>
      <Row>
        <Col xs={9}>
          <InputField
            name="sections.outputs.name"
            type="text"
          />
        </Col>
        <Col xs={3}>
          <SelectRF
            color={sections.outputs.characteristics.color}
            name="sections.outputs.characteristics.color"
            list={colorList}
          />
          {/*<Input
            type="color"
            name="sections.outputs.characteristics.color"
            id="exampleColor"
            placeholder="color placeholder"
          />*/}
        </Col>
      </Row>
      <br />
      <Row>
        <Col xs={10}>
          <Label>{ `Select material:` }</Label>
          <SelectRF
            name="sections.outputs.characteristics.material.value"
            list={structure3D.materials.value}
          />
        </Col>
      </Row>
      <br />
      <Row>
        <Col xs={12}>
          <InputsUnit
            label={sections.outputs.characteristics.xg.text}
            inputName="sections.outputs.characteristics.xg.value"
            selectList={optionsLengthUnits}
            selectName="sections.outputs.characteristics.xg.unit"
            tooltipDescription={sections.outputs.characteristics.xg.description}
            tooltipTarget={sections.outputs.characteristics.xg.name}
          />
          <InputsUnit
            label={sections.outputs.characteristics.yg.text}
            inputName="sections.outputs.characteristics.yg.value"
            selectList={optionsLengthUnits}
            selectName="sections.outputs.characteristics.yg.unit"
            tooltipDescription={sections.outputs.characteristics.yg.description}
            tooltipTarget={sections.outputs.characteristics.yg.name}
          />
          <InputsUnit
            label={sections.outputs.characteristics.area.text}
            inputName="sections.outputs.characteristics.area.value"
            selectList={optionsAreaUnits}
            selectName="sections.outputs.characteristics.area.unit"
            tooltipDescription={sections.outputs.characteristics.area.description}
            tooltipTarget={sections.outputs.characteristics.area.name}
          />
          <InputsUnit
            label={sections.outputs.characteristics.ixx.text}
            inputName="sections.outputs.characteristics.ixx.value"
            selectList={optionsInertiaUnits}
            selectName="sections.outputs.characteristics.ixx.unit"
            tooltipDescription={sections.outputs.characteristics.ixx.description}
            tooltipTarget={sections.outputs.characteristics.ixx.name}
            type="number"
          />
          <InputsUnit
            label={sections.outputs.characteristics.iyy.text}
            inputName="sections.outputs.characteristics.iyy.value"
            selectList={optionsInertiaUnits}
            selectName="sections.outputs.characteristics.iyy.unit"
            tooltipDescription={sections.outputs.characteristics.iyy.description}
            tooltipTarget={sections.outputs.characteristics.iyy.name}
          />
          <InputsUnit
            label={sections.outputs.characteristics.ixy.text}
            inputName="sections.outputs.characteristics.ixy.value"
            selectList={optionsInertiaUnits}
            selectName="sections.outputs.characteristics.ixy.unit"
            tooltipDescription={sections.outputs.characteristics.ixy.description}
            tooltipTarget={sections.outputs.characteristics.ixy.name}
          />
        </Col>
      </Row>
      <Row>
        <Col xs={12}>
          <Label style={{ color: 'red' }}>{errorMessage}</Label>
        </Col>
      </Row>
      <Row>
        <Col xs={4} className="align-center">
          <Button
            className="negativeButton"
            outline
            color="primary"
            onClick={() => {
              changeView('Geometry');
              changeSectionType(sectionType);
            }}
          >
            {'Back'}
          </Button>
        </Col>
        <Col xs={4} />
        <Col xs={4} className="align-center">
          <Button
            className="negativeButton"
            outline
            color="primary"
            onClick={() => {
              const newSection = {
                name: sections.outputs.name,
                text: sections.outputs.text,
                value: sections.outputs.name,
                label: sections.outputs.name,
                description: sections.outputs.description,
                characteristics: {
                  ...sections.outputs.characteristics,
                  material: {
                    name: 'material',
                    text: 'material',
                    label: sections.outputs.characteristics.material.value,
                    value: sections.outputs.characteristics.material.value,
                    description: 'Section material'
                  }
                }
              };
              const indexSameName = sections.value.findIndex(item => item.value === sections.outputs.name);
              if (sectionType === 'editSection') {
                const index = sections.value.findIndex(item => item.value === sections.active);
                // for (let i = 0; i < structure3D.sections.value.length; i += 1) {
                //   if (structure3D.sections.active === structure3D.sections.value[i].name) {
                if (index === indexSameName || indexSameName === -1) {
                  editFields('sections', index, newSection);
                  const list = sections.value;
                  changeListSelection('members', 'section', list, index, newSection.name);
                  startRefreshScene();
                  changeView('Select');
                } else {
                  showErrorMessage('this section already exists');
                }
              } else if (indexSameName === -1) {
                addFields('sections', newSection);
                changeView('Select');
              } else {
                showErrorMessage('this section already exists');
              }
            }}
          >
            { sectionType === 'editSection' ? 'Edit' : 'Add' }
          </Button>
        </Col>
      </Row>
    </div>
  );
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({
    addFields,
    editFields,
    setActive,
    changeGeometry,
    changeListSelection,
    fetchSectionData,
    sectionDataCalculation
  }, dispatch);
};

export default connect(null, mapDispatchToProps)(SectionData);
