import React from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Row,
  Col,
  Container,
  FormGroup
} from 'reactstrap';
import { Link } from 'react-router-dom';
import { reduxForm, Form } from 'redux-form';
import { injectIntl, FormattedMessage } from 'react-intl';
import { compose } from 'recompose';
// import TriAzurIcon from './img/TriAzurIcon.svg';
import InputField from '../../../../components/UIComponents/Fields/InputRF';
import { required } from '../../../../utils/validations';

const { REACT_APP_LOGO } = process.env;

const FormSignIn = ({ authMessage, onSubmit, handleSubmit }) => (
  <Container className="authentication-container" fluid>
    <Row>
      <Col md="6" className="col-md-6 box">
        <h2>
          <FormattedMessage id="signin.title" defaultMessage="Connection to" />
          <img src={REACT_APP_LOGO} alt="TriAzurIcon" width="auto" height="60" />
        </h2>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Row>
            <Col md="12">
              <InputField label="Email" name="email" type="email" validate={[required]} />
            </Col>
            <Col md="12">
              <FormattedMessage id="signin.pwd" defaultMessage="Password">
                {
                  label => (
                    <InputField label={label} name="password" type="password" validate={[required]} />
                  )
                }
              </FormattedMessage>
            </Col>
          </Row>
          <Row className="submit-actions">
            <Col sm="1" />
            <Col sm="4">
              <a href="https://www.triazur.com">
                <Button type="button" className="btn-return">
                    <FormattedMessage id="signin.back" defaultMessage="Back" />
                </Button>
              </a>
            </Col>
            <Col sm="2" />
            <Col sm="4">
              <Button className="btn-submit" type="submit">
                <FormattedMessage id="signin.connection" defaultMessage="Connection!" />
              </Button>
            </Col>
            <Col sm="1" />
          </Row>
          <FormGroup>
            <FormattedMessage id="signin.no.account" defaultMessage="No account?" />
            <Link to="/signup" className="ForgetPwd">
              <FormattedMessage id="signin.gotosignup" defaultMessage=" sign up" />
            </Link>
            <div className="error" style={{ color: 'red' }}>{authMessage}</div>
          </FormGroup>
          <FormGroup>
            <Link to="/forgotPassword" className="ForgetPwd">
              <FormattedMessage id="signin.forgotpwd" defaultMessage="Forgotten password?" />
            </Link>
            <div className="error" style={{ color: 'red' }}>{authMessage}</div>
          </FormGroup>
        </Form>
      </Col>
    </Row>
  </Container>
);

FormSignIn.propTypes = {
  authMessage: PropTypes.string.isRequired,
  onSubmit: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired
};

export default compose(
  injectIntl,
  reduxForm({
    form: 'signIn',
    enableReinitialize: true
  })
)(FormSignIn);
