import React from 'react';
import {
  Row,
  Col,
  Spinner
} from 'reactstrap'; 
//import Loader from 'react-loader-spinner';
//import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
// import TriAzurIcon from './img/TriAzurIcon.png';
const {
  REACT_APP_LOGO
} = process.env;

const LoaderS = ({ refreshScene }) => (
  <div className={`${refreshScene && 'refresh-page'} loading-page`}>
    {/*<Row>
      <div className="logo-spinner" style={{ backgroundImage: `url(${REACT_APP_LOGO})` }} />
    </Row>*/}
    <Row>
      <div className="loader"></div>
    </Row>
    {/*<Spinner
      className="logo-spinner"
      style={{ width: '4rem', height: '4rem' }}
      //type="grow"
      //color="primary"
    />*/}
    {/*<Loader
      type="Oval"
      color="dodgerblue"
      className="logo-spinner"
      height={80}
      width={80}
      //timeout={1000} //3 secs
    />*/}
  </div>
);

export default LoaderS;
