const optionsInertiaUnits = [{
  label: 'm⁴',
  value: 'm4'
}, {
  label: 'cm⁴',
  value: 'cm4'
}, {
  label: 'mm⁴',
  value: 'mm4'
}, {
  label: 'inch⁴',
  value: 'inch4'
}, {
  label: 'ft⁴',
  value: 'ft4'
}];

export default optionsInertiaUnits;
