import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Container,
  Row,
  Col,
  Label,
  Input,
  Card,
  CardHeader,
  Button
} from 'reactstrap';
import ToolTips from '../../../../../../../../../../components/UIComponents/ToolTips';
import Checkbox from '../../../../../../../../../../components/UIComponents/Fields/Checkbox';
import InputsUnit from '../../../../../../../../../../components/UIComponents/Fields/InputsUnit2';
import SelectRF from '../../../../../../../../../../components/UIComponents/Fields/SelectRF';
import InputField from '../../../../../../../../../../components/UIComponents/Fields/InputRF';
import optionsLengthUnits from '../../../../../../../../../../utils/units/optionsLengthUnits2';
import optionsAngleUnits from '../../../../../../../../../../utils/units/optionsAngleUnits';

import {
  addFields,
  editFields,
  changeListSelection
} from '../../../../../../actions';

const FormSupport = ({
  supports,
  view,
  showErrorMessage,
  errorMessage,
  changeView,
  addFields,
  editFields,
  startRefreshScene,
  changeListSelection
  // structure3D
}) => {
  return (
    <div>
      <Row>
        <Col xs={10}>
          <Label>{ `${supports.outputs.description}` }</Label>
          <InputField
            name={`supports.outputs.name`}
            type="text"
          />
        </Col>
      </Row>
      <br />
      <Row>
        <Col xs={12}>
          {/* <InputsUnit
            label={supports.outputs.characteristics.dispX.text}
            inputName="supports.outputs.characteristics.dispX.value"
            selectList={optionsLengthUnits}
            selectName="supports.outputs.characteristics.dispX.unit.name"
            tooltipDescription={supports.outputs.characteristics.dispX.description}
            tooltipTarget={supports.outputs.characteristics.dispX.name}
          />
          <InputsUnit
            label={supports.outputs.characteristics.dispY.text}
            inputName="supports.outputs.characteristics.dispY.value"
            selectList={optionsLengthUnits}
            selectName="supports.outputs.characteristics.dispY.unit.name"
            tooltipDescription={supports.outputs.characteristics.dispY.description}
            tooltipTarget={supports.outputs.characteristics.dispY.name}
          />
          <InputsUnit
            label={supports.outputs.characteristics.dispZ.text}
            inputName="supports.outputs.characteristics.dispZ.value"
            selectList={optionsLengthUnits}
            selectName="supports.outputs.characteristics.dispZ.unit.name"
            tooltipDescription={supports.outputs.characteristics.dispZ.description}
            tooltipTarget={supports.outputs.characteristics.dispZ.name}
          />
          <InputsUnit
            label={supports.outputs.characteristics.rotX.text}
            inputName="supports.outputs.characteristics.rotX.value"
            selectList={optionsAngleUnits}
            selectName="supports.outputs.characteristics.rotX.unit.name"
            tooltipDescription={supports.outputs.characteristics.rotX.description}
            tooltipTarget={supports.outputs.characteristics.rotX.name}
          />
          <InputsUnit
            label={supports.outputs.characteristics.rotY.text}
            inputName="supports.outputs.characteristics.rotY.value"
            selectList={optionsAngleUnits}
            selectName="supports.outputs.characteristics.rotY.unit.name"
            tooltipDescription={supports.outputs.characteristics.rotY.description}
            tooltipTarget={supports.outputs.characteristics.rotY.name}
          />
          <InputsUnit
            label={supports.outputs.characteristics.rotZ.text}
            inputName="supports.outputs.characteristics.rotZ.value"
            selectList={optionsAngleUnits}
            selectName="supports.outputs.characteristics.rotZ.unit.name"
            tooltipDescription={supports.outputs.characteristics.rotZ.description}
            tooltipTarget={supports.outputs.characteristics.rotZ.name}
          /> */}
          <Checkbox
            label={supports.outputs.characteristics.dispX.text}
            inputName="supports.outputs.characteristics.dispX.value"
            tooltipDescription={supports.outputs.characteristics.dispX.description}
            tooltipTarget={supports.outputs.characteristics.dispX.name}
            message={Boolean(supports.outputs.characteristics.dispX.value) === true ? 'Free' : 'Fixed'}
          />
          <Checkbox
            label={supports.outputs.characteristics.dispY.text}
            inputName="supports.outputs.characteristics.dispY.value"
            tooltipDescription={supports.outputs.characteristics.dispY.description}
            tooltipTarget={supports.outputs.characteristics.dispY.name}
            message={Boolean(supports.outputs.characteristics.dispY.value) === true ? 'Free' : 'Fixed'}
          />
          <Checkbox
            label={supports.outputs.characteristics.dispZ.text}
            inputName="supports.outputs.characteristics.dispZ.value"
            tooltipDescription={supports.outputs.characteristics.dispZ.description}
            tooltipTarget={supports.outputs.characteristics.dispZ.name}
            message={Boolean(supports.outputs.characteristics.dispZ.value) === true ? 'Free' : 'Fixed'}
          />
          <Checkbox
            label={supports.outputs.characteristics.rotX.text}
            inputName="supports.outputs.characteristics.rotX.value"
            tooltipDescription={supports.outputs.characteristics.rotX.description}
            tooltipTarget={supports.outputs.characteristics.rotX.name}
            message={Boolean(supports.outputs.characteristics.rotX.value) === true ? 'Free' : 'Fixed'}
          />
          <Checkbox
            label={supports.outputs.characteristics.rotY.text}
            inputName="supports.outputs.characteristics.rotY.value"
            tooltipDescription={supports.outputs.characteristics.rotY.description}
            tooltipTarget={supports.outputs.characteristics.rotY.name}
            message={Boolean(supports.outputs.characteristics.rotY.value) === true ? 'Free' : 'Fixed'}
          />
          <Checkbox
            label={supports.outputs.characteristics.rotZ.text}
            inputName="supports.outputs.characteristics.rotZ.value"
            tooltipDescription={supports.outputs.characteristics.rotZ.description}
            tooltipTarget={supports.outputs.characteristics.rotZ.name}
            message={Boolean(supports.outputs.characteristics.rotZ.value) === true ? 'Free' : 'Fixed'}
          />
        </Col>
      </Row>
      <Row>
        <Col xs={12}>
          <Label style={{ color: 'red' }}>{errorMessage}</Label>
        </Col>
      </Row>
      <Row>
        <Col xs={4} className="align-center">
          <Button
            className="negativeButton"
            outline
            color="primary"
            onClick={() => {
              changeView('Select');
            }}
          >
            {'Back'}
          </Button>
        </Col>
        <Col xs={4} />
        <Col xs={4} className="align-center">
          <Button
            className="negativeButton"
            outline
            color="primary"
            onClick={() => {
              const newSupport = {
                name: supports.outputs.name,
                text: supports.outputs.text,
                value: supports.outputs.name,
                label: supports.outputs.name,
                description: supports.outputs.description,
                characteristics: {
                  ...supports.outputs.characteristics
                }
              };
              const indexSameName = supports.value.findIndex(item => item.value === supports.outputs.name);
              if (view === 'Edit') {
                const index = supports.value.findIndex(item => item.value === supports.active);
                if (index === indexSameName || indexSameName === -1) {
                  editFields('supports', index, newSupport);
                  const list = supports.value;
                  changeListSelection('nodes', 'support', list, index, newSupport.name);
                  startRefreshScene();
                  changeView('Select');
                } else {
                  showErrorMessage('this support already exists');
                }
              } else if (indexSameName === -1) {
                addFields('supports', newSupport);
                changeView('Select');
              } else {
                showErrorMessage('this support already exists');
              }
            }}
          >
            { view === 'Edit' ? 'Edit' : 'Add' }
          </Button>
        </Col>
      </Row>
    </div>
  );
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({
    addFields,
    editFields,
    changeListSelection
  }, dispatch);
};

export default connect(null, mapDispatchToProps)(FormSupport);
