const optionsStressUnits = [{
  label: 'Pa',
  value: 'Pa'
}, {
  label: 'kPa',
  value: 'kPa'
}, {
  label: 'MPa',
  value: 'MPa'
}, {
  label: 'GPa',
  value: 'Gpa'
}, {
  label: 'psi',
  value: 'psi'
}];

export default optionsStressUnits;
