import axios from 'axios';
import { toast } from 'react-toastify';
import { URL } from '../../config/config';
import URL_PATH from '../../config/default';
import history from '../../history';
import {
  EMAIL_VALIDATION
} from './actionTypes';

const confirmEmail = async (dispatch, email) => {
  try {
    await axios.post(`${URL}${URL_PATH.authentication.resetPassword}`, {
      email
    });
    dispatch({
      type: EMAIL_VALIDATION,
      payload: true
    });
    toast.success('🚀 A message has been send to your email adress', {
      autoClose: 2500
    });
  } catch (err) {
    dispatch({
      type: EMAIL_VALIDATION,
      payload: false
    });
    toast.error('Une erreur est survenue ! Veuillez réessayer !');
  }
};

const changePassword = async (dispatch, password, token) => {
  try {
    await axios.post(`${URL}${URL_PATH.authentication.resetPassword}/${token}`, {
      password
    });
    dispatch({
      type: EMAIL_VALIDATION,
      payload: true
    });
    toast.success('Password changed with success', {
      autoClose: 2500
    });
    history.push('/signin');
  } catch (err) {
    dispatch({
      type: EMAIL_VALIDATION,
      payload: false
    });
    toast.error('Invalid Token or Password already changed');
  }
};

export {
  confirmEmail,
  changePassword
};
