import React, { Component, Fragment } from 'react';
import {
  Container,
  Media,
  Row,
  Col,
  Alert,
  Button,
  Card,
  CardTitle,
  CardText,
  CardSubtitle,
  CardHeader,
  CardBody,
  CardFooter,
  Label,
  Input,
  FormGroup
} from 'reactstrap';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import history from '../../../../history';
import TriAzurIcon from './img/TriAzurIcon.png';
import Title from '../../../../components/UIComponents/Title';
import StripeButton from './components/StripeButton';
import { LoadableDashboard } from '../../../Dashboard';
import Plans from './scenes/Plans';
import {
  getSubscribedPlan,
  getUnSubscribedPlan,
  subscribeToPlan,
  unsubscribePlan,
  createStripeCustomer,
  deleteAccount
} from './actions';
import { openModal } from '../../../../components/UIComponents/Modal/actions';
import Pricing from './components/Pricing';
import OneSvg from './img/NumberOne.svg';
import TwoSvg from './img/NumberTwo.svg';

class Settings extends Component {
  state = {
    isSubscribing: false,
    isStripeCustomer: false,
    subscriptionId: ''
  }

  componentDidMount() {
    const { user, getSubscribedPlan, openModal } = this.props;
    openModal({ status: false });
    if (user.subscriptionId) {
      getSubscribedPlan(user.subscriptionId);
      this.setState(
        state => ({
          isSubscribing: false,
          subscriptionId: user.subscriptionId
        })
      );
    }
  }

  componentWillReceiveProps(nextProps) {
    const { subscriptionId } = nextProps.user;
    if (subscriptionId) {
      this.setState(
        state => ({
          isSubscribing: true
        })
      );
    }
  }

  async onToken(card) {
    const { selectedPlan, user } = this.props;
    const customer = {
      email: user.email,
      cardToken: card.id,
      subscriptionToken: selectedPlan
    };

    try {
      const stripeCustomer = await createStripeCustomer(customer);
      this.setState(
        state => ({
          isStripeCustomer: true
        })
      );
    } catch (error) {
      this.setState(
        state => ({
          isStripeCustomer: false
        })
      );
    }
  };

  async subscribePlan() {
    const { selectedPlan, getSubscribedPlan } = this.props;
    try {
      const subscriptionId = await subscribeToPlan(selectedPlan);
      await getSubscribedPlan(subscriptionId);
      this.setState(
        state => ({
          isSubscribing: true,
          subscriptionId
        })
      );
    } catch (error) {
      this.setState(
        state => ({
          isSubscribing: false
        })
      );
    }
  }

  async unsubscribe(subscriptionId) {
    const { getUnSubscribedPlan } = this.props;
    try {
      await unsubscribePlan(subscriptionId);
      this.setState(
        state => ({
          isSubscribing: false
        })
      );
      await getUnSubscribedPlan();
    } catch (error) {
      this.setState(
        state => ({
          isSubscribing: true
        })
      );
    }
  }

  async deleteUser() {
    const { user, deleteAccount } = this.props;
    try {
      await deleteAccount(user);
      history.push('/');
    } catch (error) { }
  }

  render() {
    const { user, subscribedPlan } = this.props;
    const { isSubscribing, isStripeCustomer, subscriptionId } = this.state;
    return (
      <LoadableDashboard>
        <Container className="settings" fluid>
          <Title
            title="Plans"
            imgsrc={TriAzurIcon}
            description="Select a plan that's right for you."
          />
          <br />
          <Row>
            {
              isSubscribing
                ? <Col xs="12">
                  <Label className="subtitle">General information about your Subscribe Plan</Label>
                  <Card className="card">
                    <CardHeader className="cardHeader">Subscription Plan Status</CardHeader>
                    <CardBody>
                      <Alert color="success" transition={{ in: true, timeout: 150 }}>
                        Congratulation, your account has been created. You are ready to go.
                      </Alert>
                      <CardText>
                        <FormGroup row>
                          <Label sm={2}>Your actual plan is:</Label>
                          <Label sm={10}><strong>{subscribedPlan.nickname === '' ? 'FREE' : subscribedPlan.nickname}</strong></Label>
                        </FormGroup>
                      </CardText>
                      <FormGroup row>
                        <Label sm={2}>Stop subscribing:</Label>
                        <Col sm={10}>
                          <Button color="danger" className="deleteButton" onClick={() => this.unsubscribe(subscriptionId)}>Unsubscribe</Button>
                        </Col>
                      </FormGroup>
                    </CardBody>
                  </Card>
                </Col>
                : <Col xs="12">
                  {
                    (!user.customerStripeID && !isStripeCustomer)
                      ? <div>
                        <Media className="titleComponent">
                          <Media left className="imgNumber">
                            <Media className="imgNumber" object src={OneSvg} alt="step 1" />
                          </Media>
                          <Media body>
                            <Media heading className="title">
                              Upgrade your plan
                            </Media>
                            {/* <div className="text">Hello</div> */}
                          </Media>
                        </Media>
                        <br />
                        <Col sm="6">
                          <Card className="card">
                            <CardBody>
                              <FormGroup row>
                                <Label sm={4}>Your actual plan is:</Label>
                                <Label sm={8}><strong>{subscribedPlan.nickname === '' ? 'FREE' : subscribedPlan.nickname}</strong></Label>
                              </FormGroup>
                              <Label>If you want to <strong>Upgrade your plan</strong>, first register your Credit Card and then select the plan :</Label>
                              <Col xs="12">
                                <StripeButton onToken={this.onToken.bind(this)} />
                              </Col>
                            </CardBody>
                          </Card>
                        </Col>
                        <hr />
                      </div>
                      : ''
                  }
                  <div>
                    <Row>
                      <Col xs="12">
                        {/* <Label className="subtitle">
                          {
                            (!user.customerStripeID && !isStripeCustomer)
                              ? '2/ Select your Plan:'
                              : 'Select your Plan:'
                          }
                        </Label> */}
                        <Media className="titleComponent">
                          {
                            (!user.customerStripeID && !isStripeCustomer)
                            ? <Media left className="imgNumber">
                              <Media className="imgNumber" object src={TwoSvg} alt="step 1" />
                            </Media>
                            : ''
                          }
                          <Media body>
                            <Media heading className="title">
                                Select your Plan:
                            </Media>
                            {/* <div className="text">Hello</div> */}
                          </Media>
                        </Media>
                        <br />
                      </Col>
                    </Row>
                    <Plans
                      isStripeCustomer={isStripeCustomer}
                      subscribePlan={this.subscribePlan.bind(this)}
                    />
                    <Row>
                      {/* <Col xs="12 align-center">
                        {
                          (!user.customerStripeID && !isStripeCustomer) ? <StripeButton onToken={this.onToken.bind(this)} /> : <Button onClick={this.subscribePlan.bind(this)}>Enregistrer</Button>
                        }
                      </Col> */}
                      {/* <Col xs="12 align-center">
                        {
                          (!user.customerStripeID && !isStripeCustomer) ? '' : <Button className="positiveButton" onClick={this.subscribePlan.bind(this)}>Subscribe</Button>
                        }
                      </Col> */}
                    </Row>
                  </div>
                </Col>
            }
          </Row>
        </Container>
      </LoadableDashboard>
    );
  }
}

const mapStateToProps = (state) => {
  const userInfo = localStorage.getItem('user');
  return {
    user: userInfo ? JSON.parse(userInfo) : {},
    selectedPlan: state.settings.plan,
    subscribedPlan: state.settings.subscribedPlan
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({
    createStripeCustomer,
    deleteAccount,
    getSubscribedPlan,
    getUnSubscribedPlan,
    openModal
  }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(Settings);
