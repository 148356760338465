import React from 'react';
import PropTypes from 'prop-types';
import {
  Container,
  Col,
  Row,
  InputGroup,
  InputGroupAddon,
  InputGroupText
} from 'reactstrap';
import { Scatter } from 'react-chartjs-2';
import SelectRF from '../../../../../../../../components/UIComponents/Fields/SelectRF';
import ToolTips from '../../../../../../../../components/UIComponents/ToolTips';
import chartOptions from './utils/chartOptions';
import chartData from './utils/chartData';
import InputsUnit from '../../../../../../../../components/UIComponents/Fields/InputsUnit2/';
import optionsStressUnits from '../../../../../../../../utils/units/optionsStressUnits2';


const Steel = ({
  steel
}) => {
  return (
    <Container className="steel">
      <Row>
        <Col md={6}>
          <div className="chartContainer">
            <Scatter
              className="chart"
              width={400}
              height={400}
              data={chartData(steel.fyk.value, steel.gammas.value, steel.Es.value)}
              options={chartOptions(steel.fyk)}
            />
          </div>
        </Col>
        <Col md={6}>
          <h2 className="subtitle">Steel material:</h2>
          <InputsUnit
            label={steel.fyk.text}
            inputName="steel.fyk.value"
            selectList={optionsStressUnits}
            selectName="steel.fyk.unit.name"
            tooltipDescription={steel.fyk.description}
            tooltipTarget={steel.fyk.name}
          />
          <InputsUnit
            label={steel.gammas.text}
            inputName="steel.gammas.value"
            tooltipDescription={steel.gammas.description}
            tooltipTarget={steel.gammas.name}
          />
          {/* <div className="inputunit">
            <InputGroup>
              <InputGroupAddon className="inputgroupaddon" addonType="prepend">
                <InputGroupText className="inputgrouptext">{steel.ductilityClass.text}</InputGroupText>
              </InputGroupAddon>
              <InputGroupAddon className="inputgroupaddon" addonType="prepend">
                <ToolTips
                  description={steel.ductilityClass.description}
                  target={steel.ductilityClass.name}
                />
              </InputGroupAddon>
              <SelectRF
                name="steel.ductilityClass.value"
                list={steel.ductilityClass.list}
              />
            </InputGroup>
          </div> */}
        </Col>
      </Row>
    </Container>
  );
};

export default Steel;
