export const required = value => (value ? undefined : 'Required');
export const passwordConfirmation = (value, values) => (value === values.passwordConfirmation ? undefined : 'Password should match');
export const numberConfirmation = value => (Number.isNaN(value) ? 'must be a number' : undefined);

const requiredNestedFields = object => Object.keys(object).some((key) => {
  return !Object.prototype.hasOwnProperty.call(object[key], 'value');
});

export const isFormMaterialValidate = (material) => {
  return !material.name || requiredNestedFields(material.characteristics);
};
