import * as types from './actionTypes';
import initialState from './initialState';

const dashboard = (state = initialState.dashboard, action) => {
  switch (action.type) {
    case types.UPDATE_GRAPH:
      return {
        graph: action.payload
      };
    default:
      return state;
  }
};

export default dashboard;
