import update from 'immutability-helper';

import initialState from './initialState';
import * as types from './actionTypes';

const waveCharacteristics = (state = initialState, action = {}) => {
  switch (action.type) {
    case types.WC_CHANGE_INPUT:
      return update(state, {
        inputs: {
          [action.payload.inputs.name]: {
            value: {
              $set: action.payload.inputs.value
            }
          }
        },
        outputs: {
          L: {
            value: {
              $set: action.payload.outputs.L
            }
          },
          waterLevel: {
            value: {
              $set: action.payload.outputs.waterLevel
            }
          }
        }
      });
    // case types.WC_CHANGE_OUTPUT:
    //   return update(state, {
    //     outputs: {
    //       L: {
    //         value: {
    //           $set: action.L
    //         }
    //       },
    //       waterLevel: {
    //         value: {
    //           $set: action.waterLevel
    //         }
    //       }
    //     }
    //   });
    default:
      return state;
  }
};

export default waveCharacteristics;
