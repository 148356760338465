import { fromJS } from 'immutable';
import update from 'immutability-helper';
import initialState from './initialState';
import * as types from './actionTypes';

const regression = (state = initialState, action = {}) => {
  switch (action.type) {
    case types.RE_UPDATE_MODEL_STATUS:
      return fromJS(state).updateIn(
        ['inputs', action.payload.section],
        list => list.push(action.payload.message)
      ).toJSON();
    case types.RE_CHANGE_MESSAGE:
      return update(state, {
        inputs: {
          [action.payload.type]: {
            $set: action.payload.message
          }
        }
      });
    default:
      return state;
  }
};

export default regression;
