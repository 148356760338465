import update from 'immutability-helper';
import initialState from './initialState';
import * as types from './actionTypes';

const structuralAnalysis = (state = initialState, action = {}) => {
  switch (action.type) {
    case types.SA_CHANGE_INPUT_VALUE:
      return update(state, {
        beamFormulas: {
          inputs: {
            [action.payload.inputs.name]: {
              value: {
                $set: action.payload.inputs.value
              }
            }
          },
          outputs: {
            MA: {
              value: {
                $set: action.payload.outputs.MA
              }
            },
            dispB: {
              value: {
                $set: action.payload.outputs.dispB
              }
            }
          }
        }
      });
    case types.SA_FETCH_DATA:
      return update(state, {
        outputs: {
          MA: {
            value: {
              $set: action.payload.outputs.xg
            }
          }
        }
      });
    default:
      return state;
  }
};

export default structuralAnalysis;
