import React from 'react';
import PropTypes from 'prop-types';
import { FieldArray } from 'redux-form';
import {
  Row,
  Table,
  Button,
  ButtonGroup
} from 'reactstrap';
import InputField from '../../../../../../../../../../../../components/UIComponents/Fields/InputRF';

const renderCoordinateList = ({ fields, meta: { error, submitFailed } }) => (
  <React.Fragment>
    {fields.map((coordinate, index) => (
      <tr key={index}>
        <td className="tableTd">
          <InputField
            name={`${coordinate}.x`}
            type="text"
          />
        </td>
        <td className="tableTd">
          <InputField
            name={`${coordinate}.y`}
            type="text"
          />
        </td>
        <td className="tableTd">
          <i
            className="material-icons"
            onClick={() => fields.insert(index + 1, { x: 0, y: 0 })}
          >
            { 'add_circle' }
          </i>
        </td>
        <td className="tableTd">
          {
            fields.length > 1 &&
            <i
              className="material-icons"
              onClick={() => fields.remove(index)}
            >
              { 'remove_circle' }
            </i>
          }
        </td>
      </tr>
    ))}
  </React.Fragment>
);


class RandomSection extends React.Component {
  constructor(props) {
    super(props);
    this.state = { cSelected: [], rSelected: 1 };
  }

  render () {
    const {
      sections,
      unit,
      fields
    } = this.props;
    return (
      <div>
        <Row>
          <ButtonGroup className="sectionintext">
            <Button
              className="buttonSecOut"
              outline
              color="primary"
              onClick={() => this.setState({ rSelected: 1 })}
              active={this.state.rSelected === 1}
            >
              {'Section Outline'}
            </Button>
            <Button
              className="buttonSecInt"
              outline
              color="primary"
              onClick={() => this.setState({ rSelected: 2 })}
              active={this.state.rSelected === 2}
            >
              {'Inner Contour'}
            </Button>
          </ButtonGroup>
        </Row>
        <div id="scrollmenu">
          <div style={this.state.rSelected === 1 ? {} : { display: 'none' }}>
            <Table responsive className="table">
              <thead>
                <tr className="column">
                  <th>{`Xext(${unit.text})`}</th>
                  <th>{`Yext(${unit.text})`}</th>
                </tr>
              </thead>
              <tbody>
                <FieldArray name="sections.listSectionTypes.sectionext" component={renderCoordinateList} />
              </tbody>
            </Table>
          </div>
          <div style={this.state.rSelected === 2 ? {} : { display: 'none' }}>
            <Table responsive>
              <thead>
                <tr className="column">
                  <th>{`Xint(${unit.text})`}</th>
                  <th>{`Yint(${unit.text})`}</th>
                </tr>
              </thead>
              <tbody>
                <FieldArray name="sections.listSectionTypes.sectionint" component={renderCoordinateList} />
              </tbody>
            </Table>
          </div>
        </div>
        <Row />
      </div>
    );
  }
}

export default RandomSection;
