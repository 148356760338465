const chartOptions = (fck) => {
  return {
    maintainAspectRatio: true,
    animation: false,
    showLines: true,
    legend: {
      display: true
    },
    tooltips: {
      callbacks: {
        label: (t) => {
          const numx = t.xLabel.toFixed(4);
          const numy = t.yLabel.toFixed(2);
          const label = `deformation: ${numx} stress : ${numy}`;
          return label;
        }
      }
    },
    elements: {
      point: { radius: 1 }
    },
    scales: {
      xAxes: [{
        type: 'linear',
        position: 'bottom',
        ticks: {
          // min: -6,
          // max: 6,
          // stepSize : 12
        },
        scaleLabel: {
          display: true,
          labelString: 'deformation (m)'
        }
      }],
      yAxes: [{
        type: 'linear',
        position: 'bottom',
        ticks: {
          // min: 0,
          // max: 12,
          // stepSize : 12
        },
        scaleLabel: {
          display: true,
          labelString: `stress (${fck.unit.text})`
        }
      }]
    }
  };
};

export default chartOptions;
