import React, { Fragment } from 'react';
import {
  PaginationItem,
  PaginationLink,
  Pagination as PaginationStrap
} from 'reactstrap';
import PropTypes from 'prop-types';

const Pagination = ({ paginatedFields, setPaginatedFields, pageNumbers }) => (
  <PaginationStrap size="sm">
    {paginatedFields.currentPage !== 1 &&
      <PaginationItem>
        <PaginationLink href="#" previous onClick={(event) => setPaginatedFields({ ...paginatedFields, currentPage: Number(paginatedFields.currentPage) - 1 })} />
      </PaginationItem>
    }
    {
      pageNumbers.map((number, index) =>
        <PaginationItem
          active={paginatedFields.currentPage === Number(number)}
          key={index}
        >
          <PaginationLink href="#" onClick={(event) => setPaginatedFields({ ...paginatedFields, currentPage: Number(number) })}>
            {number}
          </PaginationLink>
        </PaginationItem>
      )
    }
    {paginatedFields.currentPage !== pageNumbers.length &&
      <PaginationItem>
        <PaginationLink href="#" next onClick={(event) => setPaginatedFields({ ...paginatedFields, currentPage: Number(paginatedFields.currentPage) + 1 })} />
      </PaginationItem>
    }
  </PaginationStrap>
);

Pagination.propTypes = {
  paginatedFields: PropTypes.shape({
    currentPage: PropTypes.number,
    todosPerPage: PropTypes.number,
    fields: PropTypes.arrayOf(PropTypes.string)
  }).isRequired,
  setPaginatedFields: PropTypes.func.isRequired,
  pageNumbers: PropTypes.arrayOf(PropTypes.number).isRequired
};

export default Pagination;
