/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint react/prop-types: 0 */
import React, { Component } from 'react';
import * as THREE from 'three';
// import { MTLLoader, OBJLoader } from 'three-obj-mtl-loader';
// import { ColladaLoader } from 'three-collada-loader';
import { connect } from 'react-redux';
// import CSS2DRenderer from 'three/examples/js/renderers/CSS2DRenderer';
import Camera from './Scene3D/Camera';
import Light from './Scene3D/Light';
import Render from './Scene3D/Render';
import Control from './Scene3D/Control';

import mapStateToScene from './Elements3D/MapStateToScene';
import CreateElements from './Elements3D/CreateElements';
// import HouseObj from '../models/247_House 15_obj.obj';
// import LibertyStatueObj from '../models/LibertyStatue/LibertStatue.obj';
// import BattleShipObj from '../models/BattleShip/BattleShip.obj';
// import BattleShipMtl from '../models/BattleShip/BattleShip.mtl';
// import Building1Obj from '../models/Building1/Building1.obj';
// import Building1Mtl from '../models/Building1/Building1.mtl';
// import Building1Dae from '../models/Building1/Building1.dae';
// import LibertyStatueMtl from '../models/LibertyStatue/LibertStatue.mtl';

class RenderTHREEScene extends Component {
  constructor(props) {
    super(props);
    // this.start = this.start.bind(this)
    this.stop = this.stop.bind(this);
    // this.animate = this.animate.bind(this);
  }

  componentDidMount() {
    const width = this.mount.clientWidth;
    const height = this.mount.clientHeight;
    // const size = 100;
    // const divisions = 100;

    // ADD SCENE
    this.scene = new THREE.Scene();

    // ADD CAMERA
    this.camera = Camera(width, height);

    // ADD LIGHT
    this.light = Light(this.scene);

    // ADD RENDERER
    this.renderer = Render.renderer(this.mount, width, height);
    this.labelRenderer = Render.labelRenderer(this.mount, width, height);

    this.labelMesh = [];

    // ADD CONTROLS
    this.controls = Control(this.camera, '.sceneThreejs', this.renderScene);

    // ADD AXIS X, Y, Z
    // const axisHelper = new THREE.AxesHelper(2);
    // this.scene.add(axisHelper);


    // ADD 3D Elements
    const {
      structure3D,
      structure3DOutputs,
      uiManagement
    } = this.props;

    // ADD Global Axis
    const globalAxisMesh = [];
    this.globalAxis = CreateElements.globalAxis({
      scene: this.scene,
      globalAxisMesh,
      labelMesh: this.labelMesh,
      uiManagement,
      data: structure3D
    });

    // ADD Grid
    const gridMesh = [];
    this.grid = CreateElements.grid({
      scene: this.scene,
      gridMesh,
      uiManagement,
      data: structure3D
    });

    // ADD Node
    const nodeMesh = [];
    this.nodes = CreateElements.nodes({
      scene: this.scene,
      nodeMesh,
      labelMesh: this.labelMesh,
      uiManagement,
      data: structure3D
    });

    // ADD Member
    const memberMesh = [];
    this.members = CreateElements.members({
      scene: this.scene,
      memberMesh,
      labelMesh: this.labelMesh,
      uiManagement,
      data: structure3D
    });

    // ADD Node Load
    const nodeLoadMesh = [];
    this.nodeLoads = CreateElements.nodeLoads({
      scene: this.scene,
      nodeLoadMesh,
      labelMesh: this.labelMesh,
      uiManagement,
      data: structure3D
    });

    // ADD Member Load
    const memberLoadMesh = [];
    this.memberLoads = CreateElements.memberLoads({
      scene: this.scene,
      memberLoadMesh,
      labelMesh: this.labelMesh,
      uiManagement,
      data: structure3D
    });

    // ADD Member Sollicitation
    const memberSollicitationMesh = [];
    this.memberSollicitations = CreateElements.memberSollicitations({
      scene: this.scene,
      memberSollicitationMesh,
      labelMesh: this.labelMesh,
      uiManagement,
      data: structure3DOutputs
    });

    // const mtlLoader = new MTLLoader();
    // const objLoader = new OBJLoader();

    // mtlLoader.load(Building1Mtl, (materials) => {
    //   materials.preload();
    //   objLoader.setMaterials(materials);
    //   objLoader.load(Building1Obj, (object) => {
    //     this.modelMesh = object;
    //     // this.modelMesh.scale.set(0.012, 0.012, 0.012);
    //     this.modelMesh.scale.set(10, 10, 10);
    //     this.modelMesh.position.set(0, 0, 10);
    //     this.modelMesh.rotation.set(0, Math.PI / 2, Math.PI / 2);
    //     this.scene.add(object);
    //   });
    // });
    window.addEventListener('resize', this.onWindowResize, false);
    this.renderScene();
  }

  shouldComponentUpdate(nextProps) {
    return nextProps.sceneRefresh;
  }


  componentDidUpdate() {
    const {
      structure3D,
      structure3DOutputs,
      uiManagement,
      graphicalUIManagement,
      sceneRefresh,
      stopRefreshScene
    } = this.props;
    if (sceneRefresh === true) {
      this.renderer.renderLists.dispose();
      const {
        nodes,
        members,
        nodeLoads,
        memberLoads,
        memberSollicitations
      } = mapStateToScene(
        structure3D,
        structure3DOutputs,
        uiManagement,
        this.labelMesh,
        this.nodes.mesh,
        this.members.mesh,
        this.nodeLoads.mesh,
        this.memberLoads.mesh,
        this.memberSollicitations.mesh,
        this.scene
      );
      this.nodes = nodes;
      this.members = members;
      this.nodeLoads = nodeLoads;
      this.memberLoads = memberLoads;
      this.memberSollicitations = memberSollicitations;
      if (uiManagement.showPlanXY === true) {
        this.controls.reset();
        this.camera.position.set(0, 0, 200);
        graphicalUIManagement('showPlanXY', uiManagement.showPlanXY);
      }
      if (uiManagement.showPlanYZ === true) {
        this.controls.reset();
        this.camera.position.set(200, 0, 0);
        graphicalUIManagement('showPlanYZ', uiManagement.showPlanYZ);
      }
      if (uiManagement.showPlanXZ === true) {
        this.controls.reset();
        this.camera.position.set(0, -200, 0);
        graphicalUIManagement('showPlanXZ', uiManagement.showPlanXZ);
      }
      this.renderScene();
      // this.start();
      // this.animate();
      stopRefreshScene();
    }
  }

  componentWillUnmount() {
    this.mount.removeChild(this.renderer.domElement);
    this.mount.removeChild(this.labelRenderer.domElement);
    window.removeEventListener('resize', this.onWindowResize, false);
    this.stop();
  }

  // start = () => {
  //   if (!this.frameId) {
  //     console.log('FFFFFF');
  //     this.frameId = requestAnimationFrame(this.animate);
  //   }
  // }

  onWindowResize = () => {
    this.camera.aspect = this.mount.clientWidth / this.mount.clientHeight;
    this.camera.updateProjectionMatrix();
    this.renderer.setSize(this.mount.clientWidth, this.mount.clientHeight);
    this.labelRenderer.setSize(this.mount.clientWidth, this.mount.clientHeight);
  }

  stop = () => {
    cancelAnimationFrame(this.frameId);
  }

  onDocMouseDown = (event) => {
    const { onClickObjectData } = this.props;
    const position = event.target.getBoundingClientRect();
    const x = position.left;
    const y = position.top;
    // const mouse3D = new THREE.Vector3(
    //   (event.clientX / this.mount.clientWidth) * 2 - 1,
    //   -(event.clientY / this.mount.clientHeight) * 2 + 1,
    //   0.5
    // );
    const mouse3D = new THREE.Vector3(((event.clientX - x) / this.mount.clientWidth) * 2 - 1, -((event.clientY - y) / this.mount.clientHeight) * 2 + 1, 3);
    const raycaster = new THREE.Raycaster();
    raycaster.setFromCamera(mouse3D, this.camera);

    const mesh = [
      ...this.nodes.mesh,
      ...this.members.mesh
    ];

    const intersects = raycaster.intersectObjects(mesh);
    // let clickedObject = [];
    const clickedObject = mesh.map((item) => {
      return {
        mesh: item,
        click: false
      };
    });

    if (intersects.length > 0) {
      let clickedObjectData = '';
      clickedObject.map((item) => {
        if (item.mesh.uuid === intersects[0].object.uuid) {
          // eslint-disable-next-line no-param-reassign
          item.click = true;
          item.mesh.material.color.set('crimson');
          clickedObjectData = item.mesh.userData;
        } else {
          // eslint-disable-next-line no-param-reassign
          item.click = false;
          item.mesh.material.color.set(item.mesh.userData.color);
        }
        return item;
      });

      if (!!onClickObjectData && clickedObjectData !== '') {
        // eslint-disable-next-line react/destructuring-assignment
        onClickObjectData(clickedObjectData);
      }
    }
    // console.log(clickedObjectData)
    // this.props.onClickObjectData(clickedObjectData.data.name);
    this.renderScene();
  }

  // animate = () => {
  //   // this.frameId = window.requestAnimationFrame(this.animate);
  //   // requestAnimationFrame(this.animate);
  //   // console.log('RENDERRRRR')
  //   // setTimeout(() => {
  //   // console.log('FFFFFFFFFFFF')
  //   this.renderScene();
  //   this.frameId = window.requestAnimationFrame(this.animate);
  //   // this.controls.update();
  //   // }, 1000 / 30);
  // }

  // animate = () => {
  //   // this.frameId = window.requestAnimationFrame(this.animate);
  //   requestAnimationFrame(this.animate);

  //   this.renderScene();
  //   this.controls.update();
  // }

  renderScene = () => {
    this.renderer.render(this.scene, this.camera);
    this.labelRenderer.render(this.scene, this.camera);
  }


  render() {
    console.log('THREEJS');

    return (
      // eslint-disable-next-line jsx-a11y/click-events-have-key-events
      // eslint-disable-next-line jsx-a11y/no-static-element-interactions
      <div
        onClick={e => this.onDocMouseDown(e)}
        className="sceneThreejs"
        id="structure3D"
        // style={{ width: '1200px', height: '400px' }}
        ref={(mount) => { this.mount = mount; }}
      />
    );
  }
}

const mapStateToProps = (state) => {
  return {
    structure3D: state.form.structure3D.inputs.values,
    structure3DOutputs: state.form.structure3D.outputs,
    uiManagement: state.form.structure3D.inputs.ui,
    sceneRefresh: state.form.structure3D.inputs.sceneRefresh
  };
};

export default connect(mapStateToProps)(RenderTHREEScene);
