import React, { Component, useState, useEffect } from 'react';
import {
  CardDeck,
  Card,
  CardBody,
  CardText,
  CardTitle,
  CardSubtitle,
  CardImg,
  Button,
  Container,
  Breadcrumb,
  BreadcrumbItem,
  Col,
  Row,
  Input
} from 'reactstrap';

const Classe = ({
  classe
}) => {

  return (
    <Card
      link={`/datbim`}
      className='cardProject'
    >
      <CardBody>
        <CardTitle className="title">{`${classe.groupname}`}</CardTitle>
        <CardText className="subtitle">{`${classe.description}`}</CardText>
      </CardBody>
    </Card>
  )
};

export default Classe;
