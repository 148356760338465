import * as THREE from 'three';

export default (
  width,
  height
) => {
  // const camera = new THREE.OrthographicCamera(width / -2, width / 2, height / 2, height / -2, 0.1, 1000);
  // camera.position.set(0, -200, 35);
  const camera = new THREE.PerspectiveCamera(10, width / height, 1, 200000);
  camera.position.set(0, -200, 35);
  camera.up = new THREE.Vector3(0, 0, 1);
  camera.lookAt(new THREE.Vector3(0, 0, 0));

  return camera;
};
