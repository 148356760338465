import React from 'react';
import PropTypes from 'prop-types';
import {
  Row,
  Col,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Tooltip
} from 'reactstrap';
import ToolTips from '../../ToolTips';
import InputRF from '../InputRF';
import SelectRF from '../SelectRF';

const InputsUnit = ({
  label,
  value,
  onChange,
  selectList,
  inputName,
  selectName,
  tooltipDescription,
  tooltipTarget,
  disabled,
  type,
  validate
}) => (
  <div className="inputunit">
    <InputGroup>
      <InputGroupAddon className="inputgroupaddon" addonType="prepend">
        <InputGroupText className="inputgrouptext">{label}</InputGroupText>
        {/* </InputGroupAddon>
      <InputGroupAddon className="inputgroupaddon" addonType="prepend"> */}
        {/* <InputGroupText className="inputgroupinfo" id={input.name}>
          <i className="material-icons">info</i>
        </InputGroupText> */}
        <ToolTips
          description={tooltipDescription}
          target={tooltipTarget}
        />
        {/* <Tooltip placement="top" isOpen={this.state.tooltipOpen} target={input.name} toggle={this.toggle}>
          {input.description}
        </Tooltip> */}
      </InputGroupAddon>
      <InputRF
        type={type}
        name={inputName}
        onChange={onChange}
        validate={validate}
      />
      <InputGroupAddon addonType="append">
        {
          selectName &&
          <SelectRF
            className="selectunits"
            name={selectName}
            list={selectList}
            disabled
          />
        }
      </InputGroupAddon>
    </InputGroup>
  </div>
  // <Row className="fields-row">
  //   <Col md={3}>
  //     <p><strong>{label}</strong></p>
  //   </Col>
  //   <Col md={1} className="no-gutter align-left">
  //     <ToolTips description={tooltipDescription} target={tooltipTarget} />
  //   </Col>
  //   <Col md={5} className="no-gutter-right">
  //     <InputRF type="text" name={inputName} />
  //   </Col>
  //   {
  //     selectName && <Col md={2} className="no-gutter-left">
  //       <SelectRF
  //         name={selectName}
  //         list={selectList}
  //         disabled
  //       />
  //     </Col>
  //   }
  // </Row>
);

export default InputsUnit;
