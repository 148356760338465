import * as THREE from 'three';
import { CSS2DRenderer } from 'three-css2drender';

const Render = {
  renderer: (
    mount,
    width,
    height
  ) => {
    const renderer = new THREE.WebGLRenderer({ antialias: true, preserveDrawingBuffer: true });
    renderer.setClearColor('white', 1);
    renderer.setSize(width, height);
    mount.appendChild(renderer.domElement);

    return renderer;
  },
  labelRenderer: (
    mount,
    width,
    height
  ) => {
    const labelRenderer = new CSS2DRenderer();
    labelRenderer.setSize(width, height);
    labelRenderer.domElement.style.position = 'absolute';
    labelRenderer.domElement.style.top = 0;
    mount.appendChild(labelRenderer.domElement);

    return labelRenderer;
  }
};

export default Render;
