/* eslint no-underscore-dangle: 0 */
import { fromJS } from 'immutable';
import update from 'immutability-helper';
import initialState from './initialState';
import {
  ADD_ACTIVE,
  ADD_FIELDS,
  EDIT_FIELDS,
  S3_CHANGE_SECTION_GEOMETRY,
  S3_FETCH_SECTION_DATA,
  S3_SECTION_DATA_CALCULATION,
  S3_OUTPUTS,
  S3_CHANGE_NAME,
  S3_CHANGE_LIST_SELECTION,
  S3_UI_MANAGEMENT,
  S3_ACTIVE_MEMBER,
  S3_REMOVE_FIELDS,
  S3_GET_FIELDS,
  S3_LOAD_PROJECT,
  S3_NEW_PROJECT,
  S3_IMPORT_FROM_CSV,
  S3_FETCH_LOADING,
  S3_SCENE_REFRESH,
  S3_UI_MANAGEMENT_RESET,
  OUTPUTS_LINKS,
  ACTIVE_OBJECT
} from './actionTypes';

const Structure3D = (state = initialState, action = {}) => {
  switch (action.type) {
    case EDIT_FIELDS:
      return fromJS(state).updateIn(
        ['inputs', 'values', action.payload.section, 'value'],
        list => list.splice(action.payload.index, 1, action.payload.fields)
      ).updateIn(
        ['inputs', 'values', action.payload.section],
        item => item.set('active', action.payload.fields.name)
      ).toJSON();
    case ADD_FIELDS:
      return fromJS(state).updateIn(
        ['inputs', 'values', action.payload.section, 'value'],
        list => list.push(action.payload.fields)
      ).toJSON();
    case S3_REMOVE_FIELDS:
      return fromJS(state).updateIn(
        ['inputs', 'values', action.payload.section, 'value'],
        list => list.remove(action.payload.index)
      ).toJSON();
    case S3_GET_FIELDS:
      console.log(state);
      return fromJS(state).updateIn(
        ['inputs', 'values', action.payload.section],
        item => item.set('outputs', action.payload.fields)
      ).toJSON();
    case ADD_ACTIVE:
      return fromJS(state).updateIn(
        ['inputs', 'values', action.payload.section, 'characteristics'],
        list => list
          .set('name', action.payload.inputName)
          .set('sectionint', action.payload.inputName)
      ).toJSON();
    case S3_CHANGE_NAME:
      return fromJS(state).updateIn(
        ['inputs', 'values', action.payload.section, 'value', action.payload.index],
        item => item
          .set('name', action.payload.inputName)
          .set('label', action.payload.inputName)
          .set('value', action.payload.inputName)
      ).toJSON();
    case S3_CHANGE_LIST_SELECTION:
      if (action.payload.section === 'sections') {
        return fromJS(state).updateIn(['inputs', 'values', action.payload.section, 'value'],
          items => items.map((item) => {
            if (item.getIn(['characteristics', action.payload.field, 'value']) === action.payload.list[action.payload.index].name) {
              return item.setIn(['characteristics', action.payload.field, 'value'], action.payload.inputName).setIn(['characteristics', action.payload.field, 'label'], action.payload.inputName);
            }
            return item;
          })).toJSON();
      }
      return fromJS(state).updateIn(['inputs', 'values', action.payload.section, 'value'],
        items => items.map((item) => {
          if (item.get(action.payload.field) === action.payload.list[action.payload.index].name) {
            return item.set(action.payload.field, action.payload.inputName);
          }
          return item;
        })).toJSON();
    case S3_UI_MANAGEMENT:
      return fromJS(state).updateIn(
        ['inputs', 'ui'],
        bool => bool
          .set(action.payload.command, action.payload.bool)
      ).toJSON();
    case S3_UI_MANAGEMENT_RESET:
      return fromJS(state).updateIn(
        ['inputs', 'ui'],
        field => field.map(() => false)
      ).toJSON();
    case S3_FETCH_SECTION_DATA:
      return fromJS(state).updateIn(
        ['inputs', 'values', 'sections', 'listSectionTypes'],
        list => list
          .set('sectionext', action.payload.sectionext)
          .set('sectionint', action.payload.sectionint)
      ).toJSON();
    case S3_CHANGE_SECTION_GEOMETRY:
      return update(state, {
        inputs: {
          values: {
            sections: {
              listSectionTypes: {
                sectionext: {
                  $set: action.payload.sectionext
                },
                sectionint: {
                  $set: action.payload.sectionint
                },
                [action.payload.sectionType]: {
                  inputs: {
                    [action.payload.name]: {
                      value: {
                        $set: action.payload.value
                      }
                    }
                  }
                }
              }
            }
          }
        }
      });
    case S3_SECTION_DATA_CALCULATION:
      return update(state, {
        inputs: {
          values: {
            sections: {
              outputs: {
                name: {
                  $set: (action.payload.sectionType === 'editSection' ? action.payload.inputs.name : 'SectionName')
                },
                value: {
                  $set: (action.payload.sectionType === 'editSection' ? action.payload.inputs.name : 'SectionName')
                },
                label: {
                  $set: (action.payload.sectionType === 'editSection' ? action.payload.inputs.name : 'SectionName')
                },
                characteristics: {
                  xg: {
                    value: {
                      $set: Math.round(1000 * action.payload.data.xgra) / 1000
                    }
                  },
                  yg: {
                    value: {
                      $set: Math.round(1000 * action.payload.data.ygra) / 1000
                    }
                  },
                  area: {
                    value: {
                      $set: Math.round(1000 * action.payload.data.surf) / 1000
                    }
                  },
                  ixx: {
                    value: {
                      $set: Math.round(1000 * Number(action.payload.data.ixx)) / 1000
                    }
                  },
                  iyy: {
                    value: {
                      $set: Math.round(1000 * action.payload.data.iyy) / 1000
                    }
                  },
                  ixy: {
                    value: {
                      $set: Math.round(1000 * action.payload.data.ixy) / 1000
                    }
                  },
                  sectionext: {
                    $set: action.payload.sectionext
                  },
                  sectionint: {
                    $set: action.payload.sectionint
                  },
                  material: {
                    value: {
                      $set: (action.payload.sectionType === 'editSection' ? action.payload.inputs.characteristics.material.value : action.payload.structure3D.materials.value[0].name)
                    },
                    label: {
                      $set: (action.payload.sectionType === 'editSection' ? action.payload.inputs.characteristics.material.label : action.payload.structure3D.materials.value[0].name)
                    }
                  },
                  color: {
                    $set: (action.payload.sectionType === 'editSection' ? action.payload.inputs.characteristics.color : 'blue')
                  }
                }
              }
            }
          }
        }
      });
    case S3_OUTPUTS:
      return update(state, {
        outputs: {
          logs: {
            $set: action.payload.logs
          },
          nodeDisplacements: {
            $set: action.payload.nodeDisplacements
          },
          reactions: {
            $set: action.payload.reactions
          },
          localForces: {
            $set: action.payload.localForces
          },
          activeMember: {
            $set: action.payload.localForces[0]
          }
        }
      });
    case S3_ACTIVE_MEMBER:
      return update(state, {
        outputs: {
          activeMember: {
            $set: action.payload.member
          }
        }
      });
    case S3_FETCH_LOADING:
      return update(state, {
        loading: {
          $set: action.payload
        }
      });
    case S3_LOAD_PROJECT:
      console.log('SSSSSS', state);
      return fromJS(state)
        .updateIn(['inputs', 'values'], list => list.set('_id', action.payload._id))
        .updateIn(['inputs', 'values'], list => list.set('userId', action.payload.userId))
        .updateIn(['inputs', 'values'], list => list.set('options', action.payload.options))
        .updateIn(['inputs', 'values', 'materials'], list => list.set('value', action.payload.materials.value))
        .updateIn(['inputs', 'values', 'sections'], list => list.set('value', action.payload.sections.value))
        .updateIn(['inputs', 'values', 'supports'], list => list.set('value', action.payload.supports.value))
        .updateIn(['inputs', 'values', 'nodes'], list => list.set('value', action.payload.nodes.value))
        .updateIn(['inputs', 'values', 'members'], list => list.set('value', action.payload.members.value))
        .updateIn(['inputs', 'values', 'nodeLoads'], list => list.set('value', action.payload.nodeLoads.value))
        .updateIn(['inputs', 'values', 'memberLoads'], list => list.set('value', action.payload.memberLoads.value))
        .toJSON();
    case ACTIVE_OBJECT:
      return fromJS(state)
        .updateIn(
          ['inputs', 'values', action.payload.findArrayToReset, 'value'],
          storeListings => storeListings.map((listing) => {
            return listing.set(
              'active',
              false
            );
          })
        )
        .updateIn(
          ['inputs', 'values', `${action.payload.object.clickObjectData.type}s`, 'value'],
          storeListings => storeListings.map((listing) => {
            if (listing.get('_id') === action.payload.object.clickObjectData._id) {
              return listing.set(
                'active',
                action.payload.object.displayObjectInfo
              ).set(
                'type',
                action.payload.object.clickObjectData.type
              );
            }
            return listing.set(
              'active',
              false
            );
          })
        )
        .toJSON();
    case S3_NEW_PROJECT:
      return fromJS(state)
        .updateIn(['inputs'], list => list.set('values', initialState.inputs.values))
        .toJSON();
    case S3_IMPORT_FROM_CSV:
      // if (action.payload.dataType === 'nodes') {
      //   return fromJS(state).updateIn(['inputs', 'values', action.payload.dataType, 'value'],
      //     list => list.map((item) => {
      //       if (item.getIn(['characteristics', action.payload.field, 'value']) === action.payload.list[action.payload.index].name) {
      //         return item.setIn(['characteristics', action.payload.field, 'value'], action.payload.inputName).setIn(['characteristics', action.payload.field, 'label'], action.payload.inputName);
      //       }
      //       return item;
      //     })).toJSON();
      // }
      return fromJS(state)
        .updateIn(['inputs', 'values', action.payload.dataType], list => list.set('value', action.payload.inputs))
        // .updateIn(['inputs', 'values', action.payload.dataType, 'value'], list => list.map(
        //   item => item.set('label', item.get('name')).set('value', item.get('name'))
        // ))
        .toJSON();
    case S3_SCENE_REFRESH:
      console.log('REDUCERREFRESH', action.payload);
      return update(state, {
        inputs: {
          sceneRefresh: {
            $set: action.payload.sceneRefresh
          }
        }
      });
    case OUTPUTS_LINKS:
      return update(state, {
        outputs: {
          links: {
            error: {
              $set: action.payload.error
            },
            link: {
              $set: action.payload.link
            },
            loading: {
              $set: action.payload.loading
            }
          },
          logs: {
            message: {
              $set: action.payload.logs
            }
          }
        }
      });
    default:
      // return fromJS(state).updateIn(
      //   ['inputs', 'values', 'materials', 'value'],
      //   materials => materials.map(
      //     material => material.set('label', material.get('name')).set('value', material.get('name'))
      //   )
      // ).toJSON();
      return state;
  }
};

export default Structure3D;
