import React, { Fragment } from 'react';
import {
  Button,
  Container,
  Row,
  Col,
  Label,
  Table
} from 'reactstrap';
import { compose } from 'recompose';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import SelectRF from '../../../../../../../../../../components/UIComponents/Fields/SelectRF';
import InputField from '../../../../../../../../../../components/UIComponents/Fields/InputRF';
import {
  changeName,
  changeListSelection
} from '../../../../../../actions';

const MembersModal = ({
  fields,
  membersList,
  sectionsList,
  nodesList,
  changeName,
  changeListSelection,
}) => (
    <Container>
      {fields.map((member, index) => (
        membersList[index].active &&
        <div key={index}>
          <Row>
            <Col xs={3}>
              <Label className="label">Name</Label>
            </Col>
            <Col xs={9}>
              <InputField
                name={`${member}.name`}
                type="text"
                onChange={(e) => {
                  const val = e.target.value;
                  changeName('members', index, val);
                }}
              />
            </Col>
          </Row>
          <Row>
            <Col xs={3}>
              <Label className="label">Sect</Label>
            </Col>
            <Col xs={9}>
              <SelectRF
                name={`${member}.section`}
                list={sectionsList}
              />
            </Col>
          </Row>
          <Row>
            <Col xs={3}>
              <Label className="label">Ni</Label>
            </Col>
            <Col xs={9}>
              <SelectRF
                name={`${member}.nodei`}
                list={nodesList}
              />
            </Col>
          </Row>
          <Row>
            <Col xs={3}>
              <Label className="label">Nj</Label>
            </Col>
            <Col xs={9}>
              <SelectRF
                name={`${member}.nodej`}
                list={nodesList}
              />
            </Col>
          </Row>
        </div>
      ))}
    </Container>
  );

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({
    changeName,
    changeListSelection
  }, dispatch);
};

const mapStateToProps = (state) => {
  return {
    membersList: state.form.structure3D.inputs.values.members.value,
    sectionsList: state.form.structure3D.inputs.values.sections.value,
    nodesList: state.form.structure3D.inputs.values.nodes.value
  };
};


export default compose(
  connect(mapStateToProps, mapDispatchToProps),
)(MembersModal);