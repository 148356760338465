import { CHANGE_LANGUAGE } from './actionTypes';

function changeLanguage(lang) {
  const { value } = lang.target;
  return {
    type: CHANGE_LANGUAGE,
    payload: {
      lang: value
    }
  };
}

export {
  changeLanguage
};
