import * as THREE from 'three';

export default (name, {
  scene,
  gridMesh,
  uiManagement,
  data
}) => {
  const listnodes = data.nodes.value;

  // Deleting all grid mesh
  if (typeof gridMesh !== 'undefined' && gridMesh.length > 0) {
    for (let i = gridMesh.length - 1; i >= 0; i -= 1) {
      scene.remove(gridMesh[i]);
      // gridMesh[i].geometry.dispose();
      // gridMesh[i].material.dispose();
      gridMesh.pop();
    }
  }

  if (!uiManagement.showGrid) {
    let Xmin = 0;
    let Ymin = 0;
    let Zmin = 0;
    let Xmax = 0;
    let Ymax = 0;
    let Zmax = 0;
    for (let i = 0; i < listnodes.length; i += 1) {
      Xmin = Math.min(Number(listnodes[i].X), Xmin);
      Ymin = Math.min(Number(listnodes[i].Y), Ymin);
      Zmin = Math.min(Number(listnodes[i].Z), Zmin);
      Xmax = Math.max(Number(listnodes[i].X), Xmax);
      Ymax = Math.max(Number(listnodes[i].Y), Ymax);
      Zmax = Math.max(Number(listnodes[i].Z), Zmax);
    }
    const H = Math.trunc(Math.abs(Xmax - Xmin)) + 1 + 100;
    const W = Math.trunc(Math.abs(Ymax - Ymin)) + 1 + 100;

    const config = {
      height: H,
      width: W,
      linesHeight: H * 2,
      linesWidth: W * 2,
      color: 0xBDBDBD,
      opacity: 0.1
    };

    const material = new THREE.LineBasicMaterial({
      color: config.color,
      opacity: config.color
    });

    const gridObject = new THREE.Object3D();
    const gridGeo = new THREE.Geometry();
    const stepw = 2 * config.width / config.linesWidth;
    const steph = 2 * config.height / config.linesHeight;
    // width
    for (let i = -config.width; i <= config.width; i += stepw) {
      gridGeo.vertices.push(new THREE.Vector3(-config.height, i, Zmin));
      gridGeo.vertices.push(new THREE.Vector3(config.height, i, Zmin));
      if (i !== config.width) {
        gridGeo.vertices.push(new THREE.Vector3(config.height, i + stepw, Zmin));
      }
    }

    // height
    for (let i = config.height; i >= -config.height; i -= steph) {
      gridGeo.vertices.push(new THREE.Vector3(i, config.width, Zmin));
      gridGeo.vertices.push(new THREE.Vector3(i, -config.width, Zmin));
      if (i !== -config.height) {
        gridGeo.vertices.push(new THREE.Vector3(i - steph, -config.width, Zmin));
      }
    }
    const line = new THREE.LineSegments(gridGeo, material, THREE.LinePieces);
    gridObject.add(line);

    scene.add(line);
  }

  return {
    mesh: gridMesh
  };
};
