import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import {
  Container,
  Row,
  Col,
  Button,
  Progress,
  Spinner
} from 'reactstrap';
import InputField from '../Fields/InputRF';

const Options = ({
  optionName,
  saveStructure3DProject,
  history,
  structure3D,
  loadingCalculation,
  isNewProject,
  saveProjectState
}) => {

  const displaySaveProjectAlert = () => {
    toast.error('You need to save your project before', {
      autoClose: 2000
    });
  };

  return (
    <Container fluid className="horizontalOptionsMenu">
      <Row>
        <Col xs={6} className="align-left">
          <Row>
            <Col xs={1} className="align-left vertical-align">
              <Link to onClick={() => history.goBack()} className="cardlink">
                <i className="material-icons">
                  keyboard_arrow_left
                </i>
              </Link>
            </Col>
            <Col xs={4} className="align-left vertical-align">
              {/* <Input className="cardprojecttitle" /> */}
              {saveStructure3DProject ?
                <InputField
                  className="projectName"
                  name="options.projectname.value"
                  type="text"
                />
                :
                <div>{optionName}</div>
              }
            </Col>
            {saveStructure3DProject &&
              <Col xs={4} className="align-left vertical-align">
                <Button
                  className="negativeButton"
                  outline
                  color="primary"
                  onClick={() => {
                    saveStructure3DProject(structure3D);
                  }}
                  disabled={!saveProjectState}
                >
                  {'Save'}
                </Button>
              </Col>
            }
          </Row>
        </Col>
        {saveStructure3DProject &&
          <Col xs={6} className="align-right">
            <Row className="justify-content-between">
              <Col xs={4} className="align-right vertical-align">
                {/* <Progress animated value={2 * 5} /> */}
              </Col>
              <Col xs={4} className="align-right vertical-align">
                {(isNewProject) &&
                  <Button
                    className="positiveButton"
                    //outline
                    style={{backgroundColor: "transparent", color: "#007bff"}}
                    color="secondary"
                    onClick={() => {
                      if(isNewProject) {
                        displaySaveProjectAlert()
                      }
                    }}
                    //disabled={isNewProject || loadingCalculation}
                  >
                    {'Analysis'}
                  </Button>
                }
                {(loadingCalculation) &&
                  <Button
                    className="positiveButton"
                    //outline
                    style={{backgroundColor: "transparent", color: "#007bff"}}
                    color="secondary"
                    onClick={() => {
                      if(isNewProject) {
                        displaySaveProjectAlert()
                      }
                    }}
                    //disabled={isNewProject || loadingCalculation}
                  >
                    <Spinner type="grow" color="info" />
                    {'Calculation...'}
                  </Button>
                }
                {!(isNewProject || loadingCalculation) &&
                  <Button
                    className="positiveButton"
                    outline
                    color="primary"
                    type="submit"
                    onClick={() => {
                      if(isNewProject) {
                        displaySaveProjectAlert()
                      }
                    }}
                    disabled={isNewProject || loadingCalculation}
                  >
                    {'Analysis'}
                  </Button>
                }
              </Col>
            </Row>
          </Col>
        }
      </Row>
    </Container>
  );
};

Options.propTypes = {
  optionName: PropTypes.string.isRequired,
  history: PropTypes.shape({
    goBack: PropTypes.func.isRequired
  }).isRequired,
  //saveStructure3DProject: PropTypes.bool.isRequired,
  isNewProject: PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => {
  return {
    structure3D: state.form.structure3D.inputs.values,
    loadingCalculation: state.form.structure3D.outputs.links.loading,
  };
};

export default connect(mapStateToProps)(memo(Options));
