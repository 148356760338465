import React, { useState, useEffect } from 'react';
import {
  Table
} from 'reactstrap';
import { compose } from 'recompose';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import SelectRF from '../../../../../../../../components/UIComponents/Fields/SelectRF';
import InputField from '../../../../../../../../components/UIComponents/Fields/InputRF';
import {
  addFields,
  editFields,
  setActive,
  changeName,
  changeListSelection
} from '../../../../actions';
import optionsDensityUnits from '../../../../../../../../utils/units/optionsDensityUnits2';
import optionsStressUnits from '../../../../../../../../utils/units/optionsStressUnits2';
import usePagination from '../../../../../../../../utils/DataTable/usePagination';
import useFilter from '../../../../../../../../utils/DataTable/useFilter';
import Pagination from '../../../../../../../../components/UIComponents/Pagination';
import SearchInput from '../../../../../../../../components/UIComponents/Fields/SearchInput';


const NodeLoads = ({
  fields,
  unit,
  active,
  nodesList,
  changeName,
  changeListSelection,
  addFields,
  setActive
}) => {
  const [filteredFields, setFilteredFields] = useState(fields);
  useEffect(() => {
    setFilteredFields(fields);
  }, [fields]);

  const onSearch = input => setFilteredFields(useFilter(fields, input).matchedFields);

  const {
    pageNumbers,
    currentTodos,
    paginatedFields,
    setPaginatedFields
  } = usePagination(filteredFields);

  const findIndex = (type) => {
    const squareBracketsRegex = /\[(.*?)\]/;
    const matchField = type.match(squareBracketsRegex)[0];
    const index = matchField.replace(/^\[(.+)\]$/, '$1');
    return parseInt(index);
  };

  return (
    <div>
      <div>
        <SearchInput onSearch={onSearch} />
      </div>
      <Table responsive>
        <thead>
          <tr className="column">
            <th>name</th>
            <th>node</th>
            <th>{`FX(${unit.force.text})`}</th>
            <th>{`FY(${unit.force.text})`}</th>
            <th>{`FZ(${unit.force.text})`}</th>
            <th>{`MX(${unit.moment.text})`}</th>
            <th>{`MY(${unit.moment.text})`}</th>
            <th>{`MZ(${unit.moment.text})`}</th>
            <th>
            </th>
            <th>
            </th>
          </tr>
        </thead>
        <tbody>
          {currentTodos.map((type, index) => (
            <tr key={index}>
              <td className="tableTd">
                <InputField
                  name={`${type}.name`}
                  type="text"
                  onChange={(e) => {
                    const val = e.target.value;
                    changeName('nodeLoads', findIndex(type), val);
                  }}
                />
              </td>
              <td className="tableTd">
                <SelectRF
                  name={`${type}.node`}
                  list={nodesList}
                />
              </td>
              <td className="tableTd">
                <InputField name={`${type}.forceX`} />
              </td>
              <td className="tableTd">
                <InputField name={`${type}.forceY`} />
              </td>
              <td className="tableTd">
                <InputField name={`${type}.forceZ`} />
              </td>
              <td className="tableTd">
                <InputField name={`${type}.momentX`} />
              </td>
              <td className="tableTd">
                <InputField name={`${type}.momentY`} />
              </td>
              <td className="tableTd">
                <InputField name={`${type}.momentZ`} />
              </td>
              <td className="tableTd">
                <i
                  className="material-icons"
                  onClick={() => {
                    const val = Math.floor(Math.random() * 10000);
                    const itemIndex = findIndex(type);
                    fields.insert(itemIndex + 1, {
                      name: `NodeLoad${val}`,
                      label: `NodeLoad${val}`,
                      value: `NodeLoad${val}`,
                      node: nodesList[0].name,
                      forceX: 0,
                      forceY: 0,
                      forceZ: 0,
                      momentX: 0,
                      momentY: 0,
                      momentZ: 0
                    });
                  }}
                >
                  {'add_circle'}
                </i>
              </td>
              <td className="tableTd">
                {
                  fields.length > 1 &&
                  <i
                    className="material-icons"
                    onClick={() => {
                      const itemIndex = findIndex(type);
                      fields.remove(itemIndex)
                    }}
                  >
                    {'remove_circle'}
                  </i>
                }
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
      <Pagination
        pageNumbers={pageNumbers}
        currentTodos={currentTodos}
        paginatedFields={paginatedFields}
        setPaginatedFields={setPaginatedFields}
      />
    </div>
  )
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({
    addFields,
    editFields,
    setActive,
    changeName,
    changeListSelection
  }, dispatch);
};

export default compose(
  connect(null, mapDispatchToProps),
)(NodeLoads);
