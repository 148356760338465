import axios from 'axios';
import { toast } from 'react-toastify';
import {
  GET_PLANS,
  SELECT_PLAN,
  SUBSCRIBED_PLAN,
  UNSUBSCRIBED_PLAN
} from './actionTypes';
import { URL } from '../../../../config/config';
import URL_PATH from '../../../../config/default';

const getEntrepriseQuotationDemand = () => {
  return async () => {
    try {
      await axios.post(`${URL}${URL_PATH.payments.entrepriseQuotationDemand}`, {
        firstname: document.getElementById('firstname').value,
        lastname: document.getElementById('lastname').value,
        company: document.getElementById('company').value,
        email: document.getElementById('email').value,
        message: document.getElementById('message').value
      }).then(() => {
        toast.success('🚀 Thank you for your message!! We will contact you.', {
          autoClose: 1500
        });
      });
    } catch (error) {
      toast.error('😞 No access to the server!', {
        autoClose: 1500
      });
      throw error;
    }
  };
};

const getPlans = () => {
  return async (dispatch) => {
    const request = await axios.get(`${URL}${URL_PATH.payments.plans}`);
    dispatch({
      type: GET_PLANS,
      payload: request.data.plans.data
    });
  };
};

const selectPlan = (props) => {
  return async (dispatch) => {
    dispatch({
      type: SELECT_PLAN,
      payload: props
    });
  };
};

const createStripeCustomer = async (customer) => {
  const subscribtion = {
    cardToken: customer.cardToken,
    email: customer.email
  };

  try {
    const stripeCustomer = await axios.post(`${URL}${URL_PATH.payments.customer}`, subscribtion);
    const customerId = stripeCustomer.data.customer.id;

    const user = JSON.parse(localStorage.getItem('user'));
    const userUpdated = { ...user, customerStripeID: customerId };
    localStorage.setItem('user', JSON.stringify(userUpdated));
  } catch (error) {
    toast.error('Cannot subscribe');
    throw error;
  }
};

const unsubscribePlan = async (planId) => {
  try {
    await axios.delete(`${URL}${URL_PATH.payments.unsubscribe}`, {
      data: { subscriptionId: planId }
    });
    const user = JSON.parse(localStorage.getItem('user'));
    const { subscriptionId, ...userWithoutSubscriptionId } = user; // Destructures subscriptionId off the user object, and collect all rest props
    localStorage.setItem('user', JSON.stringify(userWithoutSubscriptionId));
  } catch (error) {
    toast.error('Cannot unsubscribe');
    throw error;
  }
};

const subscribeToPlan = async (subscriptionToken) => {
  const user = JSON.parse(localStorage.getItem('user'));

  const subscribtion = {
    subscriptionToken,
    customerId: user.customerStripeID
  };

  try {
    const plan = await axios.post(`${URL}${URL_PATH.payments.subscription}`, subscribtion);

    const user = JSON.parse(localStorage.getItem('user'));
    const { subscriptionId } = plan.data.customerSubscription;
    const subscriptionUpdated = { ...user, subscriptionId };
    localStorage.setItem('user', JSON.stringify(subscriptionUpdated));
    return subscriptionId;
  } catch (error) {
    toast.error('Cannot subscribe');
    throw error;
  }
};

const deleteAccount = (user) => {
  return async (dispatch) => {
    try {
      const { email } = user;
      await axios.delete(`${URL}${URL_PATH.user}`, {
        data: {
          email
        }
      });
      localStorage.removeItem('token');
      localStorage.removeItem('user');
      dispatch({
        type: 'AUTHENTICATION_FAILURE',
        isAuth: false
      });
    } catch (error) {
      toast.error('Cannot delete account');
      throw error;
    }
  };
};

const getSubscribedPlan = (planId) => {
  return async (dispatch) => {
    try {
      const { data } = await axios.get(`${URL}${URL_PATH.payments.subscription}/${planId}`);
      dispatch({
        type: SUBSCRIBED_PLAN,
        payload: data
      });
    } catch (error) {
      toast.error('Cannot get Plan');
      throw error;
    }
  };
};

const getUnSubscribedPlan = () => {
  return async (dispatch) => {
    dispatch({
      type: UNSUBSCRIBED_PLAN,
      payload: ''
    });
  };
};

export {
  getPlans,
  getEntrepriseQuotationDemand,
  getSubscribedPlan,
  getUnSubscribedPlan,
  subscribeToPlan,
  selectPlan,
  unsubscribePlan,
  createStripeCustomer,
  deleteAccount
};
