import React from 'react';
import Helmet from 'react-helmet';
import {
  Router,
  Switch,
  Route
} from 'react-router-dom';
import ReactGA from 'react-ga';
import { connect } from 'react-redux';
import { ToastContainer } from 'react-toastify';
import NavBarConnection from './components/UIComponents/Bar/NavBarConnection';
import SignIn from './scenes/SignIn';
import SignUp from './scenes/SignUp';
import ForgotPassword from './scenes/ForgotPassword';
import Applications from './scenes/Dashboard/scenes/Applications';
import Projects from './scenes/Dashboard/scenes/Projects';
import Settings from './scenes/Dashboard/scenes/Settings';
import Account from './scenes/Dashboard/scenes/Account';
import ConcreteSectionProjects from './scenes/ConcreteSection/scenes/Projects';
import ConcreteSectionDocumentation from './scenes/ConcreteSection/scenes/Documentation';
import ConcreteSectionSectionTypes from './scenes/ConcreteSection/scenes/SectionTypes';
import ConcreteSectionCalculation from './scenes/ConcreteSection/scenes/ConcreteSectionCalculation/ConcreteSectionCalculation';
import SectionGeometrySectionTypes from './scenes/SectionGeometry/scenes/SectionTypes';
import SectionGeometryCalculation from './scenes/SectionGeometry/scenes/SectionGeometryCalculation';
import SteelSectionProjects from './scenes/SteelSection/scenes/Projects';
import SteelSectionDocumentation from './scenes/SteelSection/scenes/Documentation';
import SteelSectionSectionTypes from './scenes/SteelSection/scenes/SectionTypes';
import SteelSectionCalculation from './scenes/SteelSection/scenes/SteelSectionCalculation/SteelSectionCalculation';
import BeamFormulas from './scenes/StructuralAnalysis/scenes/BeamFormulas';
import RegressionCalculation from './scenes/Regression/scenes/RegressionCalculation';
import WaveCharacteristicsCalculation from './scenes/WaveCharacteristics/scenes/WaveCharacteristicsCalculation';
import Structure3DCalculation from './scenes/Structure3D/scenes/Structure3DCalculation';
import Structure3DProjects from './scenes/Structure3D/scenes/Projects';
import RectangularOutputs from './scenes/ConcreteSection/scenes/ConcreteSectionCalculation/scenes/Outputs';
import Structure3DOutputs from './scenes/Structure3D/scenes/Structure3DCalculation/scenes/Outputs';
import Structure3DErrorMessage from './scenes/Structure3D/scenes/Structure3DCalculation/scenes/ErrorMessage';
import DatBimAPI from './scenes/DatBim/scenes/API';
import DatBimAPICategory from './scenes/DatBim/scenes/API/scenes/Category';
import PrivateRoute from './components/HOC/PrivateRoute';
import 'bootstrap/dist/css/bootstrap.min.css';
import NavBarApp from './components/UIComponents/Bar/NavBarApp';
import history from './history';

const {
  REACT_APP_COMPANY,
  REACT_APP_LOGO
} = process.env;


const initializeReactGA = () => {
  ReactGA.initialize('UA-130402688-1');
  ReactGA.pageview(window.location.pathname + window.location.search);
  history.listen(location => ReactGA.pageview(location.pathname));
};


class App extends React.Component {
  componentDidMount() {
    // Include the Crisp code here, without the <script></script> tags
    window.$crisp = [];
    window.CRISP_WEBSITE_ID = 'c2bf312a-5262-4f78-b5bd-d50111d23adb';
    const chat = () => {
      const d = document;
      const s = d.createElement('script');

      s.src = 'https://client.crisp.chat/l.js';
      s.async = 1;
      d.getElementsByTagName('head')[0].appendChild(s);
    };
    chat();
    initializeReactGA();
  }

  render() {
    const { isAuthenticated } = this.props;
    return (
      <React.Fragment>
        <Helmet>
          <title>{`${REACT_APP_COMPANY} | Applications`}</title>
          <link rel="icon" type="image/png" href={REACT_APP_LOGO} sizes="16x16" />
        </Helmet>
        <ToastContainer autoClose={8000} />
        <Router history={history}>
          <React.Fragment>
            {!isAuthenticated && <NavBarConnection />}
            {isAuthenticated && <NavBarApp
              apptitle="TriAzur"
            />
            }
            <Switch>
              <Route exact path="/" component={SignUp} />
              <Route path="/signin" component={SignIn} />
              <Route path="/signup" component={SignUp} />
              <Route exact path="/forgotPassword" component={ForgotPassword} />
              <Route exact path="/forgotPassword/:token" component={ForgotPassword} />
              <Route exact path="/dashboard" component={PrivateRoute(Applications)} />
              <Route exact path="/account" component={PrivateRoute(Account)} />
              <Route exact path="/settings" component={PrivateRoute(Settings)} />
              <Route exact path="/dashboard/applications" component={PrivateRoute(Applications)} />
              <Route exact path="/dashboard/projects" component={PrivateRoute(Projects)} />
              <Route exact path="/dashboard/settings" component={PrivateRoute(Settings)} />
              <Route exact path="/concretesection" component={PrivateRoute(ConcreteSectionSectionTypes)} />
              <Route exact path="/concretesection/projects" component={PrivateRoute(ConcreteSectionProjects)} />
              <Route exact path="/concretesection/documentation" component={PrivateRoute(ConcreteSectionDocumentation)} />
              <Route exact path="/concretesection/sectiontypes" component={PrivateRoute(ConcreteSectionSectionTypes)} />
              <Route exact path="/concretesection/:sectionType" component={PrivateRoute(ConcreteSectionCalculation)} />
              <Route exact path="/steelsection" component={PrivateRoute(SteelSectionSectionTypes)} />
              <Route exact path="/steelsection/projects" component={PrivateRoute(SteelSectionProjects)} />
              <Route exact path="/steelsection/documentation" component={PrivateRoute(SteelSectionDocumentation)} />
              <Route exact path="/steelsection/sectiontypes" component={PrivateRoute(SteelSectionSectionTypes)} />
              <Route exact path="/steelsection/:sectionType" component={PrivateRoute(SteelSectionCalculation)} />
              <Route exact path="/sectiongeometry" component={PrivateRoute(SectionGeometrySectionTypes)} />
              <Route exact path="/sectiongeometry/sectiontypes" component={PrivateRoute(SectionGeometrySectionTypes)} />
              <Route exact path="/concretesection/rectangularconcretesection/output" component={PrivateRoute(RectangularOutputs)} />
              <Route exact path="/sectiongeometry/calculation/:sectionType" component={PrivateRoute(SectionGeometryCalculation)} />
              <Route exact path="/structuralanalysis/beamformulas" component={PrivateRoute(BeamFormulas)} />
              <Route exact path="/regression" component={PrivateRoute(RegressionCalculation)} />
              {/* <Route exact path="/wavecharacteristics" component={PrivateRoute(WaveCharacteristics)} /> */}
              <Route exact path="/structure3d/projects/:id" component={PrivateRoute(Structure3DCalculation)} />
              <Route exact path="/structure3d/projects/:projectId/output/:outputId" component={PrivateRoute(Structure3DOutputs)} />
              <Route exact path="/structure3d/projects/:projectId/logs" component={PrivateRoute(Structure3DErrorMessage)} />
              <Route exact path="/structure3d/projects" component={PrivateRoute(Structure3DProjects)} />
              <Route exact path="/datbim" component={PrivateRoute(DatBimAPI)} />
              <Route exact path="/datbim/:category" component={PrivateRoute(DatBimAPICategory)} />
              <Route exact path="/wavecharacteristics" component={PrivateRoute(WaveCharacteristicsCalculation)} />
              {/* <Route path="/user-profile" component={PrivateRoute(LoadableConcreteSection)} /> */}
            </Switch>
          </React.Fragment>
        </Router>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    isAuthenticated: state.signin.isAuth
  };
};

export default connect(mapStateToProps)(App);
