import React, { memo, useState, useEffect } from 'react';
import {
  Card,
  CardHeader,
  CardBody,
  Row,
  Col,
  Label,
  Input,
  ButtonGroup,
  Button,
  Collapse,
  Dropdown,
  ButtonDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Tooltip
} from 'reactstrap';
import PropTypes from 'prop-types';
import Draggable from 'react-draggable';
import DisplayButton from './components/DisplayButton';
import { connect } from 'react-redux';
import LocalAxisSvg from '../../../../img/LocalAxis.svg';
import TriDElementsSvg from '../../../../img/3DElements1.svg';
import NodesSvg from '../../../../img/Nodes.svg';
import MembersSvg from '../../../../img/Members.svg';
import NodeLoadsSvg from '../../../../img/NodeLoads.svg';
import MemberLoadsSvg from '../../../../img/MemberLoads.svg';
import TwoDPlanSvg from '../../../../img/2DPlan.svg';

const displayButtonList = [{
    name: 'showLocalAxis',
    label: 'Local Axis',
    img: LocalAxisSvg
  },{
    name: 'show3DStructure',
    label: '3D Elements',
    img: TriDElementsSvg
  },{
    name: 'showNode',
    label: 'Nodes',
    img: NodesSvg
  },{
    name: 'showMember',
    label: 'Members',
    img: MembersSvg
  },{
    name: 'showNodeLoad',
    label: 'Node Loads',
    img: NodeLoadsSvg
  },{
    name: 'showMemberLoad',
    label: 'Member Loads',
    img: MemberLoadsSvg
  },
]


const GraphicUI = ({
  uiManagement,
  refreshScene
}) => {
  const [left, setLeft] = useState(false);
  const [color, setColor] = useState({backgroundColor: "white" });
  

  return (
    <Draggable
      defaultPosition={{ x: 0, y: 0 }}
      bounds='.sceneThreejs'
    >
      <Card id="card" className="graphicUi">
        {/*<CardHeader id="headerinputs">
          <Label>Display</Label>
        </CardHeader>*/}
        <CardBody className="body">
          {displayButtonList.map((item, index) => (
            <DisplayButton
              uiManagement={uiManagement}
              refreshScene={refreshScene}
              name={item.name}
              label={item.label}
              img={item.img}
              key={index}
            />
          ))}
          <Dropdown
            direction="left"
            isOpen={left}
            toggle={() => setLeft(!left)}
          >
            <DropdownToggle
              caret
              style={color}
              className="alternativeButton"
              onClick={() => {
                if(color.backgroundColor === "white") {
                  setColor({backgroundColor: "LightGray" });
                } else {
                  setColor({backgroundColor: "white" });
                }
              }} 
            >
              <img className="" src={TwoDPlanSvg}></img>
            </DropdownToggle>
            <DropdownMenu>
              <DropdownItem
                tag="div"
              >
                <Row className="align-center">
                  <Col xs={12} className="align-center">
                    <ButtonGroup className="align-center">
                      <Button
                        className="negativeButton"
                        onClick={() => refreshScene('showPlanXY', uiManagement.showPlanXY)}
                      >
                        {'X-Y'}
                      </Button>
                      <Button
                        className="negativeButton"
                        onClick={() => refreshScene('showPlanYZ', uiManagement.showPlanYZ)}
                      >
                        {'Y-Z'}
                      </Button>
                      <Button
                        className="negativeButton"
                        onClick={() => refreshScene('showPlanXZ', uiManagement.showPlanXZ)}
                      >
                        {'X-Z'}
                      </Button>
                    </ButtonGroup>
                  </Col>
                </Row>
              </DropdownItem>
            </DropdownMenu>
          </Dropdown>
        </CardBody>
      </Card>
    </Draggable>
  );
};

GraphicUI.propTypes = {
  uiManagement: PropTypes.object.isRequired,
  refreshScene: PropTypes.func.isRequired
};

const mapStateToProps = (state) => {
  return {
    uiManagement: state.form.structure3D.inputs.ui
  };
};

export default connect(mapStateToProps)(memo(GraphicUI));
