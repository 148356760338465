const decomposeUnits = {
  m2: {
    name: 'm2',
    text: 'm²',
    unitSpec: [
      { unit: 'm', pow: 2 }
    ]
  },
  cm2: {
    name: 'cm2',
    text: 'cm²',
    unitSpec: [
      { unit: 'cm', pow: 2 }
    ]
  },
  mm2: {
    name: 'mm2',
    text: 'mm²',
    unitSpec: [
      { unit: 'mm', pow: 2 }
    ]
  },
  inch2: {
    name: 'inch2',
    text: 'inch²',
    unitSpec: [
      { unit: 'inch', pow: 2 }
    ]
  },
  ft2: {
    name: 'ft2',
    text: 'ft²',
    unitSpec: [
      { unit: 'ft', pow: 2 }
    ]
  },
  kPa: {
    name: 'kPa',
    text: 'kPa',
    unitSpec: [
      { unit: 'kN', pow: 1 },
      { unit: 'm', pow: -2 }
    ]
  },
  MPa: {
    name: 'MPa',
    text: 'MPa',
    unitSpec: [
      { unit: 'MN', pow: 1 },
      { unit: 'm', pow: -2 }
    ]
  },
  Pa: {
    name: 'Pa',
    text: 'Pa',
    unitSpec: [
      { unit: 'N', pow: 1 },
      { unit: 'm', pow: -2 }
    ]
  },
  psi: {
    name: 'psi',
    text: 'psi',
    unitSpec: [
      { unit: 'lbf', pow: 1 },
      { unit: 'inch', pow: -2 }
    ]
  },
  gOnM3: {
    name: 'gOnM3',
    text: 'g/m3',
    unitSpec: [
      { unit: 'g', pow: 1 },
      { unit: 'm', pow: 3 }
    ]
  },
  kgOnM3: {
    name: 'kgOnM3',
    text: 'kg/m3',
    unitSpec: [
      { unit: 'kg', pow: 1 },
      { unit: 'm', pow: 3 }
    ]
  },
  tOnM3: {
    name: 'tOnM3',
    text: 't/m3',
    unitSpec: [
      { unit: 'ton', pow: 1 },
      { unit: 'm', pow: 3 }
    ]
  },
  N: {
    name: 'N',
    text: 'N',
    unitSpec: [
      { unit: 'N', pow: 1 }
    ]
  },
  kN: {
    name: 'kN',
    text: 'kN',
    unitSpec: [
      { unit: 'kN', pow: 1 }
    ]
  },
  MN: {
    name: 'MN',
    text: 'MN',
    unitSpec: [
      { unit: 'MN', pow: 1 }
    ]
  },
  kip: {
    name: 'kip',
    text: 'kip',
    unitSpec: [
      { unit: 'kip', pow: 1 }
    ]
  },
  lbf: {
    name: 'lbf',
    text: 'lbf',
    unitSpec: [
      { unit: 'lbf', pow: 1 }
    ]
  },
  m: {
    name: 'm',
    text: 'm',
    unitSpec: [
      { unit: 'm', pow: 1 }
    ]
  },
  cm: {
    name: 'cm',
    text: 'cm',
    unitSpec: [
      { unit: 'cm', pow: 1 }
    ]
  },
  mm: {
    name: 'mm',
    text: 'mm',
    unitSpec: [
      { unit: 'mm', pow: 1 }
    ]
  },
  inch: {
    name: 'inch',
    text: 'inch',
    unitSpec: [
      { unit: 'inch', pow: 1 }
    ]
  },
  ft: {
    name: 'ft',
    text: 'ft',
    unitSpec: [
      { unit: 'ft', pow: 1 }
    ]
  },
  Nm: {
    name: 'Nm',
    text: 'N.m',
    unitSpec: [
      { unit: 'N', pow: 1 },
      { unit: 'm', pow: 1 }
    ]
  },
  kNm: {
    name: 'kNm',
    text: 'kN.m',
    unitSpec: [
      { unit: 'kN', pow: 1 },
      { unit: 'm', pow: 1 }
    ]
  },
  MNm: {
    name: 'MNm',
    text: 'MN.m',
    unitSpec: [
      { unit: 'MN', pow: 1 },
      { unit: 'm', pow: 1 }
    ]
  },
  MNcm: {
    name: 'MNcm',
    text: 'MN.cm',
    unitSpec: [
      { unit: 'MN', pow: 1 },
      { unit: 'cm', pow: 1 }
    ]
  },
  MNmm: {
    name: 'MNmm',
    text: 'MN.mm',
    unitSpec: [
      { unit: 'MN', pow: 1 },
      { unit: 'mm', pow: 1 }
    ]
  },
  kipinch: {
    name: 'kipinch',
    text: 'kip.inch',
    unitSpec: [
      { unit: 'kip', pow: 1 },
      { unit: 'inch', pow: 1 }
    ]
  },
  lbfinch: {
    name: 'lbfinch',
    text: 'lbf.inch',
    unitSpec: [
      { unit: 'lbf', pow: 1 },
      { unit: 'inch', pow: 1 }
    ]
  },
  s: {
    name: 's',
    text: 's',
    unitSpec: [
      { unit: 's', pow: 1 }
    ]
  },
  h: {
    name: 'h',
    text: 'h',
    unitSpec: [
      { unit: 'h', pow: 1 }
    ]
  },
  m3: {
    name: 'm3',
    text: 'm3',
    unitSpec: [
      { unit: 'm', pow: 3 }
    ]
  },
  cm3: {
    name: 'cm3',
    text: 'cm3',
    unitSpec: [
      { unit: 'cm', pow: 3 }
    ]
  },
  mm3: {
    name: 'mm3',
    text: 'mm3',
    unitSpec: [
      { unit: 'mm', pow: 3 }
    ]
  },
  inch3: {
    name: 'inch3',
    text: 'inch3',
    unitSpec: [
      { unit: 'inch', pow: 3 }
    ]
  },
  ft3: {
    name: 'ft3',
    text: 'ft3',
    unitSpec: [
      { unit: 'ft', pow: 3 }
    ]
  },
  m4: {
    name: 'm4',
    text: 'm4',
    unitSpec: [
      { unit: 'm', pow: 4 }
    ]
  },
  cm4: {
    name: 'cm4',
    text: 'cm4',
    unitSpec: [
      { unit: 'cm', pow: 4 }
    ]
  },
  mm4: {
    name: 'mm4',
    text: 'mm4',
    unitSpec: [
      { unit: 'mm', pow: 4 }
    ]
  },
  inch4: {
    name: 'inch4',
    text: 'inch4',
    unitSpec: [
      { unit: 'inch', pow: 4 }
    ]
  },
  ft4: {
    name: 'ft4',
    text: 'ft4',
    unitSpec: [
      { unit: 'ft', pow: 4 }
    ]
  }
};

export default decomposeUnits;
