import * as THREE from 'three';
import showLabel from '../ShowLabel';

const lengthForce = (valueForce) => {
  let result = 0;
  const absValue = Math.abs(valueForce);
  if (absValue === 0) {
    result = 0;
  } else {
    result = 1.3409 * Math.log(absValue);
    if (absValue < 10) {
      result = Math.max(1, 2.30258 * absValue / 10);
    }
  }
  if (valueForce < 0) {
    return -result;
  }
  return Number(result);
};

export default (name, {
  scene,
  labelMesh,
  nodeLoadMesh,
  uiManagement,
  data
}) => {
  const listnodes = data.nodes.value;
  const listnodeloads = data.nodeLoads.value;

  // Deleting all nodes mesh
  if (typeof nodeLoadMesh !== 'undefined' && nodeLoadMesh.length > 0) {
    for (let i = nodeLoadMesh.length - 1; i >= 0; i -= 1) {
      scene.remove(nodeLoadMesh[i]);
      // nodeLoadMesh[i].geometry.dispose();
      // nodeLoadMesh[i].material.dispose();
      nodeLoadMesh.pop();
    }
  }

  // const ratio = 1000;
  const color = 'red';

  const material = new THREE.LineBasicMaterial({
    color
  });

  // const dirFx = 0;
  // const dirFy = 0;
  // const dirFz = 0;
  // let dirMx = 0;
  // let dirMy = 0;
  // let dirMz = 0;
  // const originFx = 0;
  // const originFy = 0;
  // const originFz = 0;
  // let originMx = 0;
  // let originMy = 0;
  // let originMz = 0;
  let lengthFx = 0;
  let lengthFy = 0;
  let lengthFz = 0;
  let lengthMx = 0;
  let lengthMy = 0;
  let lengthMz = 0;

  const coneLength = 0.4;
  const coneWidth = 0.2;
  if (uiManagement.showNodeLoad) {
    for (let i = 0; i < listnodeloads.length; i += 1) {
      for (let j = 0; j < listnodes.length; j += 1) {
        if (listnodes[j].name === listnodeloads[i].node) {
          // forceX
          lengthFx = lengthForce(listnodeloads[i].forceX);
          if (lengthFx !== 0) {
            const toX = new THREE.Vector3(listnodes[j].X, listnodes[j].Y, listnodes[j].Z);
            const fromX = new THREE.Vector3(listnodes[j].X - lengthFx, listnodes[j].Y, listnodes[j].Z);
            const directionX = toX.clone().sub(fromX);
            const lengthX = directionX.length();
            const nodeLoadX = new THREE.ArrowHelper(directionX.normalize(), fromX, lengthX, color, coneLength, coneWidth);
            nodeLoadMesh.push(nodeLoadX);
            scene.add(nodeLoadX);

            if (uiManagement.showNodeLoad && lengthFx !== 0) {
              const labelData = {
                scene,
                mesh: labelMesh,
                type: 'nodeLoad',
                object: listnodeloads[i],
                text: `NL(FX=${listnodeloads[i].forceX})`,
                x: listnodes[j].X - lengthFx,
                y: listnodes[j].Y,
                z: listnodes[j].Z
              };
              showLabel(labelData);
            }
          }

          // forceY
          lengthFy = lengthForce(listnodeloads[i].forceY);
          if (lengthFy !== 0) {
            const toY = new THREE.Vector3(listnodes[j].X, listnodes[j].Y, listnodes[j].Z);
            const fromY = new THREE.Vector3(listnodes[j].X, listnodes[j].Y - lengthFy, listnodes[j].Z);
            const directionY = toY.clone().sub(fromY);
            const lengthY = directionY.length();
            const nodeLoadY = new THREE.ArrowHelper(directionY.normalize(), fromY, lengthY, color, coneLength, coneWidth);
            nodeLoadMesh.push(nodeLoadY);
            scene.add(nodeLoadY);

            if (uiManagement.showNodeLoad) {
              const labelData = {
                scene,
                mesh: labelMesh,
                type: 'nodeLoad',
                object: listnodeloads[i],
                text: `NL(FY=${listnodeloads[i].forceY})`,
                x: listnodes[j].X,
                y: listnodes[j].Y - lengthFy,
                z: listnodes[j].Z
              };
              showLabel(labelData);
            }
          }

          // forceZ
          lengthFz = lengthForce(listnodeloads[i].forceZ);
          if (lengthFz !== 0) {
            const toZ = new THREE.Vector3(listnodes[j].X, listnodes[j].Y, listnodes[j].Z);
            const fromZ = new THREE.Vector3(listnodes[j].X, listnodes[j].Y, listnodes[j].Z - lengthFz);
            const directionZ = toZ.clone().sub(fromZ);
            const lengthZ = directionZ.length();
            const nodeLoadZ = new THREE.ArrowHelper(directionZ.normalize(), fromZ, lengthZ, color, coneLength, coneWidth);
            nodeLoadMesh.push(nodeLoadZ);
            scene.add(nodeLoadZ);

            if (uiManagement.showNodeLoad) {
              const labelData = {
                scene,
                mesh: labelMesh,
                type: 'nodeLoad',
                object: listnodeloads[i],
                text: `NL(FZ=${listnodeloads[i].forceZ})`,
                x: listnodes[j].X,
                y: listnodes[j].Y,
                z: listnodes[j].Z - lengthFz
              };
              showLabel(labelData);
            }
          }

          // MomentX
          const R = 1;
          lengthMx = Number(listnodeloads[i].momentX);
          if (lengthMx !== 0 && typeof lengthMx !== 'undefined') {
            const geometryMX = new THREE.Geometry();
            for (let k = 0; k < 50; k += 1) {
              const teta = Math.PI * k / 49;
              geometryMX.vertices.push(
                new THREE.Vector3(listnodes[j].X, listnodes[j].Y + R * Math.cos(teta), listnodes[j].Z + R * Math.sin(teta))
              );
            }
            const nodeLoadMX = new THREE.Line(geometryMX, material);
            nodeLoadMesh.push(nodeLoadMX);
            scene.add(nodeLoadMX);

            if (uiManagement.showNodeLoad) {
              const labelData = {
                scene,
                mesh: labelMesh,
                type: 'nodeLoad',
                object: listnodeloads[i],
                text: `NL(MX=${listnodeloads[i].momentX})`,
                x: (listnodeloads[i].momentX > 0 ? listnodes[j].X : listnodes[j].X),
                y: (listnodeloads[i].momentX > 0 ? listnodes[j].Y - R : listnodes[j].Y + R),
                z: (listnodeloads[i].momentX > 0 ? listnodes[j].Z : listnodes[j].Z)
              };
              showLabel(labelData);
            }
          }

          // MomentY
          lengthMy = Number(listnodeloads[i].momentY);
          if (lengthMy !== 0 && typeof lengthMy !== 'undefined') {
            const geometryMY = new THREE.Geometry();
            for (let k = 0; k < 50; k += 1) {
              const teta = Math.PI * k / 49;
              geometryMY.vertices.push(
                new THREE.Vector3(listnodes[j].X + R * Math.cos(teta), listnodes[j].Y, listnodes[j].Z + R * Math.sin(teta))
              );
            }
            const nodeLoadMY = new THREE.Line(geometryMY, material);
            nodeLoadMesh.push(nodeLoadMY);
            scene.add(nodeLoadMY);

            if (uiManagement.showNodeLoad) {
              const labelData = {
                scene,
                mesh: labelMesh,
                type: 'nodeLoad',
                object: listnodeloads[i],
                text: `NL(MY=${listnodeloads[i].momentY})`,
                x: (listnodeloads[i].momentY > 0 ? listnodes[j].X + R : listnodes[j].X - R),
                y: (listnodeloads[i].momentY > 0 ? listnodes[j].Y : listnodes[j].Y),
                z: (listnodeloads[i].momentY > 0 ? listnodes[j].Z : listnodes[j].Z)
              };
              showLabel(labelData);
            }
          }

          // MomentZ
          lengthMz = Number(listnodeloads[i].momentZ);
          if (lengthMz !== 0 && typeof lengthMz !== 'undefined') {
            const geometryMZ = new THREE.Geometry();
            for (let k = 0; k < 50; k += 1) {
              const teta = Math.PI * k / 49;
              geometryMZ.vertices.push(
                new THREE.Vector3(listnodes[j].X + R * Math.cos(teta), listnodes[j].Y + R * Math.sin(teta), listnodes[j].Z)
              );
            }
            const nodeLoadMZ = new THREE.Line(geometryMZ, material);
            nodeLoadMesh.push(nodeLoadMZ);
            scene.add(nodeLoadMZ);

            if (uiManagement.showNodeLoad) {
              const labelData = {
                scene,
                mesh: labelMesh,
                type: 'nodeLoad',
                object: listnodeloads[i],
                text: `NL(MZ=${listnodeloads[i].momentZ})`,
                x: (listnodeloads[i].momentZ > 0 ? listnodes[j].X - R : listnodes[j].X + R),
                y: (listnodeloads[i].momentZ > 0 ? listnodes[j].Y : listnodes[j].Y),
                z: (listnodeloads[i].momentZ > 0 ? listnodes[j].Z : listnodes[j].Z)
              };
              showLabel(labelData);
            }
          }
        }
      }
    }
  }
  return {
    mesh: nodeLoadMesh
    // update
  };
};
