import React, { Component, useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  CardDeck,
  Card,
  CardBody,
  CardText,
  CardTitle,
  CardSubtitle,
  CardImg,
  Button,
  Container,
  Breadcrumb,
  BreadcrumbItem,
  Col,
  Row,
  Input
} from 'reactstrap';
import { Link, withRouter } from 'react-router-dom';
import Title from '../../../../components/UIComponents/Title';
import DatBimIconPng from './img/DatBimIcon.png';
import { LoadableDatBim } from '../../DatBim.loadable';
import CardApp from '../../../../components/UIComponents/Card/CardApp';

const API = ({
  categoriesList
}) => {
  console.log('CATEGORIES', categoriesList)
  return (
    <LoadableDatBim>
      <Title
        title="datBIM"
        imgsrc={DatBimIconPng}
        description="Select datBIM Category"
        documentation={{
          link:"https://www.datbim.com",
          text:"documentation"
        }}
      />
      <Breadcrumb>
        <BreadcrumbItem><Link to="/dashboard">Dashboard</Link></BreadcrumbItem>
        <BreadcrumbItem active>datBIM Categories</BreadcrumbItem>
      </Breadcrumb>
      <Col md={12} className="projects">
        <CardDeck>
          { categoriesList.map((app, index) => (
            <CardApp
              key={index}
              colnum={4}
              imgsrc={app.imgsrc}
              title={app.title}
              subtitle={app.category}
              description={app.description}
              link={app.link}
              tags={app.tags}
              href={app.href}
              status={app.status}
              logoCompany={app.logoCompany}
              company={app.company}
              video={app.video}
            />
          ))}
        </CardDeck>
      </Col> 
    </LoadableDatBim> 
  );
}

const mapStateToProps = (state) => {
  return {
    categoriesList: state.datBimApi.categories.categoriesList,
  };
};

export default connect(mapStateToProps)(API);


