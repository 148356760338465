import React from 'react';
import { Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import ConnectionBackground from '../../components/GraphicModules/Background/ConnectionBackground';
import FormSignUp from './components/FormSignUp';
import { compose } from 'recompose';
import { postSignUp } from './actions';

const SignUp = ({ isAuth, userSignUp }) => {
  return (
    <div className="authentication-background">
      <ConnectionBackground />
      <FormSignUp
        onSubmit={userSignUp}
      />
    </div>
  );
};

SignUp.propTypes = {
  userSignUp: PropTypes.func.isRequired
};

const mapStateToProps = (state) => {
  return {
    user: state.signin.user,
    isAuth: state.signin.isAuth,
    authMessage: state.signin.authMessage
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    userSignUp: (user) => { postSignUp(dispatch, user); }
  };
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps)
)(SignUp);
