import { CHANGE_LANGUAGE } from './actionTypes';
import initialState from './initialState';

const selectedLanguage = (state = initialState.selectedLanguage, action) => {
  switch (action.type) {
    case CHANGE_LANGUAGE:
      return action.payload;
    default:
      return state;
  }
};

export default selectedLanguage;
