const conversionUnits = {
  s: {
    s: 1,
    h: 0.000277778
  },
  h: {
    s: 3600,
    h: 1
  },
  m: {
    m: 1,
    cm: 100,
    mm: 1000,
    inch: 39.37007874,
    ft: 3.28084
  },
  cm: {
    m: 0.01,
    cm: 1,
    mm: 10,
    inch: 0.393701,
    ft: 0.0328084
  },
  mm: {
    m: 0.001,
    cm: 0.1,
    mm: 1,
    inch: 0.0393701,
    ft: 0.00328084
  },
  inch: {
    m: 0.0254,
    cm: 2.54,
    mm: 25.4,
    inch: 1,
    ft: 0.0833333
  },
  ft: {
    m: 0.3048,
    cm: 30.48,
    mm: 304.8,
    inch: 12,
    ft: 1
  },
  N: {
    N: 1,
    kN: 0.001,
    MN: 0.000001,
    lbf: 0.22482,
    kip: 0.00022482
  },
  kN: {
    N: 1000,
    kN: 1,
    MN: 0.001,
    lbf: 224.82,
    kip: 0.22482
  },
  MN: {
    N: 1000000,
    kN: 1000,
    MN: 1,
    lbf: 224820,
    kip: 224.82
  },
  lbf: {
    N: 4.4482216153,
    kN: 0.0044482216153,
    MN: 0.0000044482216153,
    lbf: 1,
    kip: 0.001
  },
  kip: {
    N: 44482.216153,
    kN: 4.4482216153,
    MN: 0.0044482216153,
    lbf: 1000,
    kip: 1
  },
  lb: {
    lb: 1,
    oz: 16,
    st: 0.071429,
    g: 453.59702440351987,
    kg: 0.4535970244035199,
    ton: 0.0004535970244035199
  },
  oz: {
    lb: 0.0625,
    oz: 1,
    st: 0.004464313,
    g: 28.34981403,
    kg: 0.028349814,
    ton: 0.000028349814
  },
  st: {
    lb: 14,
    oz: 224,
    st: 1,
    g: 6350.415952,
    kg: 6.350415952,
    ton: 0.006350415952
  },
  g: {
    lb: 0.0022046,
    oz: 0.035274,
    st: 0.00015747,
    g: 1,
    kg: 0.001,
    ton: 0.000001
  },
  kg: {
    lb: 2.2046,
    oz: 35.274,
    st: 0.15747,
    g: 1000,
    kg: 1,
    ton: 0.001
  },
  ton: {
    lb: 2204.6,
    oz: 35274,
    st: 157.47,
    g: 1000000,
    kg: 1000,
    ton: 1
  }
};

export default conversionUnits;
