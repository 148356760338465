import axios from 'axios';
import jwtDecode from 'jwt-decode';
import { toast } from 'react-toastify';
import {
  AUTHENTICATION_SUCCESS,
  AUTHENTICATION_FAILURE
} from './actionTypes';
import { URL } from '../../config/config';
import URL_PATH from '../../config/default';
import history from '../../history';

const postSignIn = async (dispatch, user) => {
  try {
    const res = await axios.post(`${URL}${URL_PATH.authentication.login}`, user);
    const { token } = res.data;
    localStorage.setItem('token', token);
    localStorage.setItem('user', JSON.stringify(jwtDecode(token)));

    history.push('/dashboard');
    dispatch({
      type: AUTHENTICATION_SUCCESS,
      isAuth: true
    });
  } catch (err) {
    let msgErr = '😞 No access to the server';
    if (err.response) {
      msgErr = err.response.data.msg;
    }
    toast.error(msgErr, {
      autoClose: 1500
    });
    dispatch({
      type: AUTHENTICATION_FAILURE,
      isAuth: false
    });
  }
};

export {
  postSignIn
};
