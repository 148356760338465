const initialState = {
  boxSection: {
    values: {
      options: {
        sectionname: {
          name: 'sectionname',
          text: 'Name',
          description: 'Project Name',
          value: 'Section Name',
          unit: '_'
        },
        unit: {
          name: 'unit',
          text: 'Units',
          description: 'Unit system',
          value: { label: 'm-MN-kg', value: 'mMNkg' },
          unit: '_'
        }
      },
      geometry: {
        steel: {
          unit: { name: 'm', text: 'm' },
          L: {
            name: 'L',
            text: 'L',
            value: 10,
            description: 'Length of the cross section',
            unit: 'm'
          },
          b: {
            name: 'b',
            text: 'b',
            value: 1,
            description: 'Width of the cross section',
            unit: 'm'
          },
          h: {
            name: 'h',
            text: 'h',
            value: 1,
            description: 'Depth of the cross section',
            unit: 'm'
          },
          d: {
            name: 'd',
            text: 'd',
            value: 0.3,
            description: 'Depth of Sraight portion of a Web',
            unit: 'm'
          },
          tw: {
            name: 'tw',
            text: 'tw',
            value: 0.05,
            description: 'Web Thickness',
            unit: 'm'
          },
          tf: {
            name: 'tf',
            text: 'tf',
            value: 0.05,
            description: 'Flange Thickness',
            unit: 'm'
          },
          c: {
            name: 'c',
            text: 'c',
            value: 0.01,
            description: 'Corrosion of the section',
            unit: 'm'
          },
          RolledOrWelded: {
            name: 'RolledOrWelded',
            text: 'RolledOrWelded',
            description: '',
            value: 'Rolled',
            list: [
              { value: 'Rolled', label: 'Rolled' },
              { value: 'Welded', label: 'Welded' }
            ],
            unit: '_'
          },
          sectionext: [
            { x: 0, y: 0 }
          ],
          sectionint: [
            { x: 0, y: 0 }
          ]
        }
      },
      steel: {
        fyk: {
          name: 'fyk',
          text: 'fyk',
          description: 'Yield Strength',
          value: 100,
          unit: { name: 'MPa', text: 'MPa' }
        },
        gammaM0: {
          name: 'gammaM0',
          text: 'ϒM0',
          description: 'Partial Factor for Resistance of cross-sections',
          value: 1.0,
          unit: '_'
        },
        gammaM1: {
          name: 'gammaM1',
          text: 'ϒM1',
          description: 'Partial Factor for Resistance of Members to Instability assessed by member checks',
          value: 1.1,
          unit: '_'
        },
        G: {
          name: 'G',
          text: 'G',
          description: 'Shear Modulus',
          value: 80770,
          unit: { name: 'MPa', text: 'MPa' }
        },
        Es: {
          name: 'Es',
          text: 'Es',
          description: 'Steel Young Modulus',
          value: 210000,
          unit: { name: 'MPa', text: 'MPa' }
        }
      },
      loads: {
        Ned: {
          name: 'Ned',
          text: 'N,ed',
          description: 'Design Value of the Axial Force',
          value: 500,
          unit: { name: 'kN', text: 'kN' }
        },
        Mxed: {
          name: 'Mxed',
          text: 'Mx,ed',
          description: 'Design Bending Moment around x axis',
          value: 3000,
          unit: { name: 'kNm', text: 'kN.m' }
        },
        Myed: {
          name: 'Myed',
          text: 'My,ed',
          description: 'Design Bending Moment around y axis',
          value: 0,
          unit: { name: 'kNm', text: 'kN.m' }
        },
        Ved: {
          name: 'Ved',
          text: 'V,ed',
          description: 'Total Design Vertical Load on the Frame transferred by the Storey (storey thrust)',
          value: 500,
          unit: { name: 'kN', text: 'kN' }
        },
        Ted: {
          name: 'Ted',
          text: 'T,ed',
          description: 'Design Value ot Total Torsional Moments',
          value: 750,
          unit: { name: 'kNm', text: 'kN.m' }
        },
        MomentDiagramX: {
          name: 'MomentDiagramX',
          text: 'MomentDiagramX',
          description: 'Bending Moment about x axis',
          value: 'Moment Diagram 1',
          list: [
            { value: 'Moment Diagram 1', label: 'Moment Diagram 1' },
            { value: 'Moment Diagram 2', label: 'Moment Diagram 2' },
            { value: 'Moment Diagram 3', label: 'Moment Diagram 3' },
            { value: 'Moment Diagram 4', label: 'Moment Diagram 4' }
          ],
          unit: '_'
        },
        MomentDiagramY: {
          name: 'MomentDiagramY',
          text: 'MomentDiagramY',
          description: 'Bending Moment about y axis',
          value: 'Moment Diagram 1',
          list: [
            { value: 'Moment Diagram 1', label: 'Moment Diagram 1' },
            { value: 'Moment Diagram 2', label: 'Moment Diagram 2' },
            { value: 'Moment Diagram 3', label: 'Moment Diagram 3' },
            { value: 'Moment Diagram 4', label: 'Moment Diagram 4' }
          ],
          unit: '_'
        },
        psiy: {
          name: 'psiy',
          text: 'psi,y',
          description: '',
          value: 0,
          unit: '_'
        },
        psix: {
          name: 'psix',
          text: 'psi,x',
          description: '',
          value: 0,
          unit: '_'
        },
        deltax: {
          name: 'deltax',
          text: 'delta,x',
          description: '',
          value: 0,
          unit: '_'
        },
        deltay: {
          name: 'deltay',
          text: 'delta,y',
          description: '',
          value: 0,
          unit: '_'
        }
      }
    }
  },
  iSectionSteel: {
    values: {
      options: {
        sectionname: {
          name: 'sectionname',
          text: 'Name',
          description: 'Project Name',
          value: 'Section Name',
          unit: '_'
        },
        unit: {
          name: 'unit',
          text: 'Units',
          description: 'Unit system',
          value: { label: 'm-MN-kg', value: 'mMNkg' },
          unit: '_'
        }
      },
      geometry: {
        steel: {
          unit: { name: 'm', text: 'm' },
          L: {
            name: 'L',
            text: 'L',
            value: 10,
            description: 'Length of the cross section',
            unit: 'm'
          },
          b: {
            name: 'b',
            text: 'b',
            value: 1,
            description: 'Width of the cross section',
            unit: 'm'
          },
          h: {
            name: 'h',
            text: 'h',
            value: 1,
            description: 'Depth of the cross section',
            unit: 'm'
          },
          tw: {
            name: 'tw',
            text: 'tw',
            value: 0.05,
            description: 'Web Thickness',
            unit: 'm'
          },
          tf: {
            name: 'tf',
            text: 'tf',
            value: 0.05,
            description: 'Flange Thickness',
            unit: 'm'
          },
          c: {
            name: 'c',
            text: 'c',
            value: 0.01,
            description: 'Corrosion of the section',
            unit: 'm'
          },
          RolledOrWelded: {
            name: 'RolledOrWelded',
            text: 'RolledOrWelded',
            description: '',
            value: 'R',
            list: [
              { value: 'R', label: 'Rolled' },
              { value: 'W', label: 'Welded' }
            ],
            unit: '_'
          },
          sectionext: [
            { x: 0, y: 0 }
          ],
          sectionint: [
            { x: 0, y: 0 }
          ]
        }
      },
      steel: {
        fyk: {
          name: 'fyk',
          text: 'fyk',
          description: 'Yield Strength',
          value: 100,
          unit: { name: 'MPa', text: 'MPa' }
        },
        gammaM0: {
          name: 'gammaM0',
          text: 'ϒM0',
          description: 'Partial Factor for Resistance of cross-sections',
          value: 1.0,
          unit: '_'
        },
        gammaM1: {
          name: 'gammaM1',
          text: 'ϒM1',
          description: 'Partial Factor for Resistance of Members to Instability assessed by member checks',
          value: 1.1,
          unit: '_'
        },
        G: {
          name: 'G',
          text: 'G',
          description: 'Shear Modulus',
          value: 80770,
          unit: { name: 'MPa', text: 'MPa' }
        },
        Es: {
          name: 'Es',
          text: 'Es',
          description: 'Steel Young Modulus',
          value: 210000,
          unit: { name: 'MPa', text: 'MPa' }
        }
      },
      loads: {
        Ned: {
          name: 'Ned',
          text: 'N,ed',
          description: 'Design Value of the Axial Force',
          value: 500,
          unit: { name: 'kN', text: 'kN' }
        },
        Mxed: {
          name: 'Mxed',
          text: 'Mx,ed',
          description: 'Design Bending Moment around x axis',
          value: 3000,
          unit: { name: 'kNm', text: 'kN.m' }
        },
        Myed: {
          name: 'Myed',
          text: 'My,ed',
          description: 'Design Bending Moment around y axis',
          value: 0,
          unit: { name: 'kNm', text: 'kN.m' }
        },
        Ved: {
          name: 'Ved',
          text: 'V,ed',
          description: 'Total Design Vertical Load on the Frame transferred by the Storey (storey thrust)',
          value: 500,
          unit: { name: 'kN', text: 'kN' }
        },
        Ted: {
          name: 'Ted',
          text: 'T,ed',
          description: 'Design Value ot Total Torsional Moments',
          value: 750,
          unit: { name: 'kNm', text: 'kN.m' }
        },
        MomentDiagramX: {
          name: 'MomentDiagramX',
          text: 'MomentDiagramX',
          description: 'Bending Moment about x axis',
          value: 1,
          list: [
            { value: 1, label: 'Moment Diagram 1' },
            { value: 2, label: 'Moment Diagram 2' },
            { value: 3, label: 'Moment Diagram 3' },
            { value: 4, label: 'Moment Diagram 4' }
          ],
          unit: '_'
        },
        MomentDiagramY: {
          name: 'MomentDiagramY',
          text: 'MomentDiagramY',
          description: 'Bending Moment about y axis',
          value: 1,
          list: [
            { value: 1, label: 'Moment Diagram 1' },
            { value: 2, label: 'Moment Diagram 2' },
            { value: 3, label: 'Moment Diagram 3' },
            { value: 4, label: 'Moment Diagram 4' }
          ],
          unit: '_'
        },
        psiy: {
          name: 'psiy',
          text: 'psi,y',
          description: '',
          value: 0,
          unit: '_'
        },
        psix: {
          name: 'psix',
          text: 'psi,x',
          description: '',
          value: 0,
          unit: '_'
        },
        deltax: {
          name: 'deltax',
          text: 'delta,x',
          description: '',
          value: 0,
          unit: '_'
        },
        deltay: {
          name: 'deltay',
          text: 'delta,y',
          description: '',
          value: 0,
          unit: '_'
        }
      }
    }
  },
  outputs: {
    sectionData: {
      xg: {
        name: 'xg',
        text: 'xg',
        value: 1,
        description: 'Section center of gravity x coordinate',
        unit: 'm'
      },
      yg: {
        name: 'yg',
        text: 'yg',
        value: 1.5,
        description: 'Section center of gravity y coordinate',
        unit: 'm'
      },
      A: {
        name: 'A',
        text: 'A',
        value: 0,
        description: 'Section Area',
        unit: 'm2'
      },
      Av: {
        name: 'Av',
        text: 'Av',
        value: 0,
        description: 'Shear Area',
        unit: 'm2'
      },
      Iy: {
        name: 'Iy',
        text: 'Iy',
        value: 0,
        description: 'Second Moment of Area about y axis',
        unit: 'm4'
      },
      Ix: {
        name: 'Ix',
        text: 'Ix',
        value: 0,
        description: 'Second Moment of Area about x axis',
        unit: 'm4'
      },
      It: {
        name: 'It',
        text: 'It',
        value: 0,
        description: 'Torsional Constant',
        unit: 'm4'
      },
      Iw: {
        name: 'Iw',
        text: 'Iw',
        value: 0,
        description: 'Warping Constant',
        unit: 'm6'
      },
      Wely: {
        name: 'Wely',
        text: 'Wely',
        value: 0,
        description: 'Section Modulus for Class 3 Cross-sections about y axis',
        unit: 'm3'
      },
      Welx: {
        name: 'Welx',
        text: 'Welx',
        value: 0,
        description: 'Section Modulus for Class 3 Cross-sections about x axis',
        unit: 'm3'
      },
      Wply: {
        name: 'Wply',
        text: 'Wply',
        value: 0,
        description: 'Section Modulus for Class 1 or 2 Cross-sections about y axis',
        unit: 'm3'
      },
      Wplx: {
        name: 'Wplx',
        text: 'Wplx',
        value: 0,
        description: 'Section Modulus for Class 1 or 2 Cross-sections about x axis',
        unit: 'm3'
      }
    },
    bending: {
      CL: {
        name: 'CL',
        text: 'Classe',
        value: 0,
        description: 'Classe',
        unit: 'NA'
      },
      NplRd: {
        name: 'NplRd',
        text: 'Npl,Rd',
        value: 0,
        description: 'Design plastic resistance to normal forces of the gross cross-section',
        unit: 'kN'
      },
      MelxRd: {
        name: 'MelxRd',
        text: 'Melx,Rd',
        value: 0,
        description: 'Characteristic value of resistance to bending moments about x axis',
        unit: 'kNm'
      },
      MelyRd: {
        name: 'MelyRd',
        text: 'Mely,Rd',
        value: 0,
        description: 'Characteristic value of resistance to bending moments about y axis',
        unit: 'kNm'
      },
      MplxRd: {
        name: 'MplxRd',
        text: 'Mplx,Rd',
        value: 0,
        description: 'Characteristic value of resistance to bending moments about x axis',
        unit: 'kNm'
      },
      MplyRd: {
        name: 'MplyRd',
        text: 'Mply,Rd',
        value: 0,
        description: 'Characteristic value of resistance to bending moments about y axis',
        unit: 'kNm'
      },
      khix: {
        name: 'khix',
        text: 'χx',
        value: 0,
        description: 'Reduction factor due to flexural buckling (x axis)',
        unit: 'NA'
      },
      khiy: {
        name: 'khiy',
        text: 'χy',
        value: 0,
        description: 'Reduction factor due to flexural buckling (y axis)',
        unit: 'NA'
      },
      khiLT: {
        name: 'khiLT',
        text: 'χLT',
        value: 0,
        description: 'Reduction factor for lateral-torsional buckling',
        unit: 'NA'
      },
      kxx: {
        name: 'kxx',
        text: 'kxx',
        value: 0,
        description: 'Interaction factor',
        unit: 'NA'
      },
      kyy: {
        name: 'kyy',
        text: 'kyy',
        value: 0,
        description: 'Interaction factor',
        unit: 'NA'
      },
      kxy: {
        name: 'kxy',
        text: 'kxy',
        value: 0,
        description: 'Interaction factor',
        unit: 'NA'
      },
      kyx: {
        name: 'kyx',
        text: 'kyx',
        value: 0,
        description: 'Interaction factor',
        unit: 'NA'
      },
      Condition1: {
        name: 'Condition1',
        text: 'C1',
        value: 0,
        description: 'Condition 1',
        unit: 'NA'
      },
      Condition2: {
        name: 'Condition2',
        text: 'C2',
        value: 0,
        description: 'Condition 2',
        unit: 'NA'
      },
      Condition3: {
        name: 'Condition3',
        text: 'C3',
        value: 0,
        description: 'Condition 3',
        unit: 'NA'
      },
      SF: {
        name: 'SF',
        text: 'SF',
        value: 0,
        description: 'Safety factor',
        unit: 'NA'
      },
      Condition1p: {
        name: 'Condition1p',
        text: 'C1',
        value: 0,
        description: 'Condition 1',
        unit: 'NA'
      },
      Condition2p: {
        name: 'Condition2p',
        text: 'C2',
        value: 0,
        description: 'Condition 2',
        unit: 'NA'
      },
      Condition3p: {
        name: 'Condition3p',
        text: 'C3',
        value: 0,
        description: 'Condition 3',
        unit: 'NA'
      },
      SFp: {
        name: 'SFp',
        text: 'SF',
        value: 0,
        description: 'Safety factor',
        unit: 'NA'
      }
    },
    shear: {
      VplRd: {
        name: 'VplRd',
        text: 'Vpl,Rd',
        value: 0,
        description: 'Design plastic shear resistance',
        unit: 'kN'
      },
      VedVplRd: {
        name: 'VedVplRd',
        text: 'Ved/Vpl,Rd',
        value: 0,
        description: 'Ved/Vpl,Rd',
        unit: 'NA'
      },
      UnMoinsRho: {
        name: 'UnMoinsRho',
        text: '1-ρ',
        value: 0,
        description: 'ρ : reduction  factor  to  determine  reduced  design  values  of  the  resistance  to  bending  moments  making allowance for the presence of shear forces',
        unit: 'NA'
      }
    },

    torsion: {
      Tt: {
        name: 'Tt',
        text: 'Tt',
        value: 0,
        description: 'Design value of internal St. Venant torsional moment',
        unit: 'kNm'
      },
      Am: {
        name: 'Am',
        text: 'Am',
        value: 0,
        description: '',
        unit: 'm2'
      },
      thotweb: {
        name: 'thotweb',
        text: 'τt,web',
        value: 0,
        description: 'Design shear stresses due to St. Venant torsion',
        unit: 'MPa'
      },
      Tw: {
        name: 'Tw',
        text: 'Tw',
        value: 0,
        description: 'Design value of internal warping torsional moment',
        unit: 'kNm'
      },
      Sw: {
        name: 'Sw',
        text: 'Sw',
        value: 0,
        description: '',
        unit: 'm4'
      },
      w: {
        name: 'w',
        text: 'w',
        value: 0,
        description: '',
        unit: 'm2'
      },
      sigmaw: {
        name: 'sigmaw',
        text: 'σw',
        value: 0,
        description: 'Design direct stresses due to the bimoment BEd',
        unit: 'MPa'
      },
      thowweb: {
        name: 'thowweb',
        text: 'τw,web',
        value: 0,
        description: 'Design shear stresses due to warping torsion',
        unit: 'MPa'
      },
      Aw: {
        name: 'Aw',
        text: 'Aw',
        value: 0,
        description: 'Area of a web',
        unit: 'm2'
      },
      thoshearweb: {
        name: 'thoshearweb',
        text: 'τshear,web',
        value: 0,
        description: '',
        unit: 'MPa'
      },
      thoEdweb: {
        name: 'thoEdweb',
        text: 'τEd,web',
        value: 0,
        description: 'Design value of the local shear stress',
        unit: 'MPa'
      },
      SFt: {
        name: 'SFt',
        text: 'SF',
        value: 0,
        description: 'Safety factor',
        unit: 'NA'
      }
    }
  }
};

export default initialState;
