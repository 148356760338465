export const ADD_ACTIVE = 'ADD_ACTIVE';
export const ADD_FIELDS = 'ADD_FIELDS';
export const EDIT_FIELDS = 'EDIT_FIELDS';
export const S3_CHANGE_SECTION_GEOMETRY = 'S3_CHANGE_SECTION_GEOMETRY';
export const S3_FETCH_SECTION_DATA = 'S3_FETCH_SECTION_DATA';
export const S3_SECTION_DATA_CALCULATION = 'S3_SECTION_DATA_CALCULATION';
export const S3_OUTPUTS = 'S3_OUTPUTS';
export const S3_CHANGE_NAME = 'S3_CHANGE_NAME';
export const S3_CHANGE_LIST_SELECTION = 'S3_CHANGE_LIST_SELECTION';
export const S3_UI_MANAGEMENT = 'S3_UI_MANAGEMENT';
export const S3_ACTIVE_MEMBER = 'S3_ACTIVE_MEMBER';
export const S3_REMOVE_FIELDS = 'S3_REMOVE_FIELDS';
export const S3_GET_FIELDS = 'S3_GET_FIELDS';
export const S3_LOAD_PROJECT = 'S3_LOAD_PROJECT';
export const S3_NEW_PROJECT = 'S3_NEW_PROJECT';
export const S3_IMPORT_FROM_CSV = 'S3_IMPORT_FROM_CSV';
export const S3_FETCH_LOADING = 'S3_FETCH_LOADING';
export const S3_SCENE_REFRESH = 'S3_SCENE_REFRESH';
export const S3_UI_MANAGEMENT_RESET = 'S3_UI_MANAGEMENT_RESET';
export const OUTPUTS_LINKS = 'OUTPUTS_LINKS';
export const ACTIVE_OBJECT = 'ACTIVE_OBJECT';
