import React from 'react';
import PropTypes from 'prop-types';
import InputsUnit from '../../../../../../../../../../components/UIComponents/Fields/InputsUnit2';
import optionsLengthUnits from '../../../../../../../../../../utils/units/optionsLengthUnits2';

const BoxSection = ({
  sectionType,
  geometry,
  changeGeometry
}) => {
  return (
    <div>
      <InputsUnit
        label={geometry.steel.L.text}
        inputName="geometry.steel.L.value"
        selectList={optionsLengthUnits}
        selectName="geometry.steel.L.unit"
        tooltipDescription={geometry.steel.L.description}
        tooltipTarget={geometry.steel.L.name}
        onChange={(e) => {
          const val = e.target.value;
          changeGeometry(geometry.steel, sectionType, val, geometry.steel.L.name);
        }}
      />
      <InputsUnit
        label={geometry.steel.b.text}
        inputName="geometry.steel.b.value"
        selectList={optionsLengthUnits}
        selectName="geometry.steel.b.unit"
        tooltipDescription={geometry.steel.b.description}
        tooltipTarget={geometry.steel.b.name}
        onChange={(e) => {
          const val = e.target.value;
          changeGeometry(geometry.steel, sectionType, val, geometry.steel.b.name);
        }}
      />
      <InputsUnit
        label={geometry.steel.d.text}
        inputName="geometry.steel.d.value"
        selectList={optionsLengthUnits}
        selectName="geometry.steel.d.unit"
        tooltipDescription={geometry.steel.d.description}
        tooltipTarget={geometry.steel.d.name}
        onChange={(e) => {
          const val = e.target.value;
          changeGeometry(geometry.steel, sectionType, val, geometry.steel.d.name);
        }}
      />
      <InputsUnit
        label={geometry.steel.h.text}
        inputName="geometry.steel.h.value"
        selectList={optionsLengthUnits}
        selectName="geometry.steel.h.unit"
        tooltipDescription={geometry.steel.h.description}
        tooltipTarget={geometry.steel.h.name}
        onChange={(e) => {
          const val = e.target.value;
          changeGeometry(geometry.steel, sectionType, val, geometry.steel.h.name);
        }}
      />
      <InputsUnit
        label={geometry.steel.tw.text}
        inputName="geometry.steel.tw.value"
        selectList={optionsLengthUnits}
        selectName="geometry.steel.tw.unit"
        tooltipDescription={geometry.steel.tw.description}
        tooltipTarget={geometry.steel.tw.name}
        onChange={(e) => {
          const val = e.target.value;
          changeGeometry(geometry.steel, sectionType, val, geometry.steel.tw.name);
        }}
      />
      <InputsUnit
        label={geometry.steel.tf.text}
        inputName="geometry.steel.tf.value"
        selectList={optionsLengthUnits}
        selectName="geometry.steel.tf.unit"
        tooltipDescription={geometry.steel.tf.description}
        tooltipTarget={geometry.steel.tf.name}
        onChange={(e) => {
          const val = e.target.value;
          changeGeometry(geometry.steel, sectionType, val, geometry.steel.tf.name);
        }}
      />
      <InputsUnit
        label={geometry.steel.c.text}
        inputName="geometry.steel.c.value"
        selectList={optionsLengthUnits}
        selectName="geometry.steel.c.unit"
        tooltipDescription={geometry.steel.c.description}
        tooltipTarget={geometry.steel.c.name}
        onChange={(e) => {
          const val = e.target.value;
          changeGeometry(geometry.steel, sectionType, val, geometry.steel.c.name);
        }}
      />
    </div>
  );
};

export default BoxSection;
